import axios from "axios";

export const fetchEmbeddedReports = async (companyKey) => {
    const response = await axios.get(`api/embeddedreports/?companyKey=${companyKey}`);
    const embeddedReportsData = response.data;
    return embeddedReportsData;
}

export const updateEmbeddedReport = async (embeddedReport) => {
    try {
      const response = await axios.put(`/api/embeddedReports/`, embeddedReport);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  };

export const createEmbeddedReport = async (embeddedReport) => {
    try {
      const response = await axios.post(`/api/embeddedReports/`, embeddedReport);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }

export const deleteEmbeddedReport = async (id) => {
    try {
      const response = await axios.delete(`/api/embeddedReports/${id}`);
      return response;
    } catch (error) {
      throw error.response.data;
    }
  }