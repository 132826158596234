import React from 'react'
import { FormControl, FormControlLabel, Checkbox as MuiCheckbox, useTheme } from "@mui/material";
import { tokens } from "../../assets/theme";


export default function Checkbox(props) {

    const { id, name, label, value, onChange } = props;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


    const convertToDefEventPara = (name, value) => ({
        target: {
            id, name, value, type: "checkbox", checked: value
        }
    })

    return (
        <FormControl>
            <FormControlLabel
                control={<MuiCheckbox
                    id={id}
                    name={name}
                    sx={{
                        color: colors.primary[100],
                        '&.Mui-checked': {
                          color: colors.dashbordGreen[600],
                        },
                      }}
                    checked={value}
                    onChange={e => onChange(convertToDefEventPara(name, e.target.checked))}
                />}
                label={label}
            />
        </FormControl>
    )
}