/*
 * Home Messages
 *
 * This contains all the text for the Home Feature.
 */
import { defineMessages } from 'react-intl';

export const scope = 'axionoee.features.home.index';

export default defineMessages({
  pagesLabel: {
    id: `${scope}.sidebar.label.pages`,
    defaultMessage: 'Pages',
  },
  homeMenuItem: {
    id: `${scope}.sidebar.home`,
    defaultMessage: 'Home',
  },
  ganttMenuItem: {
    id: `${scope}.sidebar.gantt`,
    defaultMessage: 'Gantt',
  },
  machinesMenuItem: {
    id: `${scope}.sidebar.machines`,
    defaultMessage: 'Machines',
  },
  machineTypesMenuItem: {
    id: `${scope}.sidebar.machineTypes`,
    defaultMessage: 'Machine types',
  },
  departmentsMenuItem: {
    id: `${scope}.sidebar.departments`,
    defaultMessage: 'Departments',
  },
  productionPlanningMenuItem: {
    id: `${scope}.sidebar.productionPlanning`,
    defaultMessage: 'Production Planning',
  },
  activitiesMenuItem: {
    id: `${scope}.sidebar.activities`,
    defaultMessage: 'Activities',
  },
  materialMenuItem: {
    id: `${scope}.sidebar.material`,
    defaultMessage: 'Material',
  },
  statisticsLabel: {
    id: `${scope}.sidebar.label.statistics`,
    defaultMessage: 'Statistics',
  },
  oeeStatisticsMenuItem: {
    id: `${scope}.sidebar.oeeStatistics`,
    defaultMessage: 'OEE statistics',
  },
  settingsLabel: {
    id: `${scope}.sidebar.label.settings`,
    defaultMessage: 'Settings',
  },
  usersMenuItem: {
    id: `${scope}.sidebar.users`,
    defaultMessage: 'Users',
  },
  systemSettingsMenuItem: {
    id: `${scope}.sidebar.systemSettings`,
    defaultMessage: 'System Settings',
  },
  stopReasonCodesMenuItem: {
    id: `${scope}.sidebar.stopReasonCodes`,
    defaultMessage: 'Stop Reason Codes',
  },
  autoCodeMenuItem: {
    id: `${scope}.sidebar.autoCode`,
    defaultMessage: 'Auto Code',
  },
  rolesMenuItem: {
    id: `${scope}.sidebar.roles`,
    defaultMessage: 'Roles',
  },
  takColumn: {
    id: `${scope}.grid.column.tak`,
    defaultMessage: 'TAK %',
  },
  idealCycleTimeActiveColumn: {
    id: `${scope}.grid.column.idealCycleTimeActive`,
    defaultMessage: 'Ideal. CT.',
  },
  lastCycleTimeColumn: {
    id: `${scope}.grid.column.lastCycleTime`,
    defaultMessage: 'Last CT.',
  },
  avgCycleTimeColumn: {
    id: `${scope}.grid.column.avgCycleTime`,
    defaultMessage: 'Avg CT.',
  },
  avgIdealCycleTimeColumn: {
    id: `${scope}.grid.column.avgIdealCycleTime`,
    defaultMessage: 'Avg I.CT.',
  },
  prioritySortOrderColumn: {
    id: `${scope}.grid.column.prioritySortOrder`,
    defaultMessage: 'PrioritySortOrder',
  },
  uptimeColumn: {
    id: `${scope}.grid.column.uptime`,
    defaultMessage: 'Uptime',
  },
  downTimeColumn: {
    id: `${scope}.grid.column.downTime`,
    defaultMessage: 'Downtime',
  },
  scrappedQtyColumn: {
    id: `${scope}.grid.column.scrappedQty`,
    defaultMessage: 'Scrapped Qty',
  },
  numberOfCyclesWithCavityColumn: {
    id: `${scope}.grid.column.numberOfCyclesWithCavity`,
    defaultMessage: 'No Of Cycles',
  },
  //FILTERS
  onlyRunningFilter: {
    id: `${scope}.grid.filters.onlyRunning`,
    defaultMessage: 'Only Running',
  },
  dontShowPlannedStopsFilter: {
    id: `${scope}.grid.filters.dontShowPlannedStops`,
    defaultMessage: "Don't show planned stops",
  },
  onlyStoppedFilter: {
    id: `${scope}.grid.filters.onlyStopped`,
    defaultMessage: 'Only stopped',
  },
  currentShiftFilter: {
    id: `${scope}.grid.filters.currentShift`,
    defaultMessage: 'Current Shift',
  },
  prevShiftFilter: {
    id: `${scope}.grid.filters.prevShift`,
    defaultMessage: 'Prev.Shift',
  },
  dayFilter: {
    id: `${scope}.grid.filters.24h`,
    defaultMessage: '24h',
  },
  threeDayFilter: {
    id: `${scope}.grid.filters.72h`,
    defaultMessage: '72h',
  },
  manualFilter: {
    id: `${scope}.grid.filters.manual`,
    defaultMessage: 'Manual',
  },
  reportsLabel: {
    id: `${scope}.sidebar.reports`,
    defaultMessage: 'Reports',
  },
  commingSoon: {
    id: `${scope}.label.commingSoon`,
    defaultMessage: 'Comming soon...',
  },
  editEmbeddedReportsMenuItem: {
    id: `${scope}.sidebar.editEmbeddedReports`,
    defaultMessage: 'Edit embedded reports',
  },
  toolsMenuItem: {
    id: `${scope}.sidebar.tools`,
    defaultMessage: 'Tools',
  },
  productionItemsMenuItem: {
    id: `${scope}.sidebar.productionItems`,
    defaultMessage: 'Production items',
  },
  signout: {
    id: `${scope}.sidebar.signout`,
    defaultMessage: 'Sign out',
  },
});
