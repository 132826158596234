import { Box,Stack} from "@mui/material";
//import { ResponsiveBar } from '@nivo/bar';
//import { linearGradientDef } from '@nivo/core'
import image from "../images/RedYellowGreen_gradient.png";
import dot from "../images/dot_black.gif";


const PercentageBar = (props) => {

  let value = props.percentage;
  if(value > 100) value = 100;
  let antiValue = 100 - value;

let content = <Stack direction="row" width="100%"
    height="30px" sx={{ backgroundImage: `url(${image})`, backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%"}}>
    <Box width={value + "%"}></Box>
    <Box width={antiValue + "%"}><img src={dot} alt="" width="100%" height="30px" /></Box>
  </Stack>

  return content;
}

// const PercentageBar2 = (props) => {

//   const barWidth = "120px";

//   let value = props.percentage;
//   if(value > 100) value = 100;
//   let antiValue = 100 - value;
  
//   let data = [{ "index": "indexValue", "value": value, "valueColor": "white", "antiValue": antiValue, "antiValueColor": "black" }];

//   let content =
//     <Box alignItems="center"
//           justifyContent="center"
//           display="flex" 
//           width={barWidth}
//           height="30px" sx={{ backgroundImage: `url(${image})`, backgroundRepeat: "no-repeat",
//           backgroundSize: "100% 100%"}}
//         >
//       <ResponsiveBar
//         data={data}
//         keys={[
//           'value',
//           'antiValue'
//         ]}
//         margin={{ top: -35, right: 0, bottom: -35, left: 0 }}
//         maxValue={100}
//         layout="horizontal"
//         valueScale={{ type: 'linear' }}
//         indexScale={{ type: 'band', round: true }}
//         colors={{ scheme: 'nivo' }}
//         defs={[
//           linearGradientDef('opac', [
//             { offset: 0, color: '#ffffff', opacity: 0.0 },
//             { offset: 100, color: '#ffffff', opacity: 0.0 },
//           ],
//             {
//               gradientTransform: 'rotate(-90 0.5 0.5)'
//             }),
//           linearGradientDef('blackGradient', [
//             { offset: 0, color: '#333333' },
//             { offset: 100, color: '#000000' },
//           ],
//             {
//               gradientTransform: 'rotate(45 0.5 0.5)'
//             }),
//         ]}
//         fill={[
//           { match: { id: 'value' }, id: 'opac' },
//           { match: { id: 'antiValue' }, id: 'blackGradient' },
//         ]}
//         axisTop={null}
//         axisRight={null}
//         axisBottom={null}
//         axisLeft={null}
//         enableGridY={false}
//         enableTotals={true}
//         enableLabel={false}
//         labelSkipWidth={12}
//         labelSkipHeight={12}
//         labelTextColor="white"
//         legends={[]}
//         isInteractive={false}
//         animate={false}
//         role="application"
//         ariaLabel=""
//         barAriaLabel={e => e.id + ": " + e.formattedValue + " in country: " + e.indexValue}
//       /></Box>;

//   return content;
// }


export default PercentageBar;