import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import Button from "../../components/Button";
import AddIcon from "@mui/icons-material/Add";
import Upload from "@mui/icons-material/Upload";
import { pushErrorMessage } from '../../utils/notifications';
import ToolsDataGrid from "./components/toolsDataGrid";
import Popup from '../../components/Popup';
import { FormattedMessage } from "react-intl";
import messages from "./components/messages";
import AddEditToolDialog from "./components/addEditToolDialog";
import { tokens } from "../../assets/theme";
import { useQuery } from "@tanstack/react-query";
import CircularProgress from "@mui/material/CircularProgress";
import { useMsal, useAccount } from "@azure/msal-react";
import AxionControls from "../../components/controls/AxionControls";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { fetchTools, updateTool, createTool, deleteTool } from "../../fetchers/tools";
import ImportToolDialog from "./components/importToolDialog";

const ToolsIndex = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const [companyKey, setCompanyKey] = useLocalStorage("companyKey", 1);
  const [openPopup, setOpenPopup] = useState(false);
  const [openImportPopup, setOpenImportPopup] = useState(false);
  const [tool, setTool] = useState({
    id: null,
    companyKey: companyKey,
    name: "",
    description: "",
  });
  const [showBackdrop, setShowBackdrop] = useState(false);

  const toolsQuery = useQuery(["tools", companyKey], () => fetchTools(companyKey),
      {
          staleTime:Infinity
      }
  );

  const handleImportTools = () => {
    setOpenImportPopup(true);
  }

  const handleAddToolClick = () => {
    setTool({
        id: null,
        companyKey: companyKey,
        name: "",
        description: "",
    });
    setOpenPopup(true);
  }

  const handleEditToolClick = (id) => {
    const tool = toolsQuery.data.find((rowData) => rowData.id === id);
    setTool(tool);
    setOpenPopup(true);
  }

  const handleDeleteToolClick = (id) => {
    handleDelete(id);
  }

  const handleChange = (event) => {
    const {id, name, value, type, checked} = event.target
    setTool(prev => {
        return {
            ...prev,
            [id ? id : name]: type === "checkbox" ? checked : value
        }
    })
  }

  const handleSave = () => {
    setShowBackdrop(true);
       if (tool.id) {
        updateTool(tool).then(() => {  
            setOpenPopup(false);
            toolsQuery.refetch();
            setShowBackdrop(false);
        }).catch((error) => {
            console.log(error);
            setShowBackdrop(false);
            pushErrorMessage(`Cannot update the tool.`);
       });
       } else {
        createTool(tool).then(() => {  
            setOpenPopup(false);
            toolsQuery.refetch();
            setShowBackdrop(false);
        }).catch((error) => {
            console.log(error);
            setShowBackdrop(false);
            pushErrorMessage(`Cannot update the tool.`);
       });
       }
  }   
  
  const handleDelete = (id) => {
    setShowBackdrop(true);
    deleteTool(id).then(() => {
      toolsQuery.refetch();
      setShowBackdrop(false);
    })
    .catch((error) => {
      console.log(error);
      setShowBackdrop(false);
      pushErrorMessage(`Cannot delete the tool.`);
    });
  }


  if (toolsQuery.isLoading)
    return <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}><CircularProgress color="secondary" /></div>;

  return (
    <Box maxWidth={1800} >
      <Button
        text={<FormattedMessage {...messages.importToolLabel} />}
        variant="contained" 
        sx={{
          margin: "20px",
          backgroundColor: colors.primary[400],
        }}
        startIcon={<Upload />}
        onClick={handleImportTools}
       />
      <Button
        text={<FormattedMessage {...messages.addToolLabel} />}
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={handleAddToolClick}
        sx={{
        "&:hover": {
            backgroundColor: colors.dashbordGreen[700],
        },
        backgroundColor: colors.dashbordGreen[500],
        color: "#000000",
        position: "absolute",
        right: "10px",
        }} />
      <ToolsDataGrid 
        tools={toolsQuery}
        handleDeleteToolClick={handleDeleteToolClick}
        handleEditToolClick={handleEditToolClick} />
      <Popup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          maxWidht="sm"
          title={tool.id != null ? <FormattedMessage {...messages.editToolLabel} /> : <FormattedMessage {...messages.addToolLabel} />}>
          <AddEditToolDialog
              onChange={handleChange}
              handleSave={handleSave}
              tool={tool}
              setOpenPopup={setOpenPopup} />
      </Popup>
      <Popup
          openPopup={openImportPopup}
          setOpenPopup={setOpenImportPopup}
          maxWidht="sm"
          title={<FormattedMessage {...messages.importToolLabel} />}>
          <ImportToolDialog
              tools={toolsQuery}
              setOpenPopup={setOpenImportPopup} />
      </Popup>
      <AxionControls.AxionBackdrop open={showBackdrop} />
    </Box>
  );
};

export default ToolsIndex;
