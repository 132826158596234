import React from 'react';
import { useQuery } from '@tanstack/react-query';
import InstructionViewer from './instructionViewer'; // Import your InstructionViewer component
import { fetchInstruction } from '../hooks/fetchers';
import { useLocation } from "react-router-dom";

const ViewInstructions = (props) => {
  //const id = parseInt(useLocation().pathname.split("/")[2]);
  const id = props.id;
  const { data: instruction, error, isLoading } = useQuery(['instruction', id], () =>
    fetchInstruction(id)
  );
  console.log(id);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return <InstructionViewer instruction={instruction} />;
};

export default ViewInstructions;