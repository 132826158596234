import React from 'react'
import { MuiColorInput } from 'mui-color-input'

export default function AxionColorPicker(props) {
    
        const { name, label, value, onChange } = props;
    
        return (
            <MuiColorInput
                format="hex"
                isAlphaHidden={true}
                id={name}
                name={name}
                label={label}
                value={value}
                onChange={onChange}
                sx={{
                  backgroundColor: 'white',  
                  borderRadius: 1,
                  outline: 'none',
                  color: 'black',
                  '& .MuiFormLabel-root': {
                    color: '#799c4a',
                    top: '15px'
                  },  
                  '& .MuiInputBase-formControl': {
                      paddingTop: '15px'
                  }
                }}
                InputProps={{
                    sx: {
                        color: 'black',
                        border: '2px solid',
                        borderColor:'#799c4a',
                        '& .MuiInputBase-root': {
                            '&.Mui-focused': {
                                outline: 'none',
                                borderColor: 'transparent', // Optional: hides the border
                              }
                          }
                    }
                }}
            />
        )
    }