import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  monitorIntegrationEnabled: false, 
  EndpointURL:'' 
};
 


export const monitorIntegrationEnabledSlice = createSlice({
  name: 'monitorIntegrationEnabled',
  initialState,
  reducers: {
    setMonitorIntegrationEnabled: (state, action) => {
      state.monitorIntegrationEnabled = action.payload;
    },
    setEndpointUrl:(state, action) => {
      state.EndpointURL = action.payload;
    }

  },
});

export const { setMonitorIntegrationEnabled, setEndpointUrl } = monitorIntegrationEnabledSlice.actions;

export default monitorIntegrationEnabledSlice.reducer;