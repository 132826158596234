import React from "react";
import { Box, Button } from "@mui/material";
import AxionControls from "../../../components/controls/AxionControls";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import Controls from "../../../components/controls/Contols";


export default function AddEditMachineType(props) {
    return (
        <Box 
            sx={{
                p:1,
                m:1
            }}>
                <Box sx={{width: 200}}>
                    <FormattedMessage {...messages.idColumn} />: {props.machineType.id}
                </Box>
                <Box sx={{width: 200}}>
                    <AxionControls.AxionTextBox 
                        name="machineTypeName"
                        label={<FormattedMessage {...messages.nameColumn} />}
                        value={props.machineType.machineTypeName}
                        onChange={props.onChange}
                    />
                </Box>
                <Box sx={{width: 400}}>
                    <AxionControls.AxionTextBox 
                        name="description"
                        label={<FormattedMessage {...messages.descriptionColumn} />}
                        value={props.machineType.description}
                        onChange={props.onChange}
                    />
                </Box>
                <Box>
                    <Controls.Checkbox 
                        name="showInGrid"
                        label={<FormattedMessage {...messages.showInGridColumn} />}
                        value={props.machineType.showInGrid}
                        onChange={props.onChange}
                    />
                </Box>
                <Box>
                    <Controls.Checkbox 
                        name="showInGantt"
                        label={<FormattedMessage {...messages.showInGanttColumn} />}
                        value={props.machineType.showInGantt}
                        onChange={props.onChange}
                    />
                </Box>
                <Box>
                    <Controls.Checkbox 
                        name="showInMachineList"
                        label={<FormattedMessage {...messages.showInMachineListColumn} />}
                        value={props.machineType.showInMachineList}
                        onChange={props.onChange}
                    />
                </Box>
                <Box>
                    <Controls.Checkbox 
                        name="showInActivitySpecialList"
                        label={<FormattedMessage {...messages.showInActivitySpecialListColumn} />}
                        value={props.machineType.showInActivitySpecialList}
                        onChange={props.onChange}
                    />
                </Box>
                <Box>
                    <Button 
                        variant="contained" 
                        sx={{
                            marginTop: 2,
                            }}
                        onClick={props.handleSave}>
                        Save
                    </Button>
                </Box>
        </Box>
    );
}