import { defineMessages } from 'react-intl';

export const scope = 'axionoee.features.tools.components';

export default defineMessages({
    save: {
        id: `${scope}.label.save`,
        defaultMessage: 'Save',
    },
    addToolLabel: {
        id: `${scope}.label.addTool`,
        defaultMessage: 'Add tool',
    },
    editToolLabel: {
        id: `${scope}.label.editTool`,
        defaultMessage: 'Edit tool',
    },
    deleteToolLabel: {
        id: `${scope}.label.deleteTool`,
        defaultMessage: 'Delete tool',
    },
    nameColumnLabel: {
        id: `${scope}.grid.column.name`,
        defaultMessage: 'Name',
    },
    descriptionColumnLabel: {
        id: `${scope}.grid.column.description`,
        defaultMessage: 'Description',
    },
    importToolDescription: {
        id: `${scope}.importToolDescription`,
        defaultMessage: 'You can import tools from an excel file by using'
    },
    importToolDescriptionLinkText: {
        id: `${scope}.importToolDescriptionLinkText`,
        defaultMessage: 'this template'
    },
    upload: {
        id: `${scope}.upload`,
        defaultMessage: 'Upload'
    },   
    fileImportSuccess: {
        id: `${scope}.fileImportSuccess`,
        defaultMessage: 'Tools imported successfully'
    },
    fileImportError: {
        id: `${scope}.fileImportError`,
        defaultMessage: 'Could not process the uploaded file'
    },
    importToolLabel: {
        id: `${scope}.importToolLabel`,
        defaultMessage: 'Import Tools items using Excel'
    },
});