import React, { useState } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
//import { loginRequest } from "./authConfig";
import {Box} from "@mui/material";
import Header from "../../components/Header";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import {Scheduler} from "../../components/Scheduler";
import TestScheduler from "../../components/testSchedulers";


const Planning = () => {
    return (
    <div>
    
    <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Production Planning" subtitle="Schedule and create production orders" />
        </Box>
    </Box>
    <div><Scheduler /></div>
    </div>
    );
};

export default Planning;
