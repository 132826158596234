import { defineMessages } from 'react-intl';

export const scope = 'axionoee.features.components.plclog';

export default defineMessages({
    machineNameColumn: {
        id: `${scope}.grid.column.machineName`,
        defaultMessage: 'Machine name',
    },
    dateAndTimeColumn: {
        id: `${scope}.grid.column.dateAndTime`,
        defaultMessage: 'Date and time',
    },
    counterColumn: {
        id: `${scope}.grid.column.counter`,
        defaultMessage: 'Counter',
    },
    machineStatusColumn: {
        id: `${scope}.grid.column.machineStatus`,
        defaultMessage: 'Status',
    },
    cycleTimeColumn: {
        id: `${scope}.grid.column.cycleTime`,
        defaultMessage: 'Cycle time',
    },
});