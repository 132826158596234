import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Box, Button } from "@mui/material";
import messages from "./messages";
import { getDate } from "date-fns";
import CountdownTimer from "./countdownTimer";

export default function NewActivityDialog(props) {

    const handleClose = () => {
        props.setOpenPopup(false);
    }

    const [lastActivity, setLastActivity] = useState(null);

    useEffect(() => {
        if(props.activitiesQuery !== undefined) {
            if(props.activitiesQuery?.data.length > 0) {
                setLastActivity(props.activitiesQuery?.data[props.activitiesQuery?.data.length - 1]);
            }
        }
      }, [props.activitiesQuery]);
    
    const openActivitesTab = () => {
        props.setOpenPopup(false);
        props.setSelectedTab(1);
    }

    return (
        <>
            <Box
                sx={{
                    p: 1,
                    m: 1
                }}>
                <Box>
                    <FormattedMessage {...messages.newActivityText} /><br />
                    <FormattedMessage {...messages.activity} /> {lastActivity?.title} <br />
                    <FormattedMessage {...messages.completeBefore} /> {new Date(lastActivity?.maxClosedDate).toLocaleString('sv-SE') } (<FormattedMessage {...messages.in} /> <CountdownTimer maxClosedDate={lastActivity?.maxClosedDate} />) 
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    p: 1,
                    m: 1
                }}>
                <Box sx={{ flexDirection: 'row' }}>
                    <Button
                        variant="contained"
                        onClick={handleClose}>
                        <FormattedMessage {...messages.Ok} />
                    </Button>
                    <Button
                        sx={{ ml: 1 }}
                        variant="contained"
                        onClick={openActivitesTab}>
                        <FormattedMessage {...messages.showActivities} />
                    </Button>
                </Box>
            </Box>
        </>
    )
}