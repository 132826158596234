import { defineMessages } from 'react-intl';

export const scope = 'axionoee.features.gantt.index';

export default defineMessages({
  onlyRunningFilter: {
    id: `${scope}.filters.onlyRunning`,
    defaultMessage: 'Only Running',
  },
  dontShowPlannedStopsFilter: {
    id: `${scope}.filters.dontShowPlannedStops`,
    defaultMessage: "Don't show planned stops",
  },
  onlyStoppedFilter: {
    id: `${scope}.filters.onlyStopped`,
    defaultMessage: 'Only stopped',
  },
  ganttTitle: {
    id: `${scope}.label.title`,
    defaultMessage: 'Machines',
  },
  editStop: {
    id: `${scope}.label.editStop`,
    defaultMessage: 'Edit stop',
  },
});