import React, { useState, useEffect } from 'react';
import { DayPilot, DayPilotScheduler, DayPilotQueue } from 'daypilot-pro-react';
import axios from 'axios';
import TestQueue from './testQueue';
import { styled, useTheme } from '@mui/material/styles';
import ToggleVisibility from './ToggleVisibility'


const TestScheduler = () => {
  const [unscheduledTasks, setUnscheduledTasks] = useState([]);
  const [resources, setResources] = useState([]);
  const theme = useTheme();
  const [showUnscheduled, setShowUnschedulerd] =  useState(false);
  var buttonText = showUnscheduled ? "Hide Component" : "Show Component";

  function toggleShow() {
    setShowUnschedulerd(!showUnscheduled);
  }


  useEffect(() => {
    const fetchUnscheduledTasks = async () => {
      const { data } = await axios.get('api/WorkOrders/GetUncheduledWorkOrders/1');
      setUnscheduledTasks(data);
    };

    
    fetchUnscheduledTasks();
  }, []);

  useEffect(() => {
    const fetchResources = async () => {
        const {data} =  await axios.get('api/WorkOrders/GetResourcesGantt/1');
        console.log("This is res " + data);
        setResources(data);
    };
    fetchResources();
  },[])

  const handleCreateTask = async (name) => {
    const params = {
      name,
    };

    const { data: newTask } = await axios.post('/api/create_task.php', params);
    setUnscheduledTasks([...unscheduledTasks, newTask]);
  };

  return (


    <div className={"wrap"}>
        <div className={"left"}>
            
            <ToggleVisibility>
<TestQueue />
</ToggleVisibility>
        </div>
        <div className={"right"}>
    <DayPilotScheduler
      startDate={DayPilot.Date.today()}
      days={1}
      businessBeginsHour={8}
      businessEndsHour={17}
      treeEnabled={true}
      resources= {resources}
      onTimeRangeSelected={(args) => {
        const modal = DayPilot.Modal.prompt('Create a new task:', 'Task 1');
        modal.then((result) => {
          if (!result.result) {
            return;
          }

          handleCreateTask(result.result);
        });
      }}
    >
      {/* <DayPilotQueue
        id="unscheduled-tasks"
        name="Unscheduled tasks"
        eventHeight={500}
        data={unscheduledTasks.map((task) => ({
          id: task.id,
          text: task.name,
          start: task.start,
          end: task.end,
        }))}
        onEventMoved={(args) => {
          const params = {
            id: args.e.id(),
            start: args.newStart.toString(),
            end: args.newEnd.toString(),
          };

          axios.post('/api/update_task.php', params);
        }}
        onEventDeleted={(args) => {
          const params = {
            id: args.e.id(),
          };

          axios.post('/api/delete_task.php', params);
        }}
      /> */}
    </DayPilotScheduler>
    </div>
    </div>
   
  );
};

export default TestScheduler;