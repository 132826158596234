import {
  Box,
  Typography,
  useTheme,
  Stack,
  CircularProgress,
  Grid,
  Button,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import {
  DataGridPro,
  GridLogicOperator,
  useGridApiRef,
  gridFilterModelSelector,
  GridToolbar,
} from "@mui/x-data-grid-pro";
import Switch from "@mui/material/Switch";
import { CustomFooterTotalComponentUS } from "./components/customFooterUS";
import FormControlLabel from "@mui/material/FormControlLabel";
import { tokens } from "../../assets/theme";
import React, { useState, useEffect } from "react";

import {
  useQuery,
  useMutation,
  useQueryClient,
  useQueries,
} from "@tanstack/react-query";
import {
  fetchOeeDashboardDataTimeSpan,
  updateMachinePriority,
  fetchOeeGridDateIntervall,
  fetchOeeDashboardDataTimeSpanDev,
} from "../oeegrid/hooks/fetchers";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";

//COMPONENTS
import GridDatePicker from "./components/GridDatePicker";

//ICONS
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import dayjs from "dayjs";
import { number } from "yup";

const OEEGrid = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [columnStorage, setColumnStorage] = useLocalStorage(
    "oeeGridColumnStorage",
    {
      plannedStop: false,
      prioritySortOrder: false,
      plannedQty: false,
      reportedQty: false,
      remainingQty: false,
      uptime: false,
      downTime: false,
      scrappedQty: false,
      numberOfCyclesWithCavity: false,
    }
  );
  const [filtersStorage, setFiltersStorage] = useLocalStorage("filterStorage", {
    items: [],
    linkOperator: GridLogicOperator.And,
  });
  //Functions to change Start and endDate state from datepickers
  function startDateChangeHandler(date) {
    console.log("startDateChangeHandler");
    setStartDate(date);
    //dateQuery.refetch();
    //console.log(startDate);
  }
  function endDateChangeHandler(date) {
    console.log("endDateChangeHandler");
    setEndDate(date);
    //console.log(endDate);
  }

  //Total number of machines in grid (affected by filters)
  const [total, setTotal] = useState(0);
  const [totalA, setTotalA] = useState(0);
  const [totalT, setTotalT] = useState(0);
  const [totalK, setTotalK] = useState(0);
  const [companyKey, setCompanyKey] = useState(2);
  //State for datepicker component
  const [startDate, setStartDate] = React.useState(dayjs());
  const [endDate, setEndDate] = React.useState(dayjs());
  //State to handle datepicker component visibility
  const [showDatepicker, setShowDatePicker] = useState(false);
  //Total number och machines running (affected by filters)
  const [machinesRunning, setMachinesRunning] = useState(0);
  //Total number och machines stopped in grid (affected by filters)
  const [machinesStopped, setMachinesStopped] = useState(0);
  //Total number och machines offline (affected by filters)
  const [machinesOffline, setMachinesOffline] = useState(0);
  const [test, setTest] = useState();

  //Total number och machines in planned stop (affected by filters)
  const [machinesPlannedStop, setMachinesPlannedStop] = useState(0);
  //timespan parameter to API Fetch default currentshift (24h, 72h available too)
  const [timeSpan, setTimeSpan] = useLocalStorage("timespan", "currentshift");
  //React Query client
  const queryClient = useQueryClient();
  const [selectedValue, setSelectedValue] = React.useState(timeSpan);
  //const [timeFrom, setTimeFrom]

  //Change handle for radio buttons when selecting new timespan for api fetch.
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    //Check if manual timespan selected, if selected then show datepickers.
    if (event.target.value !== "manual") {
      setTimeSpan(event.target.value);
      setShowDatePicker(false);
    } else {
      setShowDatePicker(true);
    }
  };
  //Message snackbar on updated priority
  const [snackbar, setSnackbar] = React.useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  //Save Machine Priority with mutation
  const useFakeMutation = () => {
    return React.useCallback(
      (item) =>
        new Promise((resolve, reject) =>
          setTimeout(() => {
            if (item.priorityName?.trim() === "") {
              reject(
                new Error("Error while saving user: name can't be empty.")
              );
            } else {
              updateMachinePriorityMutation.mutate({
                ...item,
                completed: !item.completed,
              });
              resolve({ ...item, priorityName: item.priorityName?.toString() });
            }
          }, 200)
        ),
      []
    );
  };

  //Icon handing for Machine Priority column
  const machinePriorityOptions = [
    {
      value: "1",
      label: "1" && (
        <IconButton
          sx={{ backgroundColor: colors.gray[500], width: 45, height: 45 }}
        >
          <Typography sx={{ fontSize: 30 }}>1</Typography>
        </IconButton>
      ),
    },
    {
      value: "2",
      label: "1" && (
        <IconButton
          sx={{ backgroundColor: colors.gray[500], width: 45, height: 45 }}
        >
          <Typography sx={{ fontSize: 30 }}>2</Typography>
        </IconButton>
      ),
    },
    {
      value: "3",
      label: "1" && (
        <IconButton
          sx={{ backgroundColor: colors.gray[500], width: 45, height: 45 }}
        >
          <Typography sx={{ fontSize: 30 }}>3</Typography>
        </IconButton>
      ),
    },
    {
      value: "4",
      label: "1" && (
        <IconButton
          sx={{ backgroundColor: colors.gray[500], width: 45, height: 45 }}
        >
          <Typography sx={{ fontSize: 30 }}>4</Typography>
        </IconButton>
      ),
    },
    {
      value: "Pil AUpp",
      label: "1" && (
        <IconButton sx={{ backgroundColor: colors.redAccent[500] }}>
          {" "}
          <ArrowUpwardIcon sx={{ fontSize: 28 }} />{" "}
        </IconButton>
      ),
    },
    {
      value: "Pil BHöger",
      label: "100" && (
        <IconButton sx={{ backgroundColor: colors.blueAccent[600] }}>
          <ArrowForwardIcon sx={{ fontSize: 28 }} />{" "}
        </IconButton>
      ),
    },
    {
      value: "Pil CNed",
      label: "99" && (
        <IconButton sx={{ backgroundColor: colors.dashbordGreen[500] }}>
          <ArrowDownwardIcon sx={{ fontSize: 28 }} />{" "}
        </IconButton>
      ),
    },
  ];

  //MUI Grid API
  const gridApi = useGridApiRef();
  //Mutations
  const mutateRow = useFakeMutation();

  const saveState = () => {
    const state = gridApi.current.exportState();
    setColumnStorage(state.columns.columnVisibilityModel);
  };

  const processRowUpdate = React.useCallback(
    async (newRow) => {
      console.log("Start Update");
      // Make the HTTP request to save in the backend
      const response = await mutateRow(newRow);
      setSnackbar({
        children: "Priority successfully saved",
        severity: "success",
      });
      return response;
    },
    [mutateRow]
  );

  const handleProcessRowUpdateError = React.useCallback((error) => {
    setSnackbar({ children: error.message, severity: "error" });
  }, []);

  const [timespanQuery, dateQuery] = useQueries({
    queries: [
      {
        queryKey: ["oeedata", timeSpan],
        queryFn: () => fetchOeeDashboardDataTimeSpanDev(timeSpan, companyKey),
        staleTime: 60000,
        refetchInterval: 60000,
        enabled: !showDatepicker,
      },
      {
        queryKey: ["oeedataDatePicker"],
        queryFn: () =>
          fetchOeeGridDateIntervall(startDate, endDate, companyKey),
        staleTime: 60000,
        refetchInterval: 60000,
        enabled: showDatepicker,
      },
    ],
  });

  //Track datechanges to call api again
  useEffect(() => {
    if (showDatepicker) {
      dateQuery.remove();
      dateQuery.refetch();
    }
  }, [endDate]);

  useEffect(() => {
    if (showDatepicker) {
      dateQuery.remove();
      dateQuery.refetch();
    }
  }, [startDate]);

  //     const {isError, isSuccess, isLoading, data, error} = useQueries(
  //     {queries: [
  //      {
  //       queryKey:["oeedata",timeSpan],
  //     queryFn: () => fetchOeeDashboardDataTimeSpan(timeSpan)
  //     ,

  //         staleTime:60000,
  //         refetchInterval: 60000,

  //   },
  //   ]
  //   }

  // );
  //Update machine priority and invalidate OEEdata query to fetch new records.
  const updateMachinePriorityMutation = useMutation(updateMachinePriority, {
    onSuccess: () => {
      queryClient.invalidateQueries("oeedata");
    },
  });

  //Use Effect (No logic yet.)
  useEffect(() => {
    console.log("Component mounted!");
  }, []);

  //Handle filter switches.
  const filterRunning = (newValue) => {
    const thisFilter = {
      columnField: "machineStatus",
      operatorValue: "equals",
      value: "Running",
      id: 2023,
    };

    if (filtersStorage.items.length !== 0) {
      var runningFilter = {
        ...filtersStorage,
        items: [
          ...filtersStorage.items.filter(
            (items) => items.id !== 2024 && items.id !== 2025
          ),
          thisFilter,
        ],
      };
    } else {
      runningFilter = {
        ...filtersStorage,
        items: [thisFilter],
      };
    }

    //setFilters(runningFilter);
    setFiltersStorage(runningFilter);

    if (gridApi.current) {
      gridApi.current.setFilterModel(runningFilter);
    }
  };
  const unfilterRunning = (newValue) => {
    const state = gridApi.current.state;
    const filterModel = gridFilterModelSelector(state);
    if (filterModel.items.length !== 0) {
      const itemToDelete = filterModel.items.filter((list) => list.id === 2023);
      const newState = filterModel.items.filter((list) => list.id !== 2023);
      //setFilters(newState);
      setFiltersStorage(newState);

      gridApi.current.deleteFilterItem(itemToDelete[0]);
    }
  };

  const filterStopped = (newValue) => {
    const thisFilter = {
      columnField: "machineStatus",
      operatorValue: "equals",
      value: "Stopped",
      id: 2024,
    };

    if (filtersStorage.items.length !== 0) {
      var runningFilter = {
        ...filtersStorage,
        items: [
          ...filtersStorage.items.filter((items) => items.id !== 2023),
          thisFilter,
        ],
      };
    } else {
      runningFilter = {
        ...filtersStorage,
        items: [thisFilter],
      };
    }
    //setFilters(runningFilter);
    setFiltersStorage(runningFilter);

    if (gridApi.current) {
      gridApi.current.setFilterModel(runningFilter);
    }
  };

  const unfilterStopped = (newValue) => {
    const state = gridApi.current.state;
    const filterModel = gridFilterModelSelector(state);
    if (filterModel.items.length !== 0) {
      const itemToDelete = filterModel.items.filter((list) => list.id === 2024);
      const newState = filterModel.items.filter((list) => list.id !== 2024);
      //setFilters(newState);
      setFiltersStorage(newState);

      gridApi.current.deleteFilterItem(itemToDelete[0]);
    }
  };

  const filterPlannedStops = (newValue) => {
    const thisFilter = {
      columnField: "plannedStop",
      operatorValue: "equals",
      value: "0",
      id: 2025,
    };

    if (filtersStorage.items.length !== 0) {
      var runningFilter = {
        ...filtersStorage,
        items: [
          ...filtersStorage.items.filter((items) => items.id !== 2023),
          thisFilter,
        ],
      };
    } else {
      runningFilter = {
        ...filtersStorage,
        items: [thisFilter],
      };
    }
    //setFilters(runningFilter);
    setFiltersStorage(runningFilter);

    if (gridApi.current) {
      gridApi.current.setFilterModel(runningFilter);
    }
  };
  const unfilterPlannedStops = (newValue) => {
    const state = gridApi.current.state;
    const filterModel = gridFilterModelSelector(state);

    if (filterModel.items.length !== 0) {
      const itemToDelete = filterModel.items.filter((list) => list.id === 2025);
      const newState = filterModel.items.filter((list) => list.id !== 2025);
      console.log(newState);
      //setFilters(newState);
      setFiltersStorage(newState);

      gridApi.current.deleteFilterItem(itemToDelete[0]);
    }
  };

  //Handle switch button states.
  const [checked, setCheck] = useLocalStorage("checked", {
    running: false,
    onlyPlanned: false,
    onlyStopped: false,
  });

  const handleCheck = (event) => {
    if (event.target.name === "running") {
      if (!checked.running) {
        setCheck({
          ...checked,
          running: true,
          onlyPlanned: false,
          onlyStopped: false,
        });
        filterRunning();
        //filterRunning();
      }
      if (checked.running) {
        setCheck({
          ...checked,
          running: false,
          // onlyPlanned : false
        });
        unfilterRunning();
      }
    }
    if (event.target.name === "onlyPlanned") {
      if (!checked.onlyPlanned) {
        if (!checked.onlyStopped) {
          setCheck({
            ...checked,
            running: false,
            onlyPlanned: true,
            onlyStopped: false,
          });
        } else {
          setCheck({
            ...checked,
            running: false,
            onlyPlanned: true,
            onlyStopped: true,
          });
        }

        filterPlannedStops();
      }
      if (checked.onlyPlanned) {
        setCheck({
          ...checked,
          onlyPlanned: false,
          // onlyPlanned : false
        });
        unfilterPlannedStops();
      }
    }
    if (event.target.name === "onlyStopped") {
      if (!checked.onlyStopped) {
        if (!checked.onlyPlanned) {
          setCheck({
            ...checked,
            running: false,
            onlyPlanned: false,
            onlyStopped: true,
          });
        } else {
          setCheck({
            ...checked,
            running: false,
            onlyPlanned: true,
            onlyStopped: true,
          });
        }

        filterStopped();
      }
      if (checked.onlyStopped) {
        setCheck({
          ...checked,
          onlyStopped: false,
          // onlyPlanned : false
        });
        unfilterStopped();
      }
    }
  };

  //Define columns of Team table.
  const columns = [
    {
      field: "areaName",
      headerName: "Area",
      width: 70,
      //cellClassName:"name-column--cell"
    },
    {
      field: "machineName",
      headerName: "Machine",
      width: 80,
      headerAlign: "left",
    },
    {
      field: "priorityName",
      headerName: "Priority",
      type: "singleSelect",
      valueOptions: machinePriorityOptions,
      renderCell: ({ id, value, field }) => {
        const option = machinePriorityOptions.find(
          ({ value: optionValue }) => optionValue === value
        );

        return option?.label;
      },

      width: 80,
      editable: true,
      headerAlign: "left",
    },
    {
      field: "machineStatus",
      headerName: "Status",
      type: "string",
      width: 100,
      renderCell: ({ row: { machineStatus } }) => {
        return (
          <Box
            width="100%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              machineStatus === "Running"
                ? colors.dashbordGreen[600]
                : machineStatus === "Offline"
                ? colors.yellow[500]
                : colors.redAccent[500]
            }
            borderRadius="4px"
          >
            {machineStatus === "admin" && <AdminPanelSettingsOutlinedIcon />}
            {machineStatus === "manager" && <SecurityOutlinedIcon />}
            {<kbd></kbd> === "user" && <LockOpenOutlinedIcon />}
            <Typography color={colors.gray[100]} sx={{ ml: "5px" }}>
              {machineStatus}
            </Typography>
          </Box>
        );
      },
      headerAlign: "center",
    },
    {
      field: "currentStop",
      headerName: "Current Stop",
      width: 95,
      //flex: 1,
    },
    {
      field: "currentStopTime",
      headerName: "Stop time",
      width: 80,
    },
    {
      field: "plannedStop",
      headerName: "Planned",
      flex: 1,
    },
    {
      field: "activeProductionOrder",
      headerName: "PO#",
      width: 75,
    },
    {
      field: "activeItem",
      headerName: "Item",
      width: 100,
    },
    {
      field: "tool",
      headerName: "Tool",
      width: 55,
    },
    {
      field: "plannedQty",
      type: "number",
      headerName: "Planned Qty",
      flex: 1,
      cellClassName: "font-tabular-nums",
    },
    {
      field: "reportedQty",
      type: "number",
      headerName: "Reported Qty",
      flex: 1,
    },
    {
      field: "remainingQty",
      type: "number",
      headerName: "Remaining Qty",
      flex: 1,
    },

    {
      field: "t",
      headerName: "A %",
      type: "number",
      renderCell: ({ row: { t } }) => {
        return (
          <Box
            width="100%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              t > 85
                ? colors.dashbordGreen[600]
                : t > 71
                ? colors.yellow[600]
                : colors.redAccent[600]
            }
            borderRadius="4px"
          >
            {t === "admin" && <AdminPanelSettingsOutlinedIcon />}
            {t === "manager" && <SecurityOutlinedIcon />}
            {<kbd></kbd> === "user" && <LockOpenOutlinedIcon />}
            <Typography color={colors.gray[100]} sx={{ ml: "5px" }}>
              {Number.parseFloat(t).toFixed(0)} %
            </Typography>
          </Box>
        );
      },
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "a",
      headerName: "P %",
      type: "number",
      renderCell: ({ row: { a } }) => {
        return (
          <Box
            width="100%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              a > 90
                ? colors.dashbordGreen[600]
                : a > 81
                ? colors.yellow[600]
                : colors.redAccent[600]
            }
            borderRadius="4px"
          >
            {a === "admin" && <AdminPanelSettingsOutlinedIcon />}
            {a === "manager" && <SecurityOutlinedIcon />}
            {<kbd></kbd> === "user" && <LockOpenOutlinedIcon />}
            <Typography color={colors.gray[100]} sx={{ ml: "5px" }}>
              {Number.parseFloat(a).toFixed(0)} %
            </Typography>
          </Box>
        );
      },
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "k",
      headerName: "Q %",
      type: "number",
      width: 50,
      renderCell: ({ row: { k } }) => {
        return (
          <Box
            width="100%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              k > 97
                ? colors.dashbordGreen[600]
                : k > 95
                ? colors.yellow[600]
                : colors.redAccent[600]
            }
            borderRadius="4px"
          >
            {k === "admin" && <AdminPanelSettingsOutlinedIcon />}
            {k === "manager" && <SecurityOutlinedIcon />}
            {<kbd></kbd> === "user" && <LockOpenOutlinedIcon />}
            <Typography color={colors.gray[100]} sx={{ ml: "5px" }}>
              {Number.parseFloat(k).toFixed(0)} %
            </Typography>
          </Box>
        );
      },
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "tak",
      headerName: "OEE %",
      
      valueGetter: (params) => {
        if (params.row.tak > 100) {
          params.row.tak = 100.00;
        }
      },
      type: "number",
      renderCell: ({ row: { tak } }) => {
        return (
          <Box
            width="100%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              tak > 85
                ? colors.dashbordGreen[600]
                : tak > 80
                ? colors.yellow[600]
                : colors.redAccent[600]
            }
            borderRadius="4px"
          >
            {tak === "admin" && <AdminPanelSettingsOutlinedIcon />}
            {tak === "manager" && <SecurityOutlinedIcon />}
            {<kbd></kbd> === "user" && <LockOpenOutlinedIcon />}
            <Typography color={colors.gray[100]} sx={{ ml: "5px" }}>
              {Number.parseFloat(tak).toFixed(0)} %
            </Typography>
          </Box>
        );
      },

      headerAlign: "center",
      flex: 1,
    },
    {
      field: "idealCycleTimeActive",
      headerName: "Ideal. CT.",
      width: 75,
    },
    {
      field: "lastCycleTime",
      headerName: "Last CT.",
      width: 75,
    },
    {
      field: "avgCycleTime",
      renderCell: ({ row: { avgCycleTime } }) => {
        return (
          <Typography color={colors.gray[100]} sx={{ ml: "4px" }}>
            {Number.parseFloat(avgCycleTime).toFixed(2)}
          </Typography>
        );
      },
      headerName: "Avg CT.",
      width: 75,
    },
    {
      field: "avgIdealCycleTime",
      headerName: "Avg I.CT.",
      width: 75,
    },
    {
      field: "prioritySortOrder",
      headerName: "PrioritySortOrder",
    },
    {
      field: "uptime",
      headerName: "Uptime",
    },
    {
      field: "downTime",
      headerName: "Downtime",
    },
    {
      field: "scrappedQty",
      headerName: "Scrapped Qty",
    },
    {
      field: "numberOfCyclesWithCavity",
      headerName: "No Of Cycles",
    },
  ];

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "size-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  let content;
  let radiobuttons;

  radiobuttons = (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel
          value="currentShift"
          control={
            <Radio {...controlProps("currentshift")} color="secondary" />
          }
          color="success"
          label="Current Shift"
        />
        <FormControlLabel
          value="prevShift"
          control={<Radio {...controlProps("prevShift")} color="secondary" />}
          label="Prev. Shift"
        />
        <FormControlLabel
          value="24h"
          control={<Radio {...controlProps("24h")} color="secondary" />}
          label="24h"
        />
        <FormControlLabel
          value="72h"
          control={<Radio {...controlProps("72h")} color="secondary" />}
          label="72h"
        />
        <FormControlLabel
          value="manual"
          control={<Radio {...controlProps("manual")} color="secondary" />}
          label="Manual"
        />
      </RadioGroup>
    </FormControl>
  );

  if (timespanQuery.isLoading && showDatepicker === false) {
    content = <CircularProgress color="secondary" />;
  }
  if (dateQuery.isLoading && showDatepicker === true) {
    content = <CircularProgress color="secondary" />;
  }
  if (timespanQuery.isSuccess && showDatepicker === false) {
    const running = timespanQuery.data.filter((item) => {
      return item.machineStatus === "Running";
    });
    const offline = timespanQuery.data.filter((item) => {
      return item.machineStatus === "Offline";
    });
    const stopped = timespanQuery.data.filter((item) => {
      return item.machineStatus === "Stopped";
    });
    //setTest(data);

    content = (
      <DataGridPro //checkboxSelection
        //rows={tableData}
        apiRef={gridApi}
        rows={timespanQuery.data}
        loading={timespanQuery.isLoading}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        experimentalFeatures={{ newEditingApi: true }}
        onColumnVisibilityModelChange={(columnState) => {
          saveState();
        }}
        //columnVisibilityModel= {columnStorage}

        //autoHeight={true}
        filterModel={filtersStorage}
        //maxHeight={1}
        //rowHeight={50}
        onFilterModelChange={(newValue) => {
          //setFilters(newValue);
          setFiltersStorage(newValue);
          //console.log(filters);
        }}
        components={{
          Footer: CustomFooterTotalComponentUS,
          Toolbar: GridToolbar,
        }}
        componentsProps={{
          footer: {
            machinesRunning,
            machinesOffline,
            machinesStopped,
            machinesPlannedStop,
            total,
            totalA,
            totalK,
            totalT,
          },
        }}
        onStateChange={(state) => {
          const visibleRows = state.filter.visibleRowsLookup;
          let visibleItems = [];
          for (const [id, value] of Object.entries(visibleRows)) {
            if (value === true) {
              visibleItems.push(id);
            }
          }
          const res = timespanQuery.data.filter((item) =>
            visibleItems.includes(item.id.toString())
          );
          const running = res.filter(
            (item) => item.machineStatus === "Running"
          );
          const stopped = res.filter(
            (item) => item.machineStatus === "Stopped"
          );
          const offline = res.filter(
            (item) => item.machineStatus === "Offline"
          );
          const stoppedPlanned = res.filter((item) => item.plannedStop === "1");

          setMachinesRunning(running.length);
          setMachinesStopped(stopped.length - stoppedPlanned.length);
          setMachinesPlannedStop(stoppedPlanned.length + offline.length);

          const totalT =
            res
              .map((item) => item.t)
              //.reduce((a, b) => a + b, 0);
              .reduce(function (sum, value) {
                return sum + value;
              }, 0) / res.length;
          const totalUptime = res
            .map((item) => item.uptime)
            //.reduce((a, b) => a + b, 0);
            .reduce(function (sum, value) {
              return sum + value;
            }, 0);

          const totalDownTime = res
            .map((item) => item.downTime)
            //.reduce((a, b) => a + b, 0);
            .reduce(function (sum, value) {
              return sum + value;
            }, 0);

          setTotalT((1 - totalDownTime / totalUptime) * 100);

          const totalA =
            res
              .map((item) => item.a)
              //.reduce((a, b) => a + b, 0);
              .reduce((sum, value) => {
                if (value < 100) {
                  return sum + value;
                } else {
                  return sum + 100;
                }
              }, 0) / res.filter((result) => result.a !== 0).length;
          setTotalA(totalA);

          const totalK =
            res
              .map((item) => item.k)
              //.reduce((a, b) => a + b, 0);
              .reduce(function (sum, value) {
                if (value < 0) {
                  return sum;
                } else {
                  return sum + value;
                }
              }, 0) / res.length;
          setTotalK(totalK);

          // const total = res
          // .map((item) => item.tak)
          // //.reduce((a, b) => a + b, 0);
          // .reduce(function (sum, value) {
          //   return sum + value
          // }, 0) / res.length

          setTotal(
            (1 - totalDownTime / totalUptime) *
              (totalA / 100) *
              (totalK / 100) *
              100
          );
        }}
        initialState={{
          columns: {
            columnVisibilityModel: columnStorage,
          },
          sorting: {
            sortModel: [
              { field: "areaName", sort: "asc" },
              { field: "prioritySortOrder", sort: "asc" },
            ],
          },
        }}
        columns={columns}
      />
    );
  }

  if (dateQuery.isSuccess && showDatepicker === true) {
    const running = dateQuery.data.filter((item) => {
      return item.machineStatus === "Running";
    });
    const offline = dateQuery.data.filter((item) => {
      return item.machineStatus === "Offline";
    });
    const stopped = dateQuery.data.filter((item) => {
      return item.machineStatus === "Stopped";
    });
    //setTest(data);

    content = (
      <DataGridPro //checkboxSelection
        //rows={tableData}
        apiRef={gridApi}
        rows={dateQuery.data}
        loading={dateQuery.isLoading}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        experimentalFeatures={{ newEditingApi: true }}
        //columnVisibilityModel
        //autoHeight={true}
        filterModel={filtersStorage}
        onColumnVisibilityModelChange={(columnState) => {
          saveState();
        }}
        //maxHeight={1}
        //rowHeight={50}
        //columnVisibilityModel= {columnStorage}
        onFilterModelChange={(newValue) => {
          //setFilters(newValue);
          setFiltersStorage(newValue);
          //console.log(filters);
        }}
        components={{
          Footer: CustomFooterTotalComponentUS,
          Toolbar: GridToolbar,
        }}
        componentsProps={{
          footer: {
            machinesRunning,
            machinesOffline,
            machinesStopped,
            machinesPlannedStop,
            total,
            totalA,
            totalK,
            totalT,
          },
        }}
        onStateChange={(state) => {
          const visibleRows = state.filter.visibleRowsLookup;
          let visibleItems = [];
          for (const [id, value] of Object.entries(visibleRows)) {
            if (value === true) {
              visibleItems.push(id);
            }
          }
          const res = dateQuery.data.filter((item) =>
            visibleItems.includes(item.id.toString())
          );
          const running = res.filter(
            (item) => item.machineStatus === "Running"
          );
          const stopped = res.filter(
            (item) => item.machineStatus === "Stopped"
          );
          const offline = res.filter(
            (item) => item.machineStatus === "Offline"
          );
          const stoppedPlanned = res.filter((item) => item.plannedStop === "1");

          setMachinesRunning(running.length);
          setMachinesStopped(stopped.length - stoppedPlanned.length);
          setMachinesPlannedStop(stoppedPlanned.length + offline.length);

          const totalT =
            res
              .map((item) => item.t)
              //.reduce((a, b) => a + b, 0);
              .reduce(function (sum, value) {
                return sum + value;
              }, 0) / res.length;
          const totalUptime = res
            .map((item) => item.uptime)
            //.reduce((a, b) => a + b, 0);
            .reduce(function (sum, value) {
              return sum + value;
            }, 0);

          const totalDownTime = res
            .map((item) => item.downTime)
            //.reduce((a, b) => a + b, 0);
            .reduce(function (sum, value) {
              return sum + value;
            }, 0);

          setTotalT((1 - totalDownTime / totalUptime) * 100);

          const totalA =
            res
              .map((item) => item.a)
              //.reduce((a, b) => a + b, 0);
              .reduce((sum, value) => {
                if (value < 100) {
                  return sum + value;
                } else {
                  return sum + 100;
                }
              }, 0) / res.filter((result) => result.a !== 0).length;
          setTotalA(totalA);

          const totalK =
            res
              .map((item) => item.k)
              //.reduce((a, b) => a + b, 0);
              .reduce(function (sum, value) {
                if (value < 0) {
                  return sum;
                } else {
                  return sum + value;
                }
              }, 0) / res.length;
          setTotalK(totalK);

          setTotal(
            (1 - totalDownTime / totalUptime) *
              (totalA / 100) *
              (totalK / 100) *
              100
          );
        }}
        initialState={{
          columns: {
            columnVisibilityModel: columnStorage,
          },
          sorting: {
            sortModel: [
              { field: "areaName", sort: "asc" },
              { field: "prioritySortOrder", sort: "asc" },
            ],
          },
        }}
        columns={columns}
      />
    );
  }

  return (
    <Box m="20px" maxHeight={500} sx={{ backgroundColor: colors.primary[700] }}>
      <Box>
        <Grid container spacing={0} sx={{ paddingTop: "0px" }}>
          <Grid item xs={8}>
            <Stack direction="row" width="100%" marginLeft={2} spacing={2}>
              <FormControlLabel
                control={
                  <Switch
                    checked={checked.running}
                    color="secondary"
                    onChange={handleCheck}
                    name="running"
                  />
                }
                label="Only Running"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={checked.onlyPlanned}
                    color="secondary"
                    onChange={handleCheck}
                    name="onlyPlanned"
                  />
                }
                label="Don't show planned stops"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={checked.onlyStopped}
                    color="secondary"
                    onChange={handleCheck}
                    name="onlyStopped"
                  />
                }
                label="Only stopped"
              />

              {/* DatePickers */}
              {showDatepicker && (
                <>
                  <GridDatePicker
                    label="From Date"
                    locale="sv"
                    maxDate={Date().toLocaleString()}
                    minDate=""
                    onChange={startDateChangeHandler}
                    date={startDate}
                  />
                  <GridDatePicker
                    label="To Date"
                    locale="sv"
                    maxDate={Date().toLocaleString()}
                    minDate=""
                    onChange={endDateChangeHandler}
                    date={endDate}
                  />
                </>
              )}
            </Stack>
          </Grid>
          <Grid item xs={4} sx={{ justifyContent: "end", display: "flex" }}>
            {radiobuttons}
          </Grid>
        </Grid>
      </Box>
      <Box
        m="0px 0 0 0"
        height="90vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            // borderBottom:"none",
          },
          "& .name.column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-cellContent": {
            fontSize: "13px",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.primary[600],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.primary[600],
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.gray[100]} !important`,
          },
          "& .super-app-theme--11": {
            bgcolor: `${colors.redAccent[500]} !important`,
          },
          "& .MuiDataGrid-columnHeader": {
            fontSize: "15px",
          },
        }}
      >
        {/*DATAGRID */}
        <div style={{ display: "flex", height: "100%" }}>
          <div style={{ flexGrow: 1 }}>{content}</div>
        </div>
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </Box>
    </Box>
  );
};

export default OEEGrid;
