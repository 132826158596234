import React, { useState } from 'react'
import { Box, Typography, useTheme, CircularProgress, IconButton, Button } from "@mui/material";
import { DataGridPro, useGridApiRef, GridSelectionModel } from "@mui/x-data-grid-pro"
import { tokens } from "../../../assets/theme";
import { useQuery } from "@tanstack/react-query";
import { fetchMachineStops } from "../../../fetchers/machine"
import MessageIcon from '@mui/icons-material/Message';
import MachineStopEditDialog from "./machineStopEditDialog";
import Popup from "../../../components/Popup";
import AxionControls from '../../../components/controls/AxionControls';
import { getStopReasonCodes } from "../../stopReasonCodes/hooks/fetchers";
import { useMsal, useAccount } from "@azure/msal-react";
import { patchMachineStops } from "../../../fetchers/machine";
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import messages from "./messages";
import { pushErrorMessage, pushNotification } from '../../../utils/notifications';
import ContentCutOutlinedIcon from '@mui/icons-material/ContentCutOutlined';
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import dayjs from "dayjs";

const MachineStopGrid = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { accounts } = useMsal();
    const navigate = useNavigate();
    const account = useAccount(accounts[0]);

    const [openPopup, setOpenPopup] = useState(false);
    const [machineStop, setMachineStop] = useState({});
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [stopReasonMultiUpdate, setStopReasonMultiUpdate] = useState({
        ids: [],
        stopReasonCodeId: '',
        comment: null,
        updatedBy: account.username,
        commentedBy: null
    });
    const [showBackdrop, setShowBackdrop] = useState(false);

    //MUI Grid API
    const gridApi = useGridApiRef();
    const [companyKey, setCompanyKey] = useLocalStorage("companyKey", 1);


    const stopReasonCodesQuery = useQuery(
        ["stopReasonCodes", companyKey],
        () => getStopReasonCodes(companyKey),
        {
            staleTime: 60000,
            refetchInterval: 60000
        }
    );

    const handleRefresh = () => {
        console.log("Refetch");
        props.machinestops.refetch();
    }

    const handleEditStopClick = (id) => {
        const machineStopItem = props.machinestops.data.find((rowData) => rowData.id === id);
        setMachineStop(machineStopItem);
        setOpenPopup(true);
    }

    const handleSplit = (id) => {
        navigate(`/machinestops/${id}/split`);
    }

    const handleSave = () => {
        setShowBackdrop(true);
        const machineStopPartToSave = stopReasonMultiUpdate;
        if (machineStopPartToSave.comment && machineStopPartToSave.comment.length > 0) {
            machineStopPartToSave.commentedBy = account.username;
        } else {
            machineStopPartToSave.commentedBy = null;
        }
        setStopReasonMultiUpdate(machineStopPartToSave);
        console.log(machineStopPartToSave)
        patchMachineStops(machineStopPartToSave).then((response) => {
            handleRefresh();
            setSelectionModel([]);
            setStopReasonMultiUpdate({
                ids: [],
                stopReasonCodeId: '',
                comment: null,
                updatedBy: account.username,
                commentedBy: null
            });
            setShowBackdrop(false);
            if (response && response.activityTriggered) {
                pushNotification(<FormattedMessage {...messages.activityTriggered} />);
                props.refetchActivities();
            }
        })
            .catch((error) => {
                console.log(error);
                setShowBackdrop(false);
                pushErrorMessage(`Could not update the stop.`);
            });
    }

    const handleSelection = (newSelectionModel) => {
        setSelectionModel(newSelectionModel);
        setStopReasonMultiUpdate({
            ...stopReasonMultiUpdate,
            ids: newSelectionModel
        });
    }

    const handleStopReasonChange = (e) => {
        setStopReasonMultiUpdate({
            ...stopReasonMultiUpdate,
            stopReasonCodeId: e.target.value
        });
    }

    const handleCommentChange = (e) => {
        setStopReasonMultiUpdate({
            ...stopReasonMultiUpdate,
            comment: e.target.value
        });
    }

    function isLight(color) {
        if (color.length === 7) {
            const rgb = [
                parseInt(color.substring(1, 3), 16),
                parseInt(color.substring(3, 5), 16),
                parseInt(color.substring(5), 16),
            ];
            const luminance =
                (0.2126 * rgb[0]) / 255 +
                (0.7152 * rgb[1]) / 255 +
                (0.0722 * rgb[2]) / 255;
            return luminance > 0.5;
        }
        return false
    }

    function formatFullDateTime_old(date) {
        //Change for company locale setting
        let locale = companyKey === 2 ? 'en-US' : 'sv-SE';
        let formattedDate = new Date(date).toLocaleDateString(locale);
        let formattedTime = new Date(date).toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit', second: '2-digit' });
        return date ? formattedDate + " " + formattedTime : "";
    }

    function formatFullDateTime(date) {
        let formattedDate = dayjs(date).format("YYYY-MM-DD HH:mm:ss");
        if (companyKey === 2) {
            formattedDate = dayjs(date).format("MM/DD/YYYY hh:mm:ss A");
        }
        return date ? formattedDate : "";
    }


    let filteredStopReasonCodes = [];
    if (props.machine !== undefined && stopReasonCodesQuery.isFetched) {
        filteredStopReasonCodes = stopReasonCodesQuery.data.filter(x => props.machine.machineStopReasonCodes.some(y => x.id === y.stopReasonCodeId));
    }

    //Define columns of Team table.
    const columns = [
        {
            field: "machineName",
            headerName: <FormattedMessage {...messages.machineName} />,
            //flex: 1, 
            width: 180,
            cellClassName: "name-column--cell"
        },
        {
            field: "fromDateTimeZone",
            headerName: <FormattedMessage {...messages.fromDate} />,
            //type: 'dateTime',
            headerAlign: "left",
            width: 180,
            valueGetter: ({ value, row }) =>
                // (companyKey === 2) ? formatFullDateTime(row?.fromDateEpochUS) : formatFullDateTime(row?.fromDateEpoch)
            (companyKey === 2) ? formatFullDateTime(row?.fromDateTimeZone) : formatFullDateTime(row?.fromDate)
          
        },
        {
            field: "toDateTimeZone",
            headerName: <FormattedMessage {...messages.toDate} />,
            //type: "dateTime",
            headerAlign: "left",
            width: 180,
            valueGetter: ({ value, row }) =>
                // (companyKey === 2) ? formatFullDateTime(row?.toDateEpochUS) : formatFullDateTime(row?.toDateEpoch)
            (companyKey === 2) ? formatFullDateTime(row?.toDateTimeZone) : formatFullDateTime(row?.toDate)
            
        },
        {
            field: "minutesDown",
            headerName: <FormattedMessage {...messages.duration} />,
            type: 'number',
            valueGetter: ({ value }) => parseFloat(value).toFixed(2),
            headerAlign: "left",
            align: "right",
            width: 100,
        },
        {
            field: "stopReasonCodeName",
            headerName: <FormattedMessage {...messages.stopReasonCode} />,
            width: 200,
            renderCell: (params) => {
                return (
                    <Box
                        sx={{ cursor: "pointer" }}
                        width="100%"
                        m="0 auto"
                        p="5px"
                        display="flex"
                        borderRadius="4px"
                        justifyContent="center"
                        backgroundColor={params.row.stopReasonCodeColor}
                        onClick={() => {
                            handleEditStopClick(params.row.id)
                        }}
                    >
                        <Typography
                            color={isLight(params.row.stopReasonCodeColor) ? colors.gray[900] : colors.gray[100]}
                            sx={{ ml: "5px" }}>
                            {params.row.stopReasonCodeName}
                        </Typography>
                    </Box>
                )
            }
        },
        {
            field: "comment",
            headerName: "",
            align: "center",
            renderCell: (params) => {
                return (
                    <>
                        {params.row.comment &&
                            <IconButton
                                arial-label="Edit stop reason"
                                size="small"
                                sx={{
                                    "&:hover": {
                                        backgroundColor: colors.yellow[600],
                                    },
                                    backgroundColor: colors.yellow[500],
                                }}
                                onClick={() => {
                                    handleEditStopClick(params.row.id)
                                }}
                            >
                                <MessageIcon fontSize="inherit" />
                            </IconButton>
                        }
                    </>
                )
            },
            width: 50,
        },
        {
            field: "split",
            headerName: "",
            align: "center",
            renderCell: (params) => {
                return (
                    <>
                        {params.row.splitted === 1 &&
                            <IconButton
                                arial-label="Stop has been splitted"
                                size="small"
                                sx={{
                                    backgroundColor: colors.primary[500],
                                    pointerEvents: "none"
                                }}
                            >
                                <ContentCutOutlinedIcon fontSize="inherit" />
                            </IconButton>
                        }
                    </>
                )
            },
            width: 50,
        }
    ];

    if (stopReasonCodesQuery.isLoading) {
        return (
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="73vh">
                <CircularProgress color="secondary" />
            </Box>
        )
    }

    if (stopReasonCodesQuery.isError) {
        return (
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="73vh">
                <Typography variant="h5" color="error">Error: {stopReasonCodesQuery.error.message}</Typography>
            </Box>
        )
    }

    if (props.machinestops.isLoading) {
        return (
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="73vh">
                <CircularProgress color="secondary" />
            </Box>
        )
    }

    if (props.machinestops.isError) {
        return (
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="73vh">
                <Typography variant="h5" color="error">Error: {props.machinestops.error.message}</Typography>
            </Box>
        )
    }

    if (props.machinestops.isSuccess) {
        return (
            <>
                {selectionModel.length > 0 && <>
                    <Box sx={{ paddingLeft: 2, display: 'grid', gridAutoColumns: '1fr', gap: 1, backgroundColor: colors.primary[400] }}>
                        <Box sx={{ gridRow: '1', gridColumn: 'span 3', paddingTop: '11px', paddingBottom: '11px' }}>
                            <AxionControls.AxionSelect
                                options={filteredStopReasonCodes.map((item) => {
                                    return {
                                        id: item.id,
                                        title: item.name
                                    }
                                })}
                                label={<FormattedMessage {...messages.stopReasonCode} />}
                                name="stopReason"
                                value={stopReasonMultiUpdate.stopReasonCodeId}
                                onChange={handleStopReasonChange}
                            />
                        </Box>
                        <Box sx={{ gridRow: '1', gridColumn: 'span 3' }}>
                            <AxionControls.AxionTextBox
                                label={<FormattedMessage {...messages.comment} />}
                                name="comment"
                                value={stopReasonMultiUpdate.comment}
                                onChange={handleCommentChange}
                            />
                        </Box>
                        <Box sx={{ gridRow: '1', gridColumn: '7' }}>
                            <Button
                                variant="contained"
                                sx={{
                                    marginTop: 2,
                                }}
                                onClick={handleSave}>
                                <FormattedMessage {...messages.save} />
                            </Button>
                        </Box>
                    </Box>
                </>
                }
                <Box
                    m="0px 0 0 0"
                    height="57vh"
                    sx={{
                        "& .MuiDataGrid-root": {
                            border: "none",
                            fontSize: '0.85rem'
                        },
                        "& .MuiDataGrid-cell": {
                            // borderBottom:"none",
                        },
                        "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                            display: "none"
                        },
                        "& .Mui-disabled": {
                            display: "none"
                        },
                        "& .name.column--cell": {
                            color: colors.greenAccent[300],
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: colors.primary[600],
                            borderBottom: "none"
                        },
                        "& .MuiDataGrid-virtualScroller": {
                            backgroundColor: colors.primary[400],

                        },
                        "& .MuiDataGrid-footerContainer": {
                            borderTop: "none",
                            backgroundColor: colors.primary[600]

                        },
                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                            color: `${colors.gray[100]} !important`,

                        },
                        "& .super-app-theme--11": {
                            bgcolor: `${colors.yellow[600]} !important`,

                        },
                        "& .super-app-theme--undefined-stop": {
                            bgcolor: `${colors.yellow[600]} !important`,

                        },
                        "& .MuiTablePagination-selectLabel": {
                            marginTop: `14px !important`,
                        },
                        "& .MuiTablePagination-displayedRows": {
                            marginTop: `14px !important`,
                        },
                        "& .Mui-checked": {
                            color: "#4cceac !important",
                        }
                    }}
                >

                    <DataGridPro
                        checkboxSelection
                        apiRef={gridApi}
                        experimentalFeatures={{ newEditingApi: true }}
                        width={50}
                        rows={props.machinestops.data}
                        loading={props.machinestops.isLoading}
                        columns={columns}
                        onRowSelectionModelChange={handleSelection}
                        rowSelectionModel={selectionModel}
                        getRowClassName={(params) => `super-app-theme--${params.row.isUndefined ? "undefined-stop" : ""}`}
                        //components={{Toolbar: GridToolbar}}
                        pagination
                        disableRowSelectionOnClick // > ver 6.0
                        disableSelectionOnClick // < ver 6.0
                        isRowSelectable={(params) => params.row.isUndefined}
                    />

                    <Popup
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                        maxWidht="sm"
                        title={<FormattedMessage {...messages.editStop} />}>
                        <MachineStopEditDialog
                            stopReasonCodes={filteredStopReasonCodes}
                            refresh={handleRefresh}
                            refetchActivities={props.refetchActivities}
                            machineStop={machineStop}
                            handleSplit={handleSplit}
                            setOpenPopup={setOpenPopup}
                            setShowBackdrop={setShowBackdrop}
                            machine={props.machine} />
                    </Popup>
                </Box>
                <AxionControls.AxionBackdrop open={showBackdrop} />
            </>
        )
    }
}

export default MachineStopGrid;