import React from 'react';
import {  Typography, useTheme, CircularProgress } from '@mui/material';


import {
    useQuery

  } from "@tanstack/react-query";
import { fetchActiveItems } from './hooks/fetchers';
import { tokens } from "../../assets/theme";
//import InstructionForm from "./components/createForm";
import DrawingsGrid from "./components/drawingsGrid";

const DrawingsIndex = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { isError, isSuccess, isLoading, data, error } = useQuery(
    ["activeItems", "drawings"],
    () => fetchActiveItems(),
    {
      staleTime: 60000 ,
    }
  );
  if (isLoading) {
    return <CircularProgress color="secondary" />;
  }
  return (
    <>
      <DrawingsGrid data={data} />
    </>
  );
};

export default DrawingsIndex;
