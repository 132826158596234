import React from 'react'
import {Box, Button,useTheme} from '@mui/material';
import axios from "axios";
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { tokens } from "../../../assets/theme";
import AxionControls from '../../../components/controls/AxionControls';

// API call to delete an existing instruction
const deleteInstruction = async (instruction, ) => {

  //HttpDelete to backend
  const response = await axios.delete(`/api/instructions/${instruction.id}`);
  return response;
};

function InstructionDeleteForm({instruction, setOpenPopup, data}) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  console.log("delete");
  console.log(instruction);

  const handleDelete = async () =>{
    try{
      //Try to delete the instruction
      const response =  await deleteInstruction(instruction);
      data.refetch();
      setOpenPopup(false);
    }catch(error){
      console.error("Error deleting instruction", error);
    }
  }

  return (
    <Box>

      
    <Stack spacing={2}>
      <List disablePadding>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Title" secondary={instruction.title} />
          <ListItemText primary="Created By" secondary={instruction.createdBy} />
          <ListItemText primary="Created" secondary={instruction.createdBy} />
          
        </ListItem>
      </List>
      <Divider />
      <Stack
        direction="column"
        divider={<Divider flexItem />}
        spacing={2}
        sx={{ my: 2 }}
      >

        <div>
          <Typography variant="subtitle2" gutterBottom>
            Instruction Steps
          </Typography>
          <Grid container>
            {instruction.steps.map((step) => (
              <React.Fragment key={step.id}>
                <Stack
                  direction="row"
                  spacing={1}
                  useFlexGap
                  sx={{ width: '100%', mb: 1 }}
                >
                <Typography variant="body1" color="text.secondary">
                    {step.stepNumber}
                  </Typography>
                  <Typography variant="body2">{step.instructionText}</Typography>
             
                </Stack>
              </React.Fragment>
            ))}
          </Grid>
        </div>
        <Button
            variant="contained"
            color="primary"
            sx={{
              backgroundColor: colors.redAccent[600],
              "&:hover": {
                backgroundColor: colors.redAccent[700],
                borderColor: colors.redAccent[700],
                boxShadow: "none",
              },
              marginRight: "10px",
            }}
        
         onClick={() => {handleDelete()}} > Delete </Button>
      </Stack>
    
    </Stack>

    </Box>
  )
}

export default InstructionDeleteForm