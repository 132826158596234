import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  language: localStorage.getItem('language') || 'en', 
  languageFile:`../../translations/${localStorage.getItem('language')}.json` || '../../translations/en.json'
};
 


export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
      localStorage.setItem('language', action.payload);
    },
    setLanguageFile:(state, action) => {
      state.languageFile = action.payload;
    }

  },
});

export const { setLanguage, setLanguageFile } = languageSlice.actions;

export default languageSlice.reducer;