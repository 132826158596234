import React from "react";
import { MenuItem } from "react-pro-sidebar";
import { Typography, useTheme } from "@mui/material";
import { tokens } from "../../assets/theme";
import { useQuery } from "@tanstack/react-query";
import { fetchEmbeddedReports } from "../../fetchers/settings";
import { Link } from "react-router-dom";
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import PollOutlinedIcon from '@mui/icons-material/PollOutlined';
import { useLocalStorage } from "../../hooks/useLocalStorage";


export default function EmbeddedReportLinks(props) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [companyKey, setCompanyKey] = useLocalStorage("companyKey", 1);

    const embeddedReportsQuery = useQuery(
        ["embeddedReports", companyKey],
        () => fetchEmbeddedReports(companyKey),
        {
          staleTime: 120000,
          refetchInterval: 120000
        }
      );

    return (
        <>
            {embeddedReportsQuery.data && embeddedReportsQuery.data.map((report) => (
            <MenuItem
                key={report.id}
                style={{
                color: colors.gray[100],
                }}
                icon={<AssessmentOutlinedIcon />}
                >
                <Typography>{report.reportName}</Typography>
                <Link to={report.embeddUrl} />
            </MenuItem>
            ))}
        </>
    );
}