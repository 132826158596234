import React from 'react'
import { Button as MuiButton } from '@mui/material';



export default function ActionButton(props) {

    const { color, children, onClick, ...other} = props

    return (
        <MuiButton
            onClick={onClick}
            {...other}
            >
           {children}
            
        </MuiButton>
    )
}