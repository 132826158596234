import axios from "axios";
import dayjs from "dayjs";  

export const fetchOeeDashboardData = async () => {
    const response = await axios.get("api/api/getoeepershift");
    const oeeDashboardData = response.data;
    return oeeDashboardData;
}


export const fetchOeeDashboardDataTimeSpan = async (timeSpan) => {
    const response = await axios.get("api/api/getoeetimespan/"+timeSpan);
    const oeeDashboardData = response.data;
    return oeeDashboardData;
}

export const fetchOeeDashboardDataTimeSpanDev = async (timeSpan,companyKey) => {
    const response = await axios.get("api/oeegrid/getoeetimespandev/"+companyKey+"/"+timeSpan);
    const oeeDashboardData = response.data;
    return oeeDashboardData;
}

export const fetchOeeGridDateIntervall = async(startDate,endDate,companyKey) => {
    const response = await axios.get("api/api/getoeedateintervall/"+companyKey+"/startDate="+dayjs(startDate).format("YYYY-MM-DDTHH:mm:ss.SSS")+"&endDate="+dayjs(endDate).format("YYYY-MM-DDTHH:mm:ss.SSS"));
    const oeeGridData = response.data;
    return oeeGridData;
}


export const updateMachinePriority = async (priority) => {
    return await axios.post(`api/api/updateMachinePriority/${priority.machineName}`,priority);
}

export const fetchWorkOrderComment = async(companyKey) => {
    console.log("fetching Work Order Comment");
    const response = await axios.get("api/api/getworkordercomment/"+companyKey);
    const workOrderComment = response.data;
    console.log(workOrderComment);
    return workOrderComment;
    
}
export const updateWorkOrderComment = async (comment) => {
    return await axios.post(`api/api/updateWorkOrderComment`,comment);

}