import axios from "axios";

export const fetchTools = async (companyKey) => {
    const response = await axios.get("api/tools/?companyKey=" + companyKey);
    const tools = response.data;
    return tools;
}

export const updateTool = async (tool) => {
    try {
      const response = await axios.put(`/api/tools/`, tool);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  };

export const createTool = async (tool) => {
    try {
      const response = await axios.post(`/api/tools/`, tool);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }

export const importTools  = async (tools) => {
    try {
      const response = await axios.post(`/api/tools/import`, tools);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }

export const deleteTool = async (id) => {
    try {
      const response = await axios.delete(`/api/tools/${id}`);
      return response;
    } catch (error) {
      throw error.response.data;
    }
  }