import React, { useState, useEffect } from 'react'
import { Box, useTheme, Tabs, Tab, Button } from "@mui/material";
import { tokens } from "../../assets/theme";
import { experimentalStyled as styled } from '@mui/material/styles';
import { FormattedMessage } from "react-intl";
import { useQuery } from "@tanstack/react-query";
import { fetchMachineInfo, fetchMachineData, patchMachineData, fetchMachineStops, fetchActivitesMachine } from "../../fetchers/machine";
import { pushErrorMessage } from '../../utils/notifications';
import { getStopReasonCodes } from "../stopReasonCodes/hooks/fetchers";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { fetchAreas } from "../../fetchers/area";
import { getTimespan } from "../../fetchers/timespan";
import MachineStopGrid from "./components/machineStopsGrid";
import GroupedStopsChart from "./components/GroupedStopsChart";
import CountedStopsChart from "./components/CountedStopsChart";
import MachineSettingsDialog from "./components/machineSettingsDialog";
import MachineCommentDialog from "./components/machineCommentDialog";
import DateFilter from "./components/DateFilter";
import messages from "./components/messages";
import Popup from "../../components/Popup";
import Infoboxes from "./components/Infoboxes";
import AxionControls from "../../components/controls/AxionControls";
import Paper from '@mui/material/Paper';
import 'react-toastify/dist/ReactToastify.css';
import dayjs from "dayjs";
import PlclogDialog from '../plclog/components/plclogDialog';
import { fetchCompany } from '../../fetchers/company';
import NewActivityDialog from './components/newActivityDialog';
import { fetchMachineTypes } from '../../fetchers/machineType';

//ICONS
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const StyledTabs = styled((props) => (

  <Tabs
  {...props}
  TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
/>
))({
'& .MuiTabs-indicator': {
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: 'transparent',
},
'& .MuiTabs-indicatorSpan': {
  maxWidth: 40,
  width: '100%',
  backgroundColor: '#799c4a',
},
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(

  ({ theme }) => ({
    
    textTransform: 'none', 
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(20),
    marginRight: theme.spacing(1),
    //backgroundColor: '#799c4a',
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-selected': {
      color: '#fff',
      backgroundColor: "#434957 !important",
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
    '&.MuiTab-root': {
      backgroundColor: '#0c101b',
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
      
    },

  }),
);

const MachineDetails = (props) => {
  const [companyKey, setCompanyKey] = useLocalStorage("companyKey", 1);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [machineId, setMachineId] = useState(props.machineId);
  const [openSettingsPopup, setOpenSettingsPopup] = useState(false);
  const [openCommentPopup, setOpenCommentPopup] = useState(false);
  const [openPlclogPopup, setopenPlclogPopup] = useState(false);
  const [machineSettings, setMachineSettings] = useState({});
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [machineComment, setMachineComment] = useState({ id: 0, comment: "", commentedBy: "", updatedBy: "" });
  const [timeSpan, setTimeSpan] = useLocalStorage("timespan", "currentshift");
  const useDateInterval = timeSpan === 'manual';
  const [timeSpanStartDate, setTimeSpanStartDate] = React.useState(dayjs());
  const [timeSpanEndDate, setTimeSpanEndDate] = React.useState(dayjs());
  const [startDate, setStartDate] = useLocalStorage("startDate", dayjs());
  const [endDate, setEndDate] = useLocalStorage("endDate", dayjs());
  const [currentStartDate, setCurrentStartDate] = React.useState("currentStartDate", dayjs());
  const [currentEndDate, setCurrentEndDate] = React.useState("currentEndDate", dayjs());
  const [newActivityPopup, setNewActivityPopup] = useState(false);
  const [nrOfActivities, setNrOfActivities] = useState(-1);
  //const [startDate, setStartDate] = React.useState(dayjs());
  //const [endDate, setEndDate] = React.useState(dayjs());

  const maxStopRows = 10500;

  // const params = useParams();
  // useEffect(() => {
  //   setMachineId(params.id);
  // }, [params]);


  //API FETCHERS 
  const machineInfoQuery = useQuery(
    ["machineInfo", machineId],
    () => fetchMachineInfo(machineId),
    {
      staleTime: 60000,
      refetchInterval: 60000
    }
  );

  const DatesForTimespanQuery = useQuery(
    ["datesForTimespan", timeSpan, companyKey],
    () => getTimespan(timeSpan, companyKey),
    {
      staleTime: Infinity,
    }
  );

  const machineTypes = useQuery(
    ["machineTypes"],
    () => fetchMachineTypes());

  const companyQuery = useQuery(["company", companyKey], () => fetchCompany(companyKey));


  useEffect(() => {
    if (timeSpan === 'manual') {  
      // console.log ("Set dates where manual is selected");
      setCurrentStartDate(startDate);
      setCurrentEndDate(endDate);  
    }
  }, [startDate, endDate, timeSpan]);

  useEffect(() => {
    if (DatesForTimespanQuery.isFetched && timeSpan !== 'manual' && !DatesForTimespanQuery.isError && DatesForTimespanQuery.data !== undefined ) {
      setTimeSpanStartDate(DatesForTimespanQuery.data[0].fromDate);
      setTimeSpanEndDate(DatesForTimespanQuery.data[0].toDate);
      setCurrentStartDate(DatesForTimespanQuery.data[0].fromDate);
      setCurrentEndDate(DatesForTimespanQuery.data[0].toDate);  
    }
  }, [DatesForTimespanQuery]);

  useEffect(() => {
    if(props.activitiesQuery.isFetched && props.activitiesQuery?.data !== undefined) {
      if(props.activitiesQuery.data.length > 0 && nrOfActivities < props.activitiesQuery.data.length) {
        if(nrOfActivities >= 0) {
          setNewActivityPopup(true);
        }
      }
      setNrOfActivities(props.activitiesQuery.data?.length);
    }
  }, [props.activitiesQuery?.data]);

  const machinestopsQuery = useQuery(
    ["machinestops", machineId, startDate, endDate, useDateInterval, timeSpanStartDate, timeSpanEndDate],
    () => fetchMachineStops(machineId, startDate, endDate, useDateInterval, timeSpanStartDate, timeSpanEndDate, maxStopRows),
    {
      refetchInterval: 60000,
    }
  );

  const stopReasonCodesQuery = useQuery(
    ["stopReasonCodes", companyKey],
    () => getStopReasonCodes(companyKey),
    {
      staleTime: Infinity
    });

  const aresQuery = useQuery(
    ["areas"],
    () => fetchAreas(),
    {
      staleTime: 60000,
      refetchInterval: 60000
    });

  const machineQuery = useQuery(
    ["machine", machineId],
    () => fetchMachineData(machineId),
    {
      staleTime: Infinity
    });

  const handleTimeSpan = (ts) => {
    // Update state or perform any other action with childData
    setTimeSpan(ts);
  };

  const handleOpenActivitiesTab = () => {
    props.setSelectedTab(1);
  }

  const handleSettingsSave = () => {
    setShowBackdrop(true);
    patchMachineData(machineSettings).then(() => {
      setOpenSettingsPopup(false);
      machineQuery.refetch();
      machineInfoQuery.refetch();
      setShowBackdrop(false);
    })
      .catch((error) => {
        console.log(error);
        setShowBackdrop(false);
        pushErrorMessage(`Could not update the machine settings.`);
      });
  }

  const handleSettingsCancel = () => {
    setOpenSettingsPopup(false);
  }

  const handleEditSettingsClick = () => {
    setMachineSettings(machineQuery.data)
    setOpenSettingsPopup(true);
  }

  const handleCommentClick = () => {
    setOpenCommentPopup(true);
  }

  const handleCommentCancel = () => {
    setOpenCommentPopup(false);
  }

  const handlePlcLogClick = () => {
    setopenPlclogPopup(true);
  }

  const handleRefresh = () => {
    machineQuery.refetch();
    machineInfoQuery.refetch();
  }

  let content = "<></>";
  let machineName = ""; 
  let comment = "";
  let machineInfo = {};
  let machinestopsQ = {}; 
  let machineQ = {};

  if (machineInfoQuery.isLoading || stopReasonCodesQuery.isLoading || machineQuery.isLoading || machinestopsQuery.isLoading || DatesForTimespanQuery.isLoading || machineTypes.isLoading || aresQuery.isLoading || companyQuery.isLoading) {
    content = <AxionControls.AxionBackdrop />
  } else {


    machineInfo = machineInfoQuery.data[0];
    machineName = machineInfoQuery.data[0]?.machineName;

    if (machineQuery.data !== undefined){
      comment = machineQuery.data?.comment
    }
    content =
      <Box width="98%" ml={1
      }>
        {/* GRID & CHARTS */}


        <DateFilter onDataReceived={handleTimeSpan} startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate}></DateFilter>

        <Infoboxes 
          machineId={machineId} 
          machineComment={comment} 
          machineInfo={machineInfo} 
          companyKey={companyKey} 
          timeSpan={timeSpan} 
          machineName={machineName} 
          company={companyQuery?.data}
          useDateInterval={useDateInterval} 
          startDate={currentStartDate} 
          endDate={currentEndDate} 
          machine={machineQuery?.data}
          handlePlcLogClick={handlePlcLogClick} 
          handleOpenSettingsClick={handleEditSettingsClick} 
          handleOpenCommentClick={handleCommentClick} />

        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="370px"
          gap="20px"
        >
          <Box
            gridColumn="span 8"
            gridRow="span 2"
            marginBottom={2}
          >
            <MachineStopGrid 
              machinestops={machinestopsQuery} 
              machine={machineQuery.data} 
              refetchActivities={props.refetchActivities} />
          </Box>

          <Box height="387px"
            gridColumn="span 4"
            gridRow="span 1"
            marginBottom={2}
            backgroundColor={colors.primary[400]}>
            <Box height="56px" backgroundColor={colors.primary[600]} paddingLeft="30px" paddingTop="20px">
              <FormattedMessage {...messages.downtime} />
            </Box>
            <Box width="100%" height="85%" paddingTop="10px">
              <GroupedStopsChart data={machinestopsQuery} />
            </Box>
          </Box>
          
          
          <Box height="370px"
            gridColumn="span 4"
            gridRow="span 1"
            marginBottom={2}
            backgroundColor={colors.primary[400]}>
            <Box height="56px" backgroundColor={colors.primary[600]} paddingLeft="30px" paddingTop="20px">
              <FormattedMessage {...messages.stopCount} />
            </Box>
            <Box width="100%" height="90%" paddingTop="10px">
              <CountedStopsChart data={machinestopsQuery} />
            </Box>
          </Box>


        </Box>
        <Popup
          openPopup={openCommentPopup}
          setOpenPopup={setOpenCommentPopup}
          title={<FormattedMessage {...messages.editComment} />}>
          <MachineCommentDialog
            setOpenPopup={setOpenCommentPopup}
            setShowBackdrop={setShowBackdrop}
            onCommentCancel={handleCommentCancel}
            refresh={handleRefresh}
            machine={machineQuery.data}
          />
        </Popup>
        <Popup
          openPopup={openSettingsPopup}
          setOpenPopup={setOpenSettingsPopup}
          title={<FormattedMessage {...messages.editSettings} />}>
          <MachineSettingsDialog
            setOpenPopup={setOpenSettingsPopup}
            stopReasonCodes={stopReasonCodesQuery.data}
            machineData={machineSettings}
            areas={aresQuery.data}
            machineTypes={machineTypes.data}
            setMachineSettings={setMachineSettings}
            onSettingsSave={handleSettingsSave}
            onSettingsCancel={handleSettingsCancel} />
        </Popup>
        <Popup
          openPopup={openPlclogPopup}
          setOpenPopup={setopenPlclogPopup}
          title="PLC Log">          
          <PlclogDialog
            machineName={machineName}
          />
        </Popup>
        <Popup
          openPopup={newActivityPopup}
          setOpenPopup={setNewActivityPopup}
          title={<FormattedMessage {...messages.newActivity} />}>
          <NewActivityDialog
            setOpenPopup={setNewActivityPopup}
            activitiesQuery={props.activitiesQuery}
            setSelectedTab={props.setSelectedTab}
            />
        </Popup>
        <AxionControls.AxionBackdrop open={showBackdrop} />
      </Box >
  }

  return (
    <>
      {content}

    </>
  )

}

export default MachineDetails;
