import React from "react";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme, Box, Paper, CircularProgress, IconButton } from "@mui/material";
import { tokens } from "../../../assets/theme";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DeleteIcon from "@mui/icons-material/Delete";


export default function ToolsDataGrid(props) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({});

    const columns = [
      { 
        field: "name", 
        headerName: <FormattedMessage {...messages.nameColumnLabel} />,
        flex: 1,
      },
      { 
        field: "description", 
        headerName: <FormattedMessage {...messages.descriptionColumnLabel} />,
        flex: 4,
      },
      {
        field:"edit", 
        headerName:"",
        renderCell:(params) => {
            return(
                <>
                <IconButton
                    arial-label={<FormattedMessage {...messages.editToolLabel} />}
                    size="small"
                    sx={{
                        "&:hover": {
                        backgroundColor: colors.primary[500],
                        },
                        backgroundColor: colors.primary[700],
                    }}
                    onClick={() => {
                        props.handleEditToolClick(params.row.id)
                    }}
                    >
                        <RemoveRedEyeOutlinedIcon fontSize="inherit" />
                </IconButton>
                <IconButton
                    arial-label={<FormattedMessage {...messages.deleteToolLabel} />}
                    size="small"
                    sx={{
                        "&:hover": {
                        backgroundColor: colors.primary[500],
                        },
                        backgroundColor: colors.primary[700],
                        marginLeft: "10px"
                    }}
                    onClick={() => {
                      props.handleDeleteToolClick(params.row.id)
                    }}
                    >
                        <DeleteIcon fontSize="inherit" />
                </IconButton>
                </>
            )
        },
        width: 90,
      },
      ];
  

    if(props.tools.isLoading) {
        return <Box padding={2}>
          <Paper
            sx={{
              position: "relative",
              borderRadius: "4px",
            }}
          >
            <CircularProgress color="secondary" />
          </Paper>
        </Box>
     }

     return (
      <Box padding={2}>
        <Paper
          sx={{
            position: "relative",
            borderRadius: "4px",
          }}
        >
          <Box 
            m="0px 0 0 0"
            height="76vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
              },
              "& .name.column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-cellContent": {
                fontSize: "13px",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.primary[600],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.primary[600],
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.gray[100]} !important`,
              },
              "& .super-app-theme--11": {
                bgcolor: `${colors.redAccent[500]} !important`,
              },
              "& .super-app-theme--undefined-stop": {
                bgcolor: `${colors.redAccent[500]} !important`,
              },
              "& .MuiDataGrid-columnHeader": {
                fontSize: "15px",
              },
            }}
          >
            { (!props.tools.isLoading) && <DataGrid
              rows={props.tools.data}
              getRowId={ (row) => row.id }
              columns={columns}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                setColumnVisibilityModel(newModel)
              }
            /> }
            { (props.tools.isLoading) && <CircularProgress color="secondary" />}
          </Box>
        </Paper>
      </Box>
    );
}