import {
    Box,
    useTheme,
    TextField,
    Grid,
  } from "@mui/material";
  import React from "react";
  import { tokens } from "../../../assets/theme";
  import AxionTextBoxReadOnly from "../../../components/controls/AxionTextBoxReadOnly"
  
  const DrawingsHeader = ({ itemCode, itemCodeAndName, customerName, status }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
      <Box ml="14px" mr="25px">
        <Grid container spacing={2}>
        <Grid item xs={4}>
        <AxionTextBoxReadOnly
          label="Item"
          variant="filled"
          sx={{ m: 1, flexGrow: 1 }}
       
          value={itemCodeAndName}
        />
        </Grid>
        <Grid item xs={4}>
        <AxionTextBoxReadOnly
          label="Customer Name"
          sx={{ m: 1, flexGrow: 1 }}
          variant="filled"
          
          value={customerName}
        />
       </Grid>
       <Grid item xs={4}>
        <AxionTextBoxReadOnly
          label="Item Status"
          sx={{ m: 1, flexGrow: 1 }}
          variant="filled"
          
          value={status}
        />
       </Grid>
        </Grid>
      </Box>
    );
  };
  export default DrawingsHeader;
  