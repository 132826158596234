//This is the main function of a Chart.

/**
 * @param {Object} container - DOM element in which the chart will be rendered
 * @param {(Object | Array)} data - Data for the chart
 * @returns {Object} Main chart object
 */
import anychart from "anychart"

export const gantChart = (container, data, onClickHandler, colors, startDate, endDate, companyKey, ganttDarkMode, company) => {
  var fromDate = new Date(startDate);
  var toDate = new Date(endDate);

  // Extra height on rows if few machines
  let count = 0;
  data.forEach(element => {
    if (element.children) {
      count += element.children.length;
    }
  });
  if (count <= 10) {
    data.forEach(element => {
    element.rowHeight = 30;
    element.children.forEach(element => {
      element.rowHeight = 40;
    })
  });
  }

  //function to remove anychart credits
  function removeElementsByClass() {
    const elements = document.getElementsByClassName("anychart-credits");
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }

  };

  function isGrouping(item) {
    if (item.get('statusColor') === undefined) {
      return true;
    }
    return false;
  }

  function getAvalability(item) {
    var avail = item.get('avail24h')
    var oeePercentBad = item.get('oeePercentBad');
    var oeePercentWarning = item.get('oeePercentWarning');
    var availabilityPercentWarning = item.get('availabilityPercentWarning');
    var availabilityPercentBad = item.get('availabilityPercentBad');
    var companyOeePercentBad = company.oeePercentBad;
    var companyOeePercentWarning = company.oeePercentWarning;
    var companyAvailabilityPercentWarning = company.availabilityPercentWarning;
    var companyAvailabilityPercentBad = company.availabilityPercentBad;

    var percentBad = 65;
    var percentWarning = 78;

    if (availabilityPercentBad && availabilityPercentBad !== null) {
      percentBad = availabilityPercentBad;
    }
    else if (companyAvailabilityPercentBad && companyAvailabilityPercentBad !== null) {
      percentBad = companyAvailabilityPercentBad;
    }
    else if (oeePercentBad && oeePercentBad !== null) {
      percentBad = oeePercentBad;
    }
    else if (companyOeePercentBad && companyOeePercentBad !== null) {
      percentBad = companyOeePercentBad;
    }

    if (availabilityPercentWarning && availabilityPercentWarning !== null) {
      percentWarning = availabilityPercentWarning;
    }
    else if (companyAvailabilityPercentWarning && companyAvailabilityPercentWarning !== null) {
      percentWarning = companyAvailabilityPercentWarning;
    }
    else if (oeePercentWarning && oeePercentWarning !== null) {
      percentWarning = oeePercentWarning;
    }
    else if (companyOeePercentWarning !== null) {
      percentWarning = companyOeePercentWarning;
    }

    var availColor = "";
    if (avail < percentBad) {
      availColor = "#af3f3b";
    }
    else if (avail > percentBad && avail < percentWarning) {
      availColor = "#ffc107";
    }
    else if (avail >= percentWarning) {
      availColor = "#26b334";
    }
    
    return `<div style="width:40px"><font size='1' color=${availColor}>(${avail}%)</font></div>`
  }

  function getStatusIcon(item) {
    var status = item.get('statusColor')
    var statusColor = "";
    if (status === "Running") {
      statusColor = "#26b334";
    }
    if (status === "Offline") {
      statusColor = "orange";
    }
    if (status === "Stopped") {
      statusColor = "#af3f3b";
    }
    return `<div><i class='fas fa-square' style='color: ${statusColor}'></i></div>`

  }

  function getMaintenance24h(item) {
    var maint24 = item.get('haveMaint24h');
    if (maint24 === 1) {
      return "<div><i class='fas fa-wrench' style='color: orange'></i></div>";
    }
    else {
      return "";
    }
  }


  function getPriority(item) {

    let icon = item.get("priorityIcon");
    switch (icon) {
    case "LooksOne": 
      return "<div style='margin-left:10px'><img src='../../../assets/1.png' height='15px'/></div>";
    case "LooksTwo":
      return "<div style='margin-left:10px'><img src='../../../assets/2.png' height='15px'/></div>";
    case "Looks3":
      return "<div style='margin-left:10px'><img src='../../../assets/3.png' height='15px'/></div>";
    case "Looks4":
      return "<div style='margin-left:10px'><img src='../../../assets/4.png' height='15px'/></div>";
    case "LooksFive": 
      return "<div style='margin-left:10px'><img src='../../../assets/5.png' height='15px'/></div>";
    case "LooksSix":
      return "<div style='margin-left:10px'><img src='../../../assets/6.png' height='15px'/></div>";
    case "LooksSeven":
      return "<div style='margin-left:10px'><img src='../../../assets/7.png' height='15px'/></div>";
    case "LooksEight":
      return "<div style='margin-left:10px'><img src='../../../assets/8.png' height='15px'/></div>";
    case "LooksNine":
      return "<div style='margin-left:10px'><img src='../../../assets/9.png' height='15px'/></div>";
    case "ArrowUpward":
      return "<div style='margin-left:10px'><img src='../../../assets/up.png' height='15px'/></div>";
    case "ArrowDownward":
      return "<div style='margin-left:10px'><img src='../../../assets/down.png' height='15px'/></div>";
    case "ArrowForward":
      return "<div style='margin-left:10px'><img src='../../../assets/right.png' height='15px'/></div>";
    default:
    return "";
    }  
  }

  function labelTextSettingsOverrider(label, item) {
    if (isGrouping(item)) {
      return;
    }

    label.useHtml(true);
    let machineColor = "#cccccc";
    if (ganttDarkMode === "false"){
      machineColor = "#333333";
    }

    label.format(`<div class="gantt-header-container">${getStatusIcon(item)} <div style="min-width:35px"><a href='/machines/{%id}' style='color: ${machineColor}'>{%name}</a></div> ${getAvalability(item)}${getPriority(item)} ${getMaintenance24h(item)}</div>`);
  }

  // Create Gantt project chart.
  var chart = anychart.ganttResource();

  // Set the data for the chart
  chart.data(data, "as-tree");

  // set start splitter position settings
  chart.splitterPosition(175);
  chart.background("#434957");

  // get chart data grid link to set column settings
  var dataGrid = chart.dataGrid();

  if (ganttDarkMode === "true"){
    dataGrid.rowEvenFill("#434957 0.9");
    dataGrid.rowFill("#434957 0.9");
    dataGrid.headerFill("#434957 0.9")
    dataGrid.rowHoverFill("#101624 1");
  } else {
    dataGrid.rowFill("#ffffff 1");
    dataGrid.rowEvenFill("#ffffff 1");
    dataGrid.headerFill("#ffffff 1")
    dataGrid.rowHoverFill("#e9e9e9 0.8");
  }
  dataGrid.columnStroke("2 #101624");
  dataGrid.columnStroke("1 #101624");
  dataGrid.columnStroke("0 #101624");
  dataGrid.rowStroke("#101624");
  


  // hide first column
  dataGrid.column(0).enabled(false);

  // get chart timeline
  var timeLine = chart.getTimeline();

  // set base fill
  timeLine.elements().fill(function () {
    // get status from data item
    var status = this.period.status;
    var statusColor = this.period.color;

    // create fill object
    var fill = {
      // if this element has children, then add opacity to it
      opacity: this.item.numChildren() ? 1 : 0.9
    };
    fill.color = statusColor;
    // set fill color by status


    return fill;
  });
  timeLine.columnStroke(null);
 

  if (ganttDarkMode === "true" ){
    timeLine.rowHoverFill("#101624 1");
    timeLine.rowFill("#434957 0.9");
    timeLine.rowEvenFill("#434957 0.9");
  } else {
    timeLine.rowHoverFill("#e9e9e9 0.8");
    timeLine.rowEvenFill("ffffff 1");
    timeLine.rowFill("#ffffff 1");
  }
  timeLine.rowStroke("#101624 0.8");

  // set base stroke
  timeLine.elements().stroke('none');

  var header = chart.getTimeline().header();
  if (ganttDarkMode === "true"){
    header.fill("#434957");
    header.fontColor("#ffffff");
  } else {  
    header.fill("#ffffff");
    header.fontColor("#434957");
  }
  header.stroke("#101624 0.8");

  // set first column settings
  var firstColumn = dataGrid.column(1);
  firstColumn.labels().hAlign('left');
  firstColumn
    .title('')
    .width(175)
    .labelsOverrider(labelTextSettingsOverrider)
    .labels()
    .format(function () {
      return this.name;
    });

  // set first column settings

  chart.getTimeline().scale().zoomLevels([
    [
      { unit: "hour", count: 1 },
      { unit: "day", count: 1 }
    ]
  ]);
  // set container id for the chart
  //chart.container('chart_container');
  if (companyKey === 2) {
    var header = chart.getTimeline().header();
    header.level(0).format("{%tickValue}{dateTimeFormat:hh a}");
  }
  // configure tooltips of the timeline
  chart.dataGrid().tooltip(false);
  chart.getTimeline().tooltip().useHtml(true);
  if (companyKey === 2) {
    chart.getTimeline().tooltip().format(function() {
      if(!this.period) {return ""}
      return "" +
      `Start: ${anychart.format.dateTime(this.start, "MM/dd/yyyy hh:mm a")}<br> ` +
      `End: ${anychart.format.dateTime(this.end, " MM/dd/yyyy hh:mm a")}<br>` +
      `Duration: ${this.period.duration} minutes<br>` +
      `Stop Reason: <b>${this.period.status}</b><br>` +
      `Item: ${this.period.itemId ?? ""}<br>` +
      `Production order: ${this.period.productionOrderId ?? ""}<br>` +
      `Comment: ${this.period.comment ?? ""}<br>` +
      `Updated By: ${this.period.UpdatedBy ?? ""}`
    });
  }
  if (companyKey === 1) {
    chart.getTimeline().tooltip().format(function() {
      if(!this.period) {return ""}
      return "" +
      `Start: ${anychart.format.dateTime(this.start, "yyyy-MM-dd HH:mm")}<br> ` +
      `Slut: ${anychart.format.dateTime(this.end, "yyyy-MM-dd HH:mm")}<br>` +
      `Antal minuter: ${this.period.duration} minutes<br>` +
      `Stopporsak: <b>${this.period.status}</b><br>` +
      `Artikel: ${this.period.itemId ?? ""}<br>` +
      `Produktionsorder: ${this.period.productionOrderId ?? ""}<br>` +
      `Kommentar: ${this.period.comment ?? ""}<br>` +
      `Uppdaterad av: ${this.period.UpdatedBy ?? ""}`
    });
  }

  //Show if there are any comments
  var tl = chart.getTimeline();
  tl.elements()
    .labels()
    .enabled(true)
    .fontColor('#fff')
    .format(function () {
      var comment = this.period.comment;
      var label = this.label;
      if (comment != null) {
        if (comment.length > 1) {
          comment = '*';
        } else {
          comment = '';
        }
      } else {
        comment = '';
      }
      return comment;

    })
    .textShadow({
      color: '#333333',
      offsetX: '1px',
      offsetY: '1px',
      blurRadius: '1px'
    });

  timeLine.scale().minimum(Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate(), (fromDate.getHours()), fromDate.getMinutes(), fromDate.getSeconds()));
  timeLine.scale().maximum(Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), (toDate.getHours() + 1), toDate.getMinutes(), toDate.getSeconds()));

  chart.listen("rowClick", function (e) {
    e.preventDefault();
  });
  chart.listen("rowDblClick", function (e) {

    if (e.elementType == "periods") {
      //codeStopModal(e.period.id,  parseInt(e.item.get("id")));
      onClickHandler(e.period.id)
      var itemName = e.item.get("name");
    }
  });

  // Set correct Zoom level
  chart.fitAll();

  // Set container for the chart
  chart.container('chart_container');

  chart.background("#101624");
  chart.defaultRowHeight(35);


  // Draw the chart in the container
  chart.draw();
  removeElementsByClass();
  // set the text of the second data grid column

  var column_2 = chart.dataGrid().column(1);
  column_2.labels().useHtml(true);
  column_2.title().fontColor("#ffffff");

  column_2.labels().format(function () {

    var numChildren = this.item.numChildren();
    //var duration = (this.end - this.start) / 1000 / 3600 / 24;
    var customField = " ";
    if (this.getData("custom_field")) {
      customField = "<span style='font-weight:bold'>" +
        this.getData("custom_field") + customField + "</span>";
    }

    var parentText = "<span style='color:gray;font-weight:bold'>" +
      this.name.toUpperCase() + "<span>";

    var childText = "<span style='color:#ffffff;font-weight:bold'>" + this.name + "<span>";


    // identify the resource type and display the corresponding text
    if (numChildren > 0) {
      return parentText;
    } else {
      return childText;
    }

  });

  //Return Chart object
  return chart;
};
