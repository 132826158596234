import React, { useState, useEffect, useRef, forwardRef} from 'react';
import cronstrue from 'cronstrue/i18n';
import {Paper, Tabs,Tab, useTheme, Box} from "@mui/material";
import LabelBox from './labelBox'
import { styled } from '@mui/material/styles';
import { metadata, loadHeaders } from './meta';
import { tokens } from "../../assets/theme"


const defaultValue = ['0','0','00','1/1','*','?','*']

const findTab = (value) => {

  let index = 0

  if((value[3] === '1/1')) {
      index = 0
  } else if((value[3].search('/') !== -1) || (value[5] === 'MON-FRI')) {
      index = 1
  } else if (value[3] === '?') {
      index = 2
  } else if (value[3].startsWith('L') || value[4] === '1/1') {
      index = 3
  }

  return index
}

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(

  ({ theme }) => ({
    
    textTransform: 'none', 
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(20),
    marginRight: theme.spacing(1),
    maxHeight:"20px",
   
    
    //backgroundColor: '#799c4a',
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-selected': {
      color: '#fff',
      backgroundColor: "#aaacb1 !important",
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
    '&.MuiTab-root': {
      backgroundColor: '#0c101b',
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
      maxHeight: "40px !important",
      minHeight: "40px !important",
      
    },

  }),
);


const Cron = forwardRef(({options, locale, onChange, translateFn, value:initValue, showResultText, showResultCron, classes, ...props}, ref) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [headers] = useState(loadHeaders(options))
  const [value, setValue] = useState(initValue? initValue : defaultValue)
  const [tabValue, setTabValue] = useState(findTab(value))
  const [tabWidth, setTabWidth] = useState(100)
  const [tradValue, setTradValue] = useState(initValue? initValue : defaultValue);

  const tabRef = useRef(null)
  const convertToTraditional = (value) => {
    // Split the enhanced expression into its components
    const parts = value.toString().split(',');

    // Map the enhanced components to their traditional counterparts
    const traditionalParts = [
      parts[1], // Minute
      parts[2], // Hour
      parts[3], // Day of Month
      parts[4], // Month
      parts[5], // Day of Week
    ];

    // Join the traditional parts with spaces to form the traditional cron expression
    const traditionalExpression = traditionalParts.join(' ');
    setTradValue(traditionalExpression.replace(/!/g, ','));
  };


  useEffect(() => {
    onChange(value.toString().replace(/,/g,' ').replace(/!/g, ','))

    if(translateFn && !locale) {
        console.log('Warning !!! locale not set while using translateFn');
    }

    setTabWidth(tabRef.current.offsetWidth/headers.length)
    window.addEventListener('resize', handleResize)

   return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    onChange(value.toString().replace(/,/g,' ').replace(/!/g, ','))
    convertToTraditional(value);
  }, [value])

  useEffect(() => {
    if (value !== initValue) setValue(JSON.parse(JSON.stringify(getMetadata().initialCron)))
  }, [tabValue])


  const handleResize = () => {
    setTabWidth(tabRef.current.offsetWidth/headers.length)
  }

  const onValueChange = (value) => {
    setValue((value && value.length)? value : defaultValue);
  }


  const getVal = () => {
      let val = cronstrue.toString(value.toString().replace(/,/g,' ').replace(/!/g, ','), { locale: locale })
      return (val.search('undefined') === -1)? val : '-'
  }

  const getMetadata = () => {

    let selectedMetaData = metadata.find(data => data.component.muiName === `${headers[tabValue]}Cron`)

    if(!selectedMetaData) {
        selectedMetaData = metadata[tabValue];
    }
    if(!selectedMetaData) {
        throw new Error('Value does not match any available headers.');
    }

    return selectedMetaData
  }


  const getComponent = () => {
      const CronComponent = getMetadata().component;
      return <CronComponent translate={translate} value={value} onChange={onValueChange} />;
  }


  const translate = (key) => {
      let translatedText = key;
      if(translateFn) {
          translatedText = translateFn(key);
          if(typeof translatedText !== 'string') {
              throw new Error('translateFn expects a string translation');
          }
      }
      return translatedText;
  }

  const getHeaders = () => {

    const style = {
      width: `${tabWidth}px !important`,
      maxWidth: `${tabWidth}px`,
      minWidth: `${tabWidth}px`,
      maxHeight: "40px",
      minHeight: "40px",

      color: 'rgba(255, 255, 255, 0.7)',
      '&.Mui-selected': {
        color: '#000000',
      },
      '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
      },
      '&.Mui-Tab-root': {
        maxHeight: "40px !important",
        minHeight: "40px !important",
      },

    };

    return headers.map((d, index) => {
      return(
       <StyledTab key={index} label={translate(d)} />
      )
    })
  }

  return (
    <Paper ref={ref} {...props} sx={{backgroundColor:"#4d5362", backgroundImage:"none"}}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', m:'10px' }}>
      <Tabs
        ref={tabRef}
        value={tabValue}
        indicatorColor={colors.dashbordGreen[500]}
        textColor={colors.primary[500]}
        fillWidth
        size="small"
        onChange={(ev, value) => {setTabValue(value)}}
        aria-label="disabled tabs example"
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
        sx={{ maxHeight:"40px !important", minHeight:"40px !important",
          '& .MuiTabs-indicator': {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'transparent',
          },
          '& .MuiTabs-indicatorSpan': {
            maxWidth: 40,
            width: '100%',
            backgroundColor: '#799c4a',
          },
        }}
      >
        
        {getHeaders()}
      </Tabs>
      </Box>
      <Box sx={{margin:2}} className="cron_builder_bordering">{getComponent()}</Box>
      <Box sx={{margin:2}}>
      {showResultText && <LabelBox>
        {getVal()}
      </LabelBox>}
      </Box>

      {showResultCron && <LabelBox>
        {/* {value.toString().replace(/,/g,' ').replace(/!/g, ',')} */}
        {/* {tradValue.toString()} */}
      </LabelBox>}
   
    </Paper>
  )

})

Cron.defaultProps = {
  locale:'en',
  value: defaultValue,
  onChange: (value) => {console.log(value)},
  showResultText: true,
  showResultCron: true
}

export default Cron;