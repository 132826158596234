import React from 'react';
import {Box, Typography} from "@mui/material";


const LabelBox = ({classes, children, variant}) => {
  return (
    <Box
    >
      <Box m="auto">
        <Typography variant="body1" gutterBottom>
          {children}
        </Typography>
      </Box>
    </Box>
  )
}

LabelBox.defaultProps = {
  variant:"label",
}

export default LabelBox;