import React, { useState } from "react";
import {

  useTheme,
    IconButton,
  Paper,
  Box,

} from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import StepContent from "@mui/material/StepContent";
import { tokens } from "../../../assets/theme";
import { styled } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";
import DrawingsHeader from "./header";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

const ViewDrawing = ({ item }) => {
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

//Table theme
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colors.primary[700],
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: colors.primary[500],
    },
    '&:nth-of-type(even)': {
        backgroundColor: colors.primary[400],
      },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  //MOCKUP
  function createData(name) {
    return { name };
  }
  
  const rows = [
    createData('Ritning 1'),
    createData('Ritning 2'),
    createData('Ritning 3'),
    createData('Ritning 4'),
    createData('Ritning 5'),
  ];

//END OF MOCKUP

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
    backgroundColor: colors.primary[500],
  }));

  return (
    <div>
      <DrawingsHeader
        itemCode={item.itemCode}
        itemCodeAndName={`${item.itemCode} - ${item.itemName}`}
        customerName={item.customerName}
        status={item.itemStatusName}
      />
      <Box
        m="20px"
        x={{ maxWidht: 400, backgroundColor: colors.primary[700] }}
        sx={{
          
          "& .Mui-completed": {
            color: "#1b8b1e !important",
          },
          "& .Mui-active": {
            color: `${colors.yellow[600]} !important`,
          },
        }}
      >

<TableContainer sx={{ minWidth: "100%" }}  component={Paper}>
      <Table sx={{ minWidth: "100%" }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>File</StyledTableCell>
            <StyledTableCell align="right">View</StyledTableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="right">

              <IconButton
              arial-label="Edit"
              size="small"
              sx={{
                "&:hover": {
                  backgroundColor: colors.primary[500],
                },
                backgroundColor: colors.primary[700],
              }}
              onClick={() => {
                //openInPopup(item);
              }}
            >
              <RemoveRedEyeOutlinedIcon fontSize="inherit" />
            </IconButton>

              </StyledTableCell>
       
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>


      </Box>
    </div>
  );
};

export default ViewDrawing;
