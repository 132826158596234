import React, { useState } from "react";
import { fetchMachines,patchMachineData } from "../../fetchers/machine";
import { useQuery } from "@tanstack/react-query";
import MachinesGrid from "./components/machinesGrid";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import Popup from "../../components/Popup";
import MachineSettingsDialog from "../machineDetails/components/machineSettingsDialog";
import { FormattedMessage } from "react-intl";
import messages from "./components/messages";
import { getStopReasonCodes } from "../stopReasonCodes/hooks/fetchers";
import { fetchAreas } from "../../fetchers/area";
import { fetchMachineTypes } from "../../fetchers/machineType";
import AxionControls from "../../components/controls/AxionControls";
import { pushErrorMessage } from '../../utils/notifications';

export default function Machines(props) {
    const [ companyKey, setCompanyKey] = useLocalStorage("companyKey", 1);
    const [openSettingsPopup, setOpenSettingsPopup] = useState(false);
    const [machineSettings, setMachineSettings] = useState({});
    const [showBackdrop, setShowBackdrop] = useState(false);

    const machinesQuery = useQuery({
        queryKey: ['machines', companyKey], 
        queryFn: () => fetchMachines(companyKey),
        staleTime: 60000,
        refetchInterval: 60000,
    })

    const stopReasonCodesQuery = useQuery(
        ["stopReasonCodes", companyKey],
        () => getStopReasonCodes(companyKey));
    
    const aresQuery = useQuery(
        ["areas"],
        () => fetchAreas(),
        {
            staleTime: 60000,
            refetchInterval: 60000
        });
    
    const machineTypes = useQuery(
        ["machineTypes"],
        () => fetchMachineTypes());

    const handleEditSettingsClick = (id) => {
        setMachineSettings(machinesQuery.data.find((machine) => machine.id === id));
        setOpenSettingsPopup(true);
    }

    const handleSettingsSave = () => {
        setShowBackdrop(true);
        patchMachineData(machineSettings).then(() => {
            setOpenSettingsPopup(false);
            machinesQuery.refetch();
            setShowBackdrop(false);
        })
            .catch((error) => {
            console.log(error);
            setShowBackdrop(false);
            pushErrorMessage(`Could not update the machine settings.`);
        });
    }
    
    const handleSettingsCancel = () => {
        setOpenSettingsPopup(false);
    }

    if(machinesQuery?.isLoading || stopReasonCodesQuery.isLoading || aresQuery.isLoading || machineTypes.isLoading) {
        return <AxionControls.AxionBackdrop open={true} />
    }

    return (
    <>
        <MachinesGrid 
            machineResult={machinesQuery} 
            handleEditSettingsClick={handleEditSettingsClick}
            />
        <Popup
          openPopup={openSettingsPopup}
          setOpenPopup={setOpenSettingsPopup}
          title={<FormattedMessage {...messages.editSettings} />}>
          <MachineSettingsDialog
            setOpenPopup={setOpenSettingsPopup}
            stopReasonCodes={stopReasonCodesQuery.data}
            machineData={machineSettings}
            areas={aresQuery.data}
            machineTypes={machineTypes.data}
            setMachineSettings={setMachineSettings}
            onSettingsSave={handleSettingsSave}
            onSettingsCancel={handleSettingsCancel} />
        </Popup>
        <AxionControls.AxionBackdrop open={showBackdrop} />
    </>);
}
