import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import { tokens } from "../../../assets/theme";
import CircularProgress from '@mui/material/CircularProgress';
import { ResponsivePieCanvas } from '@nivo/pie'
import messages from "../messages";
import { FormattedMessage, useIntl } from "react-intl";
import {

  useTheme,
  Avatar,
  Grid,
  Typography

} from "@mui/material";
import { fontSize, fontStyle, Stack, width } from "@mui/system";
import { Rotate90DegreesCcwOutlined } from "@mui/icons-material";
function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress  thickness={5} size={60} color="secondary"  variant="determinate" {...props} sx={{marginTop: 1, marginLeft: 1, color:"#799c4a"}} />
      
      <Box
        sx={{
          top: 10,
          left: 10,
          bottom: 2,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}% ${props.text}`  }
        </Typography>
      </Box>
    </Box>
  );
}
function CustomFooterTotalComponent(props) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const intl = useIntl();
  
  return (
    <Grid container sx={{backgroundColor: colors.primary[700]}}> 
    <Grid item  md={8}> 
    <CircularProgressWithLabel text={intl.formatMessage(messages.tFooter)}  value={props.totalT} />  
    <CircularProgressWithLabel text={intl.formatMessage(messages.aFooter)} value={props.totalA} />  
    <CircularProgressWithLabel text={intl.formatMessage(messages.kFooter)} value={props.totalK} />  
    <CircularProgressWithLabel text={intl.formatMessage(messages.takFooter)} value={props.total} /> 

    
    {/* <ResponsivePieCanvas
        data={[{"id":"TAK", "label":"TAK","value":props.total  }]}
        //margin={{ top: 40, right: 200, bottom: 40, left: 80 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        width={100}
        height={100}
        activeOuterRadiusOffset={8}
        colors={{ scheme: 'paired' }}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    0.6
                ]
            ]
        }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor="#333333"
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                size: 4,
                padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            }
        ]}
        fill={[
            {
                match: {
                    id: 'TAK'
                },
                id: 'dots'
            },
           
        ]}
        legends={[
            {
                anchor: 'right',
                direction: 'column',
                justify: false,
                translateX: 140,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 60,
                itemHeight: 14,
                itemTextColor: '#999',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 14,
                symbolShape: 'circle'
            }
        ]}
    /> */}
    
    
    </Grid>
    <Grid item  md={4} justifyContent="flex-end" >
    <Box sx={{ marginTop: "5px", marginBottom: "10px"  }}>
      {/* Running : {props.machinesRunning} Stopped : {props.machinesStopped} Offline: {props.machinesOffline} */}

   <Stack direction="row" spacing={2} justifyContent="flex-end" marginRight={1}>
<Avatar sx={{ backgroundColor: colors.dashbordGreen[600] , color:"#fff", height:"56px", width:"56px" }}>{props.machinesRunning}</Avatar>
<Avatar sx={{ backgroundColor: colors.redAccent[600] , color:"#fff", height:"56px", width:"56px" }}>{props.machinesStopped} </Avatar>
<Avatar sx={{ backgroundColor: colors.gray[600], color:"#fff", height:"56px", width:"56px" }}>{props.machinesPlannedStop} </Avatar>
{/* <Avatar sx={{ backgroundColor: colors.yellow[600], color:"#fff", height:"56px", width:"56px"  }}>{props.machinesOffline} </Avatar> */}

</Stack>

      {/* <Box

marginTop={0}
height={40}
position="center"
sx={{ backgroundColor: colors.dashbordGreen[600], borderRadius: 0 }}
>
<FormControlLabel
  control={<item>{props.machinesRunning}</item>}
  labelPlacement="bottom"
  label="Machines Running"
/>
</Box>

    <Box

    marginTop={0}
    marginLeft={2}
    height={40}
    position="center"
    sx={{ backgroundColor: colors.redAccent[600], borderRadius: 0 }}
  >
    <FormControlLabel
      control={<item>{props.machinesStopped}</item>}
      labelPlacement="bottom"
      label="Machines Stopped"
    />
  </Box>

  <Box

marginTop={0}
marginLeft={2}
height={40}
position="center"
sx={{ backgroundColor: colors.yellow[600], borderRadius: 0 }}
>
<FormControlLabel
  control={<item>{props.machinesOffline}</item>}
  labelPlacement="bottom"
  label="Machines Offline"
/>
</Box>

   */}
  
  </Box>
  </Grid>
  </Grid>
  );
}

CustomFooterTotalComponent.propTypes = {
  total: PropTypes.number
};

export { CustomFooterTotalComponent };
