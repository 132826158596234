import axios from "axios";

export const fetchMachineTypes = async () => {
    const response = await axios.get("api/machinetypes/");
    const machineTypes = response.data;
    return machineTypes;
}

export const updateMachineType = async (machineType) => {
    try {
      const response = await axios.put(`/api/machinetypes/`, machineType);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  };

export const createMachineType = async (machineType) => {
    try {
      const response = await axios.post(`/api/machinetypes/`, machineType);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }

export const deleteMachineType = async (id) => {
    try {
      const response = await axios.delete(`/api/machinetypes/${id}`);
      return response;
    } catch (error) {
      throw error.response.data;
    }
  }