import React, { useState } from "react";
import {
  Stepper,
  Step,
  useTheme,
  StepLabel,
  Typography,
  Button,
  Paper,
  Box,
  Grid,
  Stack,
} from "@mui/material";
import StepContent from "@mui/material/StepContent";
import { tokens } from "../../../assets/theme";
import { styled } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";
import InstructionHeader from "./header";
import { FormattedMessage } from "react-intl";
import messages from "./messages";

const InstructionViewer = ({ instruction }) => {
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
    backgroundColor: colors.primary[500],
  }));
  const mediaItem = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: "left",
    backgroundColor: colors.primary[500],
  }));
  return (
    <div>
      <InstructionHeader
        title={instruction.title}
        item={`${instruction.item} - ${instruction.itemName}`}
        createdBy={instruction.createdBy}
      />
      <Box
        m="20px"
        x={{ maxWidht: 400, backgroundColor: colors.primary[700] }}
        sx={{
          
          "& .Mui-completed": {
            color: "#1b8b1e !important",
          },
          "& .Mui-active": {
            color: `${colors.yellow[600]} !important`,
          },
        }}
      >
        <Stepper m="10px" activeStep={activeStep} orientation="vertical">
          {instruction.steps.map((step, index) => (
            <Step m="10px" key={index} sx={{backgroundColor: colors.primary[500]}}>
              <StepLabel
                sx={{ m: 2 }}
                optional={
                  index === 2 ? (
                    <Typography variant="caption"><FormattedMessage {...messages.lastStep} /></Typography>
                  ) : null
                }
              >
                <Typography variant="h6"><FormattedMessage {...messages.step} /> {index + 1}</Typography>
              </StepLabel>
              <StepContent>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <mediaItem>
                        {step.mediaFiles.map((mediaFile, fileIndex) => (
                          <img
                            style={{
                              maxWidth: "100%",
                              height: "auto",
                              padding: "0",
                              margin: "0",
                              borderRadius: 4,
                            }}
                            src={mediaFile.filePath}
                            alt="no pic"
                          />
                        ))}
                      </mediaItem>
                    </Grid>
                    <Grid item xs={8}>
                      <Item>
                        <Typography variant="body1" gutterBottom>
                          {step.instructionText}
                        </Typography>
                      </Item>
                    </Grid>
                  </Grid>
                </Box>

                <Box sx={{ mb: 2 }}>
                  <div>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{
                        mt: 1,
                        mr: 1,
                        boxShadow: "none",
                        backgroundColor: "#799c4a",
                        "&:hover": {
                          backgroundColor: "#5e7939",
                          borderColor: "#5e7939",
                          boxShadow: "none",
                        },
                      }}
                    >
                      {index === instruction.steps.length - 1
                        ? <FormattedMessage {...messages.finish} />
                        : <FormattedMessage {...messages.continue} />
                        }
                    </Button>
                    <Button
                      disabled={index === 0}
                      onClick={handleBack}
                      sx={{
                        mt: 1,
                        mr: 1,
                        backgroundColor: colors.redAccent[500],
                        color: "white",
                        "&:hover": {
                          backgroundColor: colors.redAccent[700],
                          borderColor: "#0062cc",
                          boxShadow: "none",
                        },
                      }}
                    >
                      <FormattedMessage {...messages.back} />
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === instruction.steps.length && (
          <Box
            sx={{
              p: 2,
              backgroundColor: colors.primary[600],
              color: "#799c4a",
            }}
          >
            <Stack
              direction="row"
              height={45}
              width="50%"
              marginLeft={2}
              spacing={2}
            >
              <Alert
                icon={<CheckIcon fontSize="inherit" sx={{ color: "white" }} />}
                severity="success"
                sx={{ backgroundColor: colors.dashbordGreen[700] }}
              >
                <FormattedMessage {...messages.allStepsCompleted} />
              </Alert>
              <Button
                onClick={handleReset}
                size="small"
                sx={{
                  backgroundColor: colors.yellow[600],
                  color: "white",
                  "&:hover": {
                    backgroundColor: colors.yellow[700],
                    borderColor: "#0062cc",
                    boxShadow: "none",
                  },
                }}
              >
                <FormattedMessage {...messages.reset} />
              </Button>
            </Stack>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default InstructionViewer;
