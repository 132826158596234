import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import Button from "../../components/Button";
import AddIcon from "@mui/icons-material/Add";
import Upload from "@mui/icons-material/Upload";
import { pushErrorMessage } from '../../utils/notifications';
import ProductionItemDataGrid from "./components/productionItemsDataGrid";
import Popup from '../../components/Popup';
import { FormattedMessage } from "react-intl";
import messages from "./components/messages";
import AddEditProductionItemDialog from "./components/addEditProductionItemDialog";
import { tokens } from "../../assets/theme";
import { useQuery } from "@tanstack/react-query";
import CircularProgress from "@mui/material/CircularProgress";
import { useMsal, useAccount } from "@azure/msal-react";
import AxionControls from "../../components/controls/AxionControls";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { fetchProductionItems, updateProductionItem, createProductionItem, deleteProductionItem } from "../../fetchers/productionItems";
import ImportProductionItemDialog from "./components/importProductionItemDialog";

const ProductionItemIndex = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const [companyKey, setCompanyKey] = useLocalStorage("companyKey", 1);
  const [openPopup, setOpenPopup] = useState(false);
  const [openImportPopup, setOpenImportPopup] = useState(false);
  const [productionItem, setProductionItem] = useState({
    id: null,
    companyKey: companyKey,
    name: "",
    itemNo: "",
  });
  const [showBackdrop, setShowBackdrop] = useState(false);

  const productionItemQuery = useQuery(["productionItems", companyKey], () => fetchProductionItems(companyKey),
      {
          staleTime:Infinity
      }
  );

  const handleImportProductionItems = () => {
    setOpenImportPopup(true);
  }

  const handleAddProductionItemClick = () => {
    setProductionItem({
        id: null,
        companyKey: companyKey,
        name: "",
        itemNo: "",
    });
    setOpenPopup(true);
  }

  const handleEditProductionItemClick = (id) => {
    const productionItem = productionItemQuery.data.find((rowData) => rowData.id === id);
    setProductionItem(productionItem);
    setOpenPopup(true);
  }

  const handleDeleteProductionItemClick = (id) => {
    handleDelete(id);
  }

  const handleChange = (event) => {
    const {id, name, value, type, checked} = event.target
    setProductionItem(prev => {
        return {
            ...prev,
            [id ? id : name]: type === "checkbox" ? checked : value
        }
    })
  }

  const handleSave = () => {
    setShowBackdrop(true);
       if (productionItem.id) {
        updateProductionItem(productionItem).then(() => {  
            setOpenPopup(false);
            productionItemQuery.refetch();
            setShowBackdrop(false);
        }).catch((error) => {
            console.log(error);
            setShowBackdrop(false);
            pushErrorMessage(`Cannot update the production item.`);
       });
       } else {
        createProductionItem(productionItem).then(() => {  
            setOpenPopup(false);
            productionItemQuery.refetch();
            setShowBackdrop(false);
        }).catch((error) => {
            console.log(error);
            setShowBackdrop(false);
            pushErrorMessage(`Cannot update the production item.`);
       });
       }
  }   
  
  const handleDelete = (id) => {
    setShowBackdrop(true);
    deleteProductionItem(id).then(() => {
      productionItemQuery.refetch();
      setShowBackdrop(false);
    })
    .catch((error) => {
      console.log(error);
      setShowBackdrop(false);
      pushErrorMessage(`Cannot delete the production item.`);
    });
  }


  if (productionItemQuery.isLoading)
    return <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}><CircularProgress color="secondary" /></div>;

  return (
    <Box maxWidth={1800} >
      <Button
        text={<FormattedMessage {...messages.importProductionItemLabel} />}
        variant="contained" 
        sx={{
          margin: "20px",
          backgroundColor: colors.primary[400],
        }}
        startIcon={<Upload />}
        onClick={handleImportProductionItems}
       />
      <Button
        text={<FormattedMessage {...messages.addProductionItemLabel} />}
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={handleAddProductionItemClick}
        sx={{
        "&:hover": {
            backgroundColor: colors.dashbordGreen[700],
        },
        backgroundColor: colors.dashbordGreen[500],
        color: "#000000",
        position: "absolute",
        right: "10px",
        }} />
      <ProductionItemDataGrid
        productionItems={productionItemQuery}
        handleDeleteProductionItemClick={handleDeleteProductionItemClick}
        handleEditProductionItemClick={handleEditProductionItemClick} />
      <Popup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          maxWidht="sm"
          title={productionItem.id != null ? <FormattedMessage {...messages.editProductionItemLabel} /> : <FormattedMessage {...messages.addProductionItemLabel} />}>
          <AddEditProductionItemDialog
              onChange={handleChange}
              handleSave={handleSave}
              productionItem={productionItem}
              setOpenPopup={setOpenPopup} />
      </Popup>
      <Popup
          openPopup={openImportPopup}
          setOpenPopup={setOpenImportPopup}
          maxWidht="sm"
          title={<FormattedMessage {...messages.importProductionItemLabel} />}>
          <ImportProductionItemDialog
              productionItems={productionItemQuery}
              setOpenPopup={setOpenImportPopup} />
      </Popup>
      <AxionControls.AxionBackdrop open={showBackdrop} />
    </Box>
  );
};

export default ProductionItemIndex;
