import { useFetch } from '../requests/reactQuery';
import { apiRoutes } from '../AppRoutes';
import { pathToUrl } from "../utils/router"

export const useGetGanttData = (companyKey) => 
  
    useFetch(pathToUrl(apiRoutes.getGanttData, { companyKey }),
    undefined, {
      staleTime: 300000,
      refetchInterval: 300000,
    }
    )

