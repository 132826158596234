import React from 'react';
import { useEffect,useState } from "react";
import Axios from 'axios';

const ProfilePicture = () => {

    const [loading, setLoading] = useState(true);
    const [image, setImage] = useState(null);

    useEffect(() => {
        Axios.get('https://graph.microsoft.com/v1.0/me/photo/$value', {
        responseType: 'blob'
        }).then(response => {
            if(response.status === 200)
            {
                if(response !== null) {
                    const url = window.URL || window.webkitURL;
                    setLoading(false) 
                    setImage(url.createObjectURL(response.data));
                    
                }
                else {
                    setLoading(false);
                    setImage("../../assets/user.png");
                }
            }
            if(response.status === 404) {
                setImage(`../../assets/user.png`);
            }
            else
            {
                setImage(`../../assets/user.png`);
            }
        })
        .catch((error) => {
            //console.log(error);
            setImage(`../../assets/user.png`);
        });
    }, []);

     return (<img src={image} alt="profile-user"  widht="80px" height="80px"  style={{ cursor: "pointer", borderRadius: "50%" }} />)
    
}
export default ProfilePicture;
