import { defineMessages } from 'react-intl';

export const scope = 'axionoee.features.instructions.components';

export default defineMessages({
  search: {
    id: `${scope}.instructionsgrid.label.search`,
    defaultMessage: 'Search item',
  },
  add: {
    id: `${scope}.instructionsgrid.label.add`,
    defaultMessage: 'Add Instruction',
  },
  title: {
    id: `${scope}.instructionsgrid.label.title`,
    defaultMessage: 'Title',
  },
  createdBy: {
    id: `${scope}.instructionsgrid.label.createdBy`,
    defaultMessage: 'Created By',
  },
  created: {
      id: `${scope}.instructionsgrid.label.created`,
      defaultMessage: 'Created Date',
  },
  activitiesCount: {
    id: `${scope}.instructionsgrid.label.activitiesCount`,
    defaultMessage: 'Used in activities',
  },
  createInstruction: {
    id: `${scope}.instructionsgrid.label.createInstruction`,
    defaultMessage: 'Create Instruction',
  },
  updateInstruction: {
    id: `${scope}.instructionsgrid.label.updateInstruction`,
    defaultMessage: 'Update Instruction',
  },
  deleteInstructionQ: {
    id: `${scope}.instructionsgrid.label.deleteInstructionQ`,
    defaultMessage: 'Delete Instruction?',
  },
  deleteInstruction: {
    id: `${scope}.instructionsgrid.label.deleteInstruction`,
    defaultMessage: 'Delete Instruction',
  },
  deleteInstructionNotPossible: {
    id: `${scope}.instructionsgrid.label.deleteInstructionNotPossible`,
    defaultMessage: 'Instruction is used in activities and cannot be deleted',
  },
  viewInstruction: {
    id: `${scope}.instructionsgrid.label.viewInstruction`,
    defaultMessage: 'View Instruction',
  },
  item: {
    id: `${scope}.instructionsgrid.label.item`,
    defaultMessage: 'Item',
  },
  step: {
    id: `${scope}.instructionsgrid.label.step`,
    defaultMessage: 'Step',
  },
  lastStep: {
    id: `${scope}.instructionsgrid.label.lastStep`,
    defaultMessage: 'Last step',
  },
  continue: {
    id: `${scope}.instructionsgrid.label.continue`,
    defaultMessage: 'Continue',
  },
  back: {
    id: `${scope}.instructionsgrid.label.back`,
    defaultMessage: 'Back',
  },
  finish: {
    id: `${scope}.instructionsgrid.label.finish`,
    defaultMessage: 'Finish',
  },
  allStepsCompleted: {
    id: `${scope}.instructionsgrid.label.allStepsCompleted`,
    defaultMessage: 'All steps completed - you\'re finished',
  },
  reset: {
    id: `${scope}.instructionsgrid.label.reset`,
    defaultMessage: 'Reset',
  },
});