import React from 'react';
import { useTheme, CircularProgress } from '@mui/material';
import { useQuery  } from "@tanstack/react-query";
import { fetchAllInstructions } from './hooks/fetchers';
import { tokens } from "../../assets/theme";
import InstructionGrid from "./components/instructionsGrid";
import { useLocalStorage } from '../../hooks/useLocalStorage';

const InstructionIndex = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [companyKey, setCompanyKey] = useLocalStorage("companyKey", 1);

  const allInstructionsQuery = useQuery(
    ["allInstructions", companyKey],
    () => fetchAllInstructions(companyKey),
    {
      staleTime: 60000 ,
    }
  );
  if (allInstructionsQuery.isLoading) {
    return <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}><CircularProgress color="secondary" /></div>;
  }
  return (
    <>
      <InstructionGrid allInstructionsQuery={allInstructionsQuery} companyKey={companyKey} />
    </>
  );
};

export default InstructionIndex;
