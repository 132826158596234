import React, {Component, useState, useEffect} from 'react';
import axios from "axios";
import {DayPilot, DayPilotQueue} from "daypilot-pro-react";

const TestQueue  = () => {
    const [unscheduledTasks, setUnscheduledTasks] = useState([]);
    const   durations = [
        {id: 60, name: "1 hour"},
        {id: 90, name: "1.5 hours"},
        {id: 120, name: "2 hours"},
        {id: 150, name: "2.5 hours"},
        {id: 180, name: "3 hours"},
        {id: 210, name: "3.5 hours"},
        {id: 240, name: "4 hours"},
        {id: 270, name: "4.5 hours"},
        {id: 300, name: "5 hours"},
        {id: 330, name: "5.5 hours"},
        {id: 360, name: "6 hours"},
        {id: 390, name: "6.5 hours"},
        {id: 420, name: "7 hours"},
        {id: 450, name: "7.5 hours"},
        {id: 480, name: "8 hours"},
      ];
    const form = [
        {
          name: 'Description',
          id: 'text',
          type: 'text',
        },
        {
          type: 'select',
          id: 'duration',
          name: 'Duration',
          options: durations,
        },
      ];

    const menu = new DayPilot.Menu({
        items: [
          {
            text: "Edit...",
            onClick: args => {
              clickQueueEdit(args.source.data);
            }
          },
          {
            text: "-",
          },
          {
            text: "Delete",
            onClick: args => {
              clickDelete(args.source.data);
            }
          },
          
        ]
      });


    const [config, setConfig] = useState( {contextMenu: menu,
        onEventClick: args => {
            clickQueueEdit(args.e.data);
          },
          onEventMove: async args => {
            console.log(args);
            console.log("Item Moved");
            const {data: item} = await axios.post("/api/work_order_move.php", {
              id: args.e.data.id,
              position: args.position
            });
            if (args.external) {
                console.log("Will remove");
              args.source.events.remove(args.e);
            }
          },     
          onBeforeEventRender: args => {
            const duration = new DayPilot.Duration(args.data.start, args.data.end);
            args.data.html = "";
  
            args.data.areas = [
              {
                top: 11,
                right: 5,
                height: 16,
                width: 16,
                fontColor: "#999",
                symbol: "icons/daypilot.svg#minichevron-down-4",
                visibility: "Visible",
                action: "ContextMenu",
                menu: menu,
                style: "background-color: rgba(255, 255, 255, .5); border: 1px solid #aaa; box-sizing: border-box; cursor:pointer;"
              },
              {
                top: 0,
                left: 6,
                bottom: 0,
                width: 12,
                fontColor: "#999",
                symbol: "icons/daypilot.svg#move-vertical",
                style: "cursor: move",
                visibility: "Hover",
                toolTip: "Drag task to the scheduler"
              },
              {
                top: 3,
                left: 20,
                text: args.data.text
              },
              {
                bottom: 3,
                left: 20,
                text: formatDuration(duration)
              }
            ];
          }
        })


        function startEndFromMinutes(minutes) {
            const start = new DayPilot.Date("2000-01-01");
            const end = start.addMinutes(minutes);
        
            return {
              start,
              end
            };
          }

         function formatDuration(duration) {
            // const duration = DayPilot.Duration.ofMinutes(minutes);
            let result = duration.hours() + "h ";
        
            if (duration.minutes() > 0) {
              result += duration.minutes() + "m";
            }
        
            return result;
          }

          function add(item) {
            this.TestQueue.events.add(item);
          }
        
          function remove(id) {
            this.TestQueue.events.remove(id);
          }

          async function clickQueueEdit(item) {
            //const form = form;
        
            const data = {
              ...item,
              duration: new DayPilot.Duration(item.start, item.end).totalMinutes()
            };
        
            const modal = await DayPilot.Modal.form(form, data);
        
            if (modal.canceled) {
              return;
            }
        
            const params = {
              ...modal.result,
              ...startEndFromMinutes(modal.result.duration)
            }
        
            const {data: updated} = await axios.post("/api/work_order_unscheduled_update.php", params);
        
            this.TestQueue.events.update(updated);
        
          }

          async function clickAdd(ev) {

            //const form = this.form;
        
            const data = {
              text: "Task",
              duration: 60
            };
        
            const modal = await DayPilot.Modal.form(form, data);
        
            if (modal.canceled) {
              return;
            }
        
            const params = {
              ...modal.result,
              ...startEndFromMinutes(modal.result.duration)
            };
        
            const {data: created} = await axios.post("/api/work_order_unscheduled_create.php", params);
        
            add(created);
        
          }

          async function clickDelete(item) {

            await axios.post("/api/work_order_delete.php", {
              id: item.id
            });
        
            remove(item.id);
          }

   


      async function clickAdd(ev) {

        //const form = this.form;
    
        const data = {
          text: "Task",
          duration: 60
        };
    
        const modal = await DayPilot.Modal.form(form, data);
    
        if (modal.canceled) {
          return;
        }
    
        const params = {
          ...modal.result,
          ...this.startEndFromMinutes(modal.result.duration)
        };
    
        const {data: created} = await axios.post("/api/work_order_unscheduled_create.php", params);
    
        this.add(created);
    
      }
  
    useEffect(() => {
      const fetchUnscheduledTasks = async () => {
        const { data } = await axios.get('api/WorkOrders/GetUncheduledWorkOrders/1');
        setUnscheduledTasks(data);
      };
  
      
      fetchUnscheduledTasks();
    }, []);
    


    return (
        <div className={"queue"}>
        
          <DayPilotQueue
            events={unscheduledTasks}
            resources={[{ id: '0', name: 'Unscheduled' }]}
            //config= {config}
            {...config}
           // {...this.state.config}
            //ref={component => this.TestQueue = component}
          />
        </div>
      );
}

export default TestQueue;