import React, { useState, useEffect } from 'react'
import { experimentalStyled as styled } from '@mui/material/styles';
import { Box, Tabs, Tab, Badge } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { fetchActivitesMachine, fetchMachineData } from "../../fetchers/machine";
import { useParams, useSearchParams } from 'react-router-dom';
import MachineDetails from './MachineDetails';
import MachineActivities from './components/machineActivities';
import CircularProgress from "@mui/material/CircularProgress";

const StyledTabs = styled((props) => (


    <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
  ))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: '#799c4a',
  },
  });
  
  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  
    ({ theme }) => ({
      
      textTransform: 'none', 
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(20),
      marginRight: theme.spacing(1),
      //backgroundColor: '#799c4a',
      color: 'rgba(255, 255, 255, 0.7)',
      '&.Mui-selected': {
        color: '#fff',
        backgroundColor: "#434957 !important",
      },
      '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
      },
      '&.MuiTab-root': {
        backgroundColor: '#0c101b',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        
      },
  
    }),
  );


  const MachineDetailsIndex = () => {
    const {id} = useParams(); 
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [searchParams] = useSearchParams();
    const [activityFilter, setActivityFilter] = useState("");
    const [statusFilter, setStatusFilter] = useState("");
  
    useEffect(() => {
      setSelectedTab(Number(searchParams.get('tab')) || 0);
    }, [searchParams])

    const machineQuery = useQuery(
      ["machine", id],
      () => fetchMachineData(id));

    const activitiesQuery = useQuery(
        ["machineActivities", id],
        () => fetchActivitesMachine(id),
        {
          staleTime: 60000,
          refetchInterval: 60000
        }
      );

    let filteredActivitiesResult = activitiesQuery.data?.filter((activity) => {
      if(activityFilter === "") return true;
      else if(activity?.machine?.machineName?.toLowerCase().includes(activityFilter.toLowerCase())) return true;
      else if(activity?.machine?.machineType?.machineTypeName?.toLowerCase().includes(activityFilter.toLowerCase())) return true;
      else if(activity?.title?.toLowerCase().includes(activityFilter.toLowerCase())) return true;
      else if(activity?.tool?.name?.toLowerCase().includes(activityFilter.toLowerCase())) return true;
      else if(activity?.productionItem?.itemNo?.toLowerCase().includes(activityFilter.toLowerCase())) return true;
      return false;
    });

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
      };

    if (activitiesQuery.isLoading || machineQuery.isLoading) {
        return <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}><CircularProgress color="secondary" /></div>;
    }

    var showOnlyActivities = false;
    if(machineQuery.data?.machineType?.showInActivitySpecialList === true && selectedTab !== 1) {
      showOnlyActivities = true;
      handleTabChange(null, 1);
    }

    return (
      <Box sx={{ width: '100%' }} ml={1}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', m:'10px' }}>
          <StyledTabs value={selectedTab} onChange={handleTabChange} aria-label="styled tabs example">
            { showOnlyActivities ? <StyledTab label="Dashboard" disabled /> : <StyledTab label="Dashboard" /> }
            <StyledTab label={<Badge badgeContent={activitiesQuery.data.length} color="warning" >Activities</Badge>} />
            { showOnlyActivities ? <StyledTab label="Reports" disabled /> : <StyledTab label="Reports" /> }
          </StyledTabs>
        </Box>
        <Box>
          {selectedTab === 0 && <MachineDetails 
                                  machineId={id} 
                                  refetchActivities={activitiesQuery?.refetch} 
                                  activitiesQuery={activitiesQuery} 
                                  setSelectedTab={setSelectedTab} />}
          {selectedTab === 1 && <MachineActivities 
                                  activitesQuery={activitiesQuery} 
                                  filteredResult={filteredActivitiesResult} 
                                  activityFilter={activityFilter} 
                                  statusFilter={statusFilter}
                                  setActivityFilter={setActivityFilter}
                                  setStatusFilter={setStatusFilter}
                                  hideStatusFilter={true} />}
          {selectedTab === 2 && <MachineDetails 
                                  machineId={id} 
                                  refetchActivities={activitiesQuery?.refetch} 
                                  activitiesQuery={activitiesQuery} />}
        </Box>
      </Box>
  
  
  
    );

  }

  export default MachineDetailsIndex;

