import React from 'react';
import RecurringTaskForm from '../test';

const Tasks = () => {
  const handleCreateTask = (task) => {
    console.log('Created task:', task);
  };

  return (
    <div>
      <RecurringTaskForm onSubmit={handleCreateTask} />
    </div>
  );
};

export default Tasks;
