import axios from "axios"
export const api = {
  get: (url, params) =>
    axios.get(url, {
 
      ...params
    }),
  post: (url, data) =>
    axios.post(url, data, {
      headers: {
        token: "a"
      }
    }),
  patch: (url, data) =>
    axios.patch(url, data, {
      headers: {
        token: "a"
      }
    }),
  delete: url =>
    axios.delete(url, {
      headers: {
        token: "a"
      }
    })
}
