import { defineMessages } from 'react-intl';

export const scope = 'axionoee.features.productionItems.components';

export default defineMessages({
    save: {
        id: `${scope}.label.save`,
        defaultMessage: 'Save',
    },
    addProductionItemLabel: {
        id: `${scope}.label.addProductionItem`,
        defaultMessage: 'Add production item',
    },
    editProductionItemLabel: {
        id: `${scope}.label.editProductionItem`,
        defaultMessage: 'Edit production item',
    },
    deleteProductionItemLabel: {
        id: `${scope}.label.deleteProductionItem`,
        defaultMessage: 'Delete production item',
    },
    nameColumnLabel: {
        id: `${scope}.grid.column.name`,
        defaultMessage: 'Name',
    },
    itemNoColumnLabel: {
        id: `${scope}.grid.column.itemNo`,
        defaultMessage: 'ItemNo',
    },
    importProductionItemDescription: {
        id: `${scope}.importProductionItemDescription`,
        defaultMessage: 'You can import items from an excel file by using'
    },
    importProductionItemDescriptionLinkText: {
        id: `${scope}.importProductionItemDescriptionLinkText`,
        defaultMessage: 'this template'
    },
    upload: {
        id: `${scope}.upload`,
        defaultMessage: 'Upload'
    },   
    fileImportSuccess: {
        id: `${scope}.fileImportSuccess`,
        defaultMessage: 'Production Items imported successfully'
    },
    fileImportError: {
        id: `${scope}.fileImportError`,
        defaultMessage: 'Could not process the uploaded file'
    },
    importProductionItemLabel: {
        id: `${scope}.importProductionItemLabel`,
        defaultMessage: 'Import Production items using Excel'
    },
});