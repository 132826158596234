import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { fetchMachineInfo } from "../fetchers/machine";
import { fetchCompany } from "../fetchers/company";
import { useLocalStorage } from "../hooks/useLocalStorage";
import '../features/machineDashboard/css/flashingBackground.css';

export default function MachineDashboardPage(props) {
    const [companyKey, setCompanyKey] = useLocalStorage("companyKey", 1);
    const {id} = useParams(); 

    const machineInfoQuery = useQuery(
        ["machineInfo", id],
        () => fetchMachineInfo(id),
        {
          staleTime: 60000,
          refetchInterval: 60000
        }
      );
    
    const companyQuery = useQuery(["company", companyKey], () => fetchCompany(companyKey));
    
    if(machineInfoQuery.isLoading || companyQuery.isLoading) return (<div>Loading...</div>);

    if (machineInfoQuery?.data[0]?.machineStatus === 'Stopped') {
        return ( 
            <div className="flashing-background full-screen">
                <p>{machineInfoQuery?.data[0]?.machineName}<br />Stopped<br />({machineInfoQuery?.data[0]?.currentStop})</p>
            </div>
        );
    }

    if (machineInfoQuery?.data[0]?.machineStatus === 'Offline') {
        return ( 
            <div className="warning-background full-screen">
                <p>{machineInfoQuery?.data[0]?.machineName}<br />Offline</p>
            </div>
        );
    }
    
    return (
        <iframe className="full-screen" title="machine-dashboard-iframe" src={companyQuery?.data?.grafanaBaseUrl.replace("{machineName}", machineInfoQuery?.data[0]?.machineName)}></iframe>
    );
}
