import React from "react";
import { useState } from "react";
import { Box, Paper, useTheme, IconButton, Link } from '@mui/material';
import { tokens } from "../../../assets/theme";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import AxionControls from "../../../components/controls/AxionControls";
import RemoveRedEyeOutlined from "@mui/icons-material/RemoveRedEyeOutlined";

export default function MachinesGrid(props) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate(); 
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
    const [sortModelStorage, setSortModelStorage] = useState([
        { field: "machineName", sort: "asc" }
      ]);

    const columns = [
        { 
          field: "machineName", 
          headerName: <FormattedMessage {...messages.machineNameColumn} />,
          flex: 0.5,
          renderCell: ({ id, value, field }) => {
            return <Link href={`/machines/${id}`} sx={{color: '#fff', textDecoration: 'underline'}}>{value}</Link>
          },
        },
        { 
          field: "description", 
          headerName: <FormattedMessage {...messages.descriptionColumn} />,
          flex: 2,
        },
        { 
          field: "machineTypeName", 
          headerName: <FormattedMessage {...messages.machineTypeColumn} />,
          flex: 2,
          valueGetter: ({ row }) => row.machineType?.machineTypeName
        },
        { 
          field: "url", 
          headerName: <FormattedMessage {...messages.urlColumn} />,
          flex: 2,
        },
        { 
            field: "machineStatus", 
            headerName: <FormattedMessage {...messages.machineStatusColumn} />,
            flex: 1,
        },
        {
          field:"view", 
          headerName:"",
          flex: 1,
          renderCell:(params) => {
              return(
                  <>
                    <IconButton
                      arial-label={<FormattedMessage {...messages.showMachine} />}
                      size="small"
                      sx={{
                          "&:hover": {
                          backgroundColor: colors.primary[500],
                          },
                          backgroundColor: colors.primary[700],
                          marginLeft: "10px",
                      }}
                      onClick={() => {
                          props.handleEditSettingsClick(params.row.id);
                      }}
                      >
                          <SettingsOutlinedIcon fontSize="inherit" />
                    </IconButton>
                </>
              )
          },
        },
        ];

    if(props.machineResult?.isLoading) {
        return <AxionControls.AxionBackdrop open={true} />
    }

    return (
        <Box padding={2} paddingTop={8}>
          <Paper
            sx={{
              position: "relative",
              borderRadius: "4px",
            }}
          >
            <Box 
              m="0px 0 0 0"
              height="76vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                },
                "& .name.column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-cellContent": {
                  fontSize: "13px",
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.primary[600],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.primary[600],
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.gray[100]} !important`,
                },
                "& .super-app-theme--11": {
                  bgcolor: `${colors.redAccent[500]} !important`,
                },
                "& .super-app-theme--undefined-stop": {
                  bgcolor: `${colors.redAccent[500]} !important`,
                },
                "& .MuiDataGrid-columnHeader": {
                  fontSize: "15px",
                },
              }}
            >
              { (!props.machineResult?.isLoading) && <DataGrid
                rows={props.machineResult?.data}
                sortModel={sortModelStorage}
                onSortModelChange={(newModel) => setSortModelStorage(newModel)}
                columns={columns}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)
                }
              /> }
            </Box>
          </Paper>
        </Box>
    );
}