import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import AxionControls from "../../../components/controls/AxionControls";
import { Box, Button, Paper, Grid, useTheme } from "@mui/material";
import messages from "./messages";
import { useMsal, useAccount } from "@azure/msal-react";
import { patchMachineStop } from "../../../fetchers/machine";
import { pushErrorMessage } from "../../../utils/notifications";
import { tokens } from "../../../assets/theme";
import { pushNotification } from "../../../utils/notifications";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import dayjs from "dayjs";

export default function MachineStopEditDialog(props) {

    const { accounts } = useMsal();
    const account = useAccount(accounts[0]);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [companyKey, setCompanyKey] = useLocalStorage("companyKey", 1);
    let locale = (companyKey === 2)? 'en-US': 'sv-SE';

    const [machineStopPart, setMachineStopPart] = useState({
        Id: props.machineStop.id,
        stopReasonCodeId: props.machineStop.stopReasonCodeId,
        comment: props.machineStop.comment,
        updatedBy: account.username,
        commentedBy: account.username
    });

    const handleChange = (event) => {
        const { id, name, value, type, checked } = event.target
        setMachineStopPart(prev => {
            return {
                ...prev,
                [id ? id : name]: type === "checkbox" ? checked : value
            }
        })
    }

    const handleSave = () => {
        props.setShowBackdrop(true);
        const machineStopPartToSave = machineStopPart
        if (machineStopPartToSave.comment && machineStopPart.comment.length > 0) {
            machineStopPartToSave.commentedBy = account.username
        } else {
            machineStopPartToSave.commentedBy = null
        }
        setMachineStopPart(machineStopPartToSave);
        patchMachineStop(machineStopPartToSave).then((response) => {
            props.refresh();
            props.setOpenPopup(false);
            props.setShowBackdrop(false);
            if(response && response.activityTriggered) {
                pushNotification(<FormattedMessage {...messages.activityTriggered} />);
                props.refetchActivities();
            }
        })
            .catch((error) => {
                console.log(error);
                props.setShowBackdrop(false);
                pushErrorMessage(`Could not update the stop.`);
            });
    }

    const handleSplit = () => {
        props.setOpenPopup(false);
        props.handleSplit(machineStopPart.Id);
    }

    function formatDate(date) {
        locale = (companyKey === 2)? 'en-US': 'sv-SE';
        return date ? new Date(date).toLocaleDateString(locale) : "";
    }
    function formatFullTime(date) {
        return date ? new Date(date).toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit', second: '2-digit' }) : "";
    }
    function formatFullDateTime_old(date) {
        let formattedDate = formatDate(date) + " " + formatFullTime(date);
        return formattedDate;
    }
    function formatFullDateTime(date) {
        let formattedDate = dayjs(date).format("YYYY-MM-DD HH:mm:ss");
        if (companyKey === 2) {
            formattedDate = dayjs(date).format("MM/DD/YYYY hh:mm:ss A");
        }
        return date ? formattedDate : "";
    }

    function convertTZ(date, tzString) {
        return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
    }
    function getUSDateAndTime(date) {
        return convertTZ(date, "America/New_York");


    }

    const createdby = props.machineStop.commentCreatedBy != null ? "Last updated by: " + props.machineStop.commentCreatedBy : "";
    const updatedDate = props.machineStop.commentCreatedBy != null ? formatFullDateTime(props.machineStop.updatedDate) : "";

    return (
        <>
            <Box
                sx={{
                    p: 1,
                    m: 1
                }}>
                <Box sx={{ marginTop: 2, width: 400 }}>
                    <AxionControls.AxionSelect
                        options={props.stopReasonCodes.map((item) => {
                            return {
                                id: item.id,
                                title: item.name
                            }
                        }
                        )}
                        showNoneOption={false}
                        name="stopReasonCodeId"
                        label={<FormattedMessage {...messages.stopReasonCode} />}
                        value={machineStopPart.stopReasonCodeId}
                        onChange={handleChange}
                    />
                </Box>
                <Box>
                    <AxionControls.AxionTextBoxMultiline
                        name="comment"
                        label={<FormattedMessage {...messages.comment} />}
                        value={machineStopPart.comment}
                        onChange={handleChange}
                    />
                </Box>
                <Paper
                    sx={{
                        marginTop: "20px",
                        position: "relative",
                        borderRadius: "4px",
                        backgroundColor: colors.primary[700],
                    }}
                >
                    <Grid container p={2}>
                        <Grid item xs={2}>
                            <FormattedMessage {...messages.fromDate} />: 
                        </Grid>
                        <Grid item xs={10}>                            
                            {(companyKey === 2) ? formatFullDateTime(props.machineStop?.fromDateUs): formatFullDateTime(props.machineStop?.fromDate)}
                        </Grid>
                        <Grid item xs={2}>
                            <FormattedMessage {...messages.toDate} />: 
                        </Grid>
                        <Grid item xs={10}>
                            {(companyKey === 2) ? formatFullDateTime(props.machineStop?.toDateUs): formatFullDateTime(props.machineStop?.toDate)}
                        </Grid>
                        <Grid item xs={2}>
                            <FormattedMessage {...messages.duration} />: 
                        </Grid>
                        <Grid item xs={10}>
                            { props.machineStop.minutesDown } <FormattedMessage {...messages.minutes} />
                        </Grid>
                        <Grid item xs={2}>
                            <FormattedMessage {...messages.item} />: 
                        </Grid>
                        <Grid item xs={10}>
                            { props.machineStop.itemId }
                        </Grid>
                        <Grid item xs={2}>
                            <FormattedMessage {...messages.productionOrder} />: 
                        </Grid>
                        <Grid item xs={10}>
                            { props.machineStop.productionOrderId }
                        </Grid>
                        <Grid item xs={2}>
                            <FormattedMessage {...messages.updated} />: 
                        </Grid>
                        <Grid item xs={10}>
                            { formatFullDateTime(getUSDateAndTime(props.machineStop?.updatedDate))}
                        </Grid>
                        <Grid item xs={2}>
                            <FormattedMessage {...messages.updatedBy} />: 
                        </Grid>
                        <Grid item xs={10}>
                            { props.machineStop.updatedBy}
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    p: 1,
                    m: 1
                }}>
                <Box sx={{ flexDirection: 'row' }}>
                    <Button
                        variant="contained"
                        sx={{marginRight: 2}}
                        onClick={handleSave}>
                        <FormattedMessage {...messages.save} />
                    </Button>

                    {
                    // companyKey === 1 && 
                    <Button
                        variant="contained"
                        onClick={handleSplit}>
                        <FormattedMessage {...messages.splitStop} />
                    </Button>
                    }
                </Box>
                <Box justify="flex-end" display="flex" alignItems="center">
                    <Box paddingLeft={1}>{createdby}</Box>
                    <Box paddingLeft={1}>{updatedDate}</Box>
                </Box>
            </Box>
        </>
    )
}