import axios from "axios";

export const getStopReasonCodes = async (companyKey) => {
    try {
        const response = await axios.get(`/api/stopReasonCodes/?companyKey=${companyKey}`);
        return response.data;
    } 
    catch (error) {
        throw error.response.data;
    }
}

export const updateStopResonCode = async (stopReasonCode) => {
    try {
      const response = await axios.put(`/api/stopReasonCodes/`, stopReasonCode);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  };

export const createStopResonCode = async (stopReasonCode) => {
    try {
      const response = await axios.post(`/api/stopReasonCodes/`, stopReasonCode);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }

export const deleteStopResonCode = async (id) => {
    try {
      const response = await axios.delete(`/api/stopReasonCodes/${id}`);
      return response;
    } catch (error) {
      throw error.response.data;
    }
  }