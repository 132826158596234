
/**
 * ActivitiesDashboard component displays a dashboard for activities.
 *
 * @param {Object} props - The component props.
 * @returns {JSX.Element} - The rendered component.
 */
import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../../assets/theme";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TimerIcon from "@mui/icons-material/Timer";
import BarChart from "../../../components/BarChart";
import StatBox from "../../../components/StatBox";
import moment from "moment-timezone";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import SpeedIcon from '@mui/icons-material/Speed';
import EditNotificationsOutlinedIcon from '@mui/icons-material/EditNotificationsOutlined';
import ActivitiesGrid from "./activitiesGrid";
import { lateInitialState, onlyOpenInitialState } from "./gridInitialStates";

const getOpenActivitiesByMachine = (activities) => {
  const openActivities = activities.filter(activity => activity.activityStatusId === 1);

  const groupedActivities = openActivities.reduce((acc, activity) => {
      const machineName = activity.machine.machineName;
      if (!acc[machineName]) {
          acc[machineName] = 0;
      }
      acc[machineName]++;
      return acc;
  }, {});

  return Object.keys(groupedActivities).map(machine => ({
      machine: machine,
      openActivity: groupedActivities[machine],
      openActivityColor: "#4cceac"
  }));
};

const getAutoClosedOrCancelledByMachineTimeSpan = (activities) => {


const groupedActivities = activities.reduce((acc, activity) => {
    const machineName = activity.machine.machineName;
    if (!acc[machineName]) {
        acc[machineName] = 0;
    }
    acc[machineName]++;
    return acc;
    }, {});

    return Object.keys(groupedActivities).map(machine => ({
      machine: machine,
      openActivity: groupedActivities[machine],
      openActivityColor: "#4cceac"
  }));
};


function ActivitiesDashboard(props) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [openActivitiesFilter, setOpenActivitiesFilter] = useState("");
  const [lateActivitiesFilter, setLateActivitiesFilter] = useState("");

  const filterRows = (rows) => {
    return rows.filter((row) => {
      if (row.closed === null) {
        const startDate = moment(row.created);
        const endDate = moment(row.maxClosedDate);
        const timeLeft = endDate.diff(moment(), "hours", true);
        const duration = endDate.diff(startDate, "hours", true);
        return timeLeft < duration / 2;
      }
      return false;
    });
  };

  const filteredOpenActivitiesResult = props.activitiesResult.data?.filter((activity) => {
    if(openActivitiesFilter === "") return true;
    else if(activity?.machine?.machineName?.toLowerCase().includes(openActivitiesFilter.toLowerCase())) return true;
    else if(activity?.machine?.machineType?.machineTypeName?.toLowerCase().includes(openActivitiesFilter.toLowerCase())) return true;
    else if(activity?.title?.toLowerCase().includes(openActivitiesFilter.toLowerCase())) return true;
    else if(activity?.tool?.name?.toLowerCase().includes(openActivitiesFilter.toLowerCase())) return true;
    else if(activity?.productionItem?.itemNo?.toLowerCase().includes(openActivitiesFilter.toLowerCase())) return true;
    return false;
  });

  const filteredLateActivitiesResult = props.activitiesResult.data?.filter((activity) => {
    if(lateActivitiesFilter === "") return true;
    else if(activity?.machine?.machineName?.toLowerCase().includes(lateActivitiesFilter.toLowerCase())) return true;
    else if(activity?.machine?.machineType?.machineTypeName?.toLowerCase().includes(lateActivitiesFilter.toLowerCase())) return true;
    else if(activity?.title?.toLowerCase().includes(lateActivitiesFilter.toLowerCase())) return true;
    else if(activity?.tool?.name?.toLowerCase().includes(lateActivitiesFilter.toLowerCase())) return true;
    else if(activity?.productionItem?.itemNo?.toLowerCase().includes(lateActivitiesFilter.toLowerCase())) return true;
    return false;
  });

  return (
    <Box mr={4} ml={2}>
      <Box display="flex" justifyContent="start" alignItems="center"></Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={props.activitiesResult.data 
              .filter((x) =>  x.closed === null )
              .length.toString()}
            subtitle={<FormattedMessage {...messages.openActivities} />}
            progress="0.75"
            increase="+14%"
            icon={
              <EditNotificationsOutlinedIcon sx={{ color: colors.gray[300], fontSize: "50px" }} />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={props.activitiesResult.data 
              .filter((x) =>  moment(x.created).isBetween(moment(props.timeSpanStartDate), moment(props.timeSpanEndDate) ))
              .length.toString()}
            subtitle={<FormattedMessage {...messages.createdActivities} />}
            progress="0.75"
            increase="+14%"
            icon={
              <TimerIcon sx={{ color: colors.gray[300], fontSize: "50px" }} />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={props.activitiesResult.data
              .filter((x) => x.activityStatus.text === "Completed" && moment(x.closed).isBetween(moment(props.timeSpanStartDate), moment(props.timeSpanEndDate) ))
              .length.toString()}
            subtitle={<FormattedMessage {...messages.completedActivities} />}
            progress="0.50"
            increase="+21%"
            icon={
              <CheckCircleIcon
                sx={{ color: colors.dashbordGreen[600], fontSize: "50px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={filterRows(props.activitiesResult.data).length.toString()}
            subtitle={<FormattedMessage {...messages.lateActivities} />}
            progress="0.30"
            increase="+5%"
            icon={
              <ErrorIcon sx={{ color: colors.yellow[600], fontSize: "50px" }} />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={props.activitiesResult.data
              .filter(
                (x) =>
                  x.activityStatus.text === "Not Completed" && moment(x.closed).isBetween(moment(props.timeSpanStartDate), moment(props.timeSpanEndDate) )
              )
              .length.toString()}
            subtitle={<FormattedMessage {...messages.uncompletedActivities} />}
            progress="0.80"
            increase="+43%"
            icon={
              <CancelIcon
                sx={{ color: colors.redAccent[600], fontSize: "50px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={(props.activitiesResult.data.filter((x) => x.activityStatus.text === "Completed" && moment(x.closed).isBetween(moment(props.timeSpanStartDate), moment(props.timeSpanEndDate) )).length /(props.activitiesResult.data
              .filter(
                (x) =>
                  x.activityStatus.text === "Not Completed" && moment(x.closed).isBetween(moment(props.timeSpanStartDate), moment(props.timeSpanEndDate) )
              )
              .length + props.activitiesResult.data
              .filter((x) => x.activityStatus.text === "Completed" && moment(x.closed).isBetween(moment(props.timeSpanStartDate), moment(props.timeSpanEndDate) ))
              .length)*100).toFixed(0).toString() +" %"}
            subtitle={<FormattedMessage {...messages.completedInTime} />}
            progress="0.80"
            increase="+43%"
            icon={
              <SpeedIcon
                sx={{ color: colors.blueAccent[600], fontSize: "50px" }}
              />
            }
          />
        </Box>

        {/* ROW 2 */}
        <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.gray[100]}
              >
                <FormattedMessage {...messages.openActivitesByMachine} />
              </Typography>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
          <BarChart 
            isDashboard={true} 
            data={getOpenActivitiesByMachine(props.activitiesResult.data)}
            />
          </Box>
        </Box>
        <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.gray[100]}
              >
                <FormattedMessage {...messages.cancelledActivitesByMachine} />
              </Typography>
            </Box>
           
          </Box>
          <Box height="250px" m="-20px 0 0 0">
          <BarChart isDashboard={true} data= {getAutoClosedOrCancelledByMachineTimeSpan(props.activitiesResult.data
              .filter(
                (x) =>
                  (x.activityStatus.text === "Not Completed" || x.activityStatus.text === "Cancelled") && moment(x.closed).isBetween(moment(props.timeSpanStartDate), moment(props.timeSpanEndDate))))} />
          </Box>
        </Box>

        {/* ROW 3 */}
        <Box
          gridColumn="span 6"
          gridRow="span 2"
          height={400}
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.gray[100]}
            p="15px"
          >
            <Typography color={colors.gray[100]} variant="h5" fontWeight="600">
            <FormattedMessage {...messages.lateActivities} />
            </Typography>
          </Box>
         <ActivitiesGrid 
            activitiesResult={props.activitiesResult} 
            filteredResult={filteredLateActivitiesResult} 
            InitialState ={lateInitialState} 
            activityFilter={lateActivitiesFilter} 
            setActivityFilter={setLateActivitiesFilter}
            hideStatusFilter={true} />
        </Box>
        <Box
          gridColumn="span 6"
          gridRow="span 2"
          height={400}
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.gray[100]}
            p="15px"
          >
            <Typography color={colors.gray[100]} variant="h5" fontWeight="600">
              <FormattedMessage {...messages.openActivities} />
            </Typography>
          </Box>
         <ActivitiesGrid 
            activitiesResult={props.activitiesResult} 
            filteredResult={filteredOpenActivitiesResult} 
            InitialState ={onlyOpenInitialState} 
            activityFilter={openActivitiesFilter} 
            setActivityFilter={setOpenActivitiesFilter}
            hideStatusFilter={true} />
        </Box>
      </Box>
    </Box>
  );
}

export default ActivitiesDashboard;
