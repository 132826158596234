import React, { useState } from "react";
import {
  Stepper,
  Step,
  useTheme,
  StepLabel,
  Typography,
  Button,
  Box,
  Grid,
  Stack,
  Link
} from "@mui/material";
import StepContent from "@mui/material/StepContent";
import { tokens } from "../../../assets/theme";

import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";
import ActivityHeader from "./ActivityHeader";
import AxionControls from "../../../components/controls/AxionControls";
import moment from "moment";

const ActivityViewer = (props) => {
  console.log(moment.tz(moment(), "Europe/Stockholm"));
  const { activity, setShowBackdrop, setOpenPopup } = props;
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div>
      <ActivityHeader
        setOpenPopup={props.setOpenPopup}
        setShowBackdrop={props.setShowBackdrop}
        activity={props.activity}
        activityResult={props.activityResult}

      />
      <Box
        m="20px"
        x={{ maxWidht: 400, backgroundColor: colors.primary[700] }}
        sx={{

          "& .Mui-completed": {
            color: "#1b8b1e !important",
          },
          "& .Mui-active": {
            color: `${colors.yellow[600]} !important`,
          },
        }}
      >
        <Stepper m="10px" activeStep={activeStep} orientation="vertical">
          {props.activity.activitySteps.map((step, index) => (
            <Step m="10px" key={index} sx={{ backgroundColor: colors.primary[500] }}>
              <StepLabel
                sx={{ m: 2 }}
                optional={
                  index === 2 ? (
                    <Typography variant="caption">Last step</Typography>
                  ) : null
                }
              >
                <Typography variant="h6">Step {index + 1}</Typography>
              </StepLabel>
              <StepContent>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>

                    <Grid item xs={4}>
                      <Box>Picture</Box>
                      <Box justifyContent="center" display="flex">
                        {step.activityMediaFiles.map((mediaFile, fileIndex) => (
                          <a href={"/api/job/activities/image?imageurl=" + encodeURIComponent(mediaFile.fileName) + "&type=" + mediaFile.fileType} target="_blank" rel="noopener noreferrer">
                            <img
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                // height: "auto",
                                padding: "0",
                                margin: "0",
                                marginTop: 11,
                                borderRadius: 4,
                              }}
                              src={mediaFile.fileName}
                              alt="no pic"
                            />
                          </a>
                        ))}
                      </Box>
                    </Grid>
                    <Grid item xs={8}>
                      Instruction text
                      <AxionControls.AxionMultiLineText
                        disabled={true}
                        defaultValue={step.text} rows={15} />

                    </Grid>
                  </Grid>
                </Box>

                <Box sx={{ mb: 2 }}>
                  <div>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{
                        mt: 1,
                        mr: 1,
                        boxShadow: "none",
                        backgroundColor: "#799c4a",
                        "&:hover": {
                          backgroundColor: "#5e7939",
                          borderColor: "#5e7939",
                          boxShadow: "none",
                        },
                      }}
                    >
                      {index === props.activity.activitySteps.length - 1
                        ? "Finish"
                        : "Continue"}
                    </Button>
                    <Button
                      disabled={index === 0}
                      onClick={handleBack}
                      sx={{
                        mt: 1,
                        mr: 1,
                        backgroundColor: colors.redAccent[500],
                        color: "white",
                        "&:hover": {
                          backgroundColor: colors.redAccent[700],
                          borderColor: "#0062cc",
                          boxShadow: "none",
                        },
                      }}
                    >
                      Back
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === props.activity.activitySteps.length && (
          <Box
            sx={{
              p: 2,
              backgroundColor: colors.primary[600],
              color: "#799c4a",
            }}
          >
            <Stack
              direction="row"
              height={45}
              width="80%"
              marginLeft={2}
              spacing={2}
            >

              <Button
                onClick={props.handleCloseActivity}
                size="small"
                sx={{
                  backgroundColor: colors.dashbordGreen[700],
                  color: "white",
                  "&:hover": {
                    backgroundColor: colors.dashbordGreen[800],
                    borderColor: "#0062cc",
                    boxShadow: "none",
                  },
                }}
              >
                <CheckIcon fontSize="inherit" sx={{ color: "white" }} />Close Activity
              </Button>
              <Button
                onClick={handleReset}
                size="small"
                sx={{
                  backgroundColor: colors.yellow[600],
                  color: "white",
                  "&:hover": {
                    backgroundColor: colors.yellow[700],
                    borderColor: "#0062cc",
                    boxShadow: "none",
                  },
                }}
              >
                Reset
              </Button>

            </Stack>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default ActivityViewer;
