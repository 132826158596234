import React from 'react'
import ActivitiesGrid from '../../activities/components/activitiesGrid'
import { Box } from '@mui/material'

function MachineActivities(props) {
  return (
    <>
      <Box ml={1} mr={2}>
        <ActivitiesGrid 
          activitiesResult={props.activitesQuery} 
          filteredResult={props.filteredResult} 
          activityFilter={props.activityFilter} 
          setActivityFilter={props.setActivityFilter}
          statusFilter={props.statusFilter}
          setStatusFilter={props.setStatusFilter}
          hideStatusFilter={props.hideStatusFilter} />
      </Box>
    </>
  )
}

export default MachineActivities