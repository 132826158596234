import React, { useState } from "react";
import Minutes from "../minutes-select";
import Hour from "../hour-select";
import LabelBox from "../labelBox";
import {
  FormControlLabel,
  Box,
} from "@mui/material";
import Radio from "@mui/material/Radio";

const HourlyCron = ({ classes, value, translate: translateFn, onChange }) => {
  const [every, setEvery] = useState(
    value[2].split("/")[1] || value[2] === "*"
  );

  const onHourChange = (e) => {
    if (
      every &&
      ((e.target.value > 0 && e.target.value < 24) || e.target.value === "")
    ) {
      let val = ["0", "0", "*", "*", "*", "?", "*"];
      val[2] = e.target.value ? `0/${e.target.value}` : e.target.value;
      val[3] = "1/1";
      onChange(val);
    }
  };

  const onAtHourChange = (e) => {
    let val = ["0", value[1], "*", "1/1", "*", "?", "*"];
    val[2] = `${e.target.value}`;
    onChange(val);
  };

  const onAtMinuteChange = (e) => {
    let val = ["0", "*", value[2], "1/1", "*", "?", "*"];
    val[1] = `${e.target.value}`;
    onChange(val);
  };

  return (
    <Box>
  <LabelBox variant="content">
      <FormControlLabel
        value="every"
        control={
          <Radio
            onChange={(e) => {
              setEvery(true);
              onChange(["0", "0", "0/1", "1/1", "*", "?", "*"]);
            }}
            checked={every}
            sx={{
              "&.Mui-checked": {
                color: "#799c4a !important",
            }}}
          />
        }
        label={translateFn("Every")}
      />
      <input disabled={!every} type="Number" onChange={onHourChange} value={value[2].split('/')[1] ? value[2].split('/')[1] : ''}  />
      <span> {translateFn("hour(s)")}</span>
    </LabelBox>
    </Box>
  );
};

HourlyCron.muiName = "HourlyCron";

export default HourlyCron;
