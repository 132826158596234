import { defineMessages } from 'react-intl';

export const scope = 'axionoee.features.activities.components';

export default defineMessages({
  idColumnLabel: {
    id: `${scope}.activities.label.idcolumn`,
    defaultMessage: 'Id',
  },
  currentShiftFilter: {
    id: `${scope}.label.filters.currentShift`,
    defaultMessage: 'Current shift',
},
prevShiftFilter: {
  id: `${scope}.label.filters.prevShift`,
  defaultMessage: 'Prev.shift',
},
dayFilter: {
  id: `${scope}.label.filters.24h`,
  defaultMessage: '24h',
},
threeDayFilter: {
  id: `${scope}.label.filters.72h`,
  defaultMessage: '72h',
},
weekFilter: {
  id: `${scope}.label.filters.week`,
  defaultMessage: 'Week',
},  
manualFilter: {
  id: `${scope}.label.filters.manual`,
  defaultMessage: 'Manual',
},    
fromDate: {
  id: `${scope}.label.filters.fromDate`,
  defaultMessage: 'From date',
},
toDate: {
  id: `${scope}.label.filters.toDate`,
  defaultMessage: 'To date',
},
fromTime: {
  id: `${scope}.label.filters.fromTime`,
  defaultMessage: 'From time',
},
toTime: {
  id: `${scope}.label.filters.toTime`,
  defaultMessage: 'To time',
},
dashboard: {
  id: `${scope}.tab.dashboard`,
  defaultMessage: 'Dashboard',
},
instructions: {
  id: `${scope}.tab.instructions`,
  defaultMessage: 'Activity Instructions',
},
recurringActivities: {
  id: `${scope}.tab.recurringActivities`,
  defaultMessage: 'Active Recurrring Activities',
},
departments: {
  id: `${scope}.tab.departments`,
  defaultMessage: 'Departments',
},
allActivities: {
  id: `${scope}.tab.allActivities`,
  defaultMessage: 'All Created Activities',
},
openActivities: {
  id: `${scope}.dashboard.openActivities`,
  defaultMessage: 'Open Activities (now)',
},
createdActivities: {
  id: `${scope}.dashboard.createdActivities`,
  defaultMessage: 'Created Activities',
},
completedActivities: {
  id: `${scope}.dashboard.completedActivities`,
  defaultMessage: 'Completed Activities',
},
lateActivities: {
  id: `${scope}.dashboard.lateActivities`,
  defaultMessage: 'Late Activities',
},
uncompletedActivities: {
  id: `${scope}.dashboard.uncompletedActivities`,
  defaultMessage: 'Uncompleted Activities',
},
completedInTime: {
  id: `${scope}.dashboard.completedInTime`,
  defaultMessage: 'Completed in time',
},
openActivitesByMachine: { 
  id: `${scope}.dashboard.openActivitesByMachine`,
  defaultMessage: 'Open Activities by Machine',
},
cancelledActivitesByMachine: { 
  id: `${scope}.dashboard.cancelledActivitesByMachine`,
  defaultMessage: 'Not completed or cancelled activities by machine in selected timespan',
},
machineName: {
  id: `${scope}.activitiesgrid.machineName`,
  defaultMessage: 'Machine name/Department',
},
machineType: {
  id: `${scope}.activitiesgrid.machineType`,
  defaultMessage: 'Machine type',
},
toolName: {
  id: `${scope}.activitiesgrid.toolName`,
  defaultMessage: 'Tool',
},
itemName: {
  id: `${scope}.activitiesgrid.itemName`,
  defaultMessage: 'Item no',
},
activityName: {
  id: `${scope}.activitiesgrid.activityName`,
  defaultMessage: 'Activity name',
},
instructionName: {
  id: `${scope}.activitiesgrid.instructionName`,
  defaultMessage: 'Instruction Name',
},
created: {
  id: `${scope}.activitiesgrid.created`,
  defaultMessage: 'Created',
},
status: {
  id: `${scope}.activitiesgrid.status`,
  defaultMessage: 'Status',
},
closed: {
  id: `${scope}.activitiesgrid.closed`,
  defaultMessage: 'Closed at',
},
timeLeft: {
  id: `${scope}.activitiesgrid.timeLeft`,
  defaultMessage: 'Time left',
},
late: {
  id: `${scope}.activitiesgrid.late`,
  defaultMessage: 'Late',
},
closedBy: {
  id: `${scope}.activitiesgrid.closedBy`,
  defaultMessage: 'Closed by',
},
autocloseAt: {
  id: `${scope}.activitiesgrid.autocloseAt`,
  defaultMessage: 'Will autoclose at',
},
schedule: {
  id: `${scope}.activitiesgrid.schedule`,
  defaultMessage: 'Schedule',
},
addRecurringActivity: {
  id: `${scope}.activitiesgrid.addRecurringActivity`,
  defaultMessage: 'Add recurring activity',
},
runningTimeIntervalSchedule: {
  id: `${scope}.activitiesgrid.runningTimeIntervalSchedule`,
  defaultMessage: 'Every {runningTimeInterval} running hours',
},
quantityIntervalSchedule: {
  id: `${scope}.activitiesgrid.quantityIntervalSchedule`,
  defaultMessage: 'Every {quantityInterval} items produced',
},
stopReasonCodeSchedule: {
  id: `${scope}.activitiesgrid.stopReasonCodeSchedule`,
  defaultMessage: 'When a stop of type "{stopReasonCode}" is coded',
},
toolRunningTimeIntervalSchedule: {
  id: `${scope}.activitiesgrid.toolRunningTimeIntervalSchedule`,
  defaultMessage: 'Every {runningTimeInterval} running hours for tool "{toolName}"',
},
toolQuantityIntervalSchedule: {
  id: `${scope}.activitiesgrid.toolQuantityIntervalSchedule`,
  defaultMessage: 'Every {quantityInterval} items produced using tool "{toolName}"',
},
itemRunningTimeIntervalSchedule: {
  id: `${scope}.activitiesgrid.itemRunningTimeIntervalSchedule`,
  defaultMessage: 'Every {runningTimeInterval} running hours of item "{itemName}"',
},
itemQuantityIntervalSchedule: {
  id: `${scope}.activitiesgrid.itemQuantityIntervalSchedule`,
  defaultMessage: 'Every {quantityInterval} items produced of item "{itemName}"',
},
activityNamePlaceholder: {
  id: `${scope}.activitiesgrid.dialog.activityNamePlaceholder`,
  defaultMessage: 'Activity name *',
},
instructionPlaceholder: {
  id: `${scope}.activitiesgrid.dialog.instructionPlaceholder`,
  defaultMessage: 'Select instruction *',
},
machinePlaceholder: {
  id: `${scope}.activitiesgrid.dialog.machinePlaceholder`,
  defaultMessage: 'Select machine *',
},
departmentPlaceholder: {
  id: `${scope}.activitiesgrid.dialog.departmentPlaceholder`,
  defaultMessage: 'Select department *',
},
toolPlaceholder: {
  id: `${scope}.activitiesgrid.dialog.toolPlaceholder`,
  defaultMessage: 'Select tool *',
},
productionItemPlaceholder: {
  id: `${scope}.activitiesgrid.dialog.productionItemPlaceholder`,
  defaultMessage: 'Select item *',
},
scheduleTypePlaceholder: {
  id: `${scope}.activitiesgrid.dialog.scheduleTypePlaceholder`,
  defaultMessage: 'Select schedule type *',
},
maxDurationPlaceholder: {
  id: `${scope}.activitiesgrid.dialog.maxDurationPlaceholder`,
  defaultMessage: 'Hours to autoclose *',
},
typeDate: {
  id: `${scope}.activitiesgrid.dialog.typeDate`,
  defaultMessage: 'Date',
},
typeRunningTime: {
  id: `${scope}.activitiesgrid.dialog.typeRunningTime`,
  defaultMessage: 'Running time',
},
typeQuantity: {
  id: `${scope}.activitiesgrid.dialog.typeQuantity`,
  defaultMessage: 'Quantity',
},
typeStopReasonCode: {
  id: `${scope}.activitiesgrid.dialog.typeStopReasonCode`,
  defaultMessage: 'Stop reason code',
},
typeToolRunningTime: {
  id: `${scope}.activitiesgrid.dialog.typeToolRunningTime`,
  defaultMessage: 'Running time for a tool',
},
typeToolQuantity: {
  id: `${scope}.activitiesgrid.dialog.typeToolQuantity`,
  defaultMessage: 'Quantity for a tool',
},
typeItemRunningTime: {
  id: `${scope}.activitiesgrid.dialog.typeItemRunningTime`,
  defaultMessage: 'Running time for an item no',
},
typeItemQuantity: {
  id: `${scope}.activitiesgrid.dialog.typeItemQuantity`,
  defaultMessage: 'Quantity for an item no',
},
typeDepartment: {
  id: `${scope}.activitiesgrid.dialog.typeItemDepartment`,
  defaultMessage: 'Department',
},
itemNo: {
  id: `${scope}.activitiesgrid.dialog.itemNo`,
  defaultMessage: 'Item no *',
},
createRecurringActivity: {
  id: `${scope}.activitiesgrid.dialog.createRecurringActivity`,
  defaultMessage: 'Create new recurring activity',
},
save: {
  id: `${scope}.activitiesgrid.dialog.save`,
  defaultMessage: 'Save',
},
createSchedule: {
  id: `${scope}.activitiesgrid.dialog.createSchedule`,
  defaultMessage: 'Create schedule',
},
hourly: {
  id: `${scope}.activitiesgrid.dialog.hourly`,
  defaultMessage: 'Hourly',
},
daily: {
  id: `${scope}.activitiesgrid.dialog.daily`,
  defaultMessage: 'Daily',
},
weekly: {
  id: `${scope}.activitiesgrid.dialog.weekly`,
  defaultMessage: 'Weekly',
},
monthly: {
  id: `${scope}.activitiesgrid.dialog.monthly`,
  defaultMessage: 'Monthly',
},
every: {
  id: `${scope}.activitiesgrid.dialog.every`,
  defaultMessage: 'Every',
},
hours: {
  id: `${scope}.activitiesgrid.dialog.hours`,
  defaultMessage: 'hour(s)',
},
at: {
  id: `${scope}.activitiesgrid.dialog.at`,
  defaultMessage: 'At',
},
days: {
  id: `${scope}.activitiesgrid.dialog.days`,
  defaultMessage: 'day(s)',
},
everyWeekDay: {
  id: `${scope}.activitiesgrid.dialog.everyWeekDay`,
  defaultMessage: 'Every week day',
},
startTime: {
  id: `${scope}.activitiesgrid.dialog.startTime`,
  defaultMessage: 'Start time',
},
monday: {
  id: `${scope}.activitiesgrid.dialog.monday`,
  defaultMessage: 'Monday',
},
tuesday: {
  id: `${scope}.activitiesgrid.dialog.tuesday`,
  defaultMessage: 'Tuesday',
},
wednesday: {
  id: `${scope}.activitiesgrid.dialog.wednesday`,
  defaultMessage: 'Wednesday',
},
thursday: {
  id: `${scope}.activitiesgrid.dialog.thursday`,
  defaultMessage: 'Thursday',
},
friday: {
  id: `${scope}.activitiesgrid.dialog.friday`,
  defaultMessage: 'Friday',
},
saturday: {
  id: `${scope}.activitiesgrid.dialog.saturday`,
  defaultMessage: 'Saturday',
},
sunday: {
  id: `${scope}.activitiesgrid.dialog.sunday`,
  defaultMessage: 'Sunday',
},
day: {
  id: `${scope}.activitiesgrid.dialog.day`,
  defaultMessage: 'Day',
},
ofEveryMonth: {
  id: `${scope}.activitiesgrid.dialog.ofEveryMonth`,
  defaultMessage: 'of every month(s)',
},
lastDayOfEveryMonth: {
  id: `${scope}.activitiesgrid.dialog.lastDayOfEveryMonth`,
  defaultMessage: 'On the last day of every month',
},
lastWeekdayOfEveryMonth: {
  id: `${scope}.activitiesgrid.dialog.lastWeekdayOfEveryMonth`,
  defaultMessage: 'On the last weekday of every month',
},
daysBeforeTheEndOfTheMonth: {
  id: `${scope}.activitiesgrid.dialog.daysBeforeTheEndOfTheMonth`,
  defaultMessage: 'day(s) before the end of the month',
},
runningTimeInterval: {
  id: `${scope}.activitiesgrid.dialog.runningTimeInterval`,
  defaultMessage: 'Running time interval (hours) *',
},
quantityInterval: {
  id: `${scope}.activitiesgrid.dialog.quantityInterval`,
  defaultMessage: 'Produced quantity interval *',
},
selectStopReasonCode: {
  id: `${scope}.activitiesgrid.dialog.selectStopReasonCode`,
  defaultMessage: 'Select stop reason code *',
},
search: {
  id: `${scope}.activitiesgrid.search`,
  defaultMessage: 'Search',
},
allOption: {
  id: `${scope}.activitiesgrid.allOption`,
  defaultMessage: 'All',
},
editSettings: {
  id: `${scope}.activitiesgrid.editSettings`,
  defaultMessage: 'Edit settings',
}
});




