import React from "react";
import { Box, useTheme } from "@mui/material";
import AxionControls from "../../../components/controls/AxionControls";
import Checkbox from "../../../components/controls/Checkbox";
import Button from "../../../components/controls/Button";
import InputAdornment from '@mui/material/InputAdornment';
import Square from '@mui/icons-material/Square';
import { tokens } from "../../../assets/theme";
import messages from "./messages";
import { FormattedMessage } from "react-intl";

export default function MachineSettingsDialog(props) {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const handleChange = (event) => {
        const {id, name, value, type, checked} = event.target
        console.log(event.target)
        props.setMachineSettings(prev => {
            return {
                ...prev,
                [id ? id : name]: type === "checkbox" ? checked : value
            }
        })
    }

    const handleNumericChange = (event) => {
        let {id, name, value, type, checked} = event.target
        
        if(value === "") { 
            value = null
        }
        console.log(event.target)
        props.setMachineSettings(prev => {
            return {
                ...prev,
                [id ? id : name]: type === "checkbox" ? checked : value
            }
        })
    }

    const handleMachineStopReasonCodeChange = (event) => {
        const {id, name, value, type, checked} = event.target
        if(checked) {
            props.setMachineSettings(prev => {
                return {
                    ...prev,
                    machineStopReasonCodes: [...prev.machineStopReasonCodes, { opcMachineId: props.machineData.id, stopReasonCodeId: id}]
                }
            })
        } else {
            props.setMachineSettings(prev => {
                return {
                    ...prev,
                    machineStopReasonCodes: prev.machineStopReasonCodes.filter(code => code.stopReasonCodeId !== id)
                }
            })
        }
    }

    let currentMachineType = props.machineTypes.find((item) => item.id === props.machineData.machineTypeId)
    console.log(currentMachineType)

    return (
        <Box 
            sx={{
                p:1,
                m:1
            }}>
            <Box>
                <AxionControls.AxionTextBox 
                    name="machineName"
                    label={<FormattedMessage {...messages.machineName} />}
                    value={props.machineData.machineName}
                    onChange={handleChange}
                    />
                <AxionControls.AxionTextBox 
                    name="info"
                    label={<FormattedMessage {...messages.info} />}
                    value={props.machineData.info}
                    onChange={handleChange}
                    />
                <AxionControls.AxionTextBox 
                    name="description"
                    label={<FormattedMessage {...messages.description} />}
                    value={props.machineData.description}
                    onChange={handleChange}
                    />
                { !currentMachineType?.showInActivitySpecialList && <>
                <AxionControls.AxionTextBox 
                    name="opcUrl"
                    label={<FormattedMessage {...messages.opcServerUrl} />}
                    value={props.machineData.url}
                    onChange={handleChange}
                    /> 
                <AxionControls.AxionTextBoxReadOnly
                    name="machineStatus"
                    label={<FormattedMessage {...messages.machineStatus} />}
                    value={props.machineData.machineStatus}
                    />
                <AxionControls.AxionTextBoxReadOnly
                    sx={{marginBottom: "12px"}}
                    name="opcClientStatus"
                    label={<FormattedMessage {...messages.opcServerStatus} />}
                    value={props.machineData.opcclientStatus}
                    />
                <AxionControls.AxionSelect 
                    sx={{marginBottom: "12px"}}
                    name="areaKey"
                    label={<FormattedMessage {...messages.area} />}
                    value={props.machineData.areaKey}
                    options={props.areas.map((item) => {
                        return {id: item.areaKey, title: item.areaName}
                    })}
                    onChange={handleNumericChange}
                    /> </>}
                <AxionControls.AxionSelect 
                    name="machineTypeId"
                    sx={{
                        marginTop: currentMachineType?.showInActivitySpecialList ? "12px" : "0px",
                        marginBottom: "12px"
                    }}
                    label={<FormattedMessage {...messages.machineType} />}
                    value={props.machineData.machineTypeId}
                    options={props.machineTypes.map((item) => {
                        return {id: item.id, title: item.machineTypeName}
                    })}
                    onChange={handleNumericChange}
                    />
                <Checkbox
                    name="notifyOnNewActivity"
                    label={<FormattedMessage {...messages.notifyOnNewActivity} />}
                    value={props.machineData.notifyOnNewActivity}
                    onChange={handleChange}
                    /><br />
                <AxionControls.AxionTextBox 
                    name="notifyEmail"
                    disabled={!props.machineData.notifyOnNewActivity}
                    label={<FormattedMessage {...messages.notifyEmail} />}
                    value={props.machineData.notifyEmail}
                    onChange={handleChange}
                    /> 
                { !currentMachineType?.showInActivitySpecialList && <>
                <AxionControls.AxionTextBox
                    sx={{width: '50%', paddingRight: '6px'}}
                    name="oeePercentWarning"
                    label={<FormattedMessage {...messages.oeePercentWarning} />}
                    value={props.machineData.oeePercentWarning}
                    inputProps={{
                        endAdornment: <InputAdornment position="end" sx={{color: colors.yellow[500]}}><Square/> </InputAdornment>,
                      }}
                    onChange={handleNumericChange}
                    />
                <AxionControls.AxionTextBox
                    sx={{width: '50%', paddingLeft: '6px' }}
                    name="oeePercentBad"
                    label={<FormattedMessage {...messages.oeePercentBad} />}
                    value={props.machineData.oeePercentBad}
                    inputProps={{
                        endAdornment: <InputAdornment position="end" sx={{color: colors.redAccent[500]}}><Square/> </InputAdornment>,
                      }}
                    onChange={handleNumericChange}
                    />
                <AxionControls.AxionTextBox
                    sx={{width: '50%', paddingRight: '6px', marginBottom: "12px"}}
                    disabled={!props.machineData.meassureAvailability}
                    name="availabilityPercentWarning"
                    label={<FormattedMessage {...messages.availabilityPercentWarning} />}
                    value={props.machineData.availabilityPercentWarning}
                    inputProps={{
                        endAdornment: <InputAdornment position="end" sx={{color: colors.yellow[500]}}><Square/> </InputAdornment>,
                      }}
                    onChange={handleNumericChange}
                    />
                <AxionControls.AxionTextBox
                    sx={{width: '50%', paddingLeft: '6px'}}
                    disabled={!props.machineData.meassureAvailability}
                    name="availabilityPercentBad"
                    label={<FormattedMessage {...messages.availabilityPercentBad} />}
                    value={props.machineData.availabilityPercentBad}
                    inputProps={{
                        endAdornment: <InputAdornment position="end" sx={{color: colors.redAccent[500]}}><Square/> </InputAdornment>,
                      }}
                    onChange={handleNumericChange}
                    />
                <Checkbox
                sx={{marginTop: "12px"}}
                    name="meassurePerformance"
                    label={<FormattedMessage {...messages.meassurePerformance} />}
                    value={props.machineData.meassurePerformance}
                    onChange={handleChange}
                    /><br />
                <AxionControls.AxionTextBox
                    sx={{width: '50%', paddingRight: '6px', paddingBottom: '12px'}}
                    disabled={!props.machineData.meassurePerformance}
                    name="performancePercentWarning"
                    label={<FormattedMessage {...messages.performancePercentWarning} />}
                    value={props.machineData.performancePercentWarning}
                    inputProps={{
                        endAdornment: <InputAdornment position="end" sx={{color: colors.yellow[500]}}><Square/> </InputAdornment>,
                      }}
                    onChange={handleNumericChange}
                    />
                <AxionControls.AxionTextBox
                    sx={{width: '50%', paddingLeft: '6px'}}
                    disabled={!props.machineData.meassurePerformance}
                    name="performancePercentBad"
                    label={<FormattedMessage {...messages.performancePercentBad} />}
                    value={props.machineData.performancePercentBad}
                    inputProps={{
                        endAdornment: <InputAdornment position="end" sx={{color: colors.redAccent[500]}}><Square/> </InputAdornment>,
                      }}
                    onChange={handleNumericChange}
                    />
                <Checkbox
                    sx={{marginTop: "12px"}}
                    name="meassureQuality"
                    label={<FormattedMessage {...messages.meassureQuality} />}
                    value={props.machineData.meassureQuality}
                    onChange={handleChange}
                    /><br />
                <AxionControls.AxionTextBox
                    sx={{width: '50%', paddingRight: '6px'}}
                    disabled={!props.machineData.meassureQuality}
                    name="qualityPercentWarning"
                    label={<FormattedMessage {...messages.qualityPercentWarning} />}
                    value={props.machineData.qualityPercentWarning}
                    inputProps={{
                        endAdornment: <InputAdornment position="end" sx={{color: colors.yellow[500]}}><Square/> </InputAdornment>,
                      }}
                    onChange={handleNumericChange}
                    />
                <AxionControls.AxionTextBox
                    sx={{width: '50%', paddingLeft: '6px'}}
                    disabled={!props.machineData.meassureQuality}
                    name="qualityPercentBad"
                    label={<FormattedMessage {...messages.qualityPercentBad} />}
                    value={props.machineData.qualityPercentBad}
                    inputProps={{
                        endAdornment: <InputAdornment position="end" sx={{color: colors.redAccent[500]}}><Square/> </InputAdornment>,
                      }}
                    onChange={handleNumericChange}
                    />
                <AxionControls.AxionTextBox
                    sx={{marginBottom: "12px"}}
                    name="oeeStartDateTime"
                    label={<FormattedMessage {...messages.oeeStartDateTime} />}
                    value={props.machineData.oeeStartDateTime}
                    onChange={handleChange}
                    />
                
                <Checkbox
                    name="useStopPageOnGrafanaLink"
                    label={<FormattedMessage {...messages.useStopPageOnGrafanaLink} />}
                    value={props.machineData.useStopPageOnGrafanaLink}
                    onChange={handleChange}
                    />

                <Box marginTop="12px" sx={{width: '100%'}} >
                    <FormattedMessage {...messages.avalibleStopReasonCodes} />
                    <Box 
                        marginTop="12px" 
                        sx={{ 
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignContent: 'flex-start', 
                            }
                            }>
                        { props.stopReasonCodes.map((item) => {
                            const isEnabled = props.machineData?.machineStopReasonCodes?.some(code => code.stopReasonCodeId === item.id)
                            return <Box key={item.id} sx={{flex: "0 0 33.3333%;"}}>
                                <Checkbox
                                    id={item.id}
                                    name={item.code}
                                    label={`${item.name} (${item.type})`}
                                    value={isEnabled}
                                    onChange={handleMachineStopReasonCodeChange}
                                />
                            </Box>
                        }) }
                    </Box>
                </Box>
                </>}
                <Box>
                    <Button 
                        sx={{marginTop: currentMachineType?.showInActivitySpecialList ? "12px" : "0px"}}
                        text={<FormattedMessage {...messages.save} />}
                        variant="contained" 
                        onClick={props.onSettingsSave}
                        />
                </Box>
            </Box>
        </Box>

    );
}