import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import AxionControls from "../../../components/controls/AxionControls";
import { Box, Button, Stack, Typography } from "@mui/material";
import messages from "./messages";
import { useMsal, useAccount } from "@azure/msal-react";
import { patchMachineComment } from "../../../fetchers/machine";
import { pushErrorMessage } from "../../../utils/notifications";

export default function MachineCommentDialog(props) {

    const { accounts } = useMsal();
    const account = useAccount(accounts[0]);

    console.log(accounts)

    const [machineComment, setMachineComment] = useState({
        Id: props.machine.id,
        comment: props.machine.comment,
        CommentCreatedBy: account.username
    });

    const handleChange = (event) => {
        const { id, name, value, type, checked } = event.target
        setMachineComment(prev => {
            return {
                ...prev,
                [id ? id : name]: value
            }
        })
    }

    const handleSave = () => {
        props.setShowBackdrop(true);
        const machineCommentToSave = machineComment
        if (machineCommentToSave.comment && machineCommentToSave.comment.length > 0) {
            machineCommentToSave.CommentCreatedBy = account.username
        } else {
            machineCommentToSave.CommentCreatedBy = null
        }
        setMachineComment(machineCommentToSave);
        patchMachineComment(machineCommentToSave).then(() => {
            props.setOpenPopup(false);
            props.setShowBackdrop(false);
            props.refresh();
        })
            .catch((error) => {
                console.log(error);
                props.setShowBackdrop(false);
                pushErrorMessage(`Could not update the comment.`);
            });
    }

    const createdby = props.machine.commentCreatedBy != null ? "Last updated by:" : "";

    return (
        <>
            <Box
                sx={{
                    p: 1,
                    m: 1
                }}>
                <Box>
                    <AxionControls.AxionTextBoxMultiline
                        name="comment"
                        label=<FormattedMessage {...messages.comment} />
                        value={machineComment.comment}
                        onChange={handleChange}
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    p: 1,
                    m: 1
                }}>
                <Box sx={{ flexDirection: 'row' }}>
                    <Button
                        variant="contained"
                        onClick={handleSave}>
                        Save
                    </Button>
                </Box>
                <Box justify="flex-end" display="flex" alignItems="center">
                    <Box>{createdby}</Box>
                    <Box paddingLeft={1}>{props.machine.commentCreatedBy}</Box>
                    <Box paddingLeft={1}>{props.machine.commentUpdatedDate}</Box>
                </Box>
            </Box>
        </>
    )
}