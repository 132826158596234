/*
 * Home Messages
 *
 * This contains all the text for the Home Feature.
 */
import { defineMessages } from 'react-intl';

export const scope = 'axionoee.features.oeegrid.index';

export default defineMessages({
  machineNameColumn: {
    id: `${scope}.grid.column.machineName`,
    defaultMessage: 'Machine',
  },
  priorityNameColumn: {
    id: `${scope}.grid.column.priorityName`,
    defaultMessage: 'Priority',
  },
  machineStatusColumn: {
    id: `${scope}.grid.column.machineStatus`,
    defaultMessage: 'Status',
  },
  currentStopColumn: {
    id: `${scope}.grid.column.currentStop`,
    defaultMessage: 'Current Stop',
  },
  currentStopTimeColumn: {
    id: `${scope}.grid.column.currentStopTime`,
    defaultMessage: 'Stop time',
  },
  plannedStopColumn: {
    id: `${scope}.grid.column.plannedStop`,
    defaultMessage: 'Planned',
  },
  activeProductionOrderColumn: {
    id: `${scope}.grid.column.activeProductionOrder`,
    defaultMessage: 'PO#',
  },
  activeItemColumn: {
    id: `${scope}.grid.column.activeItem`,
    defaultMessage: 'Item',
  },
  toolColumn: {
    id: `${scope}.grid.column.tool`,
    defaultMessage: 'Tool',
  },
  plannedQtyColumn: {
    id: `${scope}.grid.column.tool`,
    defaultMessage: 'Planned Qty',
  },
  reportedQtyColumn: {
    id: `${scope}.grid.column.tool`,
    defaultMessage: 'Reported Qty',
  },
  remainingQtyColumn: {
    id: `${scope}.grid.column.tool`,
    defaultMessage: 'Remaining Qty',
  },
  openActivitiesColumn: {
    id: `${scope}.grid.column.openActivities`,
    defaultMessage: 'Activites',
  },
  tColumn: {
    id: `${scope}.grid.column.t`,
    defaultMessage: 'T %',
  },
  aColumn: {
    id: `${scope}.grid.column.a`,
    defaultMessage: 'A %',
  },
  kColumn: {
    id: `${scope}.grid.column.k`,
    defaultMessage: 'K %',
  },
  takColumn: {
    id: `${scope}.grid.column.tak`,
    defaultMessage: 'TAK %',
  },
  idealCycleTimeActiveColumn: {
    id: `${scope}.grid.column.idealCycleTimeActive`,
    defaultMessage: 'Ideal. CT.',
  },
  lastCycleTimeColumn: {
    id: `${scope}.grid.column.lastCycleTime`,
    defaultMessage: 'Last CT.',
  },
  avgCycleTimeColumn: {
    id: `${scope}.grid.column.avgCycleTime`,
    defaultMessage: 'Avg CT.',
  },
  avgIdealCycleTimeColumn: {
    id: `${scope}.grid.column.avgIdealCycleTime`,
    defaultMessage: 'Avg I.CT.',
  },
  prioritySortOrderColumn: {
    id: `${scope}.grid.column.prioritySortOrder`,
    defaultMessage: 'PrioritySortOrder',
  },
  uptimeColumn: {
    id: `${scope}.grid.column.uptime`,
    defaultMessage: 'Uptime',
  },
  downTimeColumn: {
    id: `${scope}.grid.column.downTime`,
    defaultMessage: 'Downtime',
  },
  scrappedQtyColumn: {
    id: `${scope}.grid.column.scrappedQty`,
    defaultMessage: 'Scrapped Qty',
  },
  numberOfCyclesWithCavityColumn: {
    id: `${scope}.grid.column.numberOfCyclesWithCavity`,
    defaultMessage: 'No Of Cycles',
  },
  //FILTERS
  onlyRunningFilter: {
    id: `${scope}.grid.filters.onlyRunning`,
    defaultMessage: 'Only Running',
  },
  dontShowPlannedStopsFilter: {
    id: `${scope}.grid.filters.dontShowPlannedStops`,
    defaultMessage: "Don't show planned stops",
  },
  onlyStoppedFilter: {
    id: `${scope}.grid.filters.onlyStopped`,
    defaultMessage: 'Only stopped',
  },
  clearAllFilters: {
    id: `${scope}.grid.filters.clearAll`,
    defaultMessage: 'Clear all filters',
  },
  currentShiftFilter: {
    id: `${scope}.grid.filters.currentShift`,
    defaultMessage: 'Current Shift',
  },
  prevShiftFilter: {
    id: `${scope}.grid.filters.prevShift`,
    defaultMessage: 'Prev.Shift',
  },
  dayFilter: {
    id: `${scope}.grid.filters.24h`,
    defaultMessage: '24h',
  },
  threeDayFilter: {
    id: `${scope}.grid.filters.72h`,
    defaultMessage: '72h',
  },
  manualFilter: {
    id: `${scope}.grid.filters.manual`,
    defaultMessage: 'Manual',
  },
  tFooter: {
    id: `${scope}.footer.label.t`,
    defaultMessage: 'T %',
  },
  aFooter: {
    id: `${scope}.footer.label.a`,
    defaultMessage: 'A %',
  },
  kFooter: {
    id: `${scope}.footer.label.k`,
    defaultMessage: 'K %',
  },
  takFooter: {
    id: `${scope}.footer.label.tak`,
    defaultMessage: 'TAK %',
  },
});
