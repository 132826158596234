import axios from "axios";
import dayjs from "dayjs";  
import { useSelector } from "react-redux";


// const instance = axios.create({
//     httpsAgent: new https.Agent({  
//       rejectUnauthorized: false
//     })
//   });
const monitorHeaders = {
    'Content-Type': 'application/json',
    'Accept': '*/*',
    'Access-Control-Allow-Origin':'',
    //'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
   // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
    'Cache-Control': 'no-cache',
    'X-Monitor-SessionId' : "defd1b40-7c65-4042-8c9b-cff547c24c40",

}
//const https = require('https');
// const httpsAgent = new https.Agent({ rejectUnauthorized: false })

const MonitorEndpointUrl =  () => {
    const endpointUrl = useSelector((state) => state.monitorIntegrationEnabled.endpointUrl);
    return endpointUrl;
}

export const getMonitorSettings = async (companyKey) => {
    console.log("fetching monitorSettings");
    const response = await axios.get("api/settings/getMonitorSettings/"+companyKey);
    const monitorSettings = response.data;
    console.log("Monitor Settings: ", monitorSettings);
    return monitorSettings;
}

export const monitorLogin = async () => {
    console.log("fetching test monitor");
    const response = await axios.get(MonitorEndpointUrl() + "TimeRecording/IndirectWorkCodes");
    const indirectWorkCodes = response.data;
    console.log("indirect work codes: ", indirectWorkCodes);
    return indirectWorkCodes;
}

export const monitorIndirectWorkCodes = async () => {
    console.log("fetching test monitor");
    const response = await axios.get("https://10.128.95.20:8001/sv/001_2.1/api/v1/TimeRecording/IndirectWorkCodes", {
        headers: monitorHeaders,
        
    });
    const indirectWorkCodes = response.data;
    console.log("indirect work codes: ", indirectWorkCodes);
    return indirectWorkCodes;
}



