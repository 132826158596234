import { defineMessages } from 'react-intl';

export const scope = 'axionoee.features.components.machines';

export default defineMessages({
    machineNameColumn: {
        id: `${scope}.grid.column.machineName`,
        defaultMessage: 'Machine name',
    },
    descriptionColumn: {
        id: `${scope}.grid.column.description`,
        defaultMessage: 'Description',
    },
    machineTypeColumn: {
        id: `${scope}.grid.column.machineType`,
        defaultMessage: 'Machine type',
    },
    urlColumn: {
        id: `${scope}.grid.column.url`,
        defaultMessage: 'Url',
    },
    machineStatusColumn: {
        id: `${scope}.grid.column.machineStatus`,
        defaultMessage: 'Status',
    },
    showMachine: {
        id: `${scope}.grid.column.showMachine`,
        defaultMessage: 'Show machine',
    },
    editMachine: {
        id: `${scope}.grid.column.editMachine`,
        defaultMessage: 'Edit machine',
    },
    editSettings: {
        id: `${scope}.editSettings`,
        defaultMessage: 'Edit settings',
    },
});