import React from "react";
import { useTheme, Paper, Box, Toolbar, IconButton } from "@mui/material";
import { tokens } from "../../../assets/theme";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
//import MaterialSourceModal from "./materialSourceModal";
import useTable from "../../../components/useTable";
import Controls from "../../../components/controls/Contols";
import Popup from "../../../components/Popup";
import { InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

//import MaterialSourceForm from "./materialSourceForm";
//import MatSourceForm from "./form";
import { useMutation,
  useQueryClient, } from "@tanstack/react-query";
import ViewDrawing from "./viewDrawingItem";

//import { updateMaterialSource } from "../hooks/fetchers";

const headCells = [
  { id: "itemCode", label: "Item Code" },
  { id: "itemName", label: "Item name" },
  { id: "customerCode", label: "Customer Number" },
  { id: "customerName", label: "Customer Name" },
  { id: "itemStatusName", label: "status", },
  { id: "itemPrice", label: "Price"},
  { id: "view", label: "", disableSorting: true },

];

function DrawingsGrid(props) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const queryClient = useQueryClient();
  const [instructions, se] = useState(props.rawMaterialData);
  const [show, setShow] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(0);
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupCreate, setOpenPopupCreate] = useState(false);
  const [records, setRecords] = useState(props.data);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [selectedRow, setSelectedRow] = useState(null);

  const callbackModal = () => {
    setShow(false);
  };

  const addOrEdit = (materialSource, resetForm) => {
    if (materialSource.id === 0)
      // employeeService.insertEmployee(employee)
      console.log("This is the new material source" + materialSource);
    // employeeService.updateEmployee(employee)
    else 
    console.log(materialSource);
    //updateMaterialSourceQuery(materialSource);
    resetForm();
    
    setRecordForEdit(null);
    setOpenPopup(false);
    setRecords(props.data);
  };

//   const updateMaterialSourceQuery = (materialSource) => {

//     updateMaterialSourceMutation.mutate({
//       id: materialSource.id,
//       code: materialSource.code,
//       name: materialSource.name,
//       description: materialSource.description,
//       created: materialSource.created,
//       createdBy: materialSource.createdBy,
//       updated: materialSource.updated,
//       updatedBy: materialSource.updatedBy,
//       dried: materialSource.dried,
//       companyKey: materialSource.companyKey,
//       dryingContainerId: materialSource.driedContainerId,
//       materialCode: materialSource.materialCode,
//       materialName:materialSource.materialName ,
//       materialItemKey:materialSource.materialItemKey,
//       materialCodeAndName:materialSource.materialCodeAndName,
//       companyKeyNavigation: materialSource.companyKeyNavigation,
//       driedBool: materialSource.driedBool,
//       machineMaterialConnections: materialSource.machineMaterialConnections,
//       });  
//   }


//     //    MUTATIONS
//   //Update machine material connection and invalidates previous calls.   
//   const updateMaterialSourceMutation = useMutation(
//     updateMaterialSource,
//     {
//       onSuccess: () => {
//         queryClient.invalidateQueries(
//             "materialSources",
//         );
//       },
//     }
//   );

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(records, headCells, filterFn);
    console.log(records);
  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
  
        if (target.value === "") return items;
        else
          return items.filter((x) =>
            x.itemCode.toLowerCase().includes(target.value) || x.itemName.toLowerCase().includes(target.value) || x.customerName.toLowerCase().includes(target.value) || x.customerCode.toLowerCase().includes(target.value)
          );
      },
    });
  };

  const openInPopup = (item) => {
    console.log("ItemForEdit");
    console.log(item);
    setRecordForEdit(item);
    setOpenPopup(true);
  };


  const toggleShow = (params) => () => {
    setShow((p) => !p);
    setSelectedRow(params);

  };

  return (
    <Box padding={2}>
      <Paper
        sx={{
          position: "relative",
          borderRadius: "4px",
        }}
      >
        <Toolbar sx={{ backgroundColor: colors.primary[700] }}>
          <Controls.Input
            label="Search item"
            //className={classes.searchInput}
            InputProps={{
              size: "small",
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
          
        </Toolbar>
        
        <TblContainer sx={{ maxHeight: 100}}>
          <TblHead />
          <TableBody sx={{ backgroundColor: colors.primary[400] }}>
            {recordsAfterPagingAndSorting().map((item) => (
              <TableRow key={item.itemCode}>
                <TableCell>{item.itemCode}</TableCell>
                <TableCell>{item.itemName}</TableCell>
                <TableCell>{item.customerCode}</TableCell>
                <TableCell>{item.customerName}</TableCell>
                <TableCell>{item.itemStatusName}</TableCell>
                <TableCell>{item.itemPrice}</TableCell>
                <TableCell>
                
            <IconButton
              arial-label="Edit"
              size="small"
              sx={{
                "&:hover": {
                  backgroundColor: colors.primary[500],
                },
                backgroundColor: colors.primary[700],
              }}
              onClick={() => {
                openInPopup(item);
              }}
            >
              <RemoveRedEyeOutlinedIcon fontSize="inherit" />
            </IconButton>

                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />


        <Box>
   
        </Box>
      </Paper>
      <Popup
        title="View Item details"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        maxWidht="95%"
      >
        <ViewDrawing item={recordForEdit} />
      </Popup>
      
    </Box>
  );
}

export default DrawingsGrid;
