import { defineMessages } from 'react-intl';

export const scope = 'axionoee.features.components.machinetypes';

export default defineMessages({
    idColumn: {
        id: `${scope}.grid.column.id`,
        defaultMessage: 'Id',
    },
    nameColumn: {
        id: `${scope}.grid.column.name`,
        defaultMessage: 'Name',
    },
    descriptionColumn: {
        id: `${scope}.grid.column.description`,
        defaultMessage: 'Description',
    },
    showInGridColumn: {
        id: `${scope}.grid.column.showInGrid`,
        defaultMessage: 'Show in grid',
    },
    showInGanttColumn: {
        id: `${scope}.grid.column.showInGantt`,
        defaultMessage: 'Show in gantt',
    },
    showInMachineListColumn: {
        id: `${scope}.grid.column.showInMachineList`,
        defaultMessage: 'Show in machine list',
    },
    showInActivitySpecialListColumn: {
        id: `${scope}.grid.column.showInActivitySpecialList`,
        defaultMessage: 'Show as activity department',
    },
    editMachineTypeLabel: {
        id: `${scope}.editMachineTypeLabel`,
        defaultMessage: 'Edit machine type',
    },
    addMachineTypeLabel: {
        id: `${scope}.addMachineTypeLabel`,
        defaultMessage: 'Add machine type',
    },
});