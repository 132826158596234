import React, { useEffect, useState } from "react";
import { Box, useTheme, Typography } from "@mui/material";
import AxionControls from "../../components/controls/AxionControls";
import InputAdornment from '@mui/material/InputAdornment';
import Square from '@mui/icons-material/Square';
import { tokens } from "../../assets/theme";
import messages from "./components/messages";
import { FormattedMessage } from "react-intl";
import Button from "../../components/controls/Button";
import { useQuery } from "@tanstack/react-query";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { fetchCompany, updateCompany } from "../../fetchers/company";
import { pushNotification, pushErrorMessage } from "../../utils/notifications";


export default function Settings(props) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [ companySettings, setCompanySettings ] = useState({
        id: 0,
        companyName: "",
        timezone: "",
        oeePercentWarning: 0,
        oeePercentBad: 0,
        grafanaBaseUrl: ""
    });
    const [companyKey, setCompanyKey] = useLocalStorage("companyKey", 1);

    const companyQuery = useQuery(["company", companyKey], () => fetchCompany(companyKey));
    
    useEffect(() => {
        if(companyQuery.isSuccess) {
            setCompanySettings(companyQuery.data);
        }
    }, [companyQuery.isSuccess]);

    if(companyQuery.isLoading) return <AxionControls.AxionBackdrop open={true} />

    const handleChange = (event) => {
        const {id, name, value, type, checked} = event.target
        setCompanySettings(prev => {
            return {
                ...prev,
                [id ? id : name]: type === "checkbox" ? checked : value
            }
        })
      }
    
    const handleSave = () => {
        setShowBackdrop(true);
        updateCompany(companySettings).then(() => {  
            setShowBackdrop(false);
            pushNotification(<FormattedMessage {...messages.settingsSaved} />, "success")
        }).catch((error) => {
            console.log(error);
            setShowBackdrop(false);
            pushErrorMessage(`Cannot update settings.`);
        })
    }   

    const handleClearLocalStorage = () => {
        localStorage.clear();
        pushNotification("Local settings have been cleared.", "success");
    }
    
    return <Box m="20px" p={2} sx={{ backgroundColor: colors.primary[700] }}>
        <Box width={800} height="90vh" >
            <Box
            backgroundColor={colors.primary[400]}
            overflow="auto"
            >
                <Box
                    alignItems="center"
                    borderBottom={`4px solid ${colors.primary[500]}`}
                    colors={colors.gray[100]}
                    p="15px"
                >
                    <Typography color={colors.gray[100]} variant="h5" fontWeight="600">
                        <FormattedMessage {...messages.settings} />
                    </Typography>
                </Box>
                <Box p={2}>
                    <Box sx={{marginBottom: '10px'}}> 
                        <Button 
                            text={<FormattedMessage {...messages.clearLocalStorage} />}
                            variant="contained" 
                            onClick={handleClearLocalStorage}
                            />
                    </Box>
                    <Box > 
                        <AxionControls.AxionTextBox
                            label={<FormattedMessage {...messages.timezone} />}
                            name="timezone"
                            value={companyQuery.data?.timezone}
                            onChange={handleChange}
                        />
                    </Box>
                    <Box > 
                        <AxionControls.AxionTextBox
                            label={<FormattedMessage {...messages.grafanaBaseUrl} />}
                            name="grafanaBaseUrl"
                            value={companyQuery.data?.grafanaBaseUrl}
                            onChange={handleChange}
                        />
                    </Box>
                    <Box> 
                        <AxionControls.AxionTextBox
                            sx={{width: '50%', paddingRight: '6px',}}
                            name="oeePercentWarning"
                            label={<FormattedMessage {...messages.oeePercentWarning} />}
                            value={companyQuery.data?.oeePercentWarning}
                            onChange={handleChange}
                            inputProps={{
                                endAdornment: <InputAdornment position="end" sx={{color: colors.yellow[500]}}><Square/> </InputAdornment>,
                                }}
                            />
                        <AxionControls.AxionTextBox
                            sx={{width: '50%', paddingLeft: '6px',}}
                            name="oeePercentBad"
                            label={<FormattedMessage {...messages.oeePercentBad} />}
                            value={companyQuery.data?.oeePercentBad}
                            onChange={handleChange}
                            inputProps={{
                                endAdornment: <InputAdornment position="end" sx={{color: colors.redAccent[500]}}><Square/> </InputAdornment>,
                                }}
                            />
                    </Box>
                    
                    <Box> 
                        <AxionControls.AxionTextBox
                            sx={{width: '50%', paddingRight: '6px',}}
                            name="availabilityPercentWarning"
                            label={<FormattedMessage {...messages.availabilityPercentWarning} />}
                            value={companyQuery.data?.availabilityPercentWarning}
                            onChange={handleChange}
                            inputProps={{
                                endAdornment: <InputAdornment position="end" sx={{color: colors.yellow[500]}}><Square/> </InputAdornment>,
                                }}
                            />
                        <AxionControls.AxionTextBox
                            sx={{width: '50%', paddingLeft: '6px',}}
                            name="availabilityPercentBad"
                            label={<FormattedMessage {...messages.availabilityPercentBad} />}
                            value={companyQuery.data?.availabilityPercentBad}
                            onChange={handleChange}
                            inputProps={{
                                endAdornment: <InputAdornment position="end" sx={{color: colors.redAccent[500]}}><Square/> </InputAdornment>,
                                }}
                            />
                    </Box>

                    <Box> 
                        <AxionControls.AxionTextBox
                            sx={{width: '50%', paddingRight: '6px',}}
                            name="performancePercentWarning"
                            label={<FormattedMessage {...messages.performancePercentWarning} />}
                            value={companyQuery.data?.performancePercentWarning}
                            onChange={handleChange}
                            inputProps={{
                                endAdornment: <InputAdornment position="end" sx={{color: colors.yellow[500]}}><Square/> </InputAdornment>,
                                }}
                            />
                        <AxionControls.AxionTextBox
                            sx={{width: '50%', paddingLeft: '6px',}}
                            name="performancePercentBad"
                            label={<FormattedMessage {...messages.performancePercentBad} />}
                            value={companyQuery.data?.performancePercentBad}
                            onChange={handleChange}
                            inputProps={{
                                endAdornment: <InputAdornment position="end" sx={{color: colors.redAccent[500]}}><Square/> </InputAdornment>,
                                }}
                            />
                    </Box>

                    <Box> 
                        <AxionControls.AxionTextBox
                            sx={{width: '50%', paddingRight: '6px',}}
                            name="qualityPercentWarning"
                            label={<FormattedMessage {...messages.qualityPercentWarning} />}
                            value={companyQuery.data?.qualityPercentWarning}
                            onChange={handleChange}
                            inputProps={{
                                endAdornment: <InputAdornment position="end" sx={{color: colors.yellow[500]}}><Square/> </InputAdornment>,
                                }}
                            />
                        <AxionControls.AxionTextBox
                            sx={{width: '50%', paddingLeft: '6px',}}
                            name="qualityPercentBad"
                            label={<FormattedMessage {...messages.qualityPercentBad} />}
                            value={companyQuery.data?.qualityPercentBad}
                            onChange={handleChange}
                            inputProps={{
                                endAdornment: <InputAdornment position="end" sx={{color: colors.redAccent[500]}}><Square/> </InputAdornment>,
                                }}
                            />
                    </Box>


                    <Box marginTop={2}>
                        <Button 
                            text={<FormattedMessage {...messages.save} />}
                            variant="contained" 
                            onClick={handleSave}
                            />
                    </Box>
                </Box>
            </Box>
        </Box>
        <AxionControls.AxionBackdrop open={showBackdrop} />
    </Box>
}

        