

    export const lateInitialState = {
      filter: {
                    
        filterModel: {
          items: [{ columnField: 'late', operatorValue: 'contains', value: 'late' }]
        },
      },
        sorting: {
          sortModel: [
            { field: "created", sort: "desc" },
           
          ],
        },
        columns: {
          columnVisibilityModel: {
            // Hide columns status and traderName, the other columns will remain visible
            created: false,
            closed: false,
            closedBy: false,
            maxClosedDate: false,
            edit:false,
          },
        },
      }

      export const standardInitialState = {
          sorting: {
            sortModel: [
              { field: "created", sort: "desc" },
              { field: "late", sort: "desc" },
             
            ],
          },
          columns: {
            columnVisibilityModel: {
              // Hide columns status and traderName, the other columns will remain visible          
              maxClosedDate: false,
             
            },
          },
        }

        export const onlyOpenInitialState = {
          filter: {
                    
            filterModel: {
              items: [{ columnField: 'text', operatorValue: 'contains', value: 'Not started' }]
            },
          },
          sorting: {
            sortModel: [
              { field: "created", sort: "desc" },
              { field: "late", sort: "desc" },
             
            ],
          },
          columns: {
            columnVisibilityModel: {
              // Hide columns status and traderName, the other columns will remain visible          
              created: false,
              closed: false,
              closedBy: false,
              maxClosedDate: false,
             
            },
          },
        }
    
    

