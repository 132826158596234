import axios from "axios";

export const fetchProductionItems = async (companyKey) => {
    const response = await axios.get("api/productionItems/?companyKey=" + companyKey);
    const productionItems = response.data;
    return productionItems;
}

export const updateProductionItem = async (productionItem) => {
    try {
      const response = await axios.put(`/api/productionItems/`, productionItem);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  };

export const createProductionItem = async (productionItem) => {
    try {
      const response = await axios.post(`/api/productionItems/`, productionItem);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }

export const importProductItems  = async (productionItems) => {
    try {
      const response = await axios.post(`/api/productionItems/import`, productionItems);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }

export const deleteProductionItem = async (id) => {
    try {
      const response = await axios.delete(`/api/productionItems/${id}`);
      return response;
    } catch (error) {
      throw error.response.data;
    }
  }