import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import { TextField, FormControl, InputAdornment } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { inputLabelClasses } from "@mui/material/InputLabel";


  const RedditTextField = styled((props) => (
    <TextField InputProps={{ disableUnderline: true }} {...props} />
  ))(({ theme }) => ({
    '& .MuiFilledInput-root': {
      overflow: 'hidden',
      borderRadius: 4,
      color: "#000000",
      backgroundColor: theme.palette.mode === 'light' ? '#ffffff !important'  : '#ffffff !important',
      border: '2px solid',
      borderColor: theme.palette.mode === 'light' ? '#799c4a !important' : '#799c4a !important',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      '&:hover': {
        backgroundColor: '#ffffff',
      },
      '&.Mui-focused': {
        //backgroundColor: 'transparent',
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
        //borderColor: theme.palette.primary.main,
        color: "#000000"
      },
      '& .MuiInputLabel-root': {
        //backgroundColor: 'transparent',
        color: "#000000"
      },
      '& .Mui-disabled': {
        //backgroundColor: 'transparent',
        color: "#000000"
      },
      '& .MuiInputBase-input ': {
        padding: '12px',
      }
    },
  }));

  export default function AxionTextBoxNoLabelReadOnly(props) {
    const { name, sx, label, value, error=null, size, onChange, disabled, inputRef, ...other } = props;

    return (

        <FormControl fullWidth variant="standard" sx={sx}>
               <RedditTextField
                  label={label}
                  defaultValue={value}
                  fullWidth
                  id={name}
                  inputRef={inputRef}
                  size="small"
                  variant="filled"
                  InputProps={{
                    readOnly: true,
                    endAdornment: <InputAdornment position="end" sx={{color: "#000"}}><LockIcon sx={{Color: "#000000"}} /> </InputAdornment>,
                  }}  
                  InputLabelProps={{
                    sx: {
                      // set the color of the label when not shrinked
                      color: "#799c4a",
                      [`&.${inputLabelClasses.shrink}`]: {
                        // set the color of the label when shrinked (usually when the TextField is focused)
                        color: "#799c4a"
                      }
                    }
                  }}
                  onChange={onChange}
                />
        </FormControl>

    );
  };

