import React from "react";
import { Box, Typography, useTheme, Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import { experimentalStyled as styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const MachineTopBar = ({ machineId }) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={{ xs: 3, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
       <Grid item xs={3} sm={4} md={2} key={1}>
            <Item>xs=2</Item>
          </Grid>
          <Grid item xs={3} sm={4} md={2} key={2}>
            <Item>xs=2</Item>
          </Grid>
          <Grid item xs={3} sm={4} md={2} key={3}>
            <Item>xs=2</Item>
          </Grid>
          <Grid item xs={3} sm={4} md={2} key={4}>
            <Item>xs=2</Item>
          </Grid>
      </Grid>
    </Box>
  );
};

export default MachineTopBar;
