import React from 'react'
import GanttIndex from '../features/gantt/index'

export default function Gant() {
  return (
    
    <GanttIndex />
  )

}
