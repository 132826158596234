import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { fetchCompanies } from "../../fetchers/company";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export default function CompanySelector(props) {
    const [companyKey, setCompanyKey] = useLocalStorage("companyKey", 1);
    
    const companyQuery = useQuery(
        ["companies"],
        () => fetchCompanies(),
        {
          staleTime: 120000,
          refetchInterval: 120000
        }
    );

    const handleChange = (event) => {
        setCompanyKey(event.target.value);
        window.location.reload();
    };

    return (
        <>
            { companyQuery?.data && companyQuery?.data.length > 1 &&

            <Select
                size="small"
                labelId="companyKeyLabel"
                id="companyKey"
                value={companyKey}
                onChange={handleChange}
            >
                {companyQuery.data && companyQuery.data.map((company) => (
                    <MenuItem key={company.id} value={company.id}>{company.companyName}</MenuItem>
                ))}
            </Select> }
        </>
    );
}