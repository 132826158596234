import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { MsalProvider, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./auth/authConfig";
import App from './App';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import '@fortawesome/fontawesome-free/css/all.min.css';
import store from "./store/store.js";
import { Provider } from "react-redux";
import { registerLicense } from '@syncfusion/ej2-base';
import ReactDOM from "react-dom/client";



const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
const msalInstance = new PublicClientApplication(msalConfig);
registerLicense('Mgo+DSMBaFt+QHFqVkNrXVNbdV5dVGpAd0N3RGlcdlR1fUUmHVdTRHRcQl5hQX5adUZhWXldcHw=;Mgo+DSMBPh8sVXJ1S0d+X1RPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSX1RfkVqWnxccHJQQWg=;ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5QdE1jUHxdcnRTQWNU;MTQ5ODE0NUAzMjMxMmUzMTJlMzMzNVp1aGxXWldGbkFRWHNqVVRXeHc5cXZ2dXpaZXM0VTY0YUpzYm1JOHkwQTQ9;MTQ5ODE0NkAzMjMxMmUzMTJlMzMzNWNJZmJZcE82Z3grY1JUWE1DTk9sUGtHaDhpd2JVSmkrN1huTVpqZ21jcFk9;NRAiBiAaIQQuGjN/V0d+XU9Hc1RDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TdUdrWXZdc3ZVQWRUWQ==;MTQ5ODE0OEAzMjMxMmUzMTJlMzMzNWFuTWJOLzAyd1FnVXpLa1IzRXJ4WmdzYWZ0VUlCZlhOQ2o5K1FkTis1WUE9;MTQ5ODE0OUAzMjMxMmUzMTJlMzMzNWNqdytnSFBOM0N0Tm45Wk1ZNkR1cVh1NENRd1d0OHZsMDk0U2hQQ0I1eGM9;Mgo+DSMBMAY9C3t2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5QdE1jUHxdcnRdRGVV;MTQ5ODE1MUAzMjMxMmUzMTJlMzMzNUJrWFFJc0NUQnRLdGI3UzVrTGVOdE9MNVRGOEVmcUlrTzRIQ1FIbkpjL009;MTQ5ODE1MkAzMjMxMmUzMTJlMzMzNW5GVUFaQWsyelZJVW1ySFVNM3UxU29WWURjaTY1RWcxWTVHNWlpY3RUbkk9;MTQ5ODE1M0AzMjMxMmUzMTJlMzMzNWFuTWJOLzAyd1FnVXpLa1IzRXJ4WmdzYWZ0VUlCZlhOQ2o5K1FkTis1WUE9');

root.render(
 <React.StrictMode>
  <Provider store ={store}>
  <MsalProvider instance={msalInstance} >
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>
  </MsalProvider>
  </Provider>
  </React.StrictMode>
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
