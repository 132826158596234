import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme, Divider, Button, useMediaQuery } from "@mui/material";
import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "../auth/SignInButton";
import { SignOutButton } from "../auth/SignOutButton";
import { useMsal, useAccount } from "@azure/msal-react";
import { useSelector } from "react-redux";
//LANGUAGE
import messages from "./messages";
import { FormattedMessage } from "react-intl";

import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../assets/theme";
import ProfilePicture from "../auth/ProfilePicture";
//ICONS
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import FlakyOutlinedIcon from '@mui/icons-material/FlakyOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import FormatColorFillOutlinedIcon from '@mui/icons-material/FormatColorFillOutlined';
import EmbeddedReportLinks from "../components/sidebar/embeddedReportLinks";
import ViewTimelineOutlinedIcon from '@mui/icons-material/ViewTimelineOutlined';
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import VersionNumber from "../components/sidebar/versionNumber";
import ConstructionIcon from '@mui/icons-material/Construction';
import ArticleIcon from '@mui/icons-material/Article';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import FactoryOutlinedIcon from '@mui/icons-material/FactoryOutlined';
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const profileImage = ProfilePicture();

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.gray[100],
        margin: "0px 0 0px 0",
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isAuthenticated = useIsAuthenticated();
  const [selected, setSelected] = useState("Dashboard");
  const { accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const { instance } = useMsal();
  const [isSettingCollapsed, setIsSettingCollapsed] = useState(true);
  const [isReportCollapsed, setIsReportCollapsed] = useState(true);
  const monitorEnabled = useSelector((state) => state.monitorIntegrationEnabled.monitorIntegrationEnabled);

  const handleLogout = (logoutType) => {
    if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  };

  const isOnMobile = useMediaQuery(theme => theme.breakpoints.down('lg'));
  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "0px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#a0cb66 !important",
        },
        "& .pro-menu-item.active": {
          color: "#799c4a !important",
        },
      }}
    >
      { (!isOnMobile || (isOnMobile && !props.isCollapsed)) &&
      <ProSidebar collapsed={props.isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => props.setIsCollapsed(!props.isCollapsed)}
            icon={props.isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "0px 0 10px 0px",
              color: colors.gray[100],
            }}
          >
            {!props.isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="0px"
              >
                <img
                  alt="profile-user"
                  width="160px"
                  height="45px"
                  src={`../../assets/Axion_solutions_white.png`}
                />
                
                <IconButton onClick={() => props.setIsCollapsed(!props.isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!props.isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <ProfilePicture />
              </Box>
              <Box textAlign="center">
                <Typography
                  //variant="h4"
                  color={colors.gray[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0", fontSize: "1.0rem"}}
                >
                  {account.name}
                </Typography>
     
              </Box>
            </Box>
          )}
          <Divider />
          
          <Box paddingLeft={props.isCollapsed ? undefined : "10%"}>
          <Typography
              variant="h6"
              color={colors.gray[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              <FormattedMessage {...messages.pagesLabel} />
            </Typography>
            <Item
              title={<FormattedMessage {...messages.homeMenuItem} />}
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={<FormattedMessage {...messages.ganttMenuItem} />}
              to="/gantt"
              icon={<ViewTimelineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={<FormattedMessage {...messages.machinesMenuItem} />}
              to="/machines"
              icon={<PrecisionManufacturingOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={<FormattedMessage {...messages.productionPlanningMenuItem} />}
              to="/commingsoon"
              icon={<CalendarMonthOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={<FormattedMessage {...messages.activitiesMenuItem} />}
              to="/activities"
              icon={<NotificationsActiveOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={<FormattedMessage {...messages.materialMenuItem} />}
              to="https://axjooeeapi.azurewebsites.net/material/materialconnection"
              icon={<FormatColorFillOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Material Source"
              to="https://axjooeeapi.azurewebsites.net/material/materialsource"
              icon={<FormatColorFillOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
             <Typography
              variant="h6"
              color={colors.gray[300]}
              sx={{ m: "15px 0 5px 20px", cursor: "pointer" }}
              onClick={() => setIsReportCollapsed(!isReportCollapsed)}
            >
             <FormattedMessage {...messages.reportsLabel} />
             { isReportCollapsed && 
                <IconButton arial-label="Expand Reports" size="small">
                    <ExpandMore fontSize="inherit" />
                </IconButton>
              }    
              { !isReportCollapsed && 
                <IconButton arial-label="Collapse Reports" size="small">
                    <ExpandLess fontSize="inherit" />
                </IconButton>
              }     
            </Typography>
            { !isReportCollapsed && <EmbeddedReportLinks /> }
            <Typography
              variant="h6"
              color={colors.gray[300]}
              sx={{ m: "15px 0 5px 20px", cursor: "pointer" }}
              onClick={() => setIsSettingCollapsed(!isSettingCollapsed)}
            >
              {<FormattedMessage {...messages.settingsLabel} />} 
              { isSettingCollapsed && 
                <IconButton arial-label="Expand Settings" size="small">
                    <ExpandMore fontSize="inherit" />
                </IconButton>
              }    
              { !isSettingCollapsed && 
                <IconButton arial-label="Collapse Settings" size="small">
                    <ExpandLess fontSize="inherit" />
                </IconButton>
              }     
            </Typography>
                     
            { !isSettingCollapsed && 
              <>
                <Item
                  title={<FormattedMessage {...messages.editEmbeddedReportsMenuItem} />}
                  to="/embeddedReports"
                  icon={<ListAltOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                
                <Item
                  title={<FormattedMessage {...messages.stopReasonCodesMenuItem} />}
                  to="/stopReasonCodes"
                  icon={<FlakyOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                
                <Item
                  title={<FormattedMessage {...messages.toolsMenuItem} />}
                  to="/tools"
                  icon={<ConstructionIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />

                <Item
                  title={<FormattedMessage {...messages.productionItemsMenuItem} />}
                  to="/productionItems"
                  icon={<ArticleIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title={<FormattedMessage {...messages.machinesMenuItem} />}
                  to="/machines"
                  icon={<PrecisionManufacturingOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title={<FormattedMessage {...messages.machineTypesMenuItem} />}
                  to="/machineTypes"
                  icon={<DnsOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </>
            }

          </Box>
          <Divider />
          <Box paddingLeft={props.isCollapsed ? undefined : "10%"} >
          {!props.isCollapsed ?
              <Button
              variant="secondary"
              sx={{
                backgroundColor: "#af3f3b",
                marginLeft: 1,
                marginTop: 2,
                width: 200,
                "&:hover": {
                  backgroundColor: colors.redAccent[700],
                  borderColor: "#0062cc",
                  boxShadow: "none",
                },
              }}
              className="ml-auto"
              onClick={() => handleLogout("redirect")}
            >
              <FormattedMessage {...messages.signout} />
            </Button>
      
              :
              <Button
              variant="secondary"
              sx={{
                backgroundColor: "#af3f3b",
                marginLeft: 1,
                marginTop: 2,
                width: 10,
                "&:hover": {
                  backgroundColor: colors.redAccent[700],
                  borderColor: "#0062cc",
                  boxShadow: "none",
                },
              }}
              className="ml-auto"
              onClick={() => handleLogout("redirect")}
            >
              <PowerSettingsNewIcon />
            </Button>

          }

          </Box>
          {!props.isCollapsed && (
          <Box mb="25px" m={"20px 0 0 0"}>
              <Box textAlign="center">
                <VersionNumber />
              </Box>
          </Box>
          )}
        </Menu>

      </ProSidebar> }
      
    </Box>
  );
};

export default Sidebar;
