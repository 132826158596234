import axios from "axios";
import dayjs from "dayjs";  

export const fetchMachineStops = async (machineId, startDate, endDate, useDateInterval, timespanStartDate, timespanEndDate, maxRowCount) => {
    let start = "";
    let end = ""

    if (useDateInterval === true){
        // start = dayjs(startDate).format("YYYY-MM-DDT00:00:00.000[Z]");
        // end = dayjs(endDate).format("YYYY-MM-DDT23:59:59.999[Z]");
        start = startDate;
        end = endDate;
    } else {
        start = timespanStartDate;
        end = timespanEndDate;
    }

    let formattedStartDate = dayjs(start).format("YYYY-MM-DDTHH:mm:ss.SSS");
    let formattedEndDate = dayjs(end).format("YYYY-MM-DDTHH:mm:ss.SSS");

    // console.log("getmachinestops:");
    // console.log ("api/api/getmachinestops/"+ machineId + "?maxRowCount=" + maxRowCount + "&startDateTime=" + formattedStartDate + "&endDateTime=" + formattedEndDate);
    // const response = await axios.get("api/api/getmachinestops/"+ machineId + "?maxRowCount=" + maxRowCount + "&startDateTime=" + formattedStartDate + "&endDateTime=" + formattedEndDate);
    const response = await axios.get("api/machinestop/getmachinestops/"+ machineId + "?maxRowCount=" + maxRowCount + "&startDateTime=" + formattedStartDate + "&endDateTime=" + formattedEndDate);
    const machineStopsData = response.data;
    return machineStopsData;

}

export const fetchMachineStop = async (machineStopId) => {
    const response = await axios.get("api/machinestop/"+ machineStopId);
    const machineStop = response.data;
    return machineStop;
}

export const patchMachineStop = async (machineStop) => {
    const response = await axios.patch("api/machinestop", machineStop);
    return response.data;
}

export const putMachineStopSplit = async (machineStopList, companyKey, userName) => {
    const response = await axios.put(`api/machinestop/split?companyKey=${companyKey}&userName=${userName}`, machineStopList);
    return response.data;
}

export const patchMachineStops = async (machineStop) => {
    const response = await axios.patch("api/machinestop/multi", machineStop);
    return response.data;
}

export const fetchMachineInfo = async (machineId) => {
    const response = await axios.get("api/api/getMachineInfo/"+ machineId);
    const machineInfo = response.data;
    return machineInfo;
}

export const fetchMachineData = async (machineId) => {
    const response = await axios.get("api/machine/"+ machineId);
    const machineData = response.data;
    return machineData;
}

export const fetchMachines = async (companyKey) => {
    const response = await axios.get(`api/machine/?companyKey=${companyKey}`);
    const machines = response.data;
    return machines;
}

export const patchMachineData = async (machineData) => {
    const response = await axios.patch("api/machine", machineData);
    return response.data;
}

export const patchMachineComment = async (machineComment) => {
    const response = await axios.patch("api/machine/comment", machineComment);
    return response.data;
}

export const fetchMachineDetails = async (companykey, timespan, machinename) => {
    //console.log ("getting data:");
    //console.log ("api/oeegrid/getoeetimespanmachine/"+ companykey + "?timespan="+ timespan + "&machinename="+ machinename);
    const response = await axios.get("api/oeegrid/getoeetimespanmachine/"+ companykey + "?timespan="+ timespan + "&machinename="+ machinename);
    const machineDetails = response.data;
    return machineDetails;
}

export const fetchOeeGridDateIntervalmachine = async(startDate, endDate, companyKey, machineId) => {
    //console.log("fetching OEE grid data for machine:");
    //console.log ("api/api/getoeedateintervalmachine/"+companyKey+"/startDate="+dayjs(startDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")+"&endDate="+dayjs(endDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")+"&machineId="+machineId);
    const response = await axios.get("api/api/getoeedateintervalmachine/"+companyKey+"/startDate="+dayjs(startDate).format("YYYY-MM-DDTHH:mm:ss.SSS")+"&endDate="+dayjs(endDate).format("YYYY-MM-DDTHH:mm:ss.SSS")+"&machineId="+machineId);
    const oeeGridData = response.data;
    return oeeGridData;
    //https://localhost:7008/api/api/getoeedateintervall/1/startDate=2023-01-03T10:32:30.456Z&endDate=2023-02-03T10:32:30.456Z
}

export const fetchActivitesMachine= async(machineId) => {
    
    const response = await axios.get("api/machine/activities/"+machineId+"?onlyNotClosed=true");
    const oeeGridData = response.data;
    return oeeGridData;
    
}
