/**
 * Renders the header section of the activity component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.activity - The activity object.
 * @param {boolean} props.setShowBackdrop - The function to set the backdrop visibility.
 * @param {boolean} props.setOpenPopup - The function to set the popup visibility.
 * @returns {JSX.Element} The rendered component.
 */
import { Box, Button, Grid, useTheme } from "@mui/material";
import React, { useState } from "react";
import { tokens } from "../../../assets/theme";
import AxionTextBoxReadOnly from "../../../components/controls/AxionTextBoxReadOnly";
import AxionTextBox from "../../../components/controls/AxionTextBox";
import Controls from "../../../components/controls/Contols";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useMsal, useAccount } from "@azure/msal-react";
import { updateActivity } from "../hooks/fetchers";
import { pushErrorMessage } from "../../../utils/notifications";

const ActivityHeader = (props) => {
  const { activity, setShowBackdrop, setOpenPopup } = props;
  const [showActivityComment, setShowActivityComment] = React.useState(false);
  const [reasonComment, setReasonComment] = React.useState("");
  const { accounts } = useMsal();
  const account = useAccount(accounts[0]);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  console.log(props.activity);
  const handleSaveCancellation = () => {
    activity.activityStatus.id = 5;
    activity.activityStatus.text = "Cancelled";
    activity.closed = new Date();
    activity.closedBy = account.username;
    activity.updated = new Date();
    activity.updatedBy = account.username;
    activity.activityStatusId = 5;
    activity.reasonForCancellation = reasonComment;

    updateActivity(activity)
      .then(() => {
        props.activityResult.refetch();
        setShowBackdrop(false);
        setOpenPopup(false);
      })
      .catch((error) => {
        console.log(error);
        props.setShowBackdrop(false);
        pushErrorMessage(`Cannot cancel activity`);
      });
  };
  const handleCancelClick = () => {
    setShowActivityComment(!showActivityComment);
  };

  console.log(account);
  return (
    <Box ml="14px" mr="25px">
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <AxionTextBoxReadOnly
            //color="success"
            //variant="filled"
            sx={{ m: 1, flexGrow: 1 }}
            label="Activity Name"
            value={activity.title}
          />
        </Grid>
        <Grid item xs={4}>
          <AxionTextBoxReadOnly
            label="Will auto close at"
            variant="filled"
            sx={{ m: 1, flexGrow: 1 }}
            value={new Date(activity.maxClosedDate).toLocaleString("sv-SE")}
          />
        </Grid>
        <Grid item xs={2}>
          <AxionTextBoxReadOnly
            label="Machine"
            sx={{ m: 1, flexGrow: 1 }}
            variant="filled"
            value={activity.machine.machineName}
          />
        </Grid>
        <Grid item xs={2}>
          <Controls.ActionButton
            size="lg"
            sx={{
              ml: 1,
              marginTop: "20px",
              padding: "2px 8px !important",
              "&:hover": {
                backgroundColor: colors.redAccent[700],
              },
              backgroundColor: colors.redAccent[500],
              color: colors.primary[100],
            }}
            onClick={handleCancelClick}
          >
            <ReportProblemOutlinedIcon /> Cancel Activity
          </Controls.ActionButton>
        </Grid>
      </Grid>
      {showActivityComment ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AxionTextBox
              label="Reason for cancelling"
              variant="filled"
              sx={{ m: 1, flexGrow: 1 }}
              onChange={(e) => setReasonComment(e.target.value)}
              value={reasonComment}
            />
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      {showActivityComment && reasonComment.length > 1 ? (
        <Grid container spacing={2}>
          <Controls.ActionButton
            size="lg"
            sx={{
              ml: 3,
              marginTop: "20px",
              "&:hover": {
                backgroundColor: colors.blueAccent[700],
              },
              backgroundColor: colors.blueAccent[500],
              color: colors.primary[100],
            }}
            onClick={handleSaveCancellation}
          >
            <SaveOutlinedIcon sx={{ mr: 1 }} /> Save
          </Controls.ActionButton>
        </Grid>
      ) : (
        <></>
      )}
    </Box>
  );
};
export default ActivityHeader;
