import React from "react";
import { Box } from "@mui/system";
import { FormattedMessage } from "react-intl";
import messages from "../layouts/messages";

export default function CommingSoonPage() {
  return (
    <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
      <h2><FormattedMessage {...messages.commingSoon} /></h2>
    </Box>
  );
}