import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";

const AxionBackdrop = ({ open }) => {
    return (
        <Backdrop 
            open={open}
            sx={{ color: '#fff', zIndex: 2000}}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}

export default AxionBackdrop;
