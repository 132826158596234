import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import Button from "../../../components/Button";
import { tokens } from "../../../assets/theme";
import * as XLSX from 'xlsx';
import { importProductItems } from "../../../fetchers/productionItems";
import { pushNotification, pushErrorMessage } from "../../../utils/notifications";
import { FormattedMessage, useIntl } from "react-intl";
import messages from "./messages";

export default function ImportProductionItemDialog(props) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { formatMessage } = useIntl();
    
    const [file, setFile] = useState(null);
    
    const handleUpload = () => {
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const arrayBuffer = e.target.result;
            const data = new Uint8Array(arrayBuffer);
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet);

            importProductItems(json).then(() => {
                props.productionItems.refetch();
                pushNotification(formatMessage(messages.fileImportSuccess), "success");
                props.setOpenPopup(false);
            }).catch((error) => {
                console.log(error);
                pushErrorMessage(formatMessage(messages.fileImportError));
            });
          };
          reader.readAsArrayBuffer(file);
          
        }
      };
    

    return (
        <Box 
            sx={{
                p:1,
                m:1
            }}>
                <FormattedMessage {...messages.importProductionItemDescription} /> <a href="/import-production-items.xlsx"><FormattedMessage {...messages.importProductionItemDescriptionLinkText} /></a>.<br/><br/>
                <input type="file" accept=".xls,.xlsx" onChange={e => setFile(e.target.files[0])} /> <br/><br/>
                <Button 
                    onClick={handleUpload} 
                    text={<FormattedMessage {...messages.upload} />}
                    variant="contained" 
                    sx={{
                        backgroundColor: colors.primary[300],
                    }} />
        </Box>
    );
}