import React from 'react'
import { Autocomplete, Button as MuiButton, TextField } from "@mui/material";



export default function AutoComplete(props) {

    const { text, value, color, variant, onChange,label,sx, ...defProps } = props
    //const classes = useStyles();

    return (

        <Autocomplete
            {...defProps}
            value={value}
            renderInput={(params) => (<TextField {...params} label={label} />)}
            onChange={onChange}
            sx={sx}
            //inputValue={inputValue}
              //sx={{mb:4}}
              />

        //       <MuiButton
        //       variant={variant || "contained"}
        //       size={size || "large"}
        //       //color={color || "primary"}
        //       onClick={onClick}
        //       {...other}
        //       //classes={{ root: classes.root, label: classes.label }}
        //       >
        //       {text}
        //   </MuiButton>

    )
}