//import * as React from 'react';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState, useEffect } from "react";
import {
  Typography,
  useTheme,
  Box,
  Grid,
  CircularProgress,
  Stack,
  Divider,
  CardHeader,
  FormControlLabel,
  FormLabel,
  FormGroup,
} from "@mui/material";
import { tokens } from "../../../assets/theme";
import {
  fetchMaterialConnectionByMachineId,
  fetchConnectioinPoints,
  fetchMaterialSources,
  fetchDryingContainers,
  fetchRawMaterials,
  updateMachineMaterialConnection,
  fetchMachineWithdrawal,
} from "../hooks/fetchers";
import {
  useQuery,
  useMutation,
  useQueryClient,
  useQueries,
} from "@tanstack/react-query";

import ModeCommentIcon from "@mui/icons-material/ModeComment";
import InputAdornment from "@mui/material/InputAdornment";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import InputBase from '@mui/material/InputBase';


const BootstrapInput = styled(InputBase)(({ theme }) => ({

  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#727681',
    border: '1px solid #ced4da',
    fontSize: 14,
   
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.


    '&:hover': {
      borderRadius: 4,
      borderColor: "#07ba9c",
      //boxShadow: '0 0 0 0.2rem rgba(19, 21, 20, 0.25)',
    },
  },
}));

 const CommentInput = ({value,onChange}) => (<input defaultValue={value} onChange={onChange} />)

export default function MaterialConnectionDialog(props) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const queryClient = useQueryClient();
  //STATES
  const [companyKey, setCompanyKey] = useState(1);
  const [machineId, setMachineId] = useState(props.machineId);
  const [connectionPointId, setConnectionPointId] = useState();
  const [materialSourceState, setMaterialSourceState] = useState();
  const [dryingContainerState, setDryingContainerState] = useState("");
  const [rawMaterialState, setRawMaterialState] = useState();
  const [commentState, setCommentState] = useState("");
  const [saveButtonState, setSaveButtonState] = useState(true);
  const [backgroundColor, setBackgroundColor] = useState();
  const [dryingContainerStatusColorState, setDryingContainerStatusColorState] = useState(["True"]);
  const [dryingContainerStatusColorStates, setDryingContainerStatusColorStates] = useState("True");
  const [dryingContainerId, setDryingContainerId] = useState();
  const [dryingContainerInfoState, setDryingContainerInfoState] = useState([]);
  const [machineWithdrawal, setMachineWitdrawal] = useState();
  const [dryingContainerKgs, setDryingContainerKgs] = useState();
  const [totalDCWitdrawal, setTotalDCWitdrawal] = useState();
  const [dryingConainerName, setDryingContainerName] = useState();




  const colorStyles = {
    option: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: data.color,
      };
    },
  };

  //FUNCTIONS
  function changeDryingContainer(id) {
    if(dryingContainerQuery.isFetched === true)
    {
    return dryingContainerQuery.data
      .filter((item) => item.id === id)
      .map((item) => item.status);
    }else
    {
      return ["False"]
    }
  }
  function getDryingContainerName(id) {
    if(dryingContainerQuery.isFetched === true)
    {
    return dryingContainerQuery.data
      .filter((item) => item.id === id)
      .map((item) => item.name);
    }else
    {
      return ["False"]
    }
  }


  // function changeDryingContainer(id) {
  //   if(dryingContainerQuery.isFetched === true)
  //   {
  //   return dryingContainerQuery.data
  //     .filter((item) => item.id === id)
  //     .map((item) => item.status);
  //   }else
  //   {
  //     return ["False"]
  //   }
  // }

  function findColor(id) {

    return connectionPointQuery.data
      .filter((item) => item.id === id)
      .map((item) => item.color);
  }

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: colors.primary[400],
    padding: theme.spacing(1),
    //textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  //HANDLERS

  const handleConnectionPointSelect = (event) => {
    
    setConnectionPointId(event.target.value);

    setBackgroundColor(findColor(event.target.value));
    
    setSaveButtonState(false);
  };

  const handleMaterialSource = (event) => {
    //console.log(event);
    setMaterialSourceState(event.target.value);
    setSaveButtonState(false);
    
  };

  const handleDryingContainer = (event) => {
    //console.log(event.target);
    setDryingContainerState(event.target.value);

    setDryingContainerName(getDryingContainerName(event.target.value));
    setSaveButtonState(false);
    setDryingContainerId(event.target.value);
    setDryingContainerStatusColorState(changeDryingContainer(event.target.value));
  };

  const handleRawMaterial = (event) => {
    //console.log(event.target.value);
    setRawMaterialState(event.target.value);
    setSaveButtonState(false);
  };

  const handleComment = (event) => {
    //console.log(event.target.value);
    setCommentState(event.target.value);
    setSaveButtonState(false);
  };

  const handleSubmit = () => {
    updateMachineMaterialConnectionMutation.mutate({
      machineId: props.machineId,
      machineConnectionId: connectionPointId,
      materialSourceId: materialSourceState,
      dryingContainerId: dryingContainerId,
      rawMaterial: rawMaterialState,
      comment: document.getElementById('commentTextField').value,
      
    });
    
    props.callback();
   
  };


  // REACT - QUERIES
  const [
    connectionPointQuery,
    materialSourcesQuery,
    dryingContainerQuery,
    rawMaterialQuery,
    withdrawalQuery,
  ] = useQueries({
    queries: [
      {
        queryKey: ["connectionPoints", companyKey],
        queryFn: () => fetchConnectioinPoints(companyKey),
        staleTime: 60000,
        enabled: props.show,
      },
      {
        queryKey: ["materialSources", companyKey],
        queryFn: () => fetchMaterialSources(companyKey),
        staleTime: 60000,
        enabled: props.show,
      },
      {
        queryKey: ["dryingContainers", companyKey],
        queryFn: () => fetchDryingContainers(companyKey),
        staleTime: 60000,
        enabled: props.show,
      },
      {
        queryKey: ["rawMaterials", companyKey],
        queryFn: () => fetchRawMaterials(companyKey),
        staleTime: 60000,
        enabled: props.show,
      },
      {
        queryKey: ["withdrawal"],
        queryFn: () => fetchMachineWithdrawal(),
        staleTime: 60000,
        enabled: props.show,
      },
    ],
  });

  //Update machine material connection and invalidates previous calls.
  const updateMachineMaterialConnectionMutation = useMutation(
    updateMachineMaterialConnection,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          "connectionPoints",
          "materialSources",
          "dryingContainers",
          "rawMaterials"
        );
      },
    }
  );

  //EFFECT HOOKS
  useEffect(() => {
    if (props.show === true) {
     
      connectionPointQuery.refetch();
      setConnectionPointId(props.rowData.connectionPointId);
      setMaterialSourceState(props.rowData.materialSourceId);
      setDryingContainerState(props.rowData.dryingContainerId);
      setRawMaterialState(props.rowData.rawMaterialName);
      setCommentState(props.rowData.comment);
      setBackgroundColor(props.rowData.connectionColor);
      setSaveButtonState(true);
      setMachineId(props.rowData.machineId);
      //setDryingContainerStatusColorState(changeDryingContainer(props.rowData.dryingContainerId));
      setDryingContainerId(props.rowData.dryingContainerId);
      withdrawalQuery.refetch();
      dryingContainerQuery.refetch();
      setDryingContainerName(getDryingContainerName(props.rowData.dryingContainerId));

    }
  }, [props.show],[dryingContainerStatusColorState],[dryingContainerId]);

  // useEffect(() => {
    
  //   if(withdrawalQuery.data)
  //   {
  //     withdrawalQuery.data
  //       .filter(e => e.id === props.rowData.machineId)
  //       .map((e) => (setMachineWitdrawal(JSON.stringify(e.kgHCurrentStatus))));
  //   }
  // }, [props.show])

  useEffect(() => {
      if(dryingContainerQuery.status.data)
      {
    
        dryingContainerQuery.data
        .filter(e => e.id === dryingContainerId)
        .map((e) => (setDryingContainerKgs(JSON.stringify(e.numberOfKilos))));

        dryingContainerQuery.data
        .filter((e) => e.id === dryingContainerId )
        .map((e) => setTotalDCWitdrawal(JSON.stringify(e.demandMachines)))
      }
      if(withdrawalQuery.data)
      {
        withdrawalQuery.data
          .filter(e => e.id === props.rowData.machineId)
          .map((e) => (setMachineWitdrawal(JSON.stringify(e.kgHCurrentStatus))));
      }
      if(dryingContainerId)
      {
        setDryingContainerStatusColorState(changeDryingContainer(props.rowData.dryingContainerId));
      }
      
      
  },[])

  useEffect(() => {
    if (
      connectionPointQuery.status === "success" &&
      !connectionPointQuery.isFetching
    ) {
    
      setConnectionPointId(props.rowData.connectionPointId);
      setMaterialSourceState(props.rowData.materialSourceId);
      setDryingContainerState(props.rowData.dryingContainerId);
      setRawMaterialState(props.rowData.rawMaterialName);
      setCommentState(props.rowData.comment);
      setDryingContainerStatusColorState(changeDryingContainer(props.rowData.dryingContainerId));
      
    }
  }, [connectionPointQuery.isRefetching, connectionPointQuery.isFetched] );

  // //END EFFECT HOOKS
  // if (
  //   (connectionPointQuery.isFetching && props.show === true) ||
  //   (materialSourcesQuery.isFetching && props.show === true) ||
  //   (props.show === true && dryingContainerQuery.isFetching === true) ||
  //   (props.show === true && rawMaterialQuery.isFetching === true) ||
  //   (props.show === true && withdrawalQuery.isFetching === true) 
  // ) {
  //   return (
  //     <Dialog
  //       open={props.show}
  //       onClose={props.toggleShow}
  //       fullWidth="md"
  //       maxWidth="md"
  //       color={colors.primary[500]}
  //     >
  //       <DialogContent
  //         sx={{ backgroundColor: colors.primary[400], display: "flex" }}
  //       >
  //         <Box sx={{ display: "flex" }}>
  //           <CircularProgress color="secondary" />
  //         </Box>
  //       </DialogContent>
  //     </Dialog>
  //   );
  // }
  // if (
  //   (props.show === true && connectionPointQuery.isFetching !== true) ||
  //   (props.show === true && materialSourcesQuery.isFetching !== true) ||
  //   (props.show === true && dryingContainerQuery.isFetching !== true) ||
  //   (props.show === true && rawMaterialQuery.isFetching !== true) ||
  //   (props.show === true && withdrawalQuery.isFetching !== true) 
  // ) {    
    return (
      <Dialog
      open={props.show}
      onClose={props.toggleShow}
      //fullWidth="md"
      maxWidth="md"
      color={colors.primary[500]}
      >
    {
    
    (() => {
      if (  (connectionPointQuery.isFetching && props.show === true) ||
      (materialSourcesQuery.isFetching && props.show === true) ||
      (props.show === true && dryingContainerQuery.isFetching === true) ||
      (props.show === true && rawMaterialQuery.isFetching === true) ||
      (props.show === true && withdrawalQuery.isFetching === true) 
      )
      {
        return(
      <DialogContent
        sx={{ backgroundColor: colors.primary[400], display: "flex" }}
      >
        <Box sx={{ display: "flex" }}>
          <CircularProgress color="secondary" />
        </Box>
      </DialogContent>
        )
      }else if  ( (props.show === true && connectionPointQuery.isFetching !== true) 
      // ||
      // (props.show === true && materialSourcesQuery.isFetching !== true) ||
      // (props.show === true && dryingContainerQuery.isFetching !== true) ||
      // (props.show === true && rawMaterialQuery.isFetching !== true) ||
      // (props.show === true && withdrawalQuery.isFetching !== true) 
      )
     {    
      return(
      <DialogContent>
        <Box sx={{ width: "100%", background: colors.primary[400] }}>
          <Box sx={{ my: 3, mx: 2, background: colors.primary[400] }}>
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography gutterBottom variant="h2" component="div">
                  MATERIAL CARD 
                  {/* <DialogTitle sx={{backgroundColor: colors.primary[400]}}> <Typography variant="h2"> MATERIAL CARD {data.machineName} </Typography></DialogTitle> */}
                </Typography>
              </Grid>
              <Grid item>
                <Typography gutterBottom variant="h2" component="div">
                  {props.rowData.machineName}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <DialogContent sx={{ backgroundColor: colors.primary[400] }}>
                <DialogContentText sx={{ color: colors.gray[100] }}>
                  {/* {props.rowData.machineName} {props.rowData.areaName} {props.rowData.connectionPointName} */}

                  <Grid container spacing={2}>
                    <Grid item xs={6} md={8}>
                      <Item>
                        <Stack direction="row">
                          <Typography>Production Order info</Typography>
                        </Stack>
                        <Divider  />
                        <Stack direction="row">
                          <TextField
                            label="Machine"
                            id="filled-size-small"
                            //variant='standard'
                            defaultValue={props.rowData.machineName}
                            size="small"
                            width="50px"
                            variant="filled"
                            disabled={true}
                          ></TextField>
                          <TextField
                            label="Active Item"
                            id="filled-size-small"
                            //variant='standard'
                            defaultValue={props.rowData.activeItem}
                            size="small"
                            width="50px"
                            variant="filled"
                            disabled={true}
                          ></TextField>

                          <TextField
                            label="Area"
                            id="filled-size-small"
                            //variant='standard'
                            defaultValue={props.rowData.areaName}
                            size="small"
                            variant="filled"
                            disabled={true}
                          ></TextField>

                          <FormControl
                            sx={{ height: 5, minWidth: 120, mt: 2 }}
                            size="small"
                          >
                            <InputLabel id="demo-select-small" sx={{ mt: -1 }}>
                              Connection Point
                            </InputLabel>
                            <Select
                              value={connectionPointId}
                              onChange={(event) =>
                                handleConnectionPointSelect(event)
                              }
                              label="Connection Point"
                              sx={{ backgroundColor: backgroundColor }}
                            >
                              {connectionPointQuery.data.map(
                                (connectionPoints) => (
                                  <MenuItem
                                    name="TEST"
                                    key={connectionPoints.id}
                                    value={connectionPoints.id}
                                    sx={{
                                      backgroundColor: connectionPoints.color,
                                    }}
                                  >
                                    {" "}
                                    {connectionPoints.connectionPointName}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </Stack>
                      </Item>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <Item sx={{   '& .MuiSelect-select': {
                                  border: "none",
                                  '&:focus': {
                                    borderRadius: 4,
                                    borderColor: '#80bdff',
                                    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
                                  },
                                  
                                },}}>
                        <Stack direction="row">
                          <FormControl >
                          {/* <CommentInput value= {commentState} onChange={event => setCommentState(event.target.value)} /> */}
                            
                             <TextField
                              id="commentTextField"
                              label="Comment"
                              //key={1}
                              //multiline
                              //rows={2}
                              defaultValue={commentState}
                              onChange={handleComment}
                            
                            /> 
                          </FormControl>
                        </Stack>
                      </Item>
                    </Grid>
                    <Grid item xs={6} md={12}>
                      <Item>
                        <Stack direction="row">
                          <Typography>Material Settings</Typography>
                        </Stack>
                        <Divider />
                        <Stack direction="row">
                          <FormControl
                            sx={{ minWidth: 100, m: 2, mt: 3 }}
                            size="small"
                          >
                            <InputLabel id="demo-select-small" sx={{ m: -1 }}>
                              Material Source
                            </InputLabel>
                            <Select
                              value={materialSourceState}
                              onChange={handleMaterialSource}
                              sx={{ backgroundColor: colors.primary[300],
                              
                              
                              }}
                              input={<BootstrapInput />}

                            >
                              {materialSourcesQuery.data.map(
                                (materialSource) => (
                                  <MenuItem
                                  key={materialSource.id}
                                    value={materialSource.id}
                                    sx={{
                                      backgroundColor: colors.primary[200],
                                    }}
                                  >
                                    {/* {" "} */}
                                    {materialSource.name}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>

                          <FormControl
                            sx={{ minWidth: 250, mt: 3 }}
                            size="small"
                          >
                            <InputLabel id="demo-select-small" sx={{ m: -1 }}>
                              Material
                            </InputLabel>
                            {rawMaterialQuery.isFetched ? (
                            
                            <Select
                              value={rawMaterialState}
                              onChange={handleRawMaterial}
                              sx={{ backgroundColor: colors.primary[300] }}
                              input={<BootstrapInput />}
                            >
                              {rawMaterialQuery.data.map((rawMaterial) => (
                                <MenuItem
                                  key={rawMaterial.itemKey}
                                  value={rawMaterial.itemName}
                                >
                                 
                                  {rawMaterial.itemName}
                                </MenuItem>
                              ))}
                            </Select>
                            ) : (<Select></Select>)
                              }
                            
                          </FormControl>

                          <FormControl
                            sx={{ minWidth: 250, mt: 3, ml: 2 }}
                            size="small"
                          >
                            <InputLabel id="demo-select-small" sx={{ m: -1 }}>
                              Drying Container
                            </InputLabel>
                            <Select
                              //textAlign="left"
                              value={dryingContainerState}
                              onChange={handleDryingContainer}
                              sx={{ backgroundColor: colors.primary[300] }}
                              input={<BootstrapInput />}
                            >
                              {dryingContainerQuery.data.map(
                                (dryingContainer) => (
                                  <MenuItem
                                    key={dryingContainer.id}
                                    value={dryingContainer.id}
                                  >
                                    {" "}
                                    {dryingContainer.name}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                          <FormControl
                            sx={{ minWidth: 10, mt: 1, ml: -1 }}
                            size="small"
                          >
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={true}
                                    name="isdrying"
                                    color="success"
                                  />
                                }
                                label={
                                  <Typography sx={{ fontSize: 11 }}>
                                    Is drying?
                                  </Typography>
                                }
                                fontSize={50}
                                sx={{ fontSize: 20 }}
                                labelPlacement="top"
                              />
                            </FormGroup>
                          </FormControl>
                        </Stack>
                      </Item>
                    </Grid>
                  </Grid>

                  <Divider sx={{ m: 2 }} />
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={12}>
                      <Item>
                        Drying Container Status
                        <Stack>
        
                        </Stack>
                        <Stack direction="row">
                        <TextField
                            label="Dryer"
                            id="filled-size-small"
                            //variant='standard'
                            defaultValue={dryingConainerName} 
                            size="small"
                            width="50px"
                            variant="filled"
                            disabled={true}
                          ></TextField>
                            <TextField
                            label="status"
                            id="filled-size-small"
                            //variant='standard'
                            defaultValue={dryingContainerStatusColorState[0] ==="True"? "Running": "Stopped"}
                            size="small"
                            sx={{backgroundColor:
                              dryingContainerStatusColorState[0] === "True"
                               ? colors.dashbordGreen[600]
                               : colors.redAccent[500],
                              
                              }}
                            width="50px"
                            variant="filled"
                            disabled={true}
                          ></TextField>
          
                        
                        <TextField
                            label={"Demand "+ props.rowData.machineName}
                            id="filled-size-small"
                            //variant='standard'
                            defaultValue={withdrawalQuery.data.filter(e => e.id === props.rowData.machineId).map((e) => (JSON.stringify(e.kgHCurrentStatus))) + " kg/h"} 
                            size="small"
                            width="50px"
                            variant="filled"
                            disabled={true}
                          ></TextField>
                            <TextField
                            label="Max Withdrawal"
                            id="filled-size-small"
                            //variant='standard'
                            defaultValue={dryingContainerQuery.data.filter(e => e.id === dryingContainerId).map((e) => (JSON.stringify(e.maximumWithdrawal))) + " kg/h"} 
                            size="small"
                            width="50px"
                            variant="filled"
                            disabled={true}
                          ></TextField>
                            <TextField
                            label="Total Withdrawal"
                            id="filled-size-small"
                            //variant='standard'
                            defaultValue={parseFloat(parseFloat(dryingContainerQuery.data.filter(e => e.id === dryingContainerId).map((e) => (JSON.stringify(e.demandMachinesCurrentStatus)))) + parseFloat(withdrawalQuery.data.filter(e => e.id === props.rowData.machineId).map((e) => (JSON.stringify(e.kgHCurrentStatus))))).toFixed(2) + " kg/h"} 
                            size="small"
                            width="50px"
                            variant="filled"
                            disabled={true}
                          ></TextField>
                           <TextField
                            label="Match"
                            id="filled-size-small"
                            //variant='standard'
                            defaultValue=       {
                              parseFloat(dryingContainerQuery.data.filter(e => e.id === dryingContainerId).map((e) => (JSON.stringify(e.demandMachinesCurrentStatus)))) >
                              parseFloat(dryingContainerQuery.data.filter(e => e.id === dryingContainerId).map((e) => (JSON.stringify(e.maximumWithdrawal))))
                              ? "NO":"YES"
                            }
                            sx={{backgroundColor: 
                            
                              parseFloat(dryingContainerQuery.data.filter(e => e.id === dryingContainerId).map((e) => (JSON.stringify(e.demandMachinesCurrentStatus))))  + parseFloat(withdrawalQuery.data.filter(e => e.id === props.rowData.machineId).map((e) => (JSON.stringify(e.kgHCurrentStatus)))) >
                                parseFloat(dryingContainerQuery.data.filter(e => e.id === dryingContainerId).map((e) => (JSON.stringify(e.maximumWithdrawal))))
                                ? colors.redAccent[500]:colors.dashbordGreen[500]
                            }}
                            size="small"
                            width="50px"
                            variant="filled"
                            disabled={true}
                          />
                        </Stack>
                        <Typography>
                        
                         
          
                      


                       
                        </Typography>
                      </Item>
                    </Grid>
                  </Grid>
                </DialogContentText>
              </DialogContent>
            </Box>
          </Box>
          <DialogActions sx={{ backgroundColor: colors.primary[400] }}>
            <Button
              sx={{
                backgroundColor: colors.primary[500],
                color: colors.gray[100],
              }}
              onClick={props.callback}
            >
              Cancel
            </Button>
            <Button
              disabled={saveButtonState}
              sx={{
                backgroundColor: colors.primary[500],
                color: colors.gray[100],
              }}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </DialogActions>
        </Box>
        </DialogContent>
        )
        
      }
    })()
  }
  
    
      </Dialog>
    );
  
}
