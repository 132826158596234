import React, { useState, useEffect } from "react";
import { Box, TextField, useTheme } from '@mui/material';
import { tokens } from "../../../assets/theme";
import Stack from '@mui/material/Stack'
import dayjs from "dayjs";
import 'dayjs/locale/sv';
import 'dayjs/locale/en';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';





//const GridDatePicker = ({ locales, label, maxDate , minDate, onChange, date }) => {
const GridDateTimePicker = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [locale, setLocale] = useState(props.locales); //"sv"
  //const [datePickerValue, setDatePickerValue] = React.useState(dayjs());


  function onChangeHandler(date) {
    console.log("ResultingDateTime:" + formatDate(date) + formatFullTime(date));
    props.onChange(date);
  }

  function formatDate(date) {
    return date ? new Date(date).toLocaleDateString('sv-SE') : "";
  }
  function formatFullTime(date) {
    return date ? new Date(date).toLocaleTimeString('sv-SE', { hour: '2-digit', minute: '2-digit', second: '2-digit' }) : "";
  }

  return (
    <Box sx={{
      "& .MuiInputBase-input": {
        //backgroundColor: "#fff",
        color: "secondary",
        height: "0.4375em",
        marginTo: "5px",

      },
      "& .MuiTextField-root": {
        marginTop: "10px",
        marginBottom: "10px",
      },
      "& .MuiPaper-root": {
        backgroundColor: "#fff"
      },
    }}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sv">
        <DatePicker
          label={props.label}
          value={dayjs(props.date)}
          //onChange={(newValue) => setDatePickerValue(newValue)}
          renderInput={(params) => <TextField {...params} />}
          maxDate={dayjs(props.maxDate)}
          minDate={dayjs(props.minDate)}
          onChange={onChangeHandler}
        //sx= {{backgroundColor: "#FFF"}}

        />
        <TimePicker
          renderInput={(params) => <TextField {...params} />}
          label={props.labelTime}
          value={dayjs(props.date)}
          onChange={onChangeHandler}
        />

      </LocalizationProvider>
    </Box>

  )

}
export default GridDateTimePicker;