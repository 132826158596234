import React, { useState, useEffect } from 'react';

const CountdownTimer = (props) => {
  const initialTime = (new Date(props.maxClosedDate) - Date.now());
  const [timeRemaining, setTimeRemaining] = useState();

  useEffect(() => {
    setTimeRemaining(initialTime / 1000);
    const timerInterval = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime < 1) {
          clearInterval(timerInterval);
          // Perform actions when the timer reaches zero
          console.log('Countdown complete!');
          return 0;
        } else {
          return prevTime - 1;
        }
      });
    }, 1000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(timerInterval);
  }, [props]); // Runs if the props change

  // Convert seconds to hours, minutes, and seconds
  const hours = Math.floor(timeRemaining / 3600);
  const minutes = Math.floor((timeRemaining % 3600) / 60);
  const seconds = Math.floor(timeRemaining % 60);

  return (
      <>{`${hours}h ${minutes}m ${seconds}s`}</>
  );
};

export default CountdownTimer;