import axios from "axios";

export const fetchInstruction = async (instructionId) => {
  try {
    const response = await axios.get(`/api/instructions/${instructionId}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
export const fetchActiveItems = async () => {
  const response = await axios.get(`/api/instructions/getActiveItems`);
  console.log(response.data);
  return response.data;
};

export const fetchAllInstructions = async (companyKey) => {
  try {
    const response = await axios.get(`/api/instructions/?companyKey=${companyKey}`);
    //console.log(response.data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const updateInstruction = async (instruction, id) => {
  try {
  const response = await axios.put(`/api/instructions/${id}`, instruction);
  return response.data;
  } catch (error) {
    throw error.response.data;
  }
  
};
//API CALL to Create a new instruction
export const createInstruction = async (instruction) => {
  //Map instruction to backend viewmodel

  //HttpPost to backend
  try {
  const response = await axios.post("/api/instructions", instruction);

  return response.data;
  }catch (error) {
    throw error.response.data;
  }
};
