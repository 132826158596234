import React, { useRef } from 'react';
import { FormControl, Autocomplete, FormHelperText, TextField, Box, Typography } from "@mui/material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { styled } from '@mui/material/styles';
import Popper from '@mui/material/Popper';

  export default function AxionAutocomplete(props) {
    const { name, label, sx, value, error = null, onChange, inputValue, onInputChange, options, showNoneOption = true, disabled =false, multiple=false, defaultValue } = props;
   
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const StyledPopper = styled(Popper)({
      '& .MuiAutocomplete-listbox': {
        backgroundColor: '#fff', 
        color: '#000',
      },
      '& .MuiAutocomplete-option': {
        backgroundColor: '#fff', 
        color: '#000',
      },
      '& .MuiAutocomplete-option:hover': {
        backgroundColor: '#ccc',
      },
      '& .MuiAutocomplete-option.Mui-selected': {
        backgroundColor: '#eee',
          '&:hover': {
            backgroundColor: '#ccc',
          },
      },
      '& .MuiAutocomplete-option.Mui-selected.Mui-focused': {
        backgroundColor: '#eee'
      },
      '& .MuiAutocomplete-noOptions': {
          backgroundColor: '#fff',  
          color: '#799c4a',
          fontStyle: 'italic',
          textAlign: 'center',
          padding: '10px',
        },
    });

    return (
      <FormControl fullWidth variant="outlined" {...(error && { error: true })}>
      <Autocomplete
          id={name} 
          name={name} 
          disabled={disabled}
          multiple={multiple}
          disableCloseOnSelect={multiple}
          options={options}
          PopperComponent={StyledPopper}
          defaultValue={defaultValue}
          inputValue={inputValue}
          onInputChange={onInputChange}
          onChange={onChange}
          getOptionLabel={(option) => option.title}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => <TextField {...params} label={label} />}
          sx={{ 
            '& .MuiOutlinedInput-root': {
              overflow: 'hidden',
              backgroundColor: '#fff',
              border: '2px solid',
              borderColor: '#799c4a',
              color:"#000"
            },
            '& .MuiInputLabel-root': {
              color: "#799c4a"
            },
            '& .MuiAutocomplete-popupIndicator': {
              color: "#000"
            },
            '& .MuiAutocomplete-clearIndicator': {
              color: "#000"
            },
            '& .MuiChip-root': {
              backgroundColor: '#799c4a',
              color: '#fff'
            }
            ,...sx
           }}
        >
        </Autocomplete>
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
    
  }
  