import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    // Axjo
    clientId: "3235c665-b573-4b66-a0d0-7074ce78c50d",
    authority: "https://login.microsoftonline.com/524fb73d-cb2a-45ef-81e0-873b9a162c50", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: "https://oee.axjo.com",

    // Stoeryd
    // redirectUri: "https://oee.stoeryd.se/grid",

    //DEV
    //redirectUri: "https://localhost:44427/",// process.env.REACT_APP_REDIRECT_URI
    //redirectUri: "https://axion-oee-ci-1.azurewebsites.net/",// process.env.REACT_APP_REDIRECT_URI
    //clientId: "23090b8e-3ae6-4a52-b149-ba5dea030a75",// process.env.REACT_APP_CLIENT_ID,
    //authority: "https://login.microsoftonline.com/87b6c466-579e-41fc-8490-7c5aacd50d33", // process.env.REACT_APP_AUTHORITY,
  },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {	
      loggerOptions: {	
          loggerCallback: (level, message, containsPii) => {	
              if (containsPii) {		
                  return;		
              }		
              switch (level) {
                  case LogLevel.Error:
                    //   console.error(message);
                      return;
                  case LogLevel.Info:
                    //   console.info(message);
                      return;
                  case LogLevel.Verbose:
                    //   console.debug(message);
                      return;
                  case LogLevel.Warning:
                    //   console.warn(message);
                      return;
                  default:
                      return;
              }	
          }	
      }	
  }
  };
  
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
    // Dev
    //scopes: ["api://23090b8e-3ae6-4a52-b149-ba5dea030a75/User.Read"]

    // Axjo
    scopes: ["api://3235c665-b573-4b66-a0d0-7074ce78c50d/User.Read"]
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
      graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
  };