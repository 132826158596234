import React from "react";
import { fetchVersion } from "../../fetchers/version";
import { useQuery } from "@tanstack/react-query";
import { Typography, useTheme } from "@mui/material";
import { tokens } from "../../assets/theme";;

export default function VersionNumber() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { data, isSuccess } = useQuery(
        ["version"],
        () => fetchVersion()
    );

    return (
        <>
            { isSuccess && data && 
                <Typography
                    variant="h6"
                    color={colors.gray[200]}
                    sx={{ m: "0px 0px 10px 0px", flex: 1 }}
                >
                    { `Version: ${data.version}` }
                </Typography>
            }
        </>
    );
}