import React from "react";
import Box from "@mui/material/Box";
import { useLocalStorage } from "../../../hooks/useLocalStorage";

export default function SplitMachineStopRow(props) {

    const [companyKey, setCompanyKey] = useLocalStorage("companyKey", 1);
    
    function formatDate(date) {
        return date ? new Date(date).toLocaleDateString('sv-SE') : "";
    }

    function formatFullTime(date) {
        return date ? new Date(date).toLocaleTimeString('sv-SE', {hour: '2-digit', minute:'2-digit', second:'2-digit'}) : "";
    }

    function formatDateLocalized(date) {
        let locale = companyKey === 2 ? 'en-US' : 'sv-SE';
        return date ? new Date(date).toLocaleDateString(locale) : "";
    }

    function formatTimeLocalized(date) {
        let locale = companyKey === 2 ? 'en-US' : 'sv-SE';
        //return date ? new Date(date).toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit' }) : "";
        return date ? new Date(date).toLocaleTimeString(locale, {hour: '2-digit', minute:'2-digit', second:'2-digit'}) : "";
    }

    let i = 1;
    return (
        props.createdStops.map((stop) => {
            i++;
            return <React.Fragment key={`${stop.id}_${i}`}>
                <Box sx={{ gridRow: {i}, gridColumn: '1' }}>
                    {stop.machineName}
                </Box>
                <Box sx={{ gridRow: {i}, gridColumn: '2' }}>
                    {formatDateLocalized(stop.fromDate)}
                </Box>
                <Box sx={{ gridRow: {i}, gridColumn: '3' }}>
                    {formatTimeLocalized(stop.fromDate)}
                </Box>
                <Box sx={{ gridRow: {i}, gridColumn: '4' }}>
                    {formatDateLocalized(stop.toDate)}
                </Box>
                <Box sx={{ gridRow: {i}, gridColumn: '5' }}>
                    {formatTimeLocalized(stop.toDate)}
                </Box>
                <Box sx={{ gridRow: {i}, gridColumn: 'span 2' }}>
                        {props.stopReasonCodes.find(sr => sr.id === stop.stopReasonCodeId)?.name}
                </Box>
                <Box sx={{ gridRow: {i}, gridColumn: 'span 2' }}>
                    {stop.comment}
                </Box>
                <Box sx={{ gridRow: {i}, gridColumn: '10' }}>
                    {stop.type}
                </Box>
            </React.Fragment>
        })
    );
}