import {
  Box,
  useTheme,
  TextField,
  Grid,
} from "@mui/material";
import React from "react";
import { tokens } from "../../../assets/theme";
import AxionTextBoxReadOnly from "../../../components/controls/AxionTextBoxReadOnly"
import { FormattedMessage } from "react-intl";
import messages from "./messages";

const InstructionHeader = ({ title, item, createdBy, created }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box ml="14px" mr="25px">
      <Grid container spacing={2}>
       <Grid item xs={4}>
      <AxionTextBoxReadOnly
        //color="success"
        //variant="filled"
        sx={{ m: 1, flexGrow: 1 }}
        label={<FormattedMessage {...messages.title} />}
        value={title}
      />
      </Grid> 
      <Grid item xs={4}>
      <AxionTextBoxReadOnly
        label={<FormattedMessage {...messages.item} />}
        variant="filled"
        sx={{ m: 1, flexGrow: 1 }}
        value={item}
      />
      </Grid>
      <Grid item xs={4}>
      <AxionTextBoxReadOnly
        label={<FormattedMessage {...messages.createdBy} />}
        sx={{ m: 1, flexGrow: 1 }}
        variant="filled"
        
        value={createdBy}
      />
     </Grid>
      </Grid>
    </Box>
  );
};
export default InstructionHeader;
