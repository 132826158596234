import axios from "axios";
import dayjs from "dayjs";  

export const fetchMaterialConnections = async (companyKey) => {
    console.log("fetching material connections");
    const response = await axios.get("api/materialconnectionsDev/"+companyKey);
    const materialConnections = response.data;
    //console.log("Material Connections Data 2", materialConnections);
    return materialConnections;
}


export const fetchMaterialConnectionByMachineId = async (companyKey, machineId) => {
    console.log("fetching Material connection By MachineId");
    const response = await axios.get("api/getMaterialConnectionByMachineId/"+companyKey +"/" + machineId);
    const materialConnections = response.data;
    //console.log("Get Material Connetion By Machine", materialConnections);
    return materialConnections;
}

export const fetchConnectioinPoints = async(companyKey) => {
    console.log("fetching connectionpoints......");
    const response =  await axios.get("api/getConnectionPoints/"+companyKey);
    const connectionPoints = response.data;
    //console.log("fetched connectionpoints: " + JSON.stringify(connectionPoints));
    return connectionPoints;
}

export const fetchMaterialSources = async(companyKey) => {
    console.log("fetching material sources......");
    const response =  await axios.get("api/getMaterialSources/"+companyKey);
    const materialSources = response.data;
    //console.log("fetched material sources: " + JSON.stringify(materialSources));
    return materialSources;
}

export const fetchDryingContainers = async(companyKey) => {
    console.log("fetching drying containers......");
    const response =  await axios.get("api/getDryingContainers/"+companyKey);
    const dryingContainers = response.data;
    //console.log("fetched drying containers: " + JSON.stringify(dryingContainers));
    return dryingContainers;
}

export const fetchRawMaterials = async(companyKey) => {
    console.log("fetching Raw Materials......");
    const response =  await axios.get("api/getRawMaterials/"+companyKey);
    const rawMaterials = response.data;
    //console.log("fetched Raw Materials: " + JSON.stringify(rawMaterials));
    return rawMaterials;
}

export const fetchMachineWithdrawal = async() => {
    console.log("fetching drying machine withdrawal......");
    const response =  await axios.get("api/getMachineWithdrawal");
    const withdrawal = response.data;
    //console.log("fetched drying withdrawal: " + JSON.stringify(withdrawal));
    return withdrawal;
}

export const updateMachineMaterialConnection = async (newConnection) => {
    return await axios.post(`api/updateMachineMaterialConnection`,newConnection);
}



