import React, { useState, useEffect, useMemo } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import Dropzone  from "react-dropzone";
import { Button, Grid, Typography, useTheme, Box, Chip, IconButton, Icon } from "@mui/material";
import lang from "../lang/lang";
import { FormattedMessage, useIntl } from "react-intl";
import { useMsal, useAccount } from "@azure/msal-react";
import { tokens } from "../../../assets/theme";
import AxionControls from "../../../components/controls/AxionControls";
import Divider from "@mui/material/Divider";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { pushErrorMessage, pushNotification } from "../../../utils/notifications";
import { updateInstruction, createInstruction } from "../hooks/fetchers";
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
//Function to map data to backend
const formData = (instruction) => {
    const formData = new FormData();

    formData.append("title", instruction.title);
    formData.append("item", instruction.item);
    formData.append("itemName", instruction.itemName);
    formData.append("createdBy", instruction.createdBy);
    formData.append("type", "Activity");
    formData.append("updatedBy", instruction.createdBy);
    formData.append("companyKey", instruction.companyKey);

    for (let i = 0; i < instruction.steps.length; i++) {
        formData.append(
            `steps[${i}].instructionText`,
            instruction.steps[i].instructionText
        );
        formData.append(`steps[${i}].stepNumber`, instruction.steps[i].stepNumber);

        for (let j = 0; j < instruction.steps[i].mediaFiles.length; j++) {
            formData.append(
                `steps[${i}].mediaFiles[${j}].fileName`,
                instruction.steps[i].mediaFiles[j].fileName
            );
            formData.append(
                `steps[${i}].mediaFiles[${j}].fileType`,
                instruction.steps[i].mediaFiles[j].fileType
            );
            formData.append(
                `steps[${i}].mediaFiles[${j}].filePath`,
                instruction.steps[i].mediaFiles[j].filePath
            );
            formData.append(
                `steps[${i}].mediaFiles[${j}].fileContent`,
                instruction.steps[i].mediaFiles[j].fileContent
            );
        }
    }
    return formData;
};

const formDataUpdate = (instruction) => {
    const formData = new FormData();

    formData.append("title", instruction.title);
    formData.append("item", instruction.item);
    formData.append("itemName", instruction.itemName);
    formData.append("created", instruction.created);
    formData.append("createdBy", instruction.createdBy);
    formData.append("type", "Activity");
    formData.append("updatedBy", instruction.updatedBy);
    formData.append("companyKey", instruction.companyKey);

    for (let i = 0; i < instruction.steps.length; i++) {
        formData.append(`steps[${i}].instructionText`, instruction.steps[i].instructionText);
        if (instruction.steps[i].id !== undefined) 
        {
            formData.append(`steps[${i}].id`, instruction.steps[i].id);
        }
        formData.append(`steps[${i}].stepNumber`, instruction.steps[i].stepNumber);

        for (let j = 0; j < instruction.steps[i].mediaFiles.length; j++) {
            formData.append(
                `steps[${i}].mediaFiles[${j}].fileName`,
                instruction.steps[i].mediaFiles[j].fileName
            );
            formData.append(
                `steps[${i}].mediaFiles[${j}].fileType`,
                instruction.steps[i].mediaFiles[j].fileType
            );
            formData.append(
                `steps[${i}].mediaFiles[${j}].filePath`,
                instruction.steps[i].mediaFiles[j].filePath
            );
            formData.append(
                `steps[${i}].mediaFiles[${j}].fileContent`,
                instruction.steps[i].mediaFiles[j].fileContent
            );
        }
    }
    return formData;
};

const InstructionForm = ({ mode, instructionToUpdate, companyKey, setOpenPopup, data, setRecordForEdit }) => {

    //Accounts for AAD
    const { formatMessage } = useIntl();
    const { accounts } = useMsal();
    const [showBackdrop, setShowBackdrop] = useState(false);
    //Theme
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    //Select current AAD account
    const account = useAccount(accounts[0]);
    const [companyKeys, setCompanyKeys] = useState(companyKey);
    //Hardcoded instructiontypes MUST mactch backend Enums.
    const [instructionTypes, setInstructionTypes] = useState([
        {
            id: "1",
            title: "Activity",
        },
        {
            id: "2",
            title: "Setup",
        },
    ]);


    console.log("InstructionToUpdate", instructionToUpdate);
    // API call to update an existing instruction

    //instruction state
    const [instruction, setInstruction] = useState({
        title: "",
        type: "Activity",
        createdBy: account.username,
        item: null,
        itemName: null,
        machineId: null,
        userId: null,
        roleId: null,
        companyKey: companyKey,
        steps: [
            {
                instructionText: "",
                stepNumber: 1,
                mediaFiles: [],
            },
        ],
    });
    const [selectedFileThumbnails, setSelectedFileThumbnails] = useState(
        Array.from({ length: instruction.steps.length }, () => "")
    );

    //useMemo with dependencies to mode and instructionToUpdate, if Update load the injected instruction.
    useMemo(() => {
        if (mode === "update" && instructionToUpdate) {
            setInstruction({ ...instructionToUpdate });
        }
    }, [mode, instructionToUpdate]);

    const onDropCallback = (index) => (acceptedFiles) => {
        const file = acceptedFiles[0]; // only one file per step right now

        //console.log("onDropCallback", index, file);

        setInstruction((prevInstruction) => {
            const newSteps = [...prevInstruction.steps];

            if (newSteps[index] === undefined) {
                // If the index is out of bounds, expand the array
                newSteps[index] = {
                    instructionText: "",
                    stepNumber: 1,
                    mediaFiles: [],
                };
            }

            const newMediaFiles = [
                {
                    fileName: file.name,
                    fileType: file.type,
                    filePath: file.name,
                    fileContent: file,
                    thumbnail: URL.createObjectURL(file), // Generate thumbnail as a data URL
                },
            ];

            newSteps[index] = {
                ...newSteps[index],
                mediaFiles: newMediaFiles,
            };

            return { ...prevInstruction, steps: newSteps };
        });

        // Update the selected file thumbnail for the current step
        setSelectedFileThumbnails((prevThumbnails) => {
            const newThumbnails = [...prevThumbnails];

            if (newThumbnails[index] === undefined) {
                // If the index is out of bounds, expand the array
                newThumbnails[index] = "";
            }

            newThumbnails[index] = URL.createObjectURL(file);

            return newThumbnails;
        });
    };

    // const { getRootProps, getInputProps } = useDropzone({
    //     accept: "image/*, video/*",
    //     onDrop: onDropCallback(instruction.steps.length - 1), // Use the callback to capture the index in onDrop
    // });

    const addStep = () => {
        setInstruction((prevInstruction) => ({
            ...prevInstruction,
            steps: [
                ...prevInstruction.steps,
                {
                    instructionText: "",
                    stepNumber: prevInstruction.steps.reduce((prev, curr) => prev.stepNumber > curr.stepNumber ? prev : curr).stepNumber + 1,
                    mediaFiles: [],
                },
            ],
        }));

        // Add a corresponding empty string for the new step's thumbnail
        setSelectedFileThumbnails((prevThumbnails) => [...prevThumbnails, ""]);
    };

    function validateInstruction(instruction) {
        let isValid = true;
        if (instruction.title === "") {
            pushNotification(<FormattedMessage {...lang.validationTitle} />); //"Title is required."
            isValid = false;
        }
        if (instruction.steps.length > 0) {
            instruction.steps.map((step) => {
                if (step.instructionText === "") {
                    pushNotification(<><FormattedMessage {...lang.validationText1} />{step.stepNumber}<FormattedMessage {...lang.validationText2} /></>); //`Instruction text for step ${step.stepNumber} is required.`
                    isValid = false;
                }
            });
        }
        return isValid;
    }

    const handleSubmit = () => {

        if (validateInstruction(instruction)) {

            instruction.steps.forEach((step) => {
                step.stepNumber = instruction.steps.indexOf(step) + 1;
            });

            setShowBackdrop(true);
            if (mode === "create") {
                //console.log("CREATE INSTRUCTION!!");
                createInstruction(formData(instruction)).then(() => {
                    setOpenPopup(false);
                    data.refetch();
                    setShowBackdrop(false);
                }).catch((error) => {
                    console.log(error);
                    setShowBackdrop(false);
                    pushErrorMessage(`Could not create the instruction.`);
                })
            } else if (mode === "update") {
                //console.log("Submit instruction, update");
                instruction.type = 1;
                instruction.updatedBy = account.username;


                console.log("Update instruction", instruction);

                updateInstruction(formDataUpdate(instruction), instruction.id).then(() => {
                    setOpenPopup(false);
                    data.refetch();
                    setShowBackdrop(false);
                    setRecordForEdit(0);

                }).catch((error) => {
                    console.log(error);
                    setShowBackdrop(false);
                    pushErrorMessage(`Cannot update the instruction.`);
                });

            }
        }
    };

    const handleDeleteStep = (stepIndex) => {
        setInstruction((prevInstructionDto) => {
            var newSteps = [...prevInstructionDto.steps];

            newSteps.splice(stepIndex, 1);

            console.log ("Deletestep:", {...prevInstructionDto, steps: newSteps})

            return {
                ...prevInstructionDto,
                steps: newSteps,
            };
        });
    };

    return (
        <Box
            m={2}
            sx={{ backgroundColor: colors.primary[400], borderRadius: "4px" }}
        >
            <form style={{ margin: "8px" }}>

                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <AxionControls.AxionTextBox
                            sx={{}}
                            value={instruction.title}
                            label={<FormattedMessage {...lang.fieldTitle} />}
                            name="Tile-Textbox"
                            onChange={(e) =>
                                setInstruction({ ...instruction, title: e.target.value })
                            }
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <AxionControls.AxionTextBoxReadOnly
                            value={instruction.createdBy}
                            label={<FormattedMessage {...lang.fieldCreatedBy} />}
                            name="created-by-disabled-textbox"
                        />
                    </Grid>
                    {/* {instruction.type === "Activity" && (
            <Grid item xs={12}>
              <TextField
                label="MacineId"
                fullWidth
                value={instruction.item}
                onChange={(e) =>
                  setInstruction({ ...instruction, machineId: e.target.value })
                }
              />
            </Grid>
          )} */}

                    <Grid item xs={12}>
                        <Box
                            p={2}
                            sx={{ backgroundColor: colors.primary[700], borderRadius: "4px" }}
                        >
                            <Typography>
                                <InfoOutlinedIcon /> <FormattedMessage {...lang.createStepsBelow} />
                            </Typography>
                        </Box>
                    </Grid>

                    {instruction.steps.map((step, index) => (
                        <Grid container spacing={2} key={index}>
                            <Grid item xs={4} paddingTop="10px">

                                <Dropzone onDrop={onDropCallback(index)}
                                    name="heroImage" multiple={false}>
                                    {({ getRootProps, getInputProps }) => (
                                        <div
                                            {...getRootProps({ st: step })}
                                            style={{
                                                border: "1px dashed #ccc",
                                                borderColor: "#799c4a",
                                                padding: "5px",
                                                marginTop: "10px",
                                                marginLeft: "20px",
                                                height: "97%",
                                                justifyContent: "center",
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <input {...getInputProps()} />
                                            <Typography
                                                display="flex"
                                                sx={{ textAlign: "center", top: "auto"}}
                                                justifyContent={"center"}
                                            >

                                                {selectedFileThumbnails[index] ? (
                                                    <img
                                                        src={selectedFileThumbnails[index]}
                                                        alt={`Thumbnail for Step ${index + 1}`}
                                                        style={{ maxWidth: "100%", maxHeight: "100%" }}

                                                    />
                                                ) : step.mediaFiles[0]?.filePath ?
                                                    <img
                                                        src={step.mediaFiles[0]?.filePath}
                                                        alt=""
                                                        style={{ maxWidth: "100%", maxHeight: "281px" }}
                                                    /> :
                                                    (
                                                        <FormattedMessage {...lang.fieldDragAndDrop} />
                                                    )}
                                            </Typography>
                                        </div>

                                    )}
                                </Dropzone>
                                {(step.mediaFiles[0]?.filePath.startsWith('http')) && <Box marginLeft="230px">
                                    <Tooltip title={<Typography fontSize="12px">Fullsize image</Typography>}>
                                        <IconButton href={"/api/job/activities/image?imageurl=" + encodeURIComponent(step.mediaFiles[0]?.filePath) + "&type=" + step.mediaFiles[0]?.fileType} target="_blank" rel="noopener noreferrer"><RemoveRedEyeOutlinedIcon/></IconButton>
                                    </Tooltip>
                                </Box>}
                            </Grid>

                            {/* Pass the index to the onDropCallback */}
                            {/* <Button onClick={() => onDropCallback(index)}>
                            Add File
                            </Button> */}
                            <Grid item xs={8}>
      
                                <AxionControls.AxionMultiLineText
                                    label={`${formatMessage(lang.textStep)} ${index + 1}`}
                                    rows={15}
                                    value={step?.instructionText}
                                    onChange={(e) => {
                                        const newSteps = [...instruction.steps];
                                        newSteps[index] = {
                                            ...newSteps[index],
                                            instructionText: e.target.value,
                                        };
                                        setInstruction((prevInstruction) => ({
                                            ...prevInstruction,
                                            steps: newSteps,
                                        }));
                                    }}
                                />
                            </Grid>
                            {index !== 0 ? (
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        type="button"
                                        sx={{
                                            color: "white",
                                            backgroundColor: colors.redAccent[600],
                                            marginLeft: "20px",
                                        }}
                                        onClick={() => handleDeleteStep(index)}
                                    >
                                        <FormattedMessage {...lang.buttonDeleteStep} />
                                    </Button>{" "}
                                </Grid>
                            ) : null}
                            <Grid item xs={12}>
                                <Divider sx={{ marginLeft: "20px" }}>
                                    <Chip label={`${formatMessage(lang.textEndOfStep)} ${index + 1}`} />
                                </Divider>
                            </Grid>
                        </Grid>
                    ))}

                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "#799c4a",
                                "&:hover": {
                                    backgroundColor: "#5e7939",
                                    borderColor: "#5e7939",
                                    boxShadow: "none",
                                },
                                marginRight: "10px",
                            }}
                            onClick={addStep}
                        >
                            <AddOutlinedIcon /> <FormattedMessage {...lang.buttonAddStep} />
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{
                                backgroundColor: colors.blueAccent[700],
                                "&:hover": {
                                    backgroundColor: colors.blueAccent[800],
                                    borderColor: colors.blueAccent[800],
                                    boxShadow: "none",
                                },
                                marginRight: "10px",
                            }}
                            onClick={handleSubmit}
                        >
                            <SaveOutlinedIcon /> <FormattedMessage {...lang.buttonSubmit} />
                        </Button>
                       
                    </Grid>

                    <Grid item xs={12} >
                        <Box color={colors.primary[300]}><FormattedMessage {...lang.latestUpdatedBy} />{instruction.updatedBy} {instruction.updated}</Box>
                    </Grid>
                </Grid>
            </form>
            <AxionControls.AxionBackdrop open={showBackdrop} />
        </Box>
    );
};

export default InstructionForm;

