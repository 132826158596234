import React, { useState, useEffect } from "react";
import { Grid, Stack, useTheme} from "@mui/material";
// import GridDatePicker from "../../home/components/GridDatePicker";
import GridDateTimePicker from "../../home/components/GridDateTimePicker";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { FormattedMessage } from "react-intl";
import messages from "../components/messages";
import { tokens } from "../../../assets/theme";

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { CommentsDisabledOutlined } from "@mui/icons-material";

function isDateValid(dateStr) {
  return !isNaN(new Date(dateStr));
}

const DateFilter = (props) => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  //timespan parameter to API Fetch default currentshift (24h, 72h available too)
  const [timeSpan, setTimeSpan] = useLocalStorage("timespan", "currentshift");
  //State to handle datepicker component visibility
  const [selectedValue, setSelectedValue] = React.useState(timeSpan);
  //State to handle datepicker component visibility
  const [showDatepicker, setShowDatePicker] = useState(timeSpan === 'manual');

  useEffect(() => { 
    console.log("Initioalizing DateFilter");
    console.log("props.showWeek: ", props.showWeek);
    console.log("selectedValue: ", selectedValue);

    if (selectedValue === "week" && !props.showWeek ) {
      setSelectedValue ("currentshift");
      setTimeSpan("currentshift");
      setShowDatePicker(false);
    }
    if (selectedValue === "manual" && props.showWeek ) {
      setSelectedValue ("currentshift");
      setTimeSpan("currentshift");
      setShowDatePicker(false);
    }
  }, []);

  useEffect(() => { 
    if (selectedValue === "week" && !props.showWeek ) {
      setSelectedValue ("currentshift");
      setTimeSpan("currentshift");
      setShowDatePicker(false);
    }
  }, [selectedValue, timeSpan]);
  
  const handleStartDateChange = (event) => {
    if (isDateValid(event)){
      props.setStartDate(event);
    }
  }

  const handleEndDateChange = (event) => {
    if (isDateValid(event)){
      props.setEndDate(event);
    }
    
  }

  const sendDataToParent = (data) => {
    props.onDataReceived(data);
  };

  //Change handle for radio buttons when selecting new timespan for api fetch.
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    //Check if manual timespan selected, if selected then show datepickers.
    if (event.target.value !== "manual") {
      setTimeSpan(event.target.value);
      setShowDatePicker(false);
    } else {
      setShowDatePicker(true);
    }
  };

  useEffect(() => {
    sendDataToParent(selectedValue);
  }, [selectedValue]);

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "size-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  let radiobuttons = (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel
          value="currentShift"
          control={<Radio {...controlProps("currentshift")} sx={{
            color: "secondary",
            '&.Mui-checked': {
              color: colors.dashbordGreen[500],
            },
          }} />}
          color="success"
          label={<FormattedMessage {...messages.currentShiftFilter} />}
        />
        <FormControlLabel
          value="prevShift"
          control={<Radio {...controlProps("prevShift")} sx={{
            color: "secondary",
            '&.Mui-checked': {
              color: colors.dashbordGreen[500],
            },
          }} />}
          label={<FormattedMessage {...messages.prevShiftFilter} />}
        />
        <FormControlLabel
          value="24h"
          control={<Radio {...controlProps("24h")} sx={{
            color: "secondary",
            '&.Mui-checked': {
              color: colors.dashbordGreen[500],
            },
          }} />}
          label={<FormattedMessage {...messages.dayFilter} />}
        />
        <FormControlLabel
          value="72h"
          control={<Radio {...controlProps("72h")} sx={{
            color: "secondary",
            '&.Mui-checked': {
              color: colors.dashbordGreen[500],
            },
          }} />}
          label={<FormattedMessage {...messages.threeDayFilter} />}
        />
        { !props.hideManual && <FormControlLabel
          value="manual"
          control={<Radio {...controlProps("manual")} sx={{
            color: "secondary",
            '&.Mui-checked': {
              color: colors.dashbordGreen[500],
            },
          }} />}
          label={<FormattedMessage {...messages.manualFilter} />}
        />}
        {
          props.showWeek && <FormControlLabel
          value="week"
          control={<Radio {...controlProps("week")} sx={{
            color: "secondary",
            '&.Mui-checked': {
              color: colors.dashbordGreen[500],
            },
          }} />}
          label={<FormattedMessage {...messages.weekFilter} />}
        />
        }
      </RadioGroup>
    </FormControl>
  );

  let content =
    <>
      <Grid container spacing={0} sx={{ paddingTop: "0px" }}>
        <Grid item xs={12} lg={6} sx={{ display: "flex", paddingRight: "10px" }}>
          <Stack direction="row" width="100%" spacing={2}>
            {showDatepicker && (<><GridDateTimePicker
              label={<FormattedMessage {...messages.fromDate} />}
              labelTime={<FormattedMessage {...messages.fromTime} />}
              locale="sv"
              maxDate={Date().toLocaleString()}
              minDate=""
              onChange={handleStartDateChange}
              date={props.startDate}
            />
              <GridDateTimePicker
                label={<FormattedMessage {...messages.toDate} />}
                labelTime={<FormattedMessage {...messages.toTime} />}
                locale="sv"
                maxDate={Date().toLocaleString()}
                minDate=""
                onChange={handleEndDateChange}
                date={props.endDate}
              /> </>)}

          </Stack>
        </Grid>

        <Grid item xs={12} lg={6} sx={{ justifyContent: "end", display: "flex", paddingTop: "21px", paddingRight: "20px" }}>
          {radiobuttons}
        </Grid>
      </Grid>
    </>
  return content;
}

export default DateFilter;