import { defineMessages } from 'react-intl';

export const scope = 'axionoee.features.settings.components';

export default defineMessages({
    settings: {
        id: `${scope}.label.settings`,
        defaultMessage: 'Settings',
    },
    timezone: {
        id: `${scope}.label.timezone`,
        defaultMessage: 'Time Zone',
    },
    oeePercentWarning: {
        id: `${scope}.label.oeePercentWarning`,
        defaultMessage: 'OEE Percent Warning',
    },
    oeePercentBad: {
        id: `${scope}.label.oeePercentBad`,
        defaultMessage: 'OEE Percent Bad',
    },
    save: {
        id: `${scope}.label.save`,
        defaultMessage: 'Save',
    },
    settingsSaved: {
        id: `${scope}.label.settingsSaved`,
        defaultMessage: 'Settings saved',
    },
    grafanaBaseUrl: {
        id: `${scope}.label.grafanaBaseUrl`,
        defaultMessage: 'Grafana base url',
    },
    availabilityPercentWarning: {
        id: `${scope}.label.availabilityPercentWarning`,
        defaultMessage: 'Availability percent warning',
    },
    availabilityPercentBad: {
        id: `${scope}.label.availabilityPercentBad`,
        defaultMessage: 'Availability percent bad',
    },
    meassurePerformance: {
        id: `${scope}.label.meassurePerformance`,
        defaultMessage: 'Measure performance',
    },
    performancePercentWarning: {
        id: `${scope}.label.performancePercentWarning`,
        defaultMessage: 'Performance percent warning',
    },
    performancePercentBad: {
        id: `${scope}.label.performancePercentBad`,
        defaultMessage: 'Performance percent bad',
    },
    meassureQuality: {
        id: `${scope}.label.meassureQuality`,
        defaultMessage: 'Measure quality',
    },
    qualityPercentWarning: {
        id: `${scope}.label.qualityPercentWarning`,
        defaultMessage: 'Quality percent warning',
    },
    qualityPercentBad: {
        id: `${scope}.label.qualityPercentBad`,
        defaultMessage: 'Quality percent bad',
    },
    clearLocalStorage: {
        id: `${scope}.label.clearLocalStorage`,
        defaultMessage: 'Clear Local Storage',
    },
}
);