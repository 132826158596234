import React from "react";
import { Box, Button } from "@mui/material";
import AxionControls from "../../../components/controls/AxionControls";
import { FormattedMessage } from "react-intl";
import messages from "./messages";

export default function AddEditEmbeddedReportDialog(props) {

    return (
        <Box 
            sx={{
                p:1,
                m:1
            }}>
                <Box sx={{width: 400}}>
                    <AxionControls.AxionTextBox 
                        name="reportName"
                        label={<FormattedMessage {...messages.reportNameColumnLabel} />}
                        value={props.stopReasonCode.reportName}
                        onChange={props.onChange}
                    />
                </Box>
                <Box sx={{width: 400}}>
                    <AxionControls.AxionTextBox 
                        name="embeddUrl"
                        label={<FormattedMessage {...messages.embeddedUrlColumnLabel} />}
                        value={props.stopReasonCode.embeddUrl}
                        onChange={props.onChange}
                    />
                </Box>
                <Box sx={{width: 100}}>
                    <AxionControls.AxionTextBox 
                        name="sortOrder"
                        label={<FormattedMessage {...messages.sortOrderColumnLabel} />}
                        value={props.stopReasonCode.sortOrder}
                        onChange={props.onChange}
                    />
                </Box>
               <Box>
                    <Button 
                        variant="contained" 
                        sx={{
                            marginTop: 2,
                            }}
                        onClick={props.handleSave}>
                        <FormattedMessage {...messages.save} />
                    </Button>
                </Box>
        </Box>
    );
}