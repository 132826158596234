import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import Button from "../../components/Button";
import AddIcon from "@mui/icons-material/Add";
import { pushErrorMessage } from '../../utils/notifications';
import EmbeddedReportsDataGrid from "./components/embeddedReportsDataGrid";
import Popup from '../../components/Popup';
import { FormattedMessage } from "react-intl";
import messages from "./components/messages";
import AddEditEmbeddedReport from "./components/addEditEmbeddedReportDialog";
import { tokens } from "../../assets/theme";
import { useQuery } from "@tanstack/react-query";
import { fetchEmbeddedReports, updateEmbeddedReport, createEmbeddedReport, deleteEmbeddedReport } from "../../fetchers/settings";
import CircularProgress from "@mui/material/CircularProgress";
import { useMsal, useAccount } from "@azure/msal-react";
import AxionControls from "../../components/controls/AxionControls";
import { useLocalStorage } from "../../hooks/useLocalStorage";

const EmbeddedReports = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const [companyKey, setCompanyKey] = useLocalStorage("companyKey", 1);
  const [openPopup, setOpenPopup] = useState(false);
  const [embeddedReport, setEmbeddedReport] = useState({
    id: null,
    reportName: "",
    embeddedUrl: "",
    companyKey: companyKey,
    sortOrder: 0,
    createdBy: "",
    created: null,
    updatedBy:"",
    updated: null
  });
  const [showBackdrop, setShowBackdrop] = useState(false);

  const embeddedReportsQuery = useQuery(["embeddedReports", companyKey], () => fetchEmbeddedReports(companyKey),
      {
          staleTime:Infinity
      }
  );

  

  const handleAddEmbeddedReportClick = () => {
    setEmbeddedReport({
        id: null,
        reportName: "",
        embeddedUrl: "",
        companyKey: companyKey,
        sortOrder: 0,
        createdBy: "",
        created: null,
        updatedBy:"",
        updated: null
    });
    setOpenPopup(true);
  }

  const handleEditEmbeddedReportClick = (id) => {
    const embeddedReport = embeddedReportsQuery.data.find((rowData) => rowData.id === id);
    setEmbeddedReport(embeddedReport);
    setOpenPopup(true);
  }

  const handleDeleteEmbeddedReportClick = (id) => {
    handleDelete(id);
  }

  const handleChange = (event) => {
    const {id, name, value, type, checked} = event.target
    setEmbeddedReport(prev => {
        return {
            ...prev,
            [id ? id : name]: type === "checkbox" ? checked : value
        }
    })
  }

  const handleSave = () => {
    setShowBackdrop(true);
       if (embeddedReport.id) {
        embeddedReport.updatedBy = account.username;
          updateEmbeddedReport(embeddedReport).then(() => {  
            setOpenPopup(false);
            embeddedReportsQuery.refetch();
            setShowBackdrop(false);
          }).catch((error) => {
            console.log(error);
            setShowBackdrop(false);
            pushErrorMessage(`Cannot update the report.`);
       });
       } else {
          embeddedReport.updatedBy = account.username;
          embeddedReport.createdBy = account.username;
          createEmbeddedReport(embeddedReport).then(() => {  
            setOpenPopup(false);
            embeddedReportsQuery.refetch();
            setShowBackdrop(false);
          }).catch((error) => {
            console.log(error);
            setShowBackdrop(false);
            pushErrorMessage(`Cannot update the report.`);
       });
       }
  }   
  
  const handleDelete = (id) => {
    setShowBackdrop(true);
    deleteEmbeddedReport(id).then(() => {
      embeddedReportsQuery.refetch();
      setShowBackdrop(false);
    })
    .catch((error) => {
      console.log(error);
      setShowBackdrop(false);
      pushErrorMessage(`Cannot delete the report.`);
    });
  }


  if (embeddedReportsQuery.isLoading)
    return <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}><CircularProgress color="secondary" /></div>;

  return (
    <Box maxWidth={1800} >
      <Button
        text={<FormattedMessage {...messages.addEmbeddedReportLabel} />}
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={handleAddEmbeddedReportClick}
        sx={{
        "&:hover": {
            backgroundColor: colors.dashbordGreen[700],
        },
        backgroundColor: colors.dashbordGreen[500],
        color: "#000000",
        position: "absolute",
        right: "10px",
        }} />
      <EmbeddedReportsDataGrid 
        embeddedReports={embeddedReportsQuery}
        handleDeleteEmbeddedReportClick={handleDeleteEmbeddedReportClick}
        handleEditEmbeddedReportClick={handleEditEmbeddedReportClick} />
      <Popup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          maxWidht="sm"
          title={embeddedReport.id != "" ? <FormattedMessage {...messages.editEmbeddedReportLabel} /> : <FormattedMessage {...messages.addEmbeddedReportLabel} />}>
          <AddEditEmbeddedReport 
              onChange={handleChange}
              handleSave={handleSave}
              stopReasonCode={embeddedReport}
              setOpenPopup={setOpenPopup} />
      </Popup>
      <AxionControls.AxionBackdrop open={showBackdrop} />
    </Box>
  );
};

export default EmbeddedReports;
