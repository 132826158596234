import AxionMultiLineText from "./AxionMultiLineText";
import AxionTextBox from "./AxionTextBox";
import AxionSelect from "./AxionSelect";
import AxionTextBoxReadOnly from "./AxionTextBoxReadOnly";
import AxionColorPicker from "./AxionColorPicker";
import AxionTextBoxMultiline from "./AxionTextBoxMultiline";
import AxionTextBoxNoLabel from "./AxionTextBoxNoLabel";
import AxionSelectNoLabel from "./AxionSelectNoLabel";
import AxionTextBoxNoLabelReadOnly from "./AxionTextBoxNoLabelReadOnly";
import AxionBackdrop from "./AxionBackdrop";
import AxionAutocomplete from "./AxionAutocomplete";
 
const AxionControls = {
  AxionMultiLineText,
  AxionTextBox,
  AxionSelect,
  AxionTextBoxReadOnly,
  AxionColorPicker,
  AxionTextBoxMultiline,
  AxionTextBoxNoLabel,
  AxionTextBoxNoLabelReadOnly,
  AxionSelectNoLabel,
  AxionAutocomplete,
  AxionBackdrop
};
export default AxionControls;