import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import Button from "../../components/Button";
import AddIcon from "@mui/icons-material/Add";
import { pushErrorMessage } from '../../utils/notifications';
import StopReasonCodesDataGrid from "./components/stopReasonCodesDataGrid";
import Popup from '../../components/Popup';
import { FormattedMessage } from "react-intl";
import messages from "./components/messages";
import AddEditStopReasonCode from "./components/stopReasonCodeAddEditDialog";
import { tokens } from "../../assets/theme";
import { useQuery } from "@tanstack/react-query";
import { getStopReasonCodes, updateStopResonCode, createStopResonCode, deleteStopResonCode } from "./hooks/fetchers";
import CircularProgress from "@mui/material/CircularProgress";
import { useMsal, useAccount } from "@azure/msal-react";
import AxionControls from "../../components/controls/AxionControls";
import { useLocalStorage } from "../../hooks/useLocalStorage";

const StopReasonCodes = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const [companyKey, setCompanyKey] = useLocalStorage("companyKey", 1);
  const [openPopup, setOpenPopup] = useState(false);
  const [stopReasonCode, setStopReasonCode] = useState({
    id: "",
    code: "",
    name: "",
    description: "",
    createdBy: "",
    noOEECalculationBool: false,
    colorCode: "#CCCCCC",
    companyKey: companyKey,
    type: "",
    isUndefined: false,
  });
  const [showBackdrop, setShowBackdrop] = useState(false);

  const stopReasonCodesQuery = useQuery(["stopReasonCodes", companyKey], () => getStopReasonCodes(companyKey),
      {
          staleTime:120000,
          refetchInterval: 120000,
      }
  );

  

  const handleAddStopReasonCodeClick = () => {
    setStopReasonCode({
      id: "",
      code: "",
      name: "",
      description: "",
      createdBy: "",
      noOEECalculationBool: false,
      colorCode: "#CCCCCC",
      companyKey: companyKey,
      type: "",
      sortOrder: 0,
      isUndefined: false,
    });
    setOpenPopup(true);
  }

  const handleEditStopReasonCodeClick = (id) => {
    const stopReason = stopReasonCodesQuery.data.find((rowData) => rowData.id === id);
    setStopReasonCode(stopReason);
    setOpenPopup(true);
  }

  const handleDeleteStopReasonCodeClick = (id) => {
    handleDelete(id);
  }

  const handleChange = (event) => {
    const {id, name, value, type, checked} = event.target
    setStopReasonCode(prev => {
        return {
            ...prev,
            [id ? id : name]: type === "checkbox" ? checked : value
        }
    })
  }

  const handleSave = () => {
    setShowBackdrop(true);
       if (stopReasonCode.id) {
          stopReasonCode.updatedBy = account.username;
          updateStopResonCode(stopReasonCode).then(() => {  
            setOpenPopup(false);
            stopReasonCodesQuery.refetch();
            setShowBackdrop(false);
          }).catch((error) => {
            console.log(error);
            setShowBackdrop(false);
            pushErrorMessage(`Cannot update the stop reason code.`);
       });
       } else {
          stopReasonCode.updatedBy = account.username;
          stopReasonCode.createdBy = account.username;
          createStopResonCode(stopReasonCode).then(() => {  
            setOpenPopup(false);
            stopReasonCodesQuery.refetch();
            setShowBackdrop(false);
          }).catch((error) => {
            console.log(error);
            setShowBackdrop(false);
            pushErrorMessage(`Cannot update the stop reason code.`);
       });
       }
  }   
  
  const handleDelete = (id) => {
    setShowBackdrop(true);
    deleteStopResonCode(id).then(() => {
      stopReasonCodesQuery.refetch();
      setShowBackdrop(false);
    })
    .catch((error) => {
      console.log(error);
      setShowBackdrop(false);
      pushErrorMessage(`Cannot delete the stop reason code`);
    });
  }


  if (stopReasonCodesQuery.isLoading)
    return <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}><CircularProgress color="secondary" /></div>;

  return (
    <Box maxWidth={1800} >
      <Button
        text={<FormattedMessage {...messages.addStopCodeLabel} />}
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={handleAddStopReasonCodeClick}
        sx={{
        "&:hover": {
            backgroundColor: colors.dashbordGreen[700],
        },
        backgroundColor: colors.dashbordGreen[500],
        color: "#000000",
        position: "absolute",
        right: "10px",
        }} />
      <StopReasonCodesDataGrid 
        stopReasonCodes={stopReasonCodesQuery}
        handleDeleteStopReasonCodeClick={handleDeleteStopReasonCodeClick}
        handleEditStopReasonCodeClick={handleEditStopReasonCodeClick} />
      <Popup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          maxWidht="sm"
          title={stopReasonCode.id != "" ? <FormattedMessage {...messages.editStopCodeLabel} /> : <FormattedMessage {...messages.addStopCodeLabel} />}>
          <AddEditStopReasonCode 
              onChange={handleChange}
              handleSave={handleSave}
              stopReasonCode={stopReasonCode}
              setOpenPopup={setOpenPopup} />
      </Popup>
      <AxionControls.AxionBackdrop open={showBackdrop} />
    </Box>
  );
};

export default StopReasonCodes;
