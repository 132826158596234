import { defineMessages } from 'react-intl';

export const scope = 'axionoee.features.machinedetails.components';

export default defineMessages({
    stopReasonCode: {
        id: `${scope}.machinestopeditdialog.label.stopreasoncode`,
        defaultMessage: 'Stop Reason Code',
    },
    comment: {
        id: `${scope}.machinestopeditdialog.label.comment`,
        defaultMessage: 'Comment',
    },
    machineName: {
        id: `${scope}.machinestopeditdialog.label.machinename`,
        defaultMessage: 'Machine Name',
    },
    fromDate: {
        id: `${scope}.machinestopeditdialog.label.fromdate`,
        defaultMessage: 'From Date',
    },
    toDate: {
        id: `${scope}.machinestopeditdialog.label.todate`,
        defaultMessage: 'To Date',
    },
    duration: {
        id: `${scope}.machinestopeditdialog.label.duration`,
        defaultMessage: 'Duration',
    },
    minutes: {
        id: `${scope}.machinestopeditdialog.label.minutes`,
        defaultMessage: 'minutes',
    },
    item: {
        id: `${scope}.machinestopeditdialog.label.item`,
        defaultMessage: 'Item',
    },
    productionOrder: {
        id: `${scope}.machinestopeditdialog.label.productionOrder`,
        defaultMessage: 'Production order',
    },
    updated: {
        id: `${scope}.machinestopeditdialog.label.updated`,
        defaultMessage: 'Updated',
    },
    updatedBy: {
        id: `${scope}.machinestopeditdialog.label.updatedBy`,
        defaultMessage: 'Updated by',
    },
    machineStatus: {
        id: `${scope}.label.machineStatus`,
        defaultMessage: 'Machine status',
    },
    machine: {
        id: `${scope}.label.machine`,
        defaultMessage: 'Machine',
    },
    itemInProduction: {
        id: `${scope}.label.itemInProduction`,
        defaultMessage: 'Item in production',
    },
    productionOrder: {
        id: `${scope}.label.productionOrder`,
        defaultMessage: 'Production order',
    },
    percentageDone: {
        id: `${scope}.label.percentageDone`,
        defaultMessage: 'Percentage done',
    },
    t: {
        id: `${scope}.label.t`,
        defaultMessage: 'A',
    },
    a: {
        id: `${scope}.label.a`,
        defaultMessage: 'P',
    },
    k: {
        id: `${scope}.label.k`,
        defaultMessage: 'Q',
    },
    tak: {
        id: `${scope}.label.tak`,
        defaultMessage: 'OEE',
    },
    plannedQty: {
        id: `${scope}.label.plannedQty`,
        defaultMessage: 'Planned Qty',
    },
    reportedQty: {
        id: `${scope}.label.reportedQty`,
        defaultMessage: 'Reported Qty',
    }
    ,
    remainingQty: {
        id: `${scope}.label.remainingQty`,
        defaultMessage: 'Remaining Qty',
    },
    remainingTime: {
        id: `${scope}.label.remainingTime`,
        defaultMessage: 'Remaining time',
    },
    remainingTimeDays: {
        id: `${scope}.label.remainingTimeDays`,
        defaultMessage: 'Remaining time',
    },
    scrappedQty: {
        id: `${scope}.label.scrappedQty`,
        defaultMessage: 'Scrapped Qty',
    },
    nrOfCycles: {
        id: `${scope}.label.nrOfCycles`,
        defaultMessage: 'Nr of cycles',
    },
    days: {
        id: `${scope}.label.days`,
        defaultMessage: 'days',
    },
    downtime: {
        id: `${scope}.label.downtime`,
        defaultMessage: 'Downtime',
    },
    uptime: {
        id: `${scope}.label.uptime`,
        defaultMessage: 'Uptime',
    },
    
    info: {
        id: `${scope}.label.info`,
        defaultMessage: 'Info',
    },
    description: {
        id: `${scope}.label.description`,
        defaultMessage: 'Description',
    }, 
    opcServerUrl: {
        id: `${scope}.label.opcServerUrl`,
        defaultMessage: 'OPC Server Url',
    },
    opcServerStatus: {
        id: `${scope}.label.opcServerStatus`,
        defaultMessage: 'OPC Server Status',
    },
    area: {
        id: `${scope}.label.area`,
        defaultMessage: 'Area',
    },
    machineType: {
        id: `${scope}.label.machineType`,
        defaultMessage: 'Machine type',
    },
    oeePercentWarning: {
        id: `${scope}.label.oeePercentWarning`,
        defaultMessage: 'OEE Percent Warning',
    },
    oeePercentBad: {
        id: `${scope}.label.oeePercentBad`,
        defaultMessage: 'OEE Percent Bad',
    },
    avalibleStopReasonCodes: {
        id: `${scope}.label.avalibleStopReasonCodes`,
        defaultMessage: 'Available stop reason codes',
    },
    idealCycleTimeActive: {
        id: `${scope}.label.idealCycleTimeActive`,
        defaultMessage: 'Ideal CT',
    },
    lastCycleTime: {
        id: `${scope}.label.lastCycleTime`,
        defaultMessage: 'Last CT',
    },
    save: {
        id: `${scope}.label.save`,
        defaultMessage: 'Save',
    },
    splitStop: {
        id: `${scope}.label.splitStop`,
        defaultMessage: 'Split stop',
    },
    editStop: {
        id: `${scope}.label.editStop`,
        defaultMessage: 'Edit stop',
    },
    editSettings: {
        id: `${scope}.label.editSettings`,
        defaultMessage: 'Edit settings',
    },
    editComment: {
        id: `${scope}.label.editComment`,
        defaultMessage: 'Add/Edit comment',
    },
    stopCount: {
        id: `${scope}.label.stopCount`,
        defaultMessage: 'Stop count',
    },
    currentShiftFilter: {
        id: `${scope}.label.filters.currentShift`,
        defaultMessage: 'Current shift',
    },
    prevShiftFilter: {
        id: `${scope}.label.filters.prevShift`,
        defaultMessage: 'Prev.shift',
    },
    dayFilter: {
        id: `${scope}.label.filters.24h`,
        defaultMessage: '24h',
    },
    threeDayFilter: {
        id: `${scope}.label.filters.72h`,
        defaultMessage: '72h',
    },
    manualFilter: {
        id: `${scope}.label.filters.manual`,
        defaultMessage: 'Manual',
    },
    weekFilter: {
        id: `${scope}.label.filters.week`,
        defaultMessage: 'Week',
    }
    ,
    linksAndFunctions: {
        id: `${scope}.label.linksAndFunctions`,
        defaultMessage: 'Manual',
    }
    ,
    machineComments: {
        id: `${scope}.label.machineComments`,
        defaultMessage: 'Manual',
    },
    settingsButtonTooltip: {
        id: `${scope}.label.settingsButtonTooltip`,
        defaultMessage: 'Manual',
    },
    commentButtonTooltip: {
        id: `${scope}.label.commentButtonTooltip`,
        defaultMessage: 'Manual',
    },
    grafanaButtonTooltip: {
        id: `${scope}.label.grafanaButtonTooltip`,
        defaultMessage: 'Manual',
    },
    plcLogButtonTooltip: {
        id: `${scope}.label.plcLogButtonTooltip`,
        defaultMessage: 'Manual',
    },
    oeeStartDateTime: {
        id: `${scope}.label.oeeStartDateTime`,
        defaultMessage: 'OEE Start DateTime',
    },
    fromDate: {
        id: `${scope}.label.fromDate`,
        defaultMessage: 'From date',
    },
    fromTime: {
        id: `${scope}.label.fromTime`,
        defaultMessage: 'To date',
    },
    toDate: {
        id: `${scope}.label.toDate`,
        defaultMessage: 'From time',
    },
    toTime: {
        id: `${scope}.label.toTime`,
        defaultMessage: 'To time',
    },
    activityTriggered: {
        id: `${scope}.label.activityTriggered`,
        defaultMessage: 'An activity was created when the stop was updated.',
    },
    useStopPageOnGrafanaLink: {
        id: `${scope}.label.useStopPageOnGrafanaLink`,
        defaultMessage: 'Use stop page on Grafana link',
    },
    meassureAvailability: {
        id: `${scope}.label.meassureAvailability`,
        defaultMessage: 'Measure availability',
    },
    availabilityPercentWarning: {
        id: `${scope}.label.availabilityPercentWarning`,
        defaultMessage: 'Availability percent warning',
    },
    availabilityPercentBad: {
        id: `${scope}.label.availabilityPercentBad`,
        defaultMessage: 'Availability percent bad',
    },
    meassurePerformance: {
        id: `${scope}.label.meassurePerformance`,
        defaultMessage: 'Measure performance',
    },
    notifyOnNewActivity: {
        id: `${scope}.label.notifyOnNewActivity`,
        defaultMessage: 'Notify on new activity',
    },
    notifyEmail: {
        id: `${scope}.label.notifyEmail`,
        defaultMessage: 'Notify email',
    },
    performancePercentWarning: {
        id: `${scope}.label.performancePercentWarning`,
        defaultMessage: 'Performance percent warning',
    },
    performancePercentBad: {
        id: `${scope}.label.performancePercentBad`,
        defaultMessage: 'Performance percent bad',
    },
    meassureQuality: {
        id: `${scope}.label.meassureQuality`,
        defaultMessage: 'Measure quality',
    },
    qualityPercentWarning: {
        id: `${scope}.label.qualityPercentWarning`,
        defaultMessage: 'Quality percent warning',
    },
    qualityPercentBad: {
        id: `${scope}.label.qualityPercentBad`,
        defaultMessage: 'Quality percent bad',
    },
    targetValue: {
        id: `${scope}.label.targetValue`,
        defaultMessage: 'Target value',
    },
    newActivity: {
        id: `${scope}.label.newActivity`,
        defaultMessage: 'New activity',
    },
    newActivityText: {
        id: `${scope}.label.newActivityText`,
        defaultMessage: 'There is a new activity for this machine. Check the activity tab for more information.',
    },
    Ok: {
        id: `${scope}.label.ok`,
        defaultMessage: 'Ok',
    },
    showActivities: {
        id: `${scope}.label.showActivities`,
        defaultMessage: 'Show activities',
    },
    activity: {
        id: `${scope}.label.activity`,
        defaultMessage: 'Activity',
    },
    completeBefore: {
        id: `${scope}.label.completeBefore`,
        defaultMessage: 'Complete before',
    },
    in: {
        id: `${scope}.label.in`,
        defaultMessage: 'in',
    },
});