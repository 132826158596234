import { useTheme } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { tokens } from "../assets/theme";
import { patternLinesDef  } from '@nivo/core'
// import { mockBarData as data } from "../assets/mockData";



const BarChart = ({ isDashboard = false, data }) => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const barColors = { 'openActivity': colors.blueAccent[600] }
  const getColor = bar => barColors[bar.id]

  return (
    <ResponsiveBar
      data={data}
      theme={{
        // added
        axis: {
          domain: {
            line: {
              stroke: colors.gray[100],
            },
          },
          legend: {
            text: {
              fill: colors.gray[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.gray[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.gray[100],
            },
          },
        },
        legends: {

          text: {
            fill: colors.gray[100],
          },
        },
      }}
      keys={["openActivity"]}
      indexBy="machine"
      margin={{ top: 50, right: 30, bottom: 20, left: 30 }}
      padding={0.3}
      minValue={0}
      //maxValue={2}
      valueScale={{ type: "linear", clamp: true }}
      indexScale={{ type: "band", round: true }}
      colors={getColor}
      fill={[
        {
          match: {
              id: 'openActivity'
          },
          id: 'lines'
      }

      ]}
      borderColor={{
        from: 'color',
        modifiers: [
            [
                'darker',
                1.6
            ]
        ]
    }}
      // borderColor={{
      //   from: "color",
      //    modifiers: [["darker", "1.6"]],
      // }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        //legend: "machine", // changed
        legendPosition: "middle",
        legendOffset: 32,
      }}
      axisLeft={null}
      //   tickSize: 1,
      //   tickPadding: 5,
      //   tickRotation: 3,
      //   legend:  "number of activities", // changed
      //   legendPosition: "middle",
      //   legendOffset: -30,
      // }}
      enableLabel={true}
      enableGridY={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor="#ededed"
      legends={[]}
      role="application"
      isInteractive={false}
      barAriaLabel={function (e) {
        return e.id + ": " + e.formattedValue + " in machine: " + e.indexValue;
      }}
    />
  );
};

export default BarChart;
