import { Box, Typography, useTheme, Grid } from "@mui/material";
import { tokens } from "../../assets/theme";

import Paper from "@mui/material/Paper";
import React, { useState, useEffect, Components } from "react";
import Header from "../../components/Header";
import { color } from "@mui/system";
import MachineTopBar from "./componentes/machineTopBar";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useSelector } from "react-redux";

//import StatBox from "./components/StatBox";
import MessageIcon from "@mui/icons-material/Message";

import { fetchMachineInfo } from "../../fetchers/machine";
import { CircularProgress } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from '@mui/material/InputLabel';
import { monitorIndirectWorkCodes } from "../monitorIntegration/hooks/fetchers";
// import ProgressCircle from "./components/ProgressCircle";;
//import * from 'react';

//ICONS
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));


const MachinePages = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [machineId, setMachineId] = useState(12);
  const [age, setAge] = useState('');

  const getMonitorIndirectWorkCodes = async () => {
    try {
        const response = await monitorIndirectWorkCodes();
        console.log("testing" + response)
    } catch (err) {
        //handle error or do whatever
    }
  };

  useEffect(() =>
  {
    getMonitorIndirectWorkCodes();
  },[]);

  return (
    <Box>
    <Box m="20px" sx={{ flexGrow: 1 }}>
      <MachineTopBar machineId={machineId} />
    </Box>
        <Box sx={{ minWidth: 120 }} backgroundColor={colors.primary[400]}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Age</InputLabel>
          <Select
            
            id="demo-simple-select"
            value={age}
            label="Age"
            
            //onChange={handleChange}
          >
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </FormControl>
      </Box>
      </Box>
  );
};

export default MachinePages;
