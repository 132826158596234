import React, { useEffect } from "react";
import { useTheme, Paper, Box, Toolbar, IconButton, Tooltip, Typography } from "@mui/material";
import { tokens } from "../../../assets/theme";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import useTable from "../../../components/useTable";
import Controls from "../../../components/controls/Contols";
import Popup from "../../../components/Popup";
import { InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { useQueryClient } from "@tanstack/react-query";
import ViewInstruction from "./viewInstruction";
import InstructionForm from "./instructionForm";
import InstructionDeleteForm from "./instructionDeleteForm";
import { FormattedMessage } from "react-intl";
import messages from "./messages";

const headCells = [
    { id: "title", label: <FormattedMessage {...messages.title} /> },
    { id: "createdBy", label: <FormattedMessage {...messages.createdBy} /> },
    { id: "created", label: <FormattedMessage {...messages.created} /> },
    { id: "activitiesCount", label: <FormattedMessage {...messages.activitiesCount} /> },
    { id: "view", label: "", disableSorting: true },
];

function InstructionGrid(props) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const queryClient = useQueryClient();
    const [show, setShow] = useState(false);
    const [recordForEdit, setRecordForEdit] = useState(0);
    const [openPopup, setOpenPopup] = useState(false);
    const [openPopupCreate, setOpenPopupCreate] = useState(false);
    const [openPopupEdit, setOpenPopupEdit] = useState(false);
    const [openPopupDelete, setOpenPopupDelete] = useState(false);
    const [records, setRecords] = useState(props.allInstructionsQuery.data);
    const [filterFn, setFilterFn] = useState({
        fn: (items) => {
            return items;
        },
    });
    const [selectedRow, setSelectedRow] = useState(null);

    const callbackModal = () => {
        setShow(false);
    };

    const addOrEdit = (materialSource, resetForm) => {

        //updateMaterialSourceQuery(materialSource);
        resetForm();
        setRecordForEdit(null);
        setOpenPopup(false);
        setRecords(props.allInstructionsQuery.data);
    };

    useEffect(() => {

        setRecords(props.allInstructionsQuery.data);

    }, [props.allInstructionsQuery]);

    const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
        useTable(records, headCells, filterFn);

    const handleSearch = (e) => {
        let target = e.target;
        setFilterFn({
            fn: (items) => {
                if (target.value === "") return items;
                else
                    return items.filter(
                        (x) =>
                            x.title.toLowerCase().includes(target.value) ||
                            x.createdBy.toLowerCase().includes(target.value)
                    );
            },
        });
    };

    const openInPopup = (instruction, type) => {
        //Update to selected instruction
        setRecordForEdit(instruction);
        if (type === "view") {
            setOpenPopup(true);
        } else if (type === "edit") {
            setOpenPopupEdit(true);
        } else if (type === "delete") {
            setOpenPopupDelete(true);
        }
    };

    const openInPopupCreate = () => {
        setRecordForEdit(0);
        setOpenPopupCreate(true);
    };

    const toggleShow = (params) => () => {
        setShow((p) => !p);
        setSelectedRow(params);
    };

    return (
        <Box padding={2}>
            <Paper
                sx={{
                    position: "relative",
                    borderRadius: "4px",
                }}
            >
                <Toolbar sx={{ backgroundColor: colors.primary[700] }}>
                    <Controls.Input
                        label={<FormattedMessage {...messages.search} />}
                        //className={classes.searchInput}
                        InputProps={{
                            size: "small",
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        onChange={handleSearch}
                    />
                    <Controls.Button
                        text={<FormattedMessage {...messages.add} />}
                        variant="outlined"
                        size="small"
                        startIcon={<AddIcon />}
                        onClick={() => {
                            openInPopupCreate();
                        }}
                        sx={{
                            "&:hover": {
                                backgroundColor: "#799c4a",
                            },
                            backgroundColor: "#799c4a",
                            color: "#ffffff",
                            position: "absolute",
                            right: "10px",
                        }}
                    />
                </Toolbar>

                <TblContainer sx={{ maxHeight: 100 }}>
                    <TblHead />
                    <TableBody sx={{ backgroundColor: colors.primary[400] }}>
                        {recordsAfterPagingAndSorting().map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>{item.title}</TableCell>
                                <TableCell>{item.createdBy}</TableCell>
                                <TableCell>{item.created}</TableCell>
                                <TableCell>{item.activityCount}</TableCell>
                                <TableCell align="right">
                                  {/* <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.viewInstruction} /></Typography>}>
                                        <IconButton
                                            arial-label="View"
                                            size="small"
                                            sx={{
                                                "&:hover": {
                                                    backgroundColor: colors.primary[500],
                                                },
                                                backgroundColor: colors.primary[700],
                                                margin: 1,
                                            }}
                                            onClick={() => {
                                                openInPopup(item, "view");
                                            }}
                                        >
                                            <RemoveRedEyeOutlinedIcon fontSize="inherit" />
                                        </IconButton>
                                    </Tooltip> */}

                                    <Tooltip title={<Typography fontSize="12px"><FormattedMessage {...messages.updateInstruction} /></Typography>}>
                                        <IconButton
                                            arial-label="Edit"
                                            size="small"
                                            sx={{
                                                "&:hover": {
                                                    backgroundColor: colors.primary[500],
                                                },
                                                backgroundColor: colors.primary[700],
                                                margin: 1,
                                            }}
                                            onClick={() => {
                                                openInPopup(item, "edit");
                                            }}
                                        >
                                            <EditIcon fontSize="inherit" />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title={<Typography fontSize="12px">{item.activityCount > 0 ? <FormattedMessage {...messages.deleteInstructionNotPossible} /> : <FormattedMessage {...messages.deleteInstruction} />}</Typography>}>
                                        <img src="../../../assets/dot_transparent.png" alt="" />
                                        <IconButton
                                            disabled={item.activityCount > 0}
                                            arial-label="Delete"
                                            size="small"
                                            sx={{
                                                "&:hover": {
                                                    backgroundColor: colors.redAccent[500],
                                                },
                                                backgroundColor: colors.redAccent[700],
                                                margin: 1,
                                            }}
                                            onClick={() => { openInPopup(item, "delete") }}>
                                            <DeleteOutlineOutlinedIcon fontSize="inherit" />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </TblContainer>
                <TblPagination />

                <Box></Box>
            </Paper>
            <Popup
                title={<FormattedMessage {...messages.viewInstruction} />}
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                maxWidht="95%"
            >
                <ViewInstruction id={recordForEdit.id} />
            </Popup>
            <Popup
                title={<FormattedMessage {...messages.createInstruction} />}
                openPopup={openPopupCreate}
                setOpenPopup={setOpenPopupCreate}
            >
                <InstructionForm companyKey={props.companyKey} setOpenPopup={setOpenPopupCreate} data={props.allInstructionsQuery} mode="create" />
            </Popup>
            <Popup
                title={<FormattedMessage {...messages.updateInstruction} />}
                openPopup={openPopupEdit}
                setOpenPopup={setOpenPopupEdit}
            >
                <InstructionForm companyKey={props.companyKey} data={props.allInstructionsQuery} mode="update" instructionToUpdate={recordForEdit} setOpenPopup={setOpenPopupEdit} setRecordForEdit={setRecordForEdit} />
            </Popup>
            <Popup
                title={<FormattedMessage {...messages.deleteInstructionQ} />}
                openPopup={openPopupDelete}
                setOpenPopup={setOpenPopupDelete}
            >
                <InstructionDeleteForm companyKey={props.companyKey} instruction={recordForEdit} setOpenPopup={setOpenPopupDelete} data={props.allInstructionsQuery} />
            </Popup>
        </Box>
    );
}

export default InstructionGrid;
