import { defineMessages } from 'react-intl';

export const scope = 'axionoee.features.stopreasoncodes.components';

export default defineMessages({
  idColumnLabel: {
    id: `${scope}.stopreasoncodesgrid.label.idcolumn`,
    defaultMessage: 'Id',
  },
  codeColumnLabel: {
    id: `${scope}.stopreasoncodesgrid.label.codecolumn`,
    defaultMessage: 'Code',
  },
  colorColumnLabel: {
    id: `${scope}.stopreasoncodesgrid.label.colorcolumn`,
    defaultMessage: 'Color',
  },
  noOeeCalculationColumnLabel: {
    id: `${scope}.stopreasoncodesgrid.label.nooeecalculationcolumn`,
    defaultMessage: 'No OEE Calculation',
  },
  noOeeCalculationColumnDescription: {
    id: `${scope}.stopreasoncodesgrid.label.nooeecalculationcolumndescription`,
    defaultMessage: 'Time reported on stop codes set as "No OEE Calculation" will not be included in the avalability calculation',
  },
  typeColumnLabel: {
    id: `${scope}.stopreasoncodesgrid.label.typecolumn`,
    defaultMessage: 'Type',
  },
  isUndefinedColumnLabel: {
    id: `${scope}.stopreasoncodesgrid.label.isundefinedcolumn`,
    defaultMessage: 'Undefined (needs to be coded)',
  },
  isUndefinedColumnDescription: {
    id: `${scope}.stopreasoncodesgrid.label.isundefinedcolumndescription`,
    defaultMessage: 'Only one stop code can be set as undefined. If a stop is unknown, it will be set to this stop code. If a stop has this stop code the user will be prompted to change the stop code to another.',
  },
  nameColumnLabel: {
    id: `${scope}.stopreasoncodesgrid.label.namecolumn`,
    defaultMessage: 'Name',
  },
  addStopCodeLabel: {
    id: `${scope}.label.addstopcode`,
    defaultMessage: 'Add stop code',
  },
  editStopCodeLabel: {
    id: `${scope}.label.editstopcode`,
    defaultMessage: 'Edit stop code',
  },
  sortOrderColumnLabel: {
    id: `${scope}.stopreasoncodesgrid.label.sortordercolumn`,
    defaultMessage: 'Sort order',
  },
});