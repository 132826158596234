import React from "react";
import { Box, Button } from "@mui/material";
import AxionControls from "../../../components/controls/AxionControls";
import { FormattedMessage } from "react-intl";
import messages from "./messages";

export default function AddEditToolDialog(props) {

    return (
        <Box 
            sx={{
                p:1,
                m:1
            }}>
                <Box sx={{width: 400}}>
                    <AxionControls.AxionTextBox 
                        name="name"
                        label={<FormattedMessage {...messages.nameColumnLabel} />}
                        value={props.tool.name}
                        onChange={props.onChange}
                    />
                </Box>
                <Box sx={{width: 400}}>
                    <AxionControls.AxionTextBox 
                        name="description"
                        label={<FormattedMessage {...messages.descriptionColumnLabel} />}
                        value={props.tool.description}
                        onChange={props.onChange}
                    />
                </Box>
               <Box>
                    <Button 
                        variant="contained" 
                        sx={{
                            marginTop: 2,
                            }}
                        onClick={props.handleSave}>
                        <FormattedMessage {...messages.save} />
                    </Button>
                </Box>
        </Box>
    );
}