import { Box, Typography, useTheme, CircularProgress } from "@mui/material";
import { tokens } from "../../../assets/theme";
import React, { useState, useEffect, Components } from 'react'
import { ResponsiveBar } from '@nivo/bar';
import { linearGradientDef } from '@nivo/core'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.

const GroupedStopsChart1 = ({ data /* see data tab */ }) => {

  if (data.isLoading || data === undefined) {
    // console.log("GroupedStopsChart1: data is loading");
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="73vh">
        <CircularProgress color="secondary" />
      </Box>
    )
  };



  function processData(dataToProcess) {
    let groupedDataTemp = [];

    // if (dataToProcess.isLoading) {
    //   return groupedDataTemp;
    // }

    // console.log("GroupedStopsChart1.dataToProcess:");
    // console.log(dataToProcess);

    dataToProcess.reduce(function (res, value) {
      if (!res[value.stopReasonCodeName]) {
        res[value.stopReasonCodeName] = { stopReasonCodeName: value.stopReasonCodeName, minutesDown: 0, minutesDownColor: value.stopReasonCodeColor };
        groupedDataTemp.push(res[value.stopReasonCodeName]);
      }
      res[value.stopReasonCodeName].minutesDown += value.minutesDown;
      return res;
    }, {});
    // Order by summed up stop time
    groupedDataTemp.sort((a, b) => a.minutesDown - b.minutesDown);

    return groupedDataTemp;
  }

  if (data.isLoading || data === undefined) {
    // console.log("GroupedStopsChart1: data is loading");
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="73vh">
        <CircularProgress color="secondary" />
      </Box>
    )
  } else {
    // console.log("GroupedStopsChart1: data is loaded");

    const lineGraphSettings = {
      theme: {
        textColor: '#ffffff',
        tooltip: {
          color: '#000000'
        }
      },
    };


    // console.log("GroupedStopsChart1.data.data:");
    // console.log(data.data);

    let content = <ResponsiveBar
      data={processData(data.data)}
      keys={['minutesDown']}
      indexBy="stopReasonCodeName"
      margin={{ top: 5, right: 20, bottom: 60, left: 150 }}
      padding={0.3}
      theme={lineGraphSettings.theme}

      layout="horizontal"
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={({ id, data }) => data[`${id}Color`]}

      defs={[
        linearGradientDef('barGradient', [
          { offset: 0, color: 'inherit' },
          { offset: 50, color: 'inherit' },
          { offset: 100, color: '#dddddd' },
        ],
          {
            gradientTransform: 'rotate(225 0.5 0.5)'
          }),
        {
          id: 'col',
          background: 'inherit',
          color: "inherit",
          size: 4,
          padding: 1,
          stagger: true
        }
      ]}
      fill={[
        { match: { id: 'minutesDownz' }, id: 'barGradient' }
      ]}
      borderColor={{
        from: 'color',
        modifiers: [
          [
            'darker',
            1.6
          ]
        ]
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Stop time',
        legendPosition: 'middle',
        legendOffset: 32,
        truncateTickAt: 0,
        color: '#ff0000'
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: '',
        legendPosition: 'middle',
        legendOffset: -40,
        truncateTickAt: 0
      }}
      enableGridX={true}
      enableGridY={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      role="application"
      ariaLabel="Nivo bar chart demo"
      barAriaLabel={e => e.stopReasonCodeName + ": " + e.minutesDown}
    />

    return content;

  }

}

export default GroupedStopsChart1;
