import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { inputLabelClasses } from "@mui/material/InputLabel";


  const RedditTextField = styled((props) => (
    <TextField InputProps={{ disableUnderline: true }} {...props} />
  ))(({ theme, props }) => ({
    '& .MuiFilledInput-root': {
      overflow: 'hidden',
      borderRadius: 4,
      color: "#000000",
      backgroundColor: theme.palette.mode === 'light' ? '#ffffff !important'  : '#ffffff !important',
      border: '2px solid',
      borderColor: theme.palette.mode === 'light' ? '#799c4a !important' : '#799c4a !important',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      '&:hover': {
        backgroundColor: '#ffffff',
      },
      '&.Mui-disabled': {
        // set the color of the label when shrinked (usually when the TextField is focused)
        
        backgroundColor: "gray !important"
      },
      '&.Mui-focused': {
        //backgroundColor: 'transparent',
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
        //borderColor: theme.palette.primary.main,
        color: "#000000"
      },
      '& .MuiInputLabel-root': {
        //backgroundColor: 'transparent',
        color: "#000000"
      },
      '& .Mui-disabled': {
        //backgroundColor: 'transparent',
        color: "#000000"
      },
      //'& .MuiInputBase-input ': {
      //  padding: props.label ? '12px' : '0px',  
      //}
    },
  }));

  export default function AxionTextBox(props) {
    const { name, sx, label, value, error=null, size, onChange, disabled, inputProps, ...other } = props;

    return (

        <FormControl fullWidth variant="standard" sx={sx}>
               <RedditTextField
                  label={label}
                  defaultValue={value}
                  fullWidth
                  id={name}
                  size="small"
                  variant="filled"
                  style={{ marginTop: 11 }}
                  disabled={disabled}
                  InputProps={inputProps}
                  InputLabelProps={{
                    sx: {
                      // set the color of the label when not shrinked
                      color: "#799c4a",
                      [`&.${inputLabelClasses.shrink}`]: {
                        // set the color of the label when shrinked (usually when the TextField is focused)
                        color: "#799c4a"
                      },
              
                    }
                  }}
                  onChange={onChange}
                />
        </FormControl>

    );
  };

