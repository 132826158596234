import { Box, Button, IconButton, Typography, useTheme, FormControl, RadioGroup, FormControlLabel, Radio, Grid, Stack } from "@mui/material";
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { tokens } from "../../assets/theme";
import ActivitiesDashboard from "./components/ActivitiesDashboard";
import React, { useState, useEffect } from "react";
import ActivitiesGrid from "./components/activitiesGrid";
import { useQuery } from "@tanstack/react-query";
import { getAllActivities, getRecurringSchedules } from "./hooks/fetchers";
import CircularProgress from "@mui/material/CircularProgress";
import InstructionIndex from "../instructions/index";
import RecurringActivitiesGrid from "./components/RecurringActivitiesGrid";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { standardInitialState } from "./components/gridInitialStates";
import messages from "./components/messages";
import { FormattedMessage } from "react-intl";
import { getTimespan } from "../../fetchers/timespan";
import GridDateTimePicker from "../home/components/GridDateTimePicker";
import ActivityDepartmentGrid from "./components/activityDepartmentsGrid";
import { fetchAreas } from "../../fetchers/area";
import { fetchMachineTypes } from "../../fetchers/machineType";
import AxionControls from "../../components/controls/AxionControls";
import { pushErrorMessage } from '../../utils/notifications';
import MachineSettingsDialog from "../machineDetails/components/machineSettingsDialog";
import Popup from "../../components/Popup";
import { fetchMachines,patchMachineData } from "../../fetchers/machine";
import { getStopReasonCodes } from "../stopReasonCodes/hooks/fetchers";

function isDateValid(dateStr) {
  return !isNaN(new Date(dateStr));
}

function convertDate(date) {
  const utcDate = new Date(date);
  const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000);
  const localSwedishTimeString = localDate.toLocaleString('sv-SE', {
    timeZone: 'Europe/Stockholm',
    hour12: false,
  });
  return new Date(localSwedishTimeString).toISOString().slice(0,19);
}
const StyledTabs = styled((props) => (

    <Tabs
      {...props}
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
  ))({
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#799c4a',
    },
  });
  
  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(

    ({ theme }) => ({
      
      textTransform: 'none', 
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(20),
      marginRight: theme.spacing(1),
      //backgroundColor: '#799c4a',
      color: 'rgba(255, 255, 255, 0.7)',
      '&.Mui-selected': {
        color: '#fff',
        backgroundColor: "#434957 !important",
      },
      '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
      },
      '&.MuiTab-root': {
        backgroundColor: '#0c101b',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        
      },

    }),
  );

  const ActivitiesIndex = (props) => {
  const [value, setValue] = useState(0);
  const [companyKey, setCompanyKey] = useLocalStorage("companyKey", 1);
  const [activityFilter, setActivityFilter] = useState("");
  const [timeSpan, setTimeSpan] = useState("24h");
  const [selectedValue, setSelectedValue] = React.useState(timeSpan);
  const [timeSpanStartDate, setTimeSpanStartDate] = React.useState(null);
  const [timeSpanEndDate, setTimeSpanEndDate] = React.useState(null);
  const [showDatepicker, setShowDatePicker] = useState(timeSpan === 'manual');
  const [statusFilter, setStatusFilter] = useState("");
  const [openSettingsPopup, setOpenSettingsPopup] = useState(false);
  const [machineSettings, setMachineSettings] = useState({});
  const [showBackdrop, setShowBackdrop] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  

  const activitiesQuery = useQuery(
    ["allActivities", companyKey, timeSpanStartDate, timeSpanEndDate],
    () => getAllActivities( companyKey, 
                            timeSpan === "manual" ? convertDate(timeSpanStartDate) : timeSpanStartDate, 
                            timeSpan === "manual" ? convertDate(timeSpanEndDate) : timeSpanEndDate),
    {
      staleTime: 120000,
      refetchInterval: 120000,
      enabled: timeSpanStartDate !== null && timeSpanEndDate !== null,
    }
  );

  const recurringSchedules = useQuery(
    ["recurringSchedules"],
    () => getRecurringSchedules(companyKey),
    {
      staleTime: 60000,
      refetchInterval: 120000
    }
  );

  const DatesForTimespanQuery = useQuery(
    ["datesForTimespan", timeSpan, companyKey],
    () => getTimespan(timeSpan, companyKey),
    {
      staleTime: Infinity,
    }
  );

  const machinesQuery = useQuery({
    queryKey: ['machines', companyKey], 
    queryFn: () => fetchMachines(companyKey),
    staleTime: 60000,
    refetchInterval: 60000,
  });

  const stopReasonCodesQuery = useQuery(
      ["stopReasonCodes", companyKey],
      () => getStopReasonCodes(companyKey));

  const aresQuery = useQuery(
      ["areas"],
      () => fetchAreas(),
      {
          staleTime: 60000,
          refetchInterval: 60000
      });

  const machineTypes = useQuery(
      ["machineTypes"],
      () => fetchMachineTypes());

  useEffect(() => {
    if (selectedValue === "week" && !props.showWeek) {
      setSelectedValue("currentshift");
      setTimeSpan("currentshift");
    }
  }, [selectedValue, timeSpan,timeSpanStartDate]);

  useEffect(() => {
    if (DatesForTimespanQuery.isFetched && !DatesForTimespanQuery.isError && DatesForTimespanQuery.data !== undefined && timeSpan !== "manual") {
      setTimeSpanStartDate(DatesForTimespanQuery.data[0].fromDate);
      setTimeSpanEndDate(DatesForTimespanQuery.data[0].toDate);
    }
  }, [DatesForTimespanQuery]);

  let filteredActivitiesResult = activitiesQuery.data?.filter((activity) => {
    if(activityFilter === "") return true;
    else if(activity?.machine?.machineName?.toLowerCase().includes(activityFilter.toLowerCase())) return true;
    else if(activity?.machine?.machineType?.machineTypeName?.toLowerCase().includes(activityFilter.toLowerCase())) return true;
    else if(activity?.title?.toLowerCase().includes(activityFilter.toLowerCase())) return true;
    else if(activity?.tool?.name?.toLowerCase().includes(activityFilter.toLowerCase())) return true;
    else if(activity?.productionItem?.itemNo?.toLowerCase().includes(activityFilter.toLowerCase())) return true;

    return false;
  });

  filteredActivitiesResult = filteredActivitiesResult?.filter((activity) => {
    if(statusFilter === "") return true;
    else if(activity?.activityStatusId === statusFilter) return true;
    return false;
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEditSettingsClick = (id) => {
    setMachineSettings(machinesQuery.data.find((machine) => machine.id === id));
    setOpenSettingsPopup(true);
  }

  const handleSettingsSave = () => {
      setShowBackdrop(true);
      patchMachineData(machineSettings).then(() => {
          setOpenSettingsPopup(false);
          machinesQuery.refetch();
          setShowBackdrop(false);
      })
          .catch((error) => {
          console.log(error);
          setShowBackdrop(false);
          pushErrorMessage(`Could not update the machine settings.`);
      });
  }

  const handleSettingsCancel = () => {
      setOpenSettingsPopup(false);
  }

  //Change handle for radio buttons when selecting new timespan for api fetch.
  const handleTimeSpanChange = (event) => {
    setSelectedValue(event.target.value);
    setTimeSpan(event.target.value);
    //Check if manual timespan selected, if selected then show datepickers.
    if (event.target.value !== "manual") {
      setShowDatePicker(false);
    } else {
      setShowDatePicker(true);
    }
  };

  const handleStartDateChange = (event) => {
    if (isDateValid(event)) {
      let startDate = new Date(event);
      setTimeSpanStartDate(startDate.toISOString());
    }
  };
  
  const handleEndDateChange = (event) => {
    if (isDateValid(event)) {
      let endDate = new Date(event);
      setTimeSpanEndDate(endDate.toISOString());
    }
  };
  
  if (activitiesQuery.isLoading || recurringSchedules.isLoading ) {
    return <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}><CircularProgress color="secondary" /></div>;
  }

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleTimeSpanChange,
    value: item,
    name: "size-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  let radiobuttons = (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel
          value="currentShift"
          control={
            <Radio
              {...controlProps("currentshift")}
              sx={{
                color: "secondary",
                "&.Mui-checked": {
                  color: colors.dashbordGreen[500],
                },
              }}
            />
          }
          color="success"
          label={<FormattedMessage {...messages.currentShiftFilter} />}
        />
        <FormControlLabel
          value="prevShift"
          control={
            <Radio
              {...controlProps("prevShift")}
              sx={{
                color: "secondary",
                "&.Mui-checked": {
                  color: colors.dashbordGreen[500],
                },
              }}
            />
          }
          label={<FormattedMessage {...messages.prevShiftFilter} />}
        />
        <FormControlLabel
          value="24h"
          control={
            <Radio
              {...controlProps("24h")}
              sx={{
                color: "secondary",
                "&.Mui-checked": {
                  color: colors.dashbordGreen[500],
                },
              }}
            />
          }
          label={<FormattedMessage {...messages.dayFilter} />}
        />
        <FormControlLabel
          value="72h"
          control={
            <Radio
              {...controlProps("72h")}
              sx={{
                color: "secondary",
                "&.Mui-checked": {
                  color: colors.dashbordGreen[500],
                },
              }}
            />
          }
          label={<FormattedMessage {...messages.threeDayFilter} />}
        />

        {props.showWeek && (
          <FormControlLabel
            value="week"
            control={
              <Radio
                {...controlProps("week")}
                sx={{
                  color: "secondary",
                  "&.Mui-checked": {
                    color: colors.dashbordGreen[500],
                  },
                }}
              />
            }
            label={<FormattedMessage {...messages.weekFilter} />}
          />
        )}

        { !props.hideManual && <FormControlLabel
          value="manual"
          control={<Radio {...controlProps("manual")} sx={{
            color: "secondary",
            '&.Mui-checked': {
              color: colors.dashbordGreen[500],
            },
          }} />}
          label={<FormattedMessage {...messages.manualFilter} />}
        />}
      </RadioGroup>
    </FormControl>
  );

  const datepicker = (
      <Grid item xs={12} lg={6} sx={{ display: "flex", paddingRight: "10px" }}>
        <Stack direction="row" width="100%" spacing={2}>
          {showDatepicker && (<><GridDateTimePicker
            label={<FormattedMessage {...messages.fromDate} />}
            labelTime={<FormattedMessage {...messages.fromTime} />}
            locale="sv"
            maxDate={Date().toLocaleString()}
            minDate=""
            onChange={handleStartDateChange}
            date={timeSpanStartDate}
          />
            <GridDateTimePicker
              label={<FormattedMessage {...messages.toDate} />}
              labelTime={<FormattedMessage {...messages.toTime} />}
              locale="sv"
              maxDate={Date().toLocaleString()}
              minDate=""
              onChange={handleEndDateChange}
              date={timeSpanEndDate}
            /> </>)}

        </Stack>
      </Grid>
);

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', m:'10px' }}>
          <StyledTabs value={value} onChange={handleChange} aria-label="Change between tabs">
            <StyledTab label={<FormattedMessage {...messages.dashboard} />} />
            <StyledTab label={<FormattedMessage {...messages.instructions} />} />
            <StyledTab label={<FormattedMessage {...messages.recurringActivities} />} />
            <StyledTab label={<FormattedMessage {...messages.departments} />} />
            <StyledTab label={<FormattedMessage {...messages.allActivities} />} />
          </StyledTabs>
        </Box>
        <Grid item xs={12} lg={6} sx={{ justifyContent: "end", display: "flex", paddingTop: "21px", paddingRight: "20px" }}>
          {datepicker}
          {radiobuttons}
        </Grid>
        <Box>
          {value === 0 && <ActivitiesDashboard 
                              recurringSchedules = {recurringSchedules} 
                              activitiesResult={activitiesQuery} 
                              companyKey={companyKey} 
                              timeSpanStartDate={timeSpanStartDate} 
                              timeSpanEndDate={timeSpanEndDate} />}
          {value === 1 && <InstructionIndex />}
          {value === 2 && <RecurringActivitiesGrid recurringSchedules = {recurringSchedules} />}
          {value === 3 && (
            <>
              <ActivityDepartmentGrid
                machineResult={machinesQuery} 
                handleEditSettingsClick={handleEditSettingsClick}
                 />
            </>)}
          {value === 4 && <>
                            <Box ml={2} mr={2}>
                              <ActivitiesGrid 
                                activitiesResult={activitiesQuery} 
                                filteredResult={filteredActivitiesResult} 
                                InitialState = {standardInitialState} 
                                activityFilter={activityFilter} 
                                statusFilter={statusFilter}
                                setActivityFilter={setActivityFilter}
                                setStatusFilter={setStatusFilter}
                                hideStatusFilter={false} />
                            </Box>
                          </>}
        </Box>
      </Box>
      <Popup
          openPopup={openSettingsPopup}
          setOpenPopup={setOpenSettingsPopup}
          title={<FormattedMessage {...messages.editSettings} />}>
          <MachineSettingsDialog
            setOpenPopup={setOpenSettingsPopup}
            stopReasonCodes={stopReasonCodesQuery.data}
            machineData={machineSettings}
            areas={aresQuery.data}
            machineTypes={machineTypes.data}
            setMachineSettings={setMachineSettings}
            onSettingsSave={handleSettingsSave}
            onSettingsCancel={handleSettingsCancel} />
        </Popup>
        <AxionControls.AxionBackdrop open={showBackdrop} />
    </>
  );
};

export default ActivitiesIndex;
