import axios from "axios";
import dayjs from "dayjs";  

export const fetchOeeDashboardData = async () => {
    console.log("fetching oee dasboard data");
    const response = await axios.get("api/api/getoeepershift");
    const oeeDashboardData = response.data;
    console.log("OEE Data", oeeDashboardData);
    return oeeDashboardData;
}


export const fetchOeeDashboardDataTimeSpan = async (timeSpan) => {
    console.log("fetching oee dasboard data");
    const response = await axios.get("api/api/getoeetimespan/"+timeSpan);
    const oeeDashboardData = response.data;
    console.log("OEE Data", oeeDashboardData); 
    return oeeDashboardData;
}

export const fetchOeeDashboardDataTimeSpanDev = async (timeSpan,companyKey) => {
    console.log("fetching oee dasboard data");
    const response = await axios.get("api/api/getoeetimespandev/"+companyKey+"/"+timeSpan);
    const oeeDashboardData = response.data;
    console.log("OEE Data", oeeDashboardData); 
    return oeeDashboardData;
}

export const fetchOeeGridDateIntervall = async(startDate,endDate,companyKey) => {
    console.log("fetching OEE grid data");
    const response = await axios.get("api/api/getoeedateintervall/"+companyKey+"/startDate="+dayjs(startDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")+"&endDate="+dayjs(endDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
    const oeeGridData = response.data;
    return oeeGridData;
    //https://localhost:7008/api/api/getoeedateintervall/1/startDate=2023-01-03T10:32:30.456Z&endDate=2023-02-03T10:32:30.456Z
}


export const updateMachinePriority = async (priority) => {
    return await axios.post(`api/api/updateMachinePriority/${priority.machineName}`,priority);
}

