import { Box, IconButton, useTheme, useMediaQuery } from "@mui/material";
import { useContext, useState } from "react";
import { ColorModeContext, tokens } from "../assets/theme";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useSelector, useDispatch } from "react-redux";
import { setLanguage, setLanguageFile } from "../context/languageProvider/reducer";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CompanySelector from "../components/sidebar/companySelector";
import { useNavigate } from 'react-router-dom';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { useLocalStorage } from "../hooks/useLocalStorage";

const Topbar = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const language = useSelector((state) => state.language.language);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const url = window.location.href;
  const showViewModeSelector = url.includes('gantt');
  const [ganttDarkMode, setGanttDarkMode] = useLocalStorage("ganttDarkMode", "true");
  const isOnMobile = useMediaQuery(theme => theme.breakpoints.down('lg'));

  const languages = [
    {
      label: "English",
      src: "https://flagcdn.com/w20/us.png",
      value: "en",
      key: 1,
      file: '../../translations/en.json'
    },
    {
      label: "Svenska",
      src: "https://flagcdn.com/w20/se.png",
      value: "sv",
      key: 2,
      file: '../../translations/sv.json'
    },
  ];

  //HANDLERS
  const handleLanguageChange = (event) => {
    dispatch(setLanguage(event.target.value));
    dispatch(setLanguageFile(languages.filter(t => t.value === event.target.value).map(e => e.file)));
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleNavigateSettings = () => {
    navigate('/settings');
  }

  const handleCollapse = () => {
    props.setIsCollapsed(!props.isCollapsed);
  }

  const toggleGantDarkMode = () => {
    if (ganttDarkMode === "true") {
      let f = "false";
      setGanttDarkMode(f);
    } else {
      let t = "true";
      setGanttDarkMode(t);
    }
    window.location.reload(true);
  }

  return (
    <Box display="flex" justifyContent="space-between" paddingTop={2} paddingLeft={2} paddingRight={2}>
      <Box 
          sx={{
            display: "flex",
            justifyContent: "flex-start"
          }}>
      { (isOnMobile && props.isCollapsed) &&
        <IconButton onClick={ handleCollapse }>
          <MenuOutlinedIcon />
        </IconButton>
      }
      <Box
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
        sx={{
          marginRight: "10px",
        }}
      >
      <Select
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={languages.filter(e => e.value === language).map(e => e.value)}
          name="country"
          //sx={{marginRight: "50px"}}
          onChange={handleLanguageChange}
          size="small"
          inputProps={{
            id: "open-select",
          }}
        >
          {languages.map((option, key) => (
            <MenuItem value={option.value} key={key}>
              <img loading="lazy" src={option.src} alt={option.label} />{"   "} <span>{option.label}</span>
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        <CompanySelector />
      </Box>
      </Box>
      {/* SEARCH BAR */}
      {/* <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
        justifyContent={"left"}
      >
 
        <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box> */}
      <Box display="flex" >
      {showViewModeSelector && < IconButton onClick={toggleGantDarkMode}>
          {ganttDarkMode === 'true' ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>}

        {/* < IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton> */}
        
        
{/*
        <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton> */}

        <IconButton onClick={ handleNavigateSettings }>
          <SettingsOutlinedIcon />
        </IconButton>

        {/* <IconButton>
          <PersonOutlinedIcon />
        </IconButton>

        <IconButton>
          <SearchOutlinedIcon />
        </IconButton> */}
      </Box>
    </Box>
  );
};

export default Topbar;
