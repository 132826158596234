import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, Typography, useTheme } from '@mui/material';
import Controls from "./controls/Contols";
import CloseIcon from '@mui/icons-material/Close';
import { tokens } from "../assets/theme";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
export default function Popup(props) {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { title, children, openPopup, setOpenPopup, maxWidth } = props;

    return (
        <Dialog 
          open={openPopup} 
          fullWidth={true}
          maxWidth={maxWidth ? maxWidth : 'md'}
        >
            <DialogTitle sx={{backgroundColor: colors.primary[400]}}>
                <div style={{ display: 'flex' }}>
                <InfoOutlinedIcon sx={{marginRight: "10px", marginTop:"5px"}}/>
                    <Typography variant="h5" component="div" style={{ flexGrow: 1, marginTop:"5px" }}>
                       {title}
                    </Typography>
                    <Controls.ActionButton
                        sx={{   "&:hover": {
                            backgroundColor: colors.redAccent[700],
                          },backgroundColor: colors.redAccent[500],color: colors.primary[100]}}
                        onClick={()=>{setOpenPopup(false)}}>
                        <CloseIcon />
                    </Controls.ActionButton>
                </div>
            </DialogTitle>
            <DialogContent sx={{backgroundColor: colors.primary[400]}} dividers>
                {children}
            </DialogContent>
        </Dialog>
    )
}