import { defineMessages } from 'react-intl';

export const scope = 'axionoee.features.splitmachinestop.index';

export default defineMessages({
    originalStop: {
        id: `${scope}.label.originalStop`,
        defaultMessage: 'Original stop',
    },
    machineName: {
        id: `${scope}.label.machineName`,
        defaultMessage: 'Machine name',
    },
    fromDate: {
        id: `${scope}.label.fromDate`,
        defaultMessage: 'From date',
    },
    fromTime: {
        id: `${scope}.label.fromTime`,
        defaultMessage: 'From time',
    },
    toDate: {
        id: `${scope}.label.toDate`,
        defaultMessage: 'To date',
    },
    toTime: {
        id: `${scope}.label.toTime`,
        defaultMessage: 'To time',
    },
    stopReason: {
        id: `${scope}.label.stopReason`,
        defaultMessage: 'Stop reason',
    },
    comment: {
        id: `${scope}.label.comment`,
        defaultMessage: 'Comment',
    },
    splitStop: {
        id: `${scope}.label.splittedStop`,
        defaultMessage: 'Split stop',
    },
    add: {
        id: `${scope}.label.add`,
        defaultMessage: 'Add',
    },
    splittedStop: {
        id: `${scope}.label.splittedStop`,
        defaultMessage: 'Splitted stop',
    },
    type: {
        id: `${scope}.label.type`,
        defaultMessage: 'Type',
    },
    updated: {
        id: `${scope}.label.updated`,
        defaultMessage: 'Updated',
    },
    new: {
        id: `${scope}.label.new`,
        defaultMessage: 'New',
    },
    save: {
        id: `${scope}.label.save`,
        defaultMessage: 'Save',
    },
    reset: {
        id: `${scope}.label.reset`,
        defaultMessage: 'Reset',
    },
    cancel: {
        id: `${scope}.label.cancel`,
        defaultMessage: 'Cancel',
    },
    activityTriggered: {
        id: `${scope}.label.activityTriggered`,
        defaultMessage: 'An activity was created when the stop was updated.',
    },
})