import { defineMessages } from 'react-intl';

export const scope = 'axionoee.features.instructions.index';
export default defineMessages({
    formHeader: {
      id: `${scope}.form.header`,
      defaultMessage: 'Create Instruction',
    },
    fieldTitle: {
        id: `${scope}.form.field.title`,
        defaultMessage: 'Title',
      },
      fieldInstructionType: {
        id: `${scope}.form.field.instructionType`,
        defaultMessage: 'Instruction Type',
      },
      fieldItem: {
        id: `${scope}.form.field.item`,
        defaultMessage: 'Item',
      },
      fieldDragAndDrop: {
        id: `${scope}.form.field.dragAndDrop`,
        defaultMessage: 'Drag & drop a picture or video here, or click to select files',
      },
      buttonAddStep: {
        id: `${scope}.form.button.addStep`,
        defaultMessage: 'Add Step',
      },
      buttonDeleteStep: {
        id: `${scope}.form.button.deleteStep`,
        defaultMessage: 'Delete Step',
      },
      buttonSubmit: {
        id: `${scope}.form.button.submit`,
        defaultMessage: 'Submit',
      },
      textStep: {
        id: `${scope}.form.text.step`,
        defaultMessage: 'Step',
      },
      fieldCreatedBy: {
        id: `${scope}.form.field.createdBy`,
        defaultMessage: 'Created by',
      },
      createStepsBelow: {
        id: `${scope}.form.text.createStepsBelow`,
        defaultMessage: 'Create steps below',
      },
      textEndOfStep: {
        id: `${scope}.form.text.endOfStep`,
        defaultMessage: 'End of step',
      },
      validationTitle: {
        id: `${scope}.form.text.validationTitle`,
        defaultMessage: 'Title is a required field',
      },
      validationText1: {
        id: `${scope}.form.text.validationText1`,
        defaultMessage: 'Descriptive text for step ',
      },
      validationText2: {
        id: `${scope}.form.text.validationText2`,
        defaultMessage: ' is a required field',
      },
      latestUpdatedBy: {
        id: `${scope}.form.text.latestUpdatedBy`,
        defaultMessage: 'Latest update by ',
      },
});