import axios from "axios";

export const getTimespan = async (timeSpanType, companyKey) => {
    
    if(timeSpanType === "manual") return null;  
    
    const response = await axios.get(`api/api/getdatesfortimespan?timeSpan=${timeSpanType}&companyKey=${companyKey}`);
    const datesForTimespan = response.data;
    
    return datesForTimespan;
}