import React, { useState, useEffect, useCallback } from "react";
import { fetchMaterialConnections } from "../hooks/fetchers";
import { tokens } from "../../../assets/theme";
import {
  Box,
  Typography,
  useTheme,
  Stack,
  CircularProgress,
  Grid,
  Button,
} from "@mui/material";
import {
  useQuery,
  useMutation,
  useQueryClient,
  useQueries,
} from "@tanstack/react-query";
import {
  DataGridPro,
  GridToolbar,
  GridActionsCellItem,
  GridLogicOperator,
  useGridApiRef,
  gridFilterModelSelector,
} from "@mui/x-data-grid-pro";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { renderProgress } from "./progressBar";
import PropTypes from "prop-types";
import MaterialConnectionDialog from "./MaterialConnectionDialog";
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import dayjs from "dayjs";
import GridDatePicker from  "../../oeegrid/components/GridDatePicker"  //components/GridDatePicker";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}



CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};
//import CircularProgress from '@mui/material/CircularProgress';

const CommentInput = ({value,onChange}) => (<input defaultValue={value} onChange={onChange} />)

const MaterialConnectionGrid = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [companyKey, setCompanyKey] = useState(1);
  const [show, setShow] = useState(false);
  const [machineId, setMachineId] = useState();
  const [connectionPointId, setConnectionPointId] = useState();
  const [selectedRow, setSelectedRow] = useState(null);
  const [showDatepicker, setShowDatePicker] = useState(false);
  const [startDate, setStartDate] = React.useState(dayjs());
  const [endDate, setEndDate] = React.useState(dayjs());
  const [timeSpan, setTimeSpan] = useLocalStorage("timespan", "currentshift");
  const [selectedValue, setSelectedValue] = React.useState(timeSpan);
      //Handle switch button states.

  // const toggleShow = (id) => {
  //   setShow(p => !p);
  // }
  const gridApi = useGridApiRef();
  const callbackModal = () => {
    setShow(false);
  };
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    //Check if manual timespan selected, if selected then show datepickers.
    if (event.target.value !== "manual") {
      setTimeSpan(event.target.value);
      setShowDatePicker(false);
    } else {
      setShowDatePicker(true);
    }
  };
  const [filtersStorage, setFiltersStorage] = useLocalStorage("materialFilterStorage", {
    items: [],
    linkOperator: GridLogicOperator.And,
  });


  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "size-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  function endDateChangeHandler(date) {
    console.log("endDateChangeHandler");
    setEndDate(date);
    //console.log(endDate);
  }
  function startDateChangeHandler(date) {
    console.log("startDateChangeHandler");
    setStartDate(date);
    //dateQuery.refetch();
    //console.log(startDate);
  }


  //Handle filter switches.
  const filterRunning = (newValue) => {
    const thisFilter = {
      columnField: "machineStatus",
      operatorValue: "equals",
      value: "Running",
      id: 2023,
    };

    if (filtersStorage.items.length !== 0) {
      var runningFilter = {
        ...filtersStorage,
        items: [
          ...filtersStorage.items.filter(
            (items) => items.id !== 2024 && items.id !== 2025
          ),
          thisFilter,
        ],
      };
    } else {
      runningFilter = {
        ...filtersStorage,
        items: [thisFilter],
      };
    }

    //setFilters(runningFilter);
    setFiltersStorage(runningFilter);

    if (gridApi.current) {
      gridApi.current.setFilterModel(runningFilter);
    }
  };
  const unfilterRunning = (newValue) => {
    const state = gridApi.current.state;
    const filterModel = gridFilterModelSelector(state);
    console.log(filterModel);
    if (filterModel.items.length !== 0) {
      const itemToDelete = filterModel.items.filter((list) => list.id === 2023);
      const newState = filterModel.items.filter((list) => list.id !== 2023);
      //setFilters(newState);
      setFiltersStorage(newState);

      gridApi.current.deleteFilterItem(itemToDelete[0]);
    }
  };

  const filterStopped = (newValue) => {
    const thisFilter = {
      columnField: "machineStatus",
      operatorValue: "equals",
      value: "Stopped",
      id: 2024,
    };

    if (filtersStorage.items.length !== 0) {
      var runningFilter = {
        ...filtersStorage,
        items: [
          ...filtersStorage.items.filter((items) => items.id !== 2023),
          thisFilter,
        ],
      };
    } else {
      runningFilter = {
        ...filtersStorage,
        items: [thisFilter],
      };
    }
    //setFilters(runningFilter);
    setFiltersStorage(runningFilter);

    if (gridApi.current) {
      gridApi.current.setFilterModel(runningFilter);
    }
  };

  const unfilterStopped = (newValue) => {
    const state = gridApi.current.state;
    const filterModel = gridFilterModelSelector(state);
    if (filterModel.items.length !== 0) {
      const itemToDelete = filterModel.items.filter((list) => list.id === 2024);
      const newState = filterModel.items.filter((list) => list.id !== 2024);
      //setFilters(newState);
      setFiltersStorage(newState);

      gridApi.current.deleteFilterItem(itemToDelete[0]);
    }
  };

  const filterPlannedStops = (newValue) => {
    const thisFilter = {
      columnField: "plannedStop",
      operatorValue: "equals",
      value: "0",
      id: 2025,
    };

    if (filtersStorage.items.length !== 0) {
      
      var runningFilter = {
        ...filtersStorage,
        items: [
          ...filtersStorage.items.filter((items) => items.id !== 2023),
          thisFilter,
        ],
      };
    } else {
      runningFilter = {
        ...filtersStorage,
        items: [thisFilter],
      };
    }
    //setFilters(runningFilter);
    setFiltersStorage(runningFilter);

    if (gridApi.current) {
      gridApi.current.setFilterModel(runningFilter);
    }
  };
  const unfilterPlannedStops = (newValue) => {
    const state = gridApi.current.state;
    const filterModel = gridFilterModelSelector(state);
    console.log(filterModel);
    if (filterModel.items.length !== 0) {
      const itemToDelete = filterModel.items.filter((list) => list.id === 2025);
      const newState = filterModel.items.filter((list) => list.id !== 2025);
      console.log(newState);
      console.log(itemToDelete);
      //setFilters(newState);
      setFiltersStorage(newState);

      gridApi.current.deleteFilterItem(itemToDelete[0]);
    }
  };

  //Handle switch button states.
  const [checked, setCheck] = useLocalStorage("materialGridChecked", {
    running: false,
    onlyPlanned: false,
    onlyStopped: false,
  });

  const handleCheck = (event) => {
    if (event.target.name === "running") {
      if (!checked.running) {
        setCheck({
          ...checked,
          running: true,
          onlyPlanned: false,
          onlyStopped: false,
        });
        filterRunning();
        //filterRunning();
      }
      if (checked.running) {
        setCheck({
          ...checked,
          running: false,
          // onlyPlanned : false
        });
        unfilterRunning();
      }
    }
    if (event.target.name === "onlyPlanned") {
      if (!checked.onlyPlanned) {
        if (!checked.onlyStopped) {
          setCheck({
            ...checked,
            running: false,
            onlyPlanned: true,
            onlyStopped: false,
          });
        } else {
          setCheck({
            ...checked,
            running: false,
            onlyPlanned: true,
            onlyStopped: true,
          });
        }

        filterPlannedStops();
      }
      if (checked.onlyPlanned) {
        setCheck({
          ...checked,
          onlyPlanned: false,
          // onlyPlanned : false
        });
        unfilterPlannedStops();
      }
    }
    if (event.target.name === "onlyStopped") {
      if (!checked.onlyStopped) {
        if (!checked.onlyPlanned) {
          setCheck({
            ...checked,
            running: false,
            onlyPlanned: false,
            onlyStopped: true,
          });
        } else {
          setCheck({
            ...checked,
            running: false,
            onlyPlanned: true,
            onlyStopped: true,
          });
        }

        filterStopped();
      }
      if (checked.onlyStopped) {
        setCheck({
          ...checked,
          onlyStopped: false,
          // onlyPlanned : false
        });
        unfilterStopped();
      }
    }
  };

  const toggleShow = (params) => () => {
    setShow((p) => !p);
    setMachineId(params.id);
    setSelectedRow(params.row);
    console.log(params);
  };

  const showInfoHandler = (id) => () => {
    setTimeout(() => {});
  };

  // Fetch API Data
  const { isError, isSuccess, isLoading, data, error } = useQuery(
    ["materialConnections", companyKey],
    () => fetchMaterialConnections(companyKey),
    {
      staleTime: 60000,
      refetchInterval: 60000,
    }
  );

  const columns = [
    {
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          onClick={
            toggleShow(params)
            //setSelectedRow(params.row);
          }

          //showInMenu
        />,
      ],
      width: 10,
    },
    {
      field: "machineName",
      headerName: "Machine",
      flex: 1,
    },
    {
      field: "machineStatus",
      headerName: "Status",
      width: 150,
      renderCell: ({ row: { machineStatus } }) => {
        return (
          <Box
            width="100%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              machineStatus === "Running"
                ? colors.dashbordGreen[600]
                : machineStatus === "Offline"
                ? colors.yellow[500]
                : colors.redAccent[500]
            }
            borderRadius="4px"
          >
            <Typography color={colors.gray[100]} sx={{ ml: "5px" }}>
              {machineStatus}
            </Typography>
          </Box>
        );
      },
      headerAlign: "center",
    },
    {
      field: "currentStop",
      headerName: "Stop Reason",
      flex: 1,
    },
    {
      field: "areaName",
      headerName: "Area",
      flex: 1,
    },
    {
      field: "connectionPointName",
      headerName: "Connection Point",
      renderCell: ({ row: { connectionPointName, connectionColor } }) => {
        return (
          <Box
            width="100%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={connectionColor}
            borderRadius="4px"
          >
            <Typography color={colors.gray[100]} sx={{ ml: "5px" }}>
              {connectionPointName}
            </Typography>
          </Box>
        );
      },
      flex: 1,
    },
    {
      field: "materialSourceName",
      headerName: "MaterialSource",
      flex: 1,
    },
    {
      field: "rawMaterialName",
      headerName: "Material",
      // type: "actions",
      flex: 1,
      color: "#fff",
    },
    {
      field: "isDrying",
      headerName: "Is Drying",
      type: "boolean",
      renderCell: (params) => {
        return params.value ? (
          <CheckIcon
            style={{
              color: colors.dashbordGreen[500],
            }}
          />
        ) : (
          <CloseIcon
            style={{
              color: colors.redAccent[500],
            }}
          />
        );
      },

      flex: 1,
    },
    {
      field: "dryingContainerName",
      headerName: "Drying Container",
      flex: 1,
    },
    {
      field: "dryingContainerStatus",
      headerName: "Drying",
      flex: 1,
    },
    {
      field: "dryingContainerWithdrawalWarning",
      headerName: "Warning",
      flex: 1,
      renderCell: (params) => {
        return params.value === 0 ? (
          <Box   
            sx={{
            backgroundColor: colors.dashbordGreen[600],
            }}
            width="100%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            //backgroundColor={connectionColor}
            borderRadius="4px">
          <CheckIcon
          
          />
          </Box>
        ) : (
          <Box   
          sx={{
          backgroundColor: colors.redAccent[500],
          }}
          width="100%"
          m="0 auto"
          p="5px"
          display="flex"
          justifyContent="center"
          //backgroundColor={connectionColor}
          borderRadius="4px">
          <WarningAmberIcon
         
          />
          </Box>
        );
      },
    },
    {
      field: "dryingContainerMaximumWithdrawal",
      headerName: "Maximum Withdrawal",
      flex: 1,
    },
    {
      field: "demandRightNow",
      headerName: "Demand",
      flex: 1,
    },
    {
      field: "comment",
      headerName: "Comment",
      flex: 1,
    },
    {
      field: "kgLeft",
      headerName: "Kg Left",
      flex: 1,
    },
    {
      field: "kgUsed",
      headerName: "Kg Used",
      flex: 1,
    },
    {
      field: "kgsPlanned",
      headerName: "Kg Planned",
      flex: 1,
    },
    // {
    //   field: "percentageDone",
    //   headerName: "Percentage Done",
    //   renderCell: ({ row: { percentageDone } }) => {
    //     return (
    //       <CircularProgressWithLabel
    //         value={Number.parseFloat(percentageDone).toFixed(2)}
    //       />
    //     );
    //   },
    //   type: "number",
    //   flex: 1,
    // },
    {
      field: "connectionColor",
      headerName: "Color",
      flex: 1,
    },
    {
      field: "plannedStop",
      headerName: "plannedStop",
      flex: 1,
    },
  ];
  let radiobuttons;

 
  if (isLoading) {
    return <CircularProgress color="secondary" />;
  }
  if (isSuccess) {
    return (
      <>
      <Box m="20px" maxHeight={500} sx={{ backgroundColor: colors.primary[700] }}>
      <Box>
        <Grid container spacing={0} sx={{ paddingTop: "0px" }}>
          <Grid item xs={8}>
            <Stack direction="row" width="100%" marginLeft={2} spacing={2}>
              <FormControlLabel
                control={
                  <Switch
                    checked={checked.running}
                    color="secondary"
                    onChange={handleCheck}
                    name="running"
                  />
                }
                label="Only Running"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={checked.onlyPlanned}
                    color="secondary"
                    onChange={handleCheck}
                    name="onlyPlanned"
                  />
                }
                label="Don't show planned stops"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={checked.onlyStopped}
                    color="secondary"
                    onChange={handleCheck}
                    name="onlyStopped"
                  />
                }
                label="Only stopped"
              />

              {/* DatePickers */}
              {showDatepicker && (
                <>
                  <GridDatePicker
                    label="From Date"
                    locale="sv"
                    maxDate={Date().toLocaleString()}
                    minDate=""
                    onChange={startDateChangeHandler}
                    date={startDate}
                  />
                  <GridDatePicker
                    label="To Date"
                    locale="sv"
                    maxDate={Date().toLocaleString()}
                    minDate=""
                    onChange={endDateChangeHandler}
                    date={endDate}
                  />
                </>
              )}
            </Stack>
          </Grid>
          <Grid item xs={4} sx={{ justifyContent: "end", display: "flex" }}>
        
          </Grid>
        </Grid>
      </Box>
        <Box
          m="20px"
          height="92vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              // borderBottom:"none",
            },
            "& .name.column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-cellContent": {
              fontSize: "13px",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.primary[600],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.primary[600],
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.gray[100]} !important`,
            },
            "& .super-app-theme--11": {
              bgcolor: `${colors.redAccent[500]} !important`,
            },
            "& .MuiDataGrid-columnHeader": {
              fontSize: "15px",
            },
          }}
        >
          <DataGridPro
            rows={data}
            apiRef={gridApi}
            loading={isLoading}
            experimentalFeatures={{ newEditingApi: true }}
            components={{
              Toolbar: GridToolbar,
            }}
            filterModel={filtersStorage}
            // //maxHeight={1}
            // //rowHeight={50}
            onFilterModelChange={(newValue) => {
              //setFilters(newValue);
              setFiltersStorage(newValue);
              //console.log(filters);
            }}
            columns={columns}
            getRowId={(row) => row.machineId}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  connectionColor: false,
                  // prioritySortOrder: false,
                  // plannedQty: false,
                  // reportedQty: false,
                  // remainingQty: false,
                  // uptime: false,
                  // downTime: false,
                },
              },
              //sorting: {sortModel: [{ field: 'areaName', sort: 'asc' },{ field: 'prioritySortOrder', sort: 'asc' }]},
            }}
          />
        </Box>
        <MaterialConnectionDialog
          show={show}
          toggleShow={toggleShow}
          machineId={machineId}
          callback={callbackModal}
          rowData={selectedRow}
        />
        </Box>
      </>
    );
  }
};

export default MaterialConnectionGrid;
