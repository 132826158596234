import React, { useEffect } from 'react';
import axios from 'axios';
import { useMsal, useAccount } from '@azure/msal-react';
import { loginRequest } from './authConfig'; // Ensure you have this import

const RequestInterceptor = ({ children }) => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0]);

    useEffect(() => {
        if (!account) {
            throw Error('No active account!');
        }

        const interceptor = axios.interceptors.request.use(async (config) => {
            try {
                const response = await instance.acquireTokenSilent({
                    ...loginRequest,
                    account,
                });

                const bearer = `Bearer ${response.accessToken}`;
                config.headers['Authorization'] = bearer;
                return config;
            } catch (error) {
                // Handle token acquisition errors
                console.error('Token acquisition failed', error);
                throw error;
            }
        });

        return () => {
            axios.interceptors.request.eject(interceptor);
        };
    }, [instance, account]);

    return <>{children}</>;
};

export default RequestInterceptor;