import { defineMessages } from 'react-intl';

export const scope = 'axionoee.features.embeddedreports.components';

export default defineMessages({
    save: {
        id: `${scope}.label.save`,
        defaultMessage: 'Save',
    },
    addEmbeddedReportLabel: {
        id: `${scope}.label.addEmbeddedReport`,
        defaultMessage: 'Add embedded report',
    },
    editEmbeddedReportLabel: {
        id: `${scope}.label.editEmbeddedReport`,
        defaultMessage: 'Edit embedded report',
    },
    deleteEmbeddedReportLabel: {
        id: `${scope}.label.deleteEmbeddedReport`,
        defaultMessage: 'Delete embedded report',
    },
    reportNameColumnLabel: {
        id: `${scope}.grid.column.reportName`,
        defaultMessage: 'Report Name',
    },
    embeddedUrlColumnLabel: {
        id: `${scope}.grid.column.embeddedUrl`,
        defaultMessage: 'Embedded Url',
    },
    sortOrderColumnLabel: {
        id: `${scope}.grid.column.sortOrder`,
        defaultMessage: 'Sort Order',
    },
});