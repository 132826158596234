import React from "react";
import { useState } from "react";
import { Box, Paper, useTheme } from '@mui/material';
import { tokens } from "../../../assets/theme";
import { DataGrid } from "@mui/x-data-grid";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import AxionControls from "../../../components/controls/AxionControls";
import { fetchPlcLogs } from "../../../fetchers/plclog";
import { useQuery } from "@tanstack/react-query";

export default function PlcLogDialog(props) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
    const [sortModelStorage, setSortModelStorage] = useState([
        { field: "DateAndTime", sort: "desc" }
      ]);

    const plclogResult = useQuery({
        queryKey: ['plclog', props.machineName], 
        queryFn: () => fetchPlcLogs(props.machineName),
        refetchInterval: 60000,
        staleTime: 60000,
    });

    const columns = [
      { 
          field: "dateAndTime", 
          headerName: <FormattedMessage {...messages.dateAndTimeColumn} />,
          flex: 1,
          valueFormatter: (value) => {
            if (value == null) {
              return '';
            }
            return new Date(value.value).toLocaleString('sv-SE');
          },
      },
      { 
          field: "machine", 
          headerName: <FormattedMessage {...messages.machineNameColumn} />,
          flex: 1,
      },
      { 
          field: "counter", 
          headerName: <FormattedMessage {...messages.counterColumn} />,
          flex: 1,
      },
      { 
          field: "actCycleTime", 
          headerName: <FormattedMessage {...messages.cycleTimeColumn} />,
          flex: 1,
      },
      { 
          field: "machineStatus", 
          headerName: <FormattedMessage {...messages.machineStatusColumn} />,
          flex: 1,
      },
    ];

    if(plclogResult?.isLoading) {
        return <AxionControls.AxionBackdrop open={true} />
    }

    return (
        <Box>
          <Paper
            sx={{
              position: "relative",
              borderRadius: "4px",
            }}
          >
            <Box 
              m="0px 0 0 0"
              height="76vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                },
                "& .name.column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-cellContent": {
                  fontSize: "13px",
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.primary[600],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.primary[600],
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.gray[100]} !important`,
                },
                "& .super-app-theme--11": {
                  bgcolor: `${colors.redAccent[500]} !important`,
                },
                "& .super-app-theme--undefined-stop": {
                  bgcolor: `${colors.redAccent[500]} !important`,
                },
                "& .MuiDataGrid-columnHeader": {
                  fontSize: "15px",
                },
              }}
            >
              { (!plclogResult?.isLoading) && <DataGrid
                rows={plclogResult?.data}
                sortModel={sortModelStorage}
                onSortModelChange={(newModel) => setSortModelStorage(newModel)}
                columns={columns}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)
                }
              /> }
            </Box>
          </Paper>
        </Box>
    );
};