import React from "react";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme, Box, Paper, CircularProgress, IconButton } from "@mui/material";
import { tokens } from "../../../assets/theme";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DeleteIcon from "@mui/icons-material/Delete";


export default function StopReasonCodesDataGrid(props) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({});

    const columns = [
      { 
        field: "code", 
        headerName: <FormattedMessage {...messages.codeColumnLabel} />,
        flex: 0.3,
        minWidth: 100,
      },
      { 
        field: "name", 
        headerName: <FormattedMessage {...messages.nameColumnLabel} />,
        flex: 1,
        minWidth: 300
      },
      { 
        field: "colorCode", 
        headerName: <FormattedMessage {...messages.colorColumnLabel} />,
        flex: 0.3,
        minWidth: 100,
        align: "center",
        renderCell: (params) => (
          <Box
            sx={{
              width: "20px",
              height: "20px",
              backgroundColor: params.value,
              borderRadius: 1
            }}
          />
        ),
      },
      { 
        field: "sortOrder", 
        headerName: <FormattedMessage {...messages.sortOrderColumnLabel} />,
        minWidth: 300,
        flex: 0.5,
      },
      { 
        field: "type", 
        headerName: <FormattedMessage {...messages.typeColumnLabel} />,
        minWidth: 300,
        flex: 0.5,
      },
      { 
          field: "noOEECalculationBool", 
          headerName: <FormattedMessage {...messages.noOeeCalculationColumnLabel} />,
          description: <FormattedMessage {...messages.noOeeCalculationColumnDescription} />,
          minWidth: 200,
          flex: 0.3,
          align: "center",
          renderCell: (params) => (
            <Box
              sx={{
                width: "20px",
                height: "20px",
                backgroundColor: params.value ? "green" : "red",
                borderRadius: 1
              }}
            />
          ),
      },
      { 
        field: "isUndefined", 
        headerName: <FormattedMessage {...messages.isUndefinedColumnLabel} />,
        description: <FormattedMessage {...messages.isUndefinedColumnDescription} />,
        flex: 0.4,
        minWidth: 200,
        align: "center",
        renderCell: (params) => (
          <> 
            { params.value && <Box
              sx={{
                width: "20px",
                height: "20px",
                backgroundColor: "green",
                borderRadius: 1
              }}
            />}
          </>
        ),
      },
      {
        field:"edit", 
        headerName:"",
        renderCell:(params) => {
            return(
                <>
                <IconButton
                    arial-label="Edit stop reason code"
                    size="small"
                    sx={{
                        "&:hover": {
                        backgroundColor: colors.primary[500],
                        },
                        backgroundColor: colors.primary[700],
                    }}
                    onClick={() => {
                        props.handleEditStopReasonCodeClick(params.row.id)
                    }}
                    >
                        <RemoveRedEyeOutlinedIcon fontSize="inherit" />
                </IconButton>
                <IconButton
                    arial-label="Delete stop reason code"
                    size="small"
                    sx={{
                        "&:hover": {
                        backgroundColor: colors.primary[500],
                        },
                        backgroundColor: colors.primary[700],
                        marginLeft: "10px"
                    }}
                    onClick={() => {
                      props.handleDeleteStopReasonCodeClick(params.row.id)
                    }}
                    >
                        <DeleteIcon fontSize="inherit" />
                </IconButton>
                </>
            )
        },
        width: 90,
      },
      ];
  

    if(props.isLoading) {
        return <Box padding={2}>
          <Paper
            sx={{
              position: "relative",
              borderRadius: "4px",
            }}
          >
            <CircularProgress color="secondary" />
          </Paper>
        </Box>
     }

     return (
      <Box padding={2} paddingTop={8}>
        <Paper
          sx={{
            position: "relative",
            borderRadius: "4px",
          }}
        >
          <Box 
            m="0px 0 0 0"
            height="76vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
              },
              "& .name.column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-cellContent": {
                fontSize: "13px",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.primary[600],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.primary[600],
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.gray[100]} !important`,
              },
              "& .super-app-theme--11": {
                bgcolor: `${colors.redAccent[500]} !important`,
              },
              "& .super-app-theme--undefined-stop": {
                bgcolor: `${colors.redAccent[500]} !important`,
              },
              "& .MuiDataGrid-columnHeader": {
                fontSize: "15px",
              },
            }}
          >
            { (!props.stopReasonCodes.isLoading) && <DataGrid
              rows={props.stopReasonCodes.data}
              getRowId={ (row) => row.id }
              columns={columns}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                setColumnVisibilityModel(newModel)
              }
            /> }
            { (props.stopReasonCodes.isLoading) && <CircularProgress color="secondary" />}
          </Box>
        </Paper>
      </Box>
    );
}