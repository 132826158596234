import React, { useState, useEffect, Fragment } from "react";
import { FormControlLabel, Radio, Typography } from "@mui/material";
import Minutes from "../minutes-select";
import Hour from "../hour-select";
import LabelBox from "../labelBox";

const MonthlyCron = ({ classes, value, onChange, translate: translateFn }) => {
  const [every, setEvery] = useState(0);

  useEffect(() => {
    if (value[3] === "L") {
      setEvery("2");
    } else if (value[3] === "LW") {
      setEvery("3");
    } else if (value[3].startsWith("L")) {
      setEvery("4");
    } else {
      setEvery("1");
    }
  }, []);

  const onDayChange = (e) => {
    if (
      (parseInt(e.target.value) > 0 && parseInt(e.target.value) <= 31) ||
      e.target.value === ""
    ) {
      let val = [
        "0",
        value[1] === "*" ? "0" : value[1],
        value[2] === "*" ? "0" : value[2],
        value[3],
        "1/1",
        "?",
        "*",
      ];
      val[3] = `${e.target.value}`;
      onChange(val);
    }
  };
  const onLastDayChange = (e) => {
    if (
      (parseInt(e.target.value) >> 0 && parseInt(e.target.value) <= 31) ||
      e.target.value === ""
    ) {
      let val = [
        "0",
        value[1] === "*" ? "0" : value[1],
        value[2] === "*" ? "0" : value[2],
        value[3],
        "1/1",
        "?",
        "*",
      ];
      val[3] = e.target.value === "" ? "" : `L-${e.target.value}`;
      onChange(val);
    }
  };
  const onAtHourChange = (e) => {
    let val = value;
    val[2] = `${e.target.value}`;
    onChange(val);
  };
  const onAtMinuteChange = (e) => {
    let val = value;
    val[1] = `${e.target.value}`;
    onChange(val);
  };

  return (
    <Fragment>
      <LabelBox variant="content">
        <FormControlLabel
          value="day"
          control={
            <Radio
              onChange={(e) => {
                setEvery(e.target.value);
                onChange([
                  "0",
                  value[1] === "*" ? "0" : value[1],
                  value[2] === "*" ? "0" : value[2],
                  "1",
                  "1/1",
                  "?",
                  "*",
                ]);
              }}
              value="1"
              checked={every === "1" ? true : false}
              sx={{
                "&.Mui-checked": {
                  color: "#799c4a !important",
                },
              }}
            />
          }
          label={translateFn("Day")}
        />
        {/* <input marginRight={3} style={{marginRight:"4px"}}  type="radio" onChange={(e) => {setEvery(e.target.value); onChange(['0',this.state.value[1] === '*' ? '0' : value[1], value[2] === '*' ? '0': value[2],'1','1/1', '?','*'])}} value="1" name="MonthlyRadio" checked={every === "1" ? true : false} /> */}

        <input
          readOnly={every !== "1"}
          style={{ marginLeft: "4px" }}
          type="number"
          value={value[3]}
          onChange={onDayChange}
        />
        <span> {translateFn("of every month(s)")}</span>
      </LabelBox>
      <LabelBox variant="content">
        <FormControlLabel
          value="day"
          control={
            <Radio
              onChange={(e) => {
                setEvery(e.target.value);
                onChange([
                  "0",
                  value[1] === "*" ? "0" : value[1],
                  value[2] === "*" ? "0" : value[2],
                  "L",
                  "*",
                  "?",
                  "*",
                ]);
              }}
              value="2"
              checked={every === "2" ? true : false}
              sx={{
                "&.Mui-checked": {
                  color: "#799c4a !important",
                },
              }}
            />
          }
          label={translateFn("Last day of every month")}
        />
      </LabelBox>
      <LabelBox variant="content">
        <FormControlLabel
          value="day"
          control={
            <Radio
              onChange={(e) => {
                setEvery(e.target.value);
                onChange([
                  "0",
                  value[1] === "*" ? "0" : value[1],
                  value[2] === "*" ? "0" : value[2],
                  "LW",
                  "*",
                  "?",
                  "*",
                ]);
              }}
              value="3"
              checked={every === "3" ? true : false}
              sx={{
                "&.Mui-checked": {
                  color: "#799c4a !important",
                },
              }}
            />
          }
          label={translateFn("On the last weekday of every month")}
        />
      </LabelBox>
      <LabelBox variant="content">
        <FormControlLabel
          value="day"
          control={
            <Radio
              onChange={(e) => {
                setEvery(e.target.value);
                onChange([
                  "0",
                  value[1] === "*" ? "0" : value[1],
                  value[2] === "*" ? "0" : value[2],
                  `L-${1}`,
                  "*",
                  "?",
                  "*",
                ]);
              }}
              value="4"
              checked={every === "4" ? true : false}
              sx={{
                "&.Mui-checked": {
                  color: "#799c4a !important",
                },
              }}
            />
          }
          label=""
        />
        <input
          style={{ marginRight: "4px" }}
          readOnly={every !== "4"}
          type="number"
          value={value[3].split("-")[1]}
          onChange={onLastDayChange}
        />
        {translateFn("day(s) before the end of the month")}
      </LabelBox>
      <br />
      <div style={{ display: "flex" }}>
        <Typography sx={{ marginRight: "4px" }} variant="body1" gutterBottom>
          <span>{translateFn("Start time")} </span>
          <Hour onChange={onAtHourChange} value={value[2]} />
          <Minutes onChange={onAtMinuteChange} value={value[1]} />
        </Typography>
      </div>
    </Fragment>
  );
};

MonthlyCron.defaultProps = {
  onChange: (value) => {
    console.log(value);
  },
};

MonthlyCron.muiName = "MonthlyCron";

export default MonthlyCron;
