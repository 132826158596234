import React from "react";
import { Box } from "@mui/material";

export default function ClearLocalStorage() {
    localStorage.clear();
    
    return (
        <Box m="20px">
            Local settings have been cleared.
        </Box>
    );
}
