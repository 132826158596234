import React from "react";
import { Box, Button } from "@mui/material";
import AxionControls from "../../../components/controls/AxionControls";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import Controls from "../../../components/controls/Contols";


export default function AddEditStopReasonCode(props) {
    
    const onColorChange = (color, colors) => {
        props.onChange({ target: { id: "colorCode", value: colors.hex } });
    }

    const typeOptions = [
        { id: "Standard", title: "Standard" },
        { id: "Special", title: "Special" }
    ];

    return (
        <Box 
            sx={{
                p:1,
                m:1
            }}>
                <Box sx={{width: 200}}>
                    <AxionControls.AxionTextBox 
                        name="code"
                        label=<FormattedMessage {...messages.codeColumnLabel} />
                        value={props.stopReasonCode.code}
                        onChange={props.onChange}
                    />
                </Box>
                <Box sx={{width: 400}}>
                    <AxionControls.AxionTextBox 
                        name="name"
                        label=<FormattedMessage {...messages.nameColumnLabel} />
                        value={props.stopReasonCode.name}
                        onChange={props.onChange}
                    />
                </Box>
                <Box sx={{marginTop: 2}}>
                    <AxionControls.AxionColorPicker  
                        name="colorCode"
                        label=<FormattedMessage {...messages.colorColumnLabel} />
                        value={props.stopReasonCode.colorCode}
                        onChange={onColorChange}
                    />
                </Box>
                <Box sx={{marginTop: 2, width:400}}>
                    <AxionControls.AxionSelect  
                        options={typeOptions}
                        showNoneOption={false}
                        name="type"
                        label=<FormattedMessage {...messages.typeColumnLabel} />
                        value={props.stopReasonCode.type}
                        onChange={props.onChange}
                    />
                </Box>
                <Box sx={{width: 400}}>
                    <AxionControls.AxionTextBox 
                        name="sortOrder"
                        label=<FormattedMessage {...messages.sortOrderColumnLabel} />
                        value={props.stopReasonCode.sortOrder}
                        onChange={props.onChange}
                    />
                </Box> 
                <Box>
                    <Controls.Checkbox 
                        name="noOEECalculationBool"
                        label=<FormattedMessage {...messages.noOeeCalculationColumnLabel} />
                        value={props.stopReasonCode.noOEECalculationBool}
                        onChange={props.onChange}
                    />
                </Box>
                <Box>
                    <Controls.Checkbox 
                        name="isUndefined"
                        label=<FormattedMessage {...messages.isUndefinedColumnLabel} />
                        value={props.stopReasonCode.isUndefined }
                        onChange={props.onChange}
                    />
                </Box>
                <Box>
                    <Button 
                        variant="contained" 
                        sx={{
                            marginTop: 2,
                            }}
                        onClick={props.handleSave}>
                        Save
                    </Button>
                </Box>
        </Box>
    );
}