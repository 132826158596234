
import React, { useState, useEffect } from "react";
import { Grid, useTheme } from "@mui/material";
import Controls from "../../../components/controls/Contols";
import { useForm, Form } from "../../../components/useForm";
import { tokens } from "../../../assets/theme";
import { useQueryClient } from "@tanstack/react-query";
import SaveIcon from '@mui/icons-material/Save';
import {useAccount, useMsal } from "@azure/msal-react";

const initialValues = {
    id: 0,
    workOrderNumber:"",
    comment:"",
    companyKey: 1,
    created: null,
    createdBy: "",
  };


  export default function WorkOrderCommentForm(props) {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {accounts} = useMsal();
    const account = useAccount(accounts[0]);
    const queryClient = useQueryClient();
    const addOrEdit = props.addOrEdit;
    const recordForEdit = props.recordForEdit;
    const [workOrder, setWorkOrder] = useState(props.workOrder);



    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ("companyKey" in fieldValues)
          temp.companyKey = fieldValues.companyKey ? "" : "This field is required.";
        if ("workOrderNumber" in fieldValues)
          temp.workOrderNumber = fieldValues.workOrderNumber ? "" : "This field is required.";
        if ("comment" in fieldValues)
          temp.comment =
            fieldValues.comment 
              ? ""
              : "This field is required.";
        setErrors({
          ...temp,
        });
        if (fieldValues === values)
        return Object.values(temp).every((x) => x === "");
    };

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
        
          addOrEdit(values, resetForm);
        }
      };

      useEffect(() => {
        if (recordForEdit != null)
          setValues({
            ...recordForEdit,
          });
          else
          setValues({
            ...values,
            workOrderNumber: workOrder,
            createdBy: account.name
          });
        //   setSelectedMaterial(
        //     rawMaterialData.filter((v) => v.itemKey === recordForEdit.materialItemKey)[0]
        //   );
        
      }, [recordForEdit]);


    return(
        <Form
        onSubmit={handleSubmit}
        // sx={{
        //   "& .MuiFormControl-root": {
        //     width: "100%",
        //     margin: "0 auto",
        //   },
        // }}
      >
         <Grid container  rowSpacing={10} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6} >
          <Controls.Input
            name="workOrderNumber"
            label="Production Order"
            value={values.workOrderNumber}
            onChange={handleInputChange}
            error={errors.name}
            sx={{mb:2}}
            fullWidth={true}
          />
          <Controls.Input
            label="Created by"
            name="createdBy"
            value={values.createdBy}
            //onChange={handleInputChange}
            disabled={true}
            fullWidth={true}
            sx={{mb:2}}
          />
          <Controls.Input
            label="Created"
            name="created"
            value={values.created}
            //onChange={handleInputChange}
            disabled={true}
            fullWidth={true}
            sx={{mb:2}}
          />
    
          </Grid>

          <Grid item xs={6}>
            
    
        <Controls.Input
            label="Comment"
            name="comment"
            multiline
            value={values.comment}
            onChange={handleInputChange}
            error={errors.code}
            fullWidth={true}
            //multiline
            rows={8}
          />
   </Grid>
   </Grid>
        
        <div>
          <Controls.Button
            type="submit"
            text="Save"
            startIcon={<SaveIcon />}
            sx={{
              "&:hover": {
                backgroundColor: colors.dashbordGreen[700],
              },
              backgroundColor: colors.dashbordGreen[500],
              color: "#000000",
            }}
          />
          {/* <Controls.Button text="Reset" color="default" onClick={resetForm} /> */}
        </div>
       
      </Form>
      
    )
  }