import React, {Component} from 'react';
import {DayPilot, DayPilotScheduler} from "daypilot-pro-react";
import axios from 'axios';
import {Queue} from "./Queue";
import Zoom from "./Zoom";
import TestQueue from './testQueue';

export class Scheduler extends Component {

  constructor(props) {
    super(props);
    this.schedulerRef = React.createRef();
    this.state = {
      locale: "sv-se",
      //autoScroll: "Always",
      timeHeaders: [{"groupBy":"Day"},{"groupBy":"Hour"}],
      //scale: "Hour",
      days: DayPilot.Date.today().daysInMonth(),
      startDate: DayPilot.Date.today().firstDayOfMonth(),
      cellDuration: 30,
      //businessWeekends: true,
      businessBeginsHour: 0,
      businessEndsHour: 23,
      //timeRangeSelectedHandling: "Enabled",
      onTimeRangeSelected: async (args) => {
        const dp = args.control;
        const modal = await DayPilot.Modal.prompt("Create a new event:", "Event 1");
        dp.clearSelection();
        if (modal.canceled) { return; }
        dp.events.add({
          start: args.start,
          end: args.end,
          id: DayPilot.guid(),
          resource: args.resource,
          text: modal.result
        });
      },onBeforeCellRender: args => {
        if (args.cell.isParent) {
          const children = this.scheduler.rows.find(args.cell.resource).children();
          const total = children.length;
          const used = children.filter(row => { return !!row.events.forRange(args.cell.start, args.cell.end).length; }).length;
          const available = total - used;
          const percentage = Math.round(used/total*100);
          
          const colorFree = "#e0e0e0";
          const colorFull = "#00619b";
          const colorSome = DayPilot.ColorUtil.lighter("#00639c", 2);
          
          const colorCellAvailable = "#f6f6f6";
          const colorCellFull = DayPilot.ColorUtil.lighter("#00639c", 3);
      
          const w = this.scheduler.cellWidth / total;
          args.cell.areas = [];
          args.cell.areas.push({
            text: `${percentage}%`,
            style: "text-align: center; font-size: 12px; font-weight: normal;",
            top: 20,
            left: 0,
            right: 0
          });
      
          args.cell.backColor = colorCellAvailable;
          if (available === 0) {
            args.cell.backColor = colorCellFull;
            args.cell.areas[0].fontColor = "#f0f0f0";
          }
      
          args.cell.areas.push({
            top: 0,
            height: 10,
            left: 0,
            right: 0,
            backColor: "#ffffff"
          });
          DayPilot.list.for(total).forEach((item, i) => {
            let color = colorFree;
            if (i < used) {
              color = colorSome;
            }
            if (available === 0) {
              color = colorFull;
              
            }
            args.cell.areas.push({
              top: 0,
              height: 10,
              left: i*w,
              width: w - 1,
              backColor: color
            });
          });
        }
      },
      eventMoveHandling: "Update",
      onEventMoved: (args) => {
        args.control.message("Event moved: " + args.e.text());
      },
      eventResizeHandling: "Update",
      onEventResized: (args) => {
        args.control.message("Event resized: " + args.e.text());
      },
      eventDeleteHandling: "Update",
      onEventDeleted: (args) => {
        args.control.message("Event deleted: " + args.e.text());
      },
      eventClickHandling: "Enabled",
      onEventClicked: (args) => {
        args.control.message("Event clicked: " + args.e.text());
      },
      eventHoverHandling: "Bubble",
      bubble: new DayPilot.Bubble({
        onLoad: (args) => {
          // if event object doesn't specify "bubbleHtml" property 
          // this onLoad handler will be called to provide the bubble HTML
          args.html = "Event details";
        }
      }),
      contextMenu: new DayPilot.Menu({
        items: [
          { text: "Delete", onClick: (args) => { const dp = args.source.calendar; dp.events.remove(args.source); } }
        ]
      }),
      treeEnabled: true,
    };
  }

  componentDidMount() {

    // load resource and event data
    this.setState({
      // resources: [
      //   {name: "Resource A", id: "A"},
      //   {name: "Resource B", id: "B"},
      //   {name: "Resource C", id: "C"},
      //   {name: "Resource D", id: "D"},
      //   {name: "Resource E", id: "E"},
      //   {name: "Resource F", id: "F"},
      //   {name: "Resource G", id: "G"}
      // ],
   
    });
    this.loadData();

  }

  get scheduler() {
    return this.schedulerRef.current.control;
  }
  async loadData() {

   const eventss= [
      {
        id: 1,
        text: "Event 1",
        start: "2023-04-04T08:23:01Z",
        end: "2023-04-08T08:23:01Z",
        resource: 1
      },
      {
        id: 2,
        text: "Event 2",
        start: "2023-04-04T08:23:01Z",
        end: "2023-04-10T08:23:01Z",
        resource: "5",
        barColor: "#38761d",
        barBackColor: "#93c47d"
      }

    ]
    const start = this.scheduler.visibleStart();
    const end = this.scheduler.visibleEnd();
    const promiseEvents = eventss;
    const promiseResources = axios.get("api/WorkOrders/GetResourcesGantt/1");
    const [{data: resources}, {data: events}] = await Promise.all([promiseResources, promiseEvents]);
    this.scheduler.update({
      resources,
      events
    });
  }

  zoomChange(args) {
    switch (args.level) {
        case "month":
            this.setState({
                startDate: DayPilot.Date.today().firstDayOfMonth(),
                days: DayPilot.Date.today().daysInMonth(),
                scale: "Hour"
            });
            break;
        case "week":
            this.setState({
                startDate: DayPilot.Date.today().firstDayOfWeek(),
                days: 7,
                scale: "Day"
            });
            break;
        default:
            throw new Error("Invalid zoom level");
    }
}

  render() {
    return (
      <div className={"wrap"}>
      <div className={"left"}>
          <TestQueue></TestQueue>
        </div>
        <div className={"right"}>
        <div className="toolbar">
                  <Zoom onChange={args => this.zoomChange(args)} />
                </div>
        <DayPilotScheduler
          {...this.state}
          ref={this.schedulerRef}
        />
        </div>
      </div>
    );
  }
}

export default Scheduler;
