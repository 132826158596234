import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { createMachineType, fetchMachineTypes, updateMachineType, deleteMachineType } from "../../fetchers/machineType";
import { Box, useTheme } from "@mui/material";
import Button from "../../components/Button";
import AddIcon from "@mui/icons-material/Add";
import { tokens } from "../../assets/theme";
import { useMsal, useAccount } from "@azure/msal-react";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import MachineTypesGrid from "./components/machineTypesGrid";
import CircularProgress from "@mui/material/CircularProgress";
import AxionControls from "../../components/controls/AxionControls";
import AddEditMachineType from "./components/machineTypeAddEditDialog";
import Popup from "../../components/Popup";
import { FormattedMessage } from "react-intl";
import messages from "./components/messages";
import { pushErrorMessage } from '../../utils/notifications';

export default function MachineTypes() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { accounts } = useMsal();
    const account = useAccount(accounts[0]);
    const [companyKey, setCompanyKey] = useLocalStorage("companyKey", 1);
    const [openPopup, setOpenPopup] = useState(false);
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [machineType, setMachineType] = useState({
        id: "",
        machineTypeName: "",
        description: "",
        createdBy: "",
        updatedBy: "",
        showInGrid: false,
        showInGantt: false,
        showInMachineList: false,
        showInActivitySpecialList: false,
    });

    const machineTypeQuery = useQuery(["machineTypes"], () => fetchMachineTypes(),
        {
            staleTime:120000,
            refetchInterval: 120000,
        }
    );

    const handleEditMachineTypeClick = (id) => {
        const machineType = machineTypeQuery.data.find((machineType) => machineType.id === id);
        setMachineType(machineType);
        setOpenPopup(true);
    };

    const handleDeleteMachineTypeClick = (id) => {
        setShowBackdrop(true);
        deleteMachineType(id).then(() => {
            machineTypeQuery.refetch();
            setShowBackdrop(false);
        }).catch((error) => {
            console.log(error);
            setShowBackdrop(false);
            pushErrorMessage(`Cannot delete the machineType.`);
        });
    }

    const handleChange = (event) => {
        const {id, name, value, type, checked} = event.target
        setMachineType({
            ...machineType,
            [id ? id : name]: type === "checkbox" ? checked : value
        });
    };

    const handleAddMachineTypeClick = () => {
        setMachineType({
            machineTypeName: "",
            description: "",
            createdBy: account.username,
            updatedBy: account.username,
            showInGrid: false,
            showInGantt: false,
            showInMachineList: false,
            showInActivitySpecialList: false,
        });
        setOpenPopup(true);
    };

    const handleSave = () => {
       setShowBackdrop(true);
       if (machineType.id) {
            machineType.updatedBy = account.username;
            updateMachineType(machineType).then(() => {  
                setOpenPopup(false);
                machineTypeQuery.refetch();
                setShowBackdrop(false);
            }).catch((error) => {
                console.log(error);
                setShowBackdrop(false);
                pushErrorMessage(`Cannot update the machineType.`);
        });
       } 
       else {
            machineType.updatedBy = account.username;
            machineType.createdBy = account.username;
            createMachineType(machineType).then(() => {  
                setOpenPopup(false);
                machineTypeQuery.refetch();
                setShowBackdrop(false);
            }).catch((error) => {
                console.log(error);
                setShowBackdrop(false);
                pushErrorMessage(`Cannot update the machineType.`);
       });
       }
    };

    if (machineTypeQuery.isLoading)
        return <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}><CircularProgress color="secondary" /></div>;

    return (
        <Box maxWidth={1800} >
            <Button
                text="Add machine type"
                variant="outlined"
                onClick={handleAddMachineTypeClick}
                startIcon={<AddIcon />}
                sx={{
                "&:hover": {
                    backgroundColor: colors.dashbordGreen[700],
                },
                backgroundColor: colors.dashbordGreen[500],
                color: "#000000",
                position: "absolute",
                right: "10px",
                }} />
            <MachineTypesGrid 
                machineTypes={machineTypeQuery}
                handleDeleteMachineTypeClick={handleDeleteMachineTypeClick}
                handleEditMachineTypeClick={handleEditMachineTypeClick}
                />
            <Popup
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                maxWidht="sm"
                title={machineType.id != "" ? <FormattedMessage {...messages.editMachineTypeLabel} /> : <FormattedMessage {...messages.addMachineTypeLabel} />}>
                <AddEditMachineType 
                    onChange={handleChange}
                    handleSave={handleSave}
                    machineType={machineType}
                    setOpenPopup={setOpenPopup} />
            </Popup>
            <AxionControls.AxionBackdrop open={showBackdrop} />
        </Box>
    );
}