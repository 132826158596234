import { configureStore } from '@reduxjs/toolkit'
// import counterReducer from './counterReducer'
import languageReducer from '../context/languageProvider/reducer'
import monitorIntegrationEnabledReducer from '../features/monitorIntegration/redux/reducer'

const store = configureStore({
  reducer: {
    language: languageReducer,
    monitorIntegrationEnabled: monitorIntegrationEnabledReducer,
  },
});

export default store